import { ReactNode } from 'react';
import styles from './Feedback.module.css';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { FeaturedIcon } from '../../atoms/featured-icon';

interface FeedbackProps {
    icon: ReactNode;
    text: string;
}

const Feedback = ({
    icon,
    text,
}: FeedbackProps) => {
    return (
        <div className={styles.feedbackContainer}>
            <FeaturedIcon icon={icon} type='gray' size='lg' />
            <Text
                size={FontSizeEnum.Sm}
                color={ColorEnum.Gray400}
                weight={FontWeightEnum.Regular}
            >{text}</Text>
        </div>
    );
};

export default Feedback;

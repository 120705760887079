import { IncomeFrequencyEnum } from '../../../../componentsNew/enums';
import api from '../../../../utils/api';

export interface IncomeSourceEmployment {
    employer: string,
    contractType: string,
    startDate: string,
    endDate?: string,
    grossIncome?: number,
    incomeFrequency?: IncomeFrequencyEnum;
    holidayAllowanceIncluded?: boolean;
    thirteenthMonthPayIncluded?: boolean;
    thirtyPercentFacilityApplicable?: boolean;
    additionalIncome?: number;
    additionalIncomeFrequency?: IncomeFrequencyEnum;
    additionalIncomeComment?: string;
}

const findIncomeSourceEmployment = async (sourceId: string): Promise<IncomeSourceEmployment> => {
    return await api.getWithoutAuth(`api/screening/income-verification/details/employment`, { sourceId });
}

export default findIncomeSourceEmployment;

import React from 'react';
import ButtonNew from '../button/Button';
import SubtractLineIcon from '../../atoms/icons/SubtractLineIcon';
import AddLineIcon from '../../atoms/icons/AddLineIcon';
import styles from './IncrementalNumberInput.module.css';
import Label from '../../atoms/label/Label';
import { classNames } from '../../utils';

interface IncrementalNumberInputProps {
    readonly name?: string;
    readonly label?: string;
    readonly min?: number;
    readonly max?: number;
    readonly value: number;
    readonly onChange: (value: number) => void;
}

const IncrementalNumberInput = ({
    name,
    label,
    min,
    max,
    value,
    onChange,
}: IncrementalNumberInputProps) => {
    const handleDecrement = () => {
        if (value <= min) {
            onChange(min);
        }

        onChange(value - 1);
    }

    const handleIncrement = () => {
        if (value >= max) {
            onChange(max);
        }

        onChange(value + 1);
    }

    return (
        <div className={classNames(styles.container, !label && styles.singleColumn)}>
            {label &&
                <div>
                    <Label>{label}</Label>
                </div>
            }
            <div className={styles.buttonContainer}>
                <input name={name} type="number" value={value} min={min} max={max} onChange={() => {/** */}} hidden/>
                <ButtonNew
                    variant="secondary-gray"
                    iconBefore={<SubtractLineIcon/>}
                    disabled={value == min}
                    onClick={handleDecrement}
                    testName={`${name}-decrement`}
                />
                <div className={styles.value}>{value}</div>
                <ButtonNew
                    variant="secondary-gray"
                    iconBefore={<AddLineIcon/>}
                    disabled={value == max}
                    onClick={handleIncrement}
                    testName={`${name}-increment`}
                />
            </div>
        </div>
    )
};

export default IncrementalNumberInput;

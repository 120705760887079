import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useFeature} from '@growthbook/growthbook-react';
import useAuth from './hooks/useAuth';
import {updateMomentLocale} from './components/formattedDateContent/dateUtils';
import styles from './App.module.css';
import BasicNotification, { NotificationTypes } from './components/notifications/BasicNotification';
import routes, { findRouteAuthentication, RouteAuthentication } from './routes';
import LeadflowWindow from './LeadflowWindow';
import GeneralErrorPage from './componentsNew/pages/errorPage/GeneralErrorPage';
import { useRoutePath } from './componentsNew/utils/useRoutePath';

const App = () => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const {notifications} = useSelector((state: { ui: { notifications: Array<{ id: string, message: string, type: string }> } }) => state.ui);
    const REDIRECT_ON_LOGIN = useFeature('redirect_on_login').value;

    const [routeAuthentication, setRouteAuthentication] = useState<RouteAuthentication | null>(null);

    const location = useLocation();

    const catchAllRedirectPath = () => {
        return isAuthenticated ? routePath('ListingOverview') : routePath('LoginPage');
    }

    function renderRoutes() {
        let availableRoutes;

        if (isAuthenticated) {
            availableRoutes = [...routes.authenticatedRoutes, ...routes.unauthenticatedRoutes];
        } else {
            availableRoutes = [...routes.guestRoutes, ...routes.unauthenticatedRoutes];
        }

        const navigationElements = availableRoutes.map((route, index) => {
            if (route.component) {
                return (
                    <Route key={index} path={route?.path} element={<route.component />} >
                        {renderChildRoutes(route)}
                    </Route>
                )
            }
        });

        const path = catchAllRedirectPath();

        navigationElements.push(<Route key={'catch-all'} path="/" element={<Navigate to={path}/>}/>);

        if (isAuthenticated) {
            routes.guestRoutes.forEach((route) => {
                navigationElements.push(<Route key={'catch-all'} path={route.path} element={<Navigate to={routePath('ListingOverview')}/>}/>);
            });
        }

        navigationElements.push(<Route key={'catch-all'} path="*" element={<GeneralErrorPage />}/>);

        return navigationElements;
    }

    function renderChildRoutes(route) {
        const childRoutes = route?.childRoutes || [];

        const childRouteElements = childRoutes.map((childRoute, index) => {
            if (childRoute.component) {
                return (
                    <Route key={index} path={childRoute.path} element={<childRoute.component />}>
                        {renderChildRoutes(childRoute)}
                    </Route>
                )
            }
        });

        return childRouteElements;
    }

    useEffect(() => {
        setRouteAuthentication(findRouteAuthentication(location.pathname));
    }, [location.pathname]);

    useEffect(() => {
        if (routeAuthentication === RouteAuthentication.Authenticated && !isAuthenticated) {
            navigate('/login', {replace: true});
        }
    }, [routeAuthentication]);

    useEffect(() => {
        updateMomentLocale();

        if (isAuthenticated && LeadflowWindow.ps) {
            LeadflowWindow.ps.addMeta({isAuthenticated: true})
        }
    }, []);

    useEffect(() => {
        if (REDIRECT_ON_LOGIN) {
            const redirectToAfterLogin = sessionStorage.getItem('redirectToAfterLogin') || '/dashboard/advertenties/'
            if (isAuthenticated && redirectToAfterLogin) {
                navigate(redirectToAfterLogin, {replace: true})
            }
        }
    }, [isAuthenticated])

    return (
        <div className={styles.App}>
            <Routes>
                {renderRoutes()}
            </Routes>
            {notifications?.length > 0 && (
                <div className="fixed inset-0 flex flex-col px-4 py-6 pointer-events-none z-50 justify-end items-end">
                    <>
                        {notifications.map((notif, index) => (
                            <BasicNotification
                                key={index}
                                message={notif.message}
                                id={notif.id}
                                type={notif.type as NotificationTypes}
                            />
                        ))}
                    </>
                </div>
            )}
        </div>
    )
}

export default App

import { Trans, useTranslation } from 'react-i18next'
import Text from '../../../../components/typography/text/Text'
import style from './ConfirmationPageFragment.module.css'
import Button from '../../../../components/buttons/button/Button'
import ButtonType from '../../../../components/buttons/button/logic/models/buttonType'
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../../../enums/colorEnum'
import { useEffect, useState } from 'react'
import Checkbox from '../../../../components/forms/checkbox/Checkbox'
import { getGA4CookiebotConsent } from '../../../../utils/analytics'
import EmailAddressConfirmationService from '../../../../services/emailAddressConfirmationService'
import InlineTextLoader from '../../../../components/molecules/inlineLoader/InlineTextLoader'
import LeadflowWindow from '../../../../LeadflowWindow';

export interface ConfirmationPageFragmentProps {
    readonly onError: () => void;
    readonly service: EmailAddressConfirmationService;
    readonly homeseekerId: string;
    readonly isMarketingMessagesSubscriptionConfirmed: boolean;
    readonly isCombinedOptIn: boolean;
    readonly isParariusPlusOptIn: boolean;
    readonly onIsMarketingMessagesSubscriptionConfirmedChange: (isMarketingMessagesSubscriptionConfirmed: boolean) => void;
    readonly onIsCombinedOptInChange: (isCombinedOptIn: boolean) => void;
    readonly onIsParariusPlusOptInChange: (isParariusPlusOptIn: boolean) => void;
    readonly onSubmitButtonClick: () => void;
    readonly showOptInVersion: number;
}

export default function ConfirmationPageFragment(props: ConfirmationPageFragmentProps) {
    const {t} = useTranslation();

    const [redactedEmail, setRedactedEmail] = useState(undefined);
    const [isRedactedEmailLoading, setIsRedactedEmailLoading] = useState(false);

    useEffect(() => {
        getRedactedEmail();
    }, []);

    async function getRedactedEmail() {
        setIsRedactedEmailLoading(true);
        try {
            const redactedEmail = await props.service.getRedactedHomeseekerEmail(props.homeseekerId);
            setRedactedEmail(redactedEmail);
        } catch {
            props.onError();
        }
        setIsRedactedEmailLoading(false);
    }

    function handleSubmitButtonClick() {
        const cookie_consent = getGA4CookiebotConsent();

        if (cookie_consent !== null) {
            LeadflowWindow.dataLayer.push({
                'event': 'email_confirmed',
                'consent': cookie_consent
            });

            if (props.isMarketingMessagesSubscriptionConfirmed || props.isCombinedOptIn) {
                LeadflowWindow.dataLayer.push({
                    'event': 'email_opted_in_marketing_messages',
                    'consent': cookie_consent
                });
            }

            if (props.isParariusPlusOptIn || props.isCombinedOptIn) {
                LeadflowWindow.dataLayer.push({
                    'event': 'pararius_plus_opted_in',
                    'consent': cookie_consent
                });
            }
        }

        props.onSubmitButtonClick();
    }

    function renderCheckboxes() {
        const checkboxes = [];

        switch (props.showOptInVersion) {
            case 0:
                checkboxes.push({
                    id: 'emailAddressConfirmation',
                    text: t('emailAddressConfirmation.confirmation.checkbox'),
                    state: props.isMarketingMessagesSubscriptionConfirmed,
                    onChange: (checked: boolean) => props.onIsMarketingMessagesSubscriptionConfirmedChange(checked)
                });
                break
            case 1:
                checkboxes.push({
                    id: 'emailAddressConfirmationCombined',
                    text: t('emailAddressConfirmationCombined.confirmation.checkbox'),
                    state: props.isCombinedOptIn,
                    onChange: props.onIsCombinedOptInChange
                });
                break
            case 2:
                checkboxes.push({
                    id: 'emailAddressConfirmation',
                    text: t('emailAddressConfirmation.confirmation.checkbox'),
                    state: props.isMarketingMessagesSubscriptionConfirmed,
                    onChange: props.onIsMarketingMessagesSubscriptionConfirmedChange
                });
                checkboxes.push({
                    id: 'emailAddressConfirmationParariusPlus',
                    text: t('emailAddressConfirmationParariusPlus.confirmation.checkbox'),
                    state: props.isParariusPlusOptIn,
                    onChange: props.onIsParariusPlusOptInChange
                });
                break
        }

        return checkboxes.map(({ id, text, state, onChange }) => (
            <div className={style.checkboxContainer} key={id}>
                <div className={style.checkboxContainerWrapper}>
                    <Checkbox
                        id={id}
                        name={id}
                        isChecked={state}
                        onChange={(e) => onChange(e.target.checked)}
                    />
                </div>
                <label htmlFor={id} className={style.text}>
                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>{text}</Text>
                </label>
            </div>
        ));
    }


    if (isRedactedEmailLoading) {
        return <InlineTextLoader/>
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-primary-700">{t('emailAddressConfirmation.confirmation.title')}</h1>
            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                {t('emailAddressConfirmation.confirmation.body', { email: redactedEmail })}
            </Text>

            {renderCheckboxes()}

            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                <Trans
                    i18nKey="marketingMessagesSubscribe.confirmation.footer"
                    components={{ Link: <a/> }}
                />
            </Text>

            <Button
                title={t('emailAddressConfirmation.confirmation.button')}
                buttonType={ButtonType.Primary}
                isFullWidth
                onClick={() => handleSubmitButtonClick()}
            />
        </>
    );
}

import React, {useEffect, useRef, useState} from 'react';
import styles from './SettingsPage.module.css';
import TopNavigation from '../../components/navigation/TopNavigation';
import {PageContainer} from '../../componentsNew/atoms/page-container';
import Typography from '../../componentsNew/atoms/typography/Typography';
import PageHeader from '../../componentsNew/molecules/pageHeader/PageHeader';
import {useSearchParams} from 'react-router-dom';
import HighlightBlock from '../../componentsNew/molecules/highlightBlock/HighlightBlock';
import {useTranslation} from 'react-i18next';
import Text from '../../components/typography/text/Text';
import Button from '../../components/buttons/button/Button';
import ButtonType from '../../components/buttons/button/logic/models/buttonType';
import api from '../../utils/api';
import {setNotification} from '../../redux/actions/ui/setNotification';
import {useDispatch} from 'react-redux';
import SkeletonComponent from '../../components/SkeletonComponent';
import useAuth from '../../hooks/useAuth';
import FormError from '../../components/banners/FormError';
import {Select} from '../../componentsNew/organisms/select';
import {Item} from '../../componentsNew/organisms/select/Select';
import {InputField} from '../../componentsNew/organisms/input-field';
import {HorizontalTabs} from '../../componentsNew/molecules/horizontal-tabs';
import {TabData} from '../../componentsNew/atoms/tab';
import DutchFlagIcon from '../../componentsNew/atoms/icons/flags/DutchFlagIcon';
import GreatBritainFlagIcon from '../../componentsNew/atoms/icons/flags/GreatBritain';
import PageWithSidePanel from '../../componentsNew/atoms/page-with-side-panel/PageWithSidePanel';
import ButtonNew from '../../componentsNew/molecules/button/Button';
import {TemplateVariableExplanation} from '../../componentsNew/templates/variable-explanation';
import {SidePanel} from '../../componentsNew/organisms/side-panel';
import MailSendLineIcon from '../../componentsNew/atoms/icons/MailSendLineIcon';
import {Main} from '../../componentsNew/organisms/main';
import fetchAgencies from '../../api/agency/settings/fetchAcencies';
import fetchMailingSettings, {
    defaultMailingSettingsFormData,
    MailingSettingsFormData
} from '../../api/agency/settings/fetchMailingSettings';
import saveMailingSettings, {saveMailingSettingsPayload} from '../../api/agency/settings/saveMailingSettings';
import {TextArea} from '../../componentsNew/atoms/text-area';
import {Prompt} from '../../componentsNew/molecules/prompt';
import DraftIcon from '../../componentsNew/atoms/icons/DraftIcon';
import TextNew from '../../componentsNew/atoms/typography/Text';
import {ColorEnum as ColorEnumNew, FontSizeEnum as FontSizeEnumNew} from '../../componentsNew/enums';
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../enums/colorEnum';
import { FieldState } from '../../componentsNew/molecules/form/Form';

const SettingsPage = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)
    const [agencyOptions, setAgencyOptions] = useState<Item[]>([])
    const [selectedAgencyId, setSelectedAgencyId] = useState('')
    const [rejectionTemplateLang, setRejectionTemplateLang] = useState<{ activeLabel: string, tabs: TabData[] }>({
        activeLabel: 'Nederlands',
        tabs: [
            {
                icon: <DutchFlagIcon/>,
                label: 'Nederlands',
                required: true,
            },
            {
                icon: <GreatBritainFlagIcon/>,
                label: 'Engels',
                required: true,
            }
        ]
    });
    const rejectionTemplateLangRef = useRef(rejectionTemplateLang);
    const [enrichmentTemplateLang, setEnrichmentTemplateLang] = useState<{ activeLabel: string, tabs: TabData[] }>({
        activeLabel: 'Nederlands',
        tabs: [
            {
                icon: <DutchFlagIcon/>,
                label: 'Nederlands',
                required: true,
            },
            {
                icon: <GreatBritainFlagIcon/>,
                label: 'Engels',
                required: true,
            }
        ]
    });
    const enrichmentTemplateLangRef = useRef(enrichmentTemplateLang);
    const [storedMailingSettings, setStoredMailingSettings] = useState<MailingSettingsFormData>(defaultMailingSettingsFormData);
    const [mailingSettings, setMailingSettings] = useState<MailingSettingsFormData>(defaultMailingSettingsFormData);
    const [mailingSettingsFormIsValid, setMailingSettingsFormIsValid] = useState(false);
    const [mailingSettingsHaveChanged, setMailingSettingsHaveChanged] = useState(false);
    const mailingSettingsHaveChangedRef = useRef(false);

    const [showPrompt, setShowPrompt] = useState(false);
    const [newAgency, setNewAgency] = useState('');
    const [queryParams, setQueryParams] = useSearchParams();

    const promptBeforeNavigationMessage = 'You have unsaved changes. Are you sure you want to leave without saving?';

    const tryFetchingAgencies = async () => {
        try {
            const response = await fetchAgencies();
            const agencies = response.result.map((agency) => {
                return {
                    value: agency.id,
                    label: agency.name
                }
            });
            if (agencies && agencies[0]) {
                setAgencyOptions(agencies);

                const selectedAgencyId = queryParams.get('selectedAgencyId');

                if (selectedAgencyId) {
                    setSelectedAgencyId(selectedAgencyId)
                } else {
                    setSelectedAgencyId(agencies[0].value);
                }
            }
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('settingsPage.fetching.agencies.failed')
            }));
        }
    }

    const tryFetchingMailingSettings = async (selectedAgencyId: string) => {
        try {
            const response = await fetchMailingSettings(selectedAgencyId);
            setStoredMailingSettings(response);
            setMailingSettings(response);

            queryParams.set('selectedAgencyId', selectedAgencyId);
            setQueryParams(queryParams);

        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('settingsPage.signature.failed')
            }));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        tryFetchingAgencies();

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (mailingSettingsHaveChangedRef.current) {
                event.returnValue = promptBeforeNavigationMessage; // Most browsers
                return promptBeforeNavigationMessage; // Firefox
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        mailingSettingsHaveChangedRef.current = mailingSettingsHaveChanged;
    }, [mailingSettingsHaveChanged]);

    useEffect(() => {
        if (selectedAgencyId) {
            tryFetchingMailingSettings(selectedAgencyId);
        }
    }, [selectedAgencyId]);

    useEffect(() => {
        const valid = validateObject(mailingSettings);
        const changed = hasChanged(mailingSettings);

        setMailingSettingsFormIsValid(valid);
        setMailingSettingsHaveChanged(changed);
    }, [mailingSettings, storedMailingSettings]);

    useEffect(() => {
        rejectionTemplateLangRef.current = rejectionTemplateLang;
    }, [rejectionTemplateLang]);

    useEffect(() => {
        enrichmentTemplateLangRef.current = enrichmentTemplateLang;
    }, [enrichmentTemplateLang]);

    const handleChangeTab = (context: string, label: string) => {
        if (context === 'rejectionTemplate') {
            setRejectionTemplateLang({
                ...rejectionTemplateLang,
                activeLabel: label
            });
        } else if (context === 'enrichmentTemplate') {
            setEnrichmentTemplateLang({
                ...enrichmentTemplateLang,
                activeLabel: label
            });
        }
    }

    const handleChangeAgency = (item: Item) => {
        if (mailingSettingsHaveChanged) {
            setNewAgency(item.value);
            setShowPrompt(true);
        } else {
            setSelectedAgencyId(item.value);
        }
    }

    const handleConfirm = (data: string) => {
        setShowPrompt(false);
        setSelectedAgencyId(data);
    }

    const handleCancel = () => {
        setShowPrompt(false);
    }

    const handleMailingSettingsChange = (name: string, value: string) => {
        setMailingSettings((prevState) => {
            return ({
                ...prevState,
                [name]: value
            });
        });
    }

    async function trySavingMailingSettings() {
        try {
            const payload: saveMailingSettingsPayload = {...mailingSettings};

            await saveMailingSettings(payload);

            dispatch(setNotification({
                id: Date.now(),
                message: t('settingsPage.signature.saved'),
                type: 'success'
            }));
            setMailingSettingsHaveChanged(false);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                message: t('settingsPage.signature.error'),
                type: 'failed_dark'
            }));
        }
    }

    async function sendTestEmail(context: string) {
        let url = '';
        const data: { [key: string]: string } = {};
        data.emailSignature = mailingSettings.emailSignature;

        switch (context) {
            case 'rejectionTemplate':
                url = 'api/agency/mailing/send-test-rejection-email';
                data.rejectionEmailTitle = mailingSettings.rejectionEmailTitle;
                data.dutchRejectionEmailContent = mailingSettings.dutchRejectionEmailContent;
                data.englishRejectionEmailContent = mailingSettings.englishRejectionEmailContent;
                break;
            case 'enrichmentTemplate':
                url = 'api/agency/mailing/send-test-enrichment-email';
                data.enrichmentEmailTitle = mailingSettings.enrichmentEmailTitle;
                data.dutchEnrichmentEmailContent = mailingSettings.dutchEnrichmentEmailContent;
                data.englishEnrichmentEmailContent = mailingSettings.englishEnrichmentEmailContent;
                break;
            case 'signature':
                url = 'api/agency/mailing/send-test-manual-email';
                break;
        }

        await api.post(url, data).then(() => {
            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: t('settingsPage.signature.testMail.send.success')
            }))
        }).catch(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('settingsPage.signature.testMail.send.failed'),
                type: 'failed'
            }))
        })
    }

    const validateObject = (obj: MailingSettingsFormData): boolean => {
        return Object.entries(obj).every(([, value]) => {
            if (typeof value === 'string') {
                return validateString(value);
            } else if (typeof value === 'object' && value !== null) {
                return validateObject(value);
            }

            return true;
        });
    };

    const validateString = (value: string) => {
        if (isLoading) {
            return true;
        }

        const regex = /(<([^>]+)>)/ig;

        return (!!value.replace(regex, '').length);
    }

    const hasChanged = (obj: MailingSettingsFormData): boolean => {
        let changeFound = false;
        for (const [key, value] of Object.entries(obj)) {
            if (changeFound) break;

            if (storedMailingSettings[key] !== value) {
                changeFound = true;
            }
        }

        return changeFound;
    };

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <>
            <PageWithSidePanel>
                <PageContainer>
                    <TopNavigation showBackButton blockNavigation={mailingSettingsHaveChanged}/>
                    <Main>
                        <PageHeader title={'settingsPage.title'}/>
                        <div className={styles.formRow}>
                            <HighlightBlock bottomMarginInPx={16}>
                                <Text
                                    size={FontSizeEnum.Sm}
                                    color={ColorEnum.Gray800}
                                >
                                    {t('settingsPage.signature.info')}
                                </Text>
                            </HighlightBlock>
                        </div>
                        <div className={styles.contentContainer}>
                            <form onSubmit={(e) => e.preventDefault()}>
                                {agencyOptions.length > 1 &&
                                    <>
                                        <div className={styles.contentSection}>
                                            <div className={styles.descriptionPanel}>
                                                <Typography type="h6">
                                                    {t('settingsPage.agencySelect.title')}
                                                </Typography>
                                                <Typography type="p">
                                                    {t('settingsPage.agencySelect.description')}
                                                </Typography>
                                            </div>
                                            <div className={styles.settingsPanel}>
                                                <div className={styles.formRow}>
                                                    {!isLoading ?
                                                        <Select
                                                            selected={selectedAgencyId}
                                                            items={agencyOptions}
                                                            name='agencyd'
                                                            onChange={handleChangeAgency}
                                                        />
                                                        :
                                                        <>
                                                            <SkeletonComponent width={200} height={40}/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </>
                                }
                                <div className={styles.contentSection}>
                                    <div className={styles.descriptionPanel}>
                                        <Typography type="h6">
                                            {t('settingsPage.rejection.template.title')}
                                        </Typography>
                                        <Typography type="p">
                                            {t('settingsPage.rejection.template.description')}
                                        </Typography>
                                        <Typography type="p">
                                            {t('settingsPage.template.variable.description')}
                                        </Typography>
                                        <ButtonNew variant="link" onClick={SidePanel.toggle}>
                                            {t('sidepanel.variable.more.about')}
                                        </ButtonNew>
                                    </div>
                                    <div className={styles.settingsPanel}>
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <>
                                                    {t('settingsPage.input.subject')}
                                                    <span className={styles.required}>*</span>
                                                    <InputField
                                                        name='rejectionEmailTitle'
                                                        value={mailingSettings.rejectionEmailTitle}
                                                        onChange={(state:  FieldState) => handleMailingSettingsChange('rejectionEmailTitle', state.value as string)}
                                                    />
                                                </>
                                            ) : (
                                                <SkeletonComponent height={40}/>
                                            )}
                                        </div>
                                        {!validateString(mailingSettings.rejectionEmailTitle) &&
                                            <FormError message={t('settingsPage.template.title.error')}/>
                                        }
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <HorizontalTabs
                                                    data={rejectionTemplateLang}
                                                    onChange={(label: string) => {
                                                        handleChangeTab('rejectionTemplate', label);
                                                    }}
                                                />
                                            ) : (
                                                <SkeletonComponent height={40}/>
                                            )}
                                        </div>
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <>
                                                    <TextArea
                                                        id='dutchRejectionEmailContent'
                                                        value={mailingSettings.dutchRejectionEmailContent}
                                                        onChange={handleMailingSettingsChange}
                                                        toolbarExtensions={[
                                                            {
                                                                label: 'variables-dropdown',
                                                                options: mailingSettings.rejectionEmailVariables
                                                            }
                                                        ]}
                                                        hidden={rejectionTemplateLang.activeLabel !== 'Nederlands'}
                                                    />
                                                    <TextArea
                                                        id='englishRejectionEmailContent'
                                                        value={mailingSettings.englishRejectionEmailContent}
                                                        onChange={handleMailingSettingsChange}
                                                        toolbarExtensions={[
                                                            {
                                                                label: 'variables-dropdown',
                                                                options: mailingSettings.rejectionEmailVariables
                                                            }
                                                        ]}
                                                        hidden={rejectionTemplateLang.activeLabel !== 'Engels'}
                                                    />
                                                </>
                                            ) : (
                                                <SkeletonComponent height={200}/>
                                            )}
                                        </div>
                                        {!validateString(mailingSettings.dutchRejectionEmailContent) &&
                                            <FormError message={t('settingsPage.template.dutch.content.error')}/>
                                        }
                                        {!validateString(mailingSettings.englishRejectionEmailContent) &&
                                            <FormError message={t('settingsPage.template.english.content.error')}/>
                                        }
                                        <div className={styles.formRow}>
                                            <div className={styles.contentSectionFooter}>
                                                <div className={styles.signatureInformation}>
                                                    <div className={styles.draftIconContainer}>
                                                        <DraftIcon/>
                                                    </div>
                                                    <TextNew color={ColorEnumNew.Gray500} size={FontSizeEnumNew.Sm}>
                                                        {t('settingsPage.signature.extra.information.description')}&nbsp;
                                                        <ButtonNew variant="link" size="sm"
                                                            onClick={() => scrollToSection('settingSection')}>
                                                            {t('settingsPage.signature.directory')}
                                                        </ButtonNew>
                                                    </TextNew>
                                                </div>
                                                <div className={styles.contentSectionFooterContent}>
                                                    {!isLoading ? (
                                                        <>
                                                            <div className={styles.fullWidth}>
                                                                <TextNew color={ColorEnumNew.Gray400}
                                                                    size={FontSizeEnumNew.Sm}>
                                                                    {t('settingsPage.signature.testMail.notification', {agentEmail: user.email})}
                                                                </TextNew>
                                                            </div>
                                                            <ButtonNew
                                                                iconBefore={<MailSendLineIcon/>}
                                                                variant='secondary-gray'
                                                                onClick={() => sendTestEmail('rejectionTemplate')}
                                                                disabled={
                                                                    !validateString(mailingSettings.rejectionEmailTitle) ||
                                                                    !validateString(mailingSettings.dutchRejectionEmailContent) ||
                                                                    !validateString(mailingSettings.englishRejectionEmailContent)
                                                                }
                                                            >{t('settingsPage.signature.agencySelector.testEmail.button')}</ButtonNew>
                                                        </>
                                                    ) : (
                                                        <SkeletonComponent width={150} height={40}/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className={styles.contentSection}>
                                    <div className={styles.descriptionPanel}>
                                        <Typography type="h6">
                                            {t('settingsPage.enrichment.template.title')}
                                        </Typography>
                                        <Typography type="p">
                                            {t('settingsPage.enrichment.template.description')}
                                        </Typography>
                                        <Typography type="p">
                                            {t('settingsPage.template.variable.description')}
                                        </Typography>
                                        <ButtonNew variant="link" onClick={SidePanel.toggle}>
                                            {t('sidepanel.variable.more.about')}
                                        </ButtonNew>
                                    </div>
                                    <div className={styles.settingsPanel}>
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <>
                                                    {t('settingsPage.input.subject')}
                                                    <span className={styles.required}>*</span>
                                                    <InputField
                                                        name='enrichmentEmailTitle'
                                                        value={mailingSettings.enrichmentEmailTitle}
                                                        onChange={(state: FieldState) => handleMailingSettingsChange('enrichmentEmailTitle', state.value as string)}
                                                    />
                                                </>
                                            ) : (
                                                <SkeletonComponent height={40}/>
                                            )}
                                        </div>
                                        {!validateString(mailingSettings.enrichmentEmailTitle) &&
                                            <FormError message={t('settingsPage.template.title.error')}/>
                                        }
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <HorizontalTabs
                                                    data={enrichmentTemplateLang}
                                                    onChange={(label: string) => {
                                                        handleChangeTab('enrichmentTemplate', label);
                                                    }}
                                                />
                                            ) : (
                                                <SkeletonComponent height={40}/>
                                            )}
                                        </div>
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <>
                                                    <TextArea
                                                        id='dutchEnrichmentEmailContent'
                                                        value={mailingSettings.dutchEnrichmentEmailContent}
                                                        onChange={handleMailingSettingsChange}
                                                        toolbarExtensions={[
                                                            {
                                                                label: 'variables-dropdown',
                                                                options: mailingSettings.enrichmentEmailVariables
                                                            }
                                                        ]}
                                                        hidden={enrichmentTemplateLang.activeLabel !== 'Nederlands'}
                                                    />
                                                    <TextArea
                                                        id='englishEnrichmentEmailContent'
                                                        value={mailingSettings.englishEnrichmentEmailContent}
                                                        onChange={handleMailingSettingsChange}
                                                        toolbarExtensions={[
                                                            {
                                                                label: 'variables-dropdown',
                                                                options: mailingSettings.enrichmentEmailVariables
                                                            }
                                                        ]}
                                                        hidden={enrichmentTemplateLang.activeLabel !== 'Engels'}
                                                    />
                                                </>
                                            ) : (
                                                <SkeletonComponent height={200}/>
                                            )}
                                        </div>
                                        {!validateString(mailingSettings.dutchEnrichmentEmailContent) &&
                                            <FormError message={t('settingsPage.template.dutch.content.error')}/>
                                        }
                                        {!validateString(mailingSettings.englishEnrichmentEmailContent) &&
                                            <FormError message={t('settingsPage.template.english.content.error')}/>
                                        }
                                        <div className={styles.formRow}>
                                            <div className={styles.contentSectionFooter}>
                                                <div className={styles.signatureInformation}>
                                                    <div className={styles.draftIconContainer}>
                                                        <DraftIcon/>
                                                    </div>
                                                    <TextNew color={ColorEnumNew.Gray500} size={FontSizeEnumNew.Sm}>
                                                        {t('settingsPage.signature.extra.information.description')}&nbsp;
                                                        <ButtonNew variant="link" size="sm"
                                                            onClick={() => scrollToSection('settingSection')}>
                                                            {t('settingsPage.signature.directory')}
                                                        </ButtonNew>
                                                    </TextNew>
                                                </div>
                                                <div className={styles.contentSectionFooterContent}>
                                                    {!isLoading ? (
                                                        <>
                                                            <div className={styles.fullWidth}>
                                                                <TextNew color={ColorEnumNew.Gray400}
                                                                    size={FontSizeEnumNew.Sm}>
                                                                    {t('settingsPage.signature.testMail.notification', {agentEmail: user.email})}
                                                                </TextNew>
                                                            </div>
                                                            <ButtonNew
                                                                iconBefore={<MailSendLineIcon/>}
                                                                variant='secondary-gray'
                                                                onClick={() => sendTestEmail('enrichmentTemplate')}
                                                                disabled={
                                                                    !validateString(mailingSettings.enrichmentEmailTitle) ||
                                                                    !validateString(mailingSettings.dutchEnrichmentEmailContent) ||
                                                                    !validateString(mailingSettings.englishEnrichmentEmailContent)
                                                                }
                                                            >{t('settingsPage.signature.agencySelector.testEmail.button')}</ButtonNew>
                                                        </>
                                                    ) : (
                                                        <SkeletonComponent width={150} height={40}/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className={styles.contentSection}>
                                    <div className={styles.descriptionPanel}>
                                        <Typography type="h6">
                                            {t('settingsPage.signature.title')}
                                        </Typography>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray500}
                                        >
                                            {t('settingsPage.signature.description')}
                                            <br/>
                                            <ul className={styles.listStyle}>
                                                <li>{t('settingsPage.signature.list.first')}</li>
                                                <li>{t('settingsPage.signature.list.second')}</li>
                                                <li>{t('settingsPage.signature.list.third')}</li>
                                            </ul>
                                        </Text>
                                    </div>
                                    <div id="settingSection" className={styles.settingsPanel}>
                                        <div className={styles.formRow}>
                                            {!isLoading ? (
                                                <TextArea
                                                    id='emailSignature'
                                                    value={mailingSettings.emailSignature}
                                                    onChange={handleMailingSettingsChange}
                                                    allowImages={true}
                                                />
                                            ) : (
                                                <SkeletonComponent height={200}/>
                                            )}
                                        </div>
                                        {!validateString(mailingSettings.emailSignature) &&
                                            <FormError message={t('settingsPage.signature.invalid')}/>
                                        }
                                        <div className={styles.formRow}>
                                            <div className={styles.contentSectionFooter}>
                                                <div className={styles.contentSectionFooterContent}>
                                                    {!isLoading ? (
                                                        <>
                                                            <div className={styles.fullWidth}>
                                                                <TextNew color={ColorEnumNew.Gray400}
                                                                    size={FontSizeEnumNew.Sm}>
                                                                    {t('settingsPage.signature.testMail.notification', {agentEmail: user.email})}
                                                                </TextNew>
                                                            </div>
                                                            <ButtonNew
                                                                iconBefore={<MailSendLineIcon/>}
                                                                variant='secondary-gray'
                                                                onClick={() => sendTestEmail('signature')}
                                                                disabled={!validateString(mailingSettings.emailSignature)}
                                                            >{t('settingsPage.signature.agencySelector.testEmail.button')}</ButtonNew>
                                                        </>
                                                    ) : (
                                                        <SkeletonComponent width={150} height={40}/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Main>
                    <footer className={styles.saveButton}>
                        {mailingSettingsHaveChanged &&
                            <Text
                                size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray800}
                            >
                                {t('settingsPage.notification.of.changes')}
                            </Text>
                        }
                        <Button
                            title={'Opslaan'}
                            buttonType={ButtonType.Primary}
                            onClick={trySavingMailingSettings}
                            isDisabled={isLoading || !mailingSettingsFormIsValid || !mailingSettingsHaveChanged}
                        />
                    </footer>
                </PageContainer>
                <SidePanel title={t('sidepanel.variable.title')}>
                    <TemplateVariableExplanation/>
                </SidePanel>
                {showPrompt && <Prompt data={newAgency} onConfirm={handleConfirm} onCancel={handleCancel}/>}
            </PageWithSidePanel>
        </>
    );
};

export default SettingsPage

import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../modal/Modal';
import ArchiveListingModalActionType from './logic/models/archiveListingModalActionType';
import ArchiveListingModalModel from './logic/models/archiveListingModalModel';
import style from './ArchiveListingModal.module.css';
import { useFeature } from '@growthbook/growthbook-react';
import ModalNew from '../../organisms/modal/Modal';
import Text from '../../../componentsNew/atoms/typography/Text';
import { ColorEnum as ColorEnumNew, FontSizeEnum, FontWeightEnum } from '../../../componentsNew/enums';
import ColorEnum from '../../../enums/colorEnum';
import { Select } from '../../../componentsNew/organisms/select';
import ButtonNew from '../../../componentsNew/molecules/button/Button';
import Form from '../../../componentsNew/molecules/form/Form';
import FormError from '../../../componentsNew/molecules/form/FormError';
import { ApprovedHomeseekers, findApprovedHomeseekersById, FindApprovedHomeseekersByIdResponse } from '../../../api/transactional-listing/findApprovedHomeseekersById';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import { Item } from '../../../componentsNew/organisms/select/Select';
import assignTenantToListing from '../../../api/transactional-listing/assignTenantToListing';
import { RadioInput } from '../../../componentsNew/atoms/radio-input';
import SkeletonComponent from '../../../components/SkeletonComponent';
import Label from '../../../componentsNew/atoms/label/Label';
import { Badge } from '../../../componentsNew/molecules/badge';
import UserStarLineIcon from '../../../componentsNew/atoms/icons/UserStarLineIcon';
import LoadingSpinner from '../../../componentsNew/atoms/loading-spinner';
import { classNames } from '../../../componentsNew/utils';

export interface ArchiveModalProps {
    readonly listingId?: string;
    readonly address?: string;
    readonly model?: ArchiveListingModalModel;
    readonly onButtonClick?: (listingId: string, actionType: ArchiveListingModalActionType) => void;
    readonly onCloseButtonClick?: () => void;
    readonly open?: boolean;
}

export default function ArchiveListingModal({
    listingId,
    address,
    model,
    onButtonClick,
    onCloseButtonClick,
    open
}: ArchiveModalProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ARCHIVE_LISTINGS_WITH_AUTOMATIC_REJECTIONS = useFeature('archive-listings-with-automatic-rejections').value;

    const [archiveModel, setArchiveModel] = useState(model);
    const [loading, setLoading] = useState(false);
    const [homeseekers, setHomeseekers] = useState<FindApprovedHomeseekersByIdResponse>();
    const [radioValue, setRadioValue] = useState<string | null>('true');
    const [hasTenant, setHasTenant] = useState(false);
    const [tenant, setTenant] = useState<ApprovedHomeseekers>(null);
    const [formData, setFormData] = useState({});
    const [, setIsFormValid] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const radioOptions = [
        {
            label:
                <div className={classNames(submitting && style.radioLabel)}>
                    {t('listings.archive.modal.sendUpdate')}
                    <Text
                        tag='p'
                        size={FontSizeEnum.Sm}
                        color={submitting ? ColorEnumNew.Gray400 : ColorEnumNew.Gray500}
                    >
                        {t('listings.archive.modal.sendUpdateUnderText')}
                    </Text>
                </div>, 
            value: 'true'
        },
        {
            label:
                <div>
                    {t('listings.archive.modal.dontSendUpdate')}
                    <Text
                        tag='p'
                        size={FontSizeEnum.Sm}
                        color={submitting ? ColorEnumNew.Gray400 : ColorEnumNew.Gray500}
                    >
                        {t('listings.archive.modal.dontSendUpdateUnderText')}
                    </Text>
                </div>, 
            value: 'false'
        },
    ];

    useEffect(() => {
        if (open) {
            fetchApprovedHomeseekers();
        }
    }, [open]);

    useEffect(() => {
        if (homeseekers) {
            checkForTenant();
            setLoading(false);
        }
    }, [homeseekers])

    const fetchApprovedHomeseekers = async () => {
        try {
            setLoading(true);
            const response = await findApprovedHomeseekersById(listingId);
            setHomeseekers(response);
        } catch (e) {
            dispatch(setNotification(
                {
                    id: Date.now(),
                    message: t('listings.archive.modal.failed'),
                    type: 'failed'
                }
            ));
        }
    }

    const checkForTenant = () => {
        for(const homeseeker of homeseekers.approvedHomeseekers) {
            if (homeseeker.isTenant) {
                setHasTenant(true);
                setTenant(homeseeker);
                setFormData([]);

                return;
            }
        }

        setTenant(null);
        setHasTenant(false);
    }

    const handleFormChange = (data, isValid, errors) => {
        setFormData(data);
        setIsFormValid(isValid);
        setFormErrors(errors);
    }

    const handleSelectChange = (item: Item) => {
        if (!item) {
            return;
        }

        const formDataClone = formData;
        formDataClone['tenant'].value = item.value;
        setFormData({ ...formDataClone });
    }

    const getAllApprovedHomeseekers = (homeseekers: FindApprovedHomeseekersByIdResponse): Item[] => {
        if (!homeseekers) {
            return [];
        }

        const sortedHomeseekers = homeseekers.approvedHomeseekers.sort((first, second) => {
            const removedPrefix = (n: string) => n.replace(/^[^.]+\.\s+/, '');

            return removedPrefix(first.name).localeCompare(removedPrefix(second.name));
        }).map((hs) => ({
            label: hs.name,
            value: hs.id,
        }));

        return [
            {
                label: t('listings.archive.modal.selectTenant'),
                value: null,
                disabled: true,
                hidden: true
            },
            {
                label: t('listings.archive.modal.notInLeadFlow'),
                value: '1'
            },
            ...sortedHomeseekers
        ];
    };

    useEffect(() => {
        setArchiveModel(archiveModel);
    }, [archiveModel]);

    const dispatchNotification = () => {
        if (isHomeseekerValid()) {
            const tenantName = homeseekers.approvedHomeseekers.find((hs) => hs.id === formData['tenant']?.value).name;

            dispatch(setNotification(
                {
                    id: Date.now(),
                    message: <Trans
                        i18nKey="listings.archive.modal.validation.toast.succeededWithTenant"
                        values={{ tenantName }}
                        components={{ strong: <strong /> }}
                    />,
                    type: 'success'
                }
            ));

            return;
        }

        dispatch(setNotification(
            {
                id: Date.now(),
                message: t('listings.archive.modal.validation.toast.succeeded'),
                type: 'success'
            }
        ));
    }

    const isHomeseekerValid = () => {
        return (formData['tenant']?.value !== '1' && formData['tenant']?.value);
    }

    const getTenant = () => {
        if (formData['tenant'] && formData['tenant']?.value === '1') {
            return null;
        } else if (hasTenant) {
            return tenant.id;
        }

        return formData['tenant']?.value;
    }

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            await assignTenantToListing({
                homeseekerApplicationId: getTenant(),
                transactionalListingId: listingId,
                shouldNotifyHomeseekers: (formData['sendMessage']?.value === 'true') ? true : false
            });
            dispatchNotification();
            setSubmitting(false);
        } catch(ex) {
            dispatch(setNotification(
                {
                    id: Date.now(),
                    message: t('listings.archive.modal.archiveFailed'),
                    type: 'failed'
                }
            ));

            closeButtonClick();
        }

        closeButtonClick();
    }

    const closeButtonClick = () => {
        onCloseButtonClick();

        handleSelectChange({
            label: t('listings.archive.modal.selectTenant'),
            value: '',
            disabled: true
        })

        setRadioValue('true');
        setSubmitting(false);
    }

    const skeletonComponents = () => {
        return (
            <div>
                <div className={style.modal}>
                    <SkeletonComponent width={300} height={20} />
                    <SkeletonComponent width={600} height={40} />
                    <div className={style.selectContainer}>
                        <SkeletonComponent width={150} height={20} />
                        <SkeletonComponent width={280} height={40} />
                    </div>
                    <SkeletonComponent width={320} height={20} />
                    <SkeletonComponent width={600} height={45} />
                    <SkeletonComponent width={300} height={45} />
                </div>
                <div className={style.buttons}>
                    <SkeletonComponent width={100} height={40} />
                    <SkeletonComponent width={100} height={40} />
                </div>
            </div>
        );
    }

    return (
        <div>
            {
                ARCHIVE_LISTINGS_WITH_AUTOMATIC_REJECTIONS ?
                    <ModalNew
                        isOpen={open}
                        flowyColor={ColorEnum.Primary100}
                        icon={'ri-archive-line'}
                        iconColor={ColorEnum.Primary600}
                        iconBackgroundColor={ColorEnum.Primary100}
                        canOverflow={true}
                        isNonScrollable={true}
                        onCloseButtonClick={closeButtonClick}
                        disabled={submitting}
                    >
                        {
                            loading ?
                                skeletonComponents() :
                                <Form onChange={handleFormChange} onSubmit={handleSubmit}>
                                    <div className={style.modal}>
                                        <Text
                                            size={FontSizeEnum.Lg}
                                            weight={FontWeightEnum.Semibold}
                                        >
                                            {t('listings.archive.modal.title', {
                                                address: address
                                            })}
                                        </Text>
                                        <Text>
                                            {t('listings.archive.modal.warning')}
                                        </Text>
                                        <div className={style.selectContainer}>
                                            {
                                                hasTenant ?
                                                    <div className={style.tenantBadgeContainer}>
                                                        <Badge before={<UserStarLineIcon />} className={style.tenantBadge} label={t('listings.archive.modal.tenantBadge')} />:
                                                        <Text
                                                            size={FontSizeEnum.Md}
                                                            weight={FontWeightEnum.Regular}
                                                        >
                                                            {tenant.name}
                                                        </Text>
                                                    </div> : <Select
                                                        label={<Label required>{t('listings.archive.modal.tenant')}</Label>}
                                                        items={getAllApprovedHomeseekers(homeseekers)}
                                                        placeholder={t('listings.archive.modal.selectTenant')}
                                                        variant={(formErrors['tenant']?.length) ? 'secondary-destructive' : 'secondary-gray'}
                                                        className={style.tenantSelect}
                                                        disabled={submitting}
                                                        onChange={handleSelectChange}
                                                        selected={formData['tenant']?.value}
                                                        hasInputField
                                                        name='tenant'
                                                        required
                                                    />
                                            }
                                            <FormError errors={formErrors['tenant']} />
                                        </div>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            weight={FontWeightEnum.Regular}
                                        >
                                            {t('listings.archive.modal.sendMessage')}
                                        </Text>
                                        <RadioInput
                                            name='sendMessage'
                                            value={radioValue}
                                            options={radioOptions}
                                            onChange={setRadioValue}
                                            disabled={submitting}
                                            required
                                        />
                                        <FormError errors={formErrors['sendMessage']} />
                                    </div>
                                    <div className={style.buttons}>
                                        <ButtonNew variant='secondary-gray' disabled={submitting} onClick={closeButtonClick}>
                                            {t('listings.archive.modal.cancel')}
                                        </ButtonNew>
                                        <ButtonNew type='submit' className={submitting && style.buttonDisabled} iconBefore={submitting && <LoadingSpinner />}>
                                            {t('listings.archive.modal.archive')}
                                        </ButtonNew>
                                    </div>
                                </Form>
                        }
                    </ModalNew>
                    : <Modal
                        model={model}
                        onButtonClick={(a: ArchiveListingModalActionType) => onButtonClick(model.listingId, a)}
                        onCloseButtonClick={() => onCloseButtonClick()}
                    >
                        <p className={style.text}>{t('listings.archive.modal.info')}</p>
                        <p className={style.text}>{t('listings.archive.modal.info.enrichmentWillBeOff')}</p>
                        <p className={style.text}>{t('listings.archive.modal.info.areYouSure')}</p>
                    </Modal>
            }
        </div>
    )
}

import api, { BodyData } from '../../utils/api';

interface PetInfo extends BodyData {
    species: string;
    amount: number;
}
export interface submitScreeningHouseholdCompositionPayload extends BodyData {
    screeningVerificationRequestAuthorisationId: string;
    amountOfAdults: number;
    amountOfChildren: number;
    pets: PetInfo[];
}

export interface SubmitScreeningHouseholdCompositionResponse {
    homeseekerId: string;
}

const submitScreeningHouseholdCompositionRequest = async (data: submitScreeningHouseholdCompositionPayload): Promise<SubmitScreeningHouseholdCompositionResponse> => {
    const response = await api.postWithoutAuth(`api/screening/verification-request/household-composition/save`, data);

    return response.json();
}

export default submitScreeningHouseholdCompositionRequest;

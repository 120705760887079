import {ColorEnum} from '../../enums';
import {TopHeader} from '../../organisms/top-header';
import {PageContainer} from '../../atoms/page-container';
import {Main} from '../../organisms/main';
import Typography from '../../atoms/typography/Typography';
import {Card} from '../../organisms/card';
import styles from './MergeDuplicateListings.module.css';
import Form, {FormErrors} from '../../molecules/form/Form';
import {InputField} from '../../organisms/input-field';
import Label from '../../atoms/label/Label';
import Button from '../../molecules/button/Button';
import React, {useState} from 'react';
import HighlightBlock from '../../molecules/highlightBlock/HighlightBlock';
import {ListingDetails} from '../../organisms/listing-details';
import FormError from '../../molecules/form/FormError';
import ConfirmMergeModal from '../../../pages/admin/mergeDuplicateTransactionalListings/components/ConfirmMergeModal';
import {
    dryRunDeduplication,
    Listing,
    mergeDuplicateListings,
    Priority
} from '../../../api/transactional-listing/deduplicateListings';
import {setNewNotification, TYPES} from '../../../redux/actions/ui/setNotification';
import {PageWithSidePanel} from '../../atoms/page-with-side-panel';
import CandidateDetailsSidePanel
    from '../../../components/organisms/candidateDetailsSidePanel/CandidateDetailsSidePanel';
import CandidateDetailsSidePanelModel
    from '../../../components/organisms/candidateDetailsSidePanel/models/candidateDetailsSidePanelModel';
import api from '../../../utils/api';
import CandidateModel from '../../../models/candidate/candidateModel';
import ScreeningRequestJsonToModelConverter from '../../../converters/screeningRequestJsonToModelConverter';
import {SidePanel} from '../../organisms/side-panel';
import {HomeseekerStatusBadge, Status} from '../../molecules/homeseeker-status-badge';

interface Homeseeker {
    id: string;
    name: string;
    email: string;
    status: Status;
}

interface Conflict {
    sourceListingHomeseeker: Homeseeker;
    targetListingHomeseeker: Homeseeker;
}

export const MergeDuplicateListings = () => {
    const [ sourceListingId, setSourceListingId ] = useState<string>('');
    const [ targetListingId, setTargetListingId ] = useState<string>('');
    const [ sourceListing, setSourceListing ] = useState<Listing|null>(null);
    const [ targetListing, setTargetListing ] = useState<Listing|null>(null);
    const [ conflicts, setConflicts ] = useState<Conflict[]>([]);
    const [ priorities, setPriorities ] = useState<Priority[]>([]);
    const [ formErrors, setFormErrors ] = useState<FormErrors>({});
    const [ confirmationModelOpen, setConfirmationModelOpen ] = useState<boolean>(false);
    const [ sidePanelModel, setSidePanelModel ] = useState(CandidateDetailsSidePanelModel.createEmpty())

    function getListingIdFromInput(idInput: string): string|null {
        if (idInput.length === 0) {
            return null;
        }

        if (/^[0-9A-Fa-f]{8}(-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/.test(idInput)) {
            return idInput;
        }

        const results = /[0-9A-Fa-f]{8}(-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}/.exec(idInput)
        if (results && results[0]) {
            return results[0];
        }

        return null;
    }

    function validateListings() {
        const validatedSourceListing = getListingIdFromInput(sourceListingId);
        let validated = true;
        if (!validatedSourceListing) {
            setFormErrors((state: FormErrors) => {
                return {
                    ...state,
                    sourceListing: [{'key': 'Listing must be a valid ID or complete Leadflow URL'}]
                }
            });

            validated = false;
        }

        const validatedTargetListing = getListingIdFromInput(targetListingId);
        if (!validatedTargetListing) {
            setFormErrors((state: FormErrors) => {
                return {
                    ...state,
                    targetListing: [{'key': 'Listing must be a valid ID or complete Leadflow URL'}]
                }
            });

            validated = false;
        }

        if (validatedSourceListing === validatedTargetListing) {
            setFormErrors((state: FormErrors) => {
                return {
                    ...state,
                    targetListing: [{'key': 'Listings cannot be the same'}]
                }
            });

            validated = false;
        }
        return {validatedSourceListing, validatedTargetListing, validated};
    }

    async function dryRun(): Promise<void> {
        const {validatedSourceListing, validatedTargetListing, validated} = validateListings();
        if (!validated) {
            return;
        }

        const response = await dryRunDeduplication(validatedSourceListing, validatedTargetListing);
        setSourceListing(response.sourceListing);
        setTargetListing(response.targetListing);
        setConflicts(response.conflicts);
    }

    function prioritizeHomeseeker(email: string, homeseekerId: string): void {
        setPriorities(state => {
            if (state[email]) {
                delete state[email];
            }

            return {
                ...state,
                [email]: homeseekerId
            }
        })
    }

    async function mergeListings(): Promise<void> {
        const {validatedSourceListing, validatedTargetListing, validated} = validateListings();
        if (!validated) {
            return;
        }

        try {
            await mergeDuplicateListings(validatedSourceListing, validatedTargetListing, priorities);
            setSourceListingId('');
            setTargetListingId('');
            setSourceListing(null);
            setTargetListing(null);
            setConflicts([]);
        } catch (e) {
            setNewNotification({
                type: TYPES.FAILED,
                message: e.message,
                id: Date.now()
            })
        }
    }

    async function openSidePanel(e, homeseekerApplicationId: string): Promise<void> {
        e.stopPropagation();
        SidePanel.setOpen(true);

        try {
            setSidePanelModel(CandidateDetailsSidePanelModel.createEmpty())

            const homeseekerApplicationResponse: {
                result: {
                    messages: {
                        details: { body: string, subject: string }
                        createdAt: string
                    }[],
                    notes: {
                        id: string,
                        note: string,
                        createdAt: string
                    }[],
                    viewings: {
                        startDateTime: string,
                        endDateTime: string
                        status: string;
                        timeslotStartTime?: string;
                        timeslotEndTime?: string;
                    }[],
                    upcomingOrMostRecentViewing: {
                        readonly startDateTime: string,
                        readonly endDateTime: string
                        readonly status: string;
                        readonly timeslotStartTime?: string;
                        readonly timeslotEndTime?: string;
                    }
                }
            } = await api.get(`api/get-homeseeker-application/${homeseekerApplicationId}`);
            const candidateModel = CandidateModel.fromJson({...homeseekerApplicationResponse.result})
            let withCandidate = sidePanelModel.with({candidateModel: candidateModel})

            if (candidateModel.screeningRequestId) {
                const screeningRequestResponse = await api.get(`api/get-screening-request/${candidateModel.screeningRequestId}`)
                withCandidate = withCandidate.with({
                    screeningRequestModel: ScreeningRequestJsonToModelConverter.convert({...screeningRequestResponse.result})
                })
            }

            setSidePanelModel(withCandidate.with({isCandidateLoading: false}));
        } catch (error) {
            setSidePanelModel(sidePanelModel.with({isCandidateLoading: false}));
        }
    }

    function getSourceListingCard() {
        if (!sourceListing) {
            return <>
                <Label htmlFor={'sourceListing'}>Leadflow listing ID of URL</Label>
                <InputField
                    name={'sourceListing'}
                    type={'text'}
                    placeholder={''}
                    required={true}
                    value={sourceListingId}
                    onChange={(state) => setSourceListingId(state.value)}
                ></InputField>
                <FormError errors={formErrors.sourceListing} />
                <Button type={'submit'} iconBefore={''}>Check</Button>
            </>
        }

        return <ListingDetails
            street={sourceListing.street}
            houseNumber={sourceListing.houseNumber}
            postalCode={sourceListing.postalCode}
            city={sourceListing.city}
            price={sourceListing.price}
            surface={sourceListing.surface}
            rooms={sourceListing.rooms}
        />
    }

    function getTargetListingCard() {
        if (!targetListing) {
            return <>
                <Label htmlFor={'targetListing'}>Leadflow listing ID of URL</Label>
                <InputField
                    name={'targetListing'}
                    type={'text'}
                    placeholder={''}
                    required={true}
                    value={targetListingId}
                    onChange={(state) => setTargetListingId(state.value)}
                ></InputField>
                <FormError errors={formErrors.targetListing} />
                <Button type={'submit'} iconBefore={''}>Check</Button>
            </>
        }

        return <ListingDetails
            street={targetListing.street}
            houseNumber={targetListing.houseNumber}
            postalCode={targetListing.postalCode}
            city={targetListing.city}
            price={targetListing.price}
            surface={targetListing.surface}
            rooms={targetListing.rooms}
        />;
    }

    function getConflict(conflict: Conflict, index: number) {
        return (<div className={styles.conflict} key={index}>
            <Card
                className={`${styles.conflictHomeseeker} ${priorities[conflict.sourceListingHomeseeker.email] === conflict.sourceListingHomeseeker.id ? styles.selected : ''}`}
                unselectable={true}
                onClickHandler={() => prioritizeHomeseeker(conflict.sourceListingHomeseeker.email, conflict.sourceListingHomeseeker.id)}>
                <strong>{conflict.sourceListingHomeseeker.name}</strong><br/>
                {conflict.sourceListingHomeseeker.email}<br/>
                <HomeseekerStatusBadge status={conflict.sourceListingHomeseeker.status} />
                <a className={styles.sidePanelButton} onClick={(e) => openSidePanel(e, conflict.sourceListingHomeseeker.id)}>
                    <div className={styles.icon}>
                        <i className={`${styles.disabled} ri-information-line`}></i>
                    </div>
                </a>
            </Card>
            <Card
                className={`${styles.conflictHomeseeker} ${priorities[conflict.targetListingHomeseeker.email] === conflict.targetListingHomeseeker.id ? styles.selected : ''}`}
                unselectable={true}
                onClickHandler={() => prioritizeHomeseeker(conflict.targetListingHomeseeker.email, conflict.targetListingHomeseeker.id)}>
                <strong>{conflict.targetListingHomeseeker.name}</strong><br />
                {conflict.targetListingHomeseeker.email}<br />
                <HomeseekerStatusBadge status={conflict.targetListingHomeseeker.status} />
                <a className={styles.sidePanelButton} onClick={(e) => openSidePanel(e, conflict.targetListingHomeseeker.id)}>
                    <div className={styles.icon}>
                        <i className={`${styles.disabled} ri-information-line`}></i>
                    </div>
                </a>
            </Card>
        </div>)
    }

    return (
        <PageWithSidePanel>
            <PageContainer backgroundColor={ColorEnum.Gray100}>
                <TopHeader hasTranslationSelector={true}/>
                <Main>
                    <Typography type={'h1'}>Dubbele advertenties samenvoegen</Typography>
                    <Form onSubmit={dryRun}>
                        <div className={styles.grid}>
                            <Card elevated className={styles.listingCard}>
                                <Typography type={'h6'}>Weg te halen listing</Typography>
                                {getSourceListingCard()}
                            </Card>
                            <Card elevated className={styles.listingCard}>
                                <Typography type={'h6'}>Listing die de kandidaten moet ontvangen</Typography>
                                {getTargetListingCard()}
                            </Card>
                        </div>
                        {
                            sourceListing &&
                            targetListing &&
                            <Card elevated>
                                <Typography type={'h6'}>Conflicten</Typography>
                                {conflicts.length === 0 && <HighlightBlock type={'info'}>Geen conflicten gevonden, listing kan veilig samengevoegd worden.</HighlightBlock>}
                                {conflicts.map(getConflict)}
                                <Button onClick={() => setConfirmationModelOpen(true)} iconBefore={''}>Samenvoegen</Button>
                                <ConfirmMergeModal
                                    isOpen={confirmationModelOpen}
                                    isShowingErrorMessage={false}
                                    onConfirm={mergeListings}
                                    onCancel={() => setConfirmationModelOpen(false)}
                                    onCloseButtonClick={() => setConfirmationModelOpen(false)}
                                    primaryAddress={targetListing.street + ' ' + targetListing.houseNumber + ', ' + targetListing.postalCode + ' ' + targetListing.city}
                                    redundantAddress={sourceListing.street + ' ' + sourceListing.houseNumber + ', ' + sourceListing.postalCode + ' ' + sourceListing.city}
                                ></ConfirmMergeModal>
                            </Card>
                        }
                    </Form>
                </Main>
            </PageContainer>
            <CandidateDetailsSidePanel
                model={sidePanelModel}
                onStatusButtonClick={()=> null}
                onMessageSendButtonClick={() => null}
                onNoteCreateButtonClick={() => null}
            />
        </PageWithSidePanel>
    );
}

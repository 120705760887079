import styles from './PendingContent.module.css';
import { Card } from '../../../../organisms/card';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import { useTranslation } from 'react-i18next';
import ScreeningNavigation from '../../../../organisms/screening-navigation/ScreeningNavigation';
import { FeaturedIcon } from '../../../../atoms/featured-icon';
import SmartPhoneLineIcon from '../../../../atoms/icons/SmartPhoneLineIcon';
import ComputerLineIcon from '../../../../atoms/icons/ComputerLineIcon';
import ArrowRightLineIcon from '../../../../atoms/icons/ArrowRightLineIcon';
import screeningSteps, {Action} from '../../../../organisms/screening-navigation/screeningSteps';
import { useEffect, useState } from 'react';
import { useRoutePath } from '../../../../utils/useRoutePath';
import { useNavigate, useParams } from 'react-router-dom';
import findIdVerificationStatus, { IdVerificationStatus } from '../../../../../api/screening/findIdVerificationStatus';
import useDeviceDetection from '../../../../../utils/useDeviceDetection';

const PendingContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const device = useDeviceDetection();

    const { homeseekerId } = useParams();

    const [ idVerificationStatus, setIdVerificationStatus ] = useState<string | null>(null);

    useEffect(() => {
        fetchStatus();

        const fetchStatusInterval = setInterval(fetchStatus, 1000);

        return () => clearInterval(fetchStatusInterval);
    }, []);

    useEffect(() => {
        if (homeseekerId) {
            if (idVerificationStatus === IdVerificationStatus.SUCCEEDED) {
                navigate(routePath('ScreeningIdVerificationSuccess', { homeseekerId }));
            }
            if (idVerificationStatus === IdVerificationStatus.FAILED) {
                navigate(routePath('ScreeningIdVerificationFailure', { homeseekerId }));
            }
        }
    }, [homeseekerId, idVerificationStatus]);

    const fetchStatus = async () => {
        try {
            const response = await findIdVerificationStatus(homeseekerId);

            setIdVerificationStatus(response.status);
        } catch (error) {
            console.error('Error fetching id verification status', error);
        }
    };

    const handleClick = (action: Action) => {
        if (action === 'previous') {
            navigate(routePath('ScreeningIdVerificationIntroduction', { homeseekerId }));
        }
    }

    return (
        <div className={styles.contentContainer}>
            <div className={styles.gridLg}>
                <Text
                    color={ColorEnum.Primary700}
                    weight={FontWeightEnum.Bold}
                    size={FontSizeEnum.DisplayXs}
                >{t(screeningSteps.ScreeningIdVerificationPending.pageTitleTranslationKey)}</Text>
                <Card elevated>
                    <div className={styles.gridMd}>
                        <div className={styles.iconContainer}>
                            {(device === 'desktop') &&
                                <>
                                    <FeaturedIcon
                                        icon={<ComputerLineIcon />}
                                        size='md'
                                    />
                                    <ArrowRightLineIcon className={styles.arrowIcon} />
                                </>
                            }
                            <FeaturedIcon
                                icon={<SmartPhoneLineIcon />}
                                size='xxl'
                                type='info'
                            />
                        </div>
                        <Text
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Gray800}
                        >{t(`screening.idVerification.pending${(device) === 'mobile' ? '.smartphone' : ''}.header`)}</Text>
                        <Text color={ColorEnum.Gray800}
                        >{t('screening.idVerification.pending.text')}</Text>
                    </div>
                </Card>
                <ScreeningNavigation
                    onClick={handleClick}
                />
            </div>
        </div>
    );
}

export default PendingContent;

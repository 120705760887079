import React, { ReactNode } from 'react';
import styles from './CheckboxInput.module.css';
import Label from '../../atoms/label/Label';
import CheckLineIcon from '../../atoms/icons/CheckLineIcon';
import { ColorEnum } from '../../enums';
import { TooltipSize } from '../../atoms/tooltip';

interface BaseCheckboxInputProps {
    readonly name: string;
    readonly id: string;
    readonly label: string;
    readonly value: string | null;
    readonly checked: boolean;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly tooltip?: boolean;
    readonly tooltipSize?: TooltipSize;
    readonly tooltipIcon?: ReactNode;
    readonly tooltipContent?: ReactNode | string;
    readonly onChange?: (checked: boolean) => void;
}

interface CheckboxInputDefaultProps extends BaseCheckboxInputProps {
    readonly tooltip?: false;
}

interface CheckboxInputWithTooltipProps extends BaseCheckboxInputProps {
    readonly tooltip: true;
    readonly tooltipIcon: ReactNode;
    readonly tooltipContent: ReactNode | string;
}

export type CheckboxInputProps = CheckboxInputDefaultProps | CheckboxInputWithTooltipProps;

const CheckboxInput = ({
    name,
    id,
    label,
    value,
    checked,
    required = false,
    disabled = false,
    tooltip = false,
    tooltipSize,
    tooltipIcon,
    tooltipContent,
    onChange,
}: CheckboxInputProps) => {
    const [checkedState, setCheckedState] = React.useState<boolean>(checked);

    const handleChange = () => {
        setCheckedState(!checkedState)
        onChange?.(!checkedState);
    }

    return (
        <div className={styles.container}>
            <div className={styles.checkboxInputContainer}>
                <input
                    className={styles.checkboxInput}
                    type='checkbox'
                    id={id}
                    name={name}
                    value={value}
                    required={required}
                    disabled={disabled}
                    onChange={handleChange}
                />
                <div className={styles.iconContainer}>
                    {checked &&
                        <CheckLineIcon className={styles.checkIcon}/>
                    }
                </div>
            </div>
            <Label
                htmlFor={id}
                color={disabled ? ColorEnum.Gray400 : ColorEnum.Gray800}
                tooltip={tooltip}
                tooltipSize={tooltipSize}
                tooltipIcon={tooltipIcon}
                tooltipContent={tooltipContent}
            >
                {label}
            </Label>
        </div>
    );
};

export default CheckboxInput;

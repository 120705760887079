import api from '../../utils/api';

export interface ApprovedHomeseekers {
    id: string;
    name: string;
    isTenant: boolean;
}

export interface FindApprovedHomeseekersByIdResponse {
    approvedHomeseekers: ApprovedHomeseekers[];
}

export const findApprovedHomeseekersById = async (transactionalListingId: string): Promise<FindApprovedHomeseekersByIdResponse> => {
    return await api.getWithoutAuth(`api/transactional-listing/${transactionalListingId}/homeseeker-applications/approved`);
}

import styles from './SuccessContent.module.css';
import { Card } from '../../../../organisms/card';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import { useTranslation } from 'react-i18next';
import ScreeningNavigation from '../../../../organisms/screening-navigation/ScreeningNavigation';
import screeningSteps, {Action} from '../../../../organisms/screening-navigation/screeningSteps';
import CheckboxCircleFillIcon from '../../../../atoms/icons/CheckboxCircleFillIcon';
import Divider from '../../../../atoms/divider';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutePath } from '../../../../utils/useRoutePath';

const SuccessContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const { homeseekerId } = useParams();

    const handleClick = (action: Action) => {
        if (action === 'next') {
            navigate(routePath('ScreeningIncomeVerificationOverview', { homeseekerId }));
        }
    }

    return (
        <div className={styles.contentContainer}>
            <div className={styles.gridLg}>
                <Text
                    color={ColorEnum.Primary700}
                    weight={FontWeightEnum.Bold}
                    size={FontSizeEnum.DisplayXs}
                >{t(screeningSteps.ScreeningIdVerificationSuccess.pageTitleTranslationKey)}</Text>
                <Card elevated>
                    <div className={styles.gridMd}>
                        <div className={styles.iconContainer}>
                            <CheckboxCircleFillIcon className={styles.checkboxIcon} />
                        </div>
                        <Text
                            size={FontSizeEnum.DisplayXs}
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Primary700}
                        >{t('screening.idVerification.success.header')}</Text>
                        <Divider />
                        <div className={styles.textLeft}>
                            <Text color={ColorEnum.Gray800}
                            >{t('screening.idVerification.success.text')}</Text>
                        </div>
                    </div>
                </Card>
                <ScreeningNavigation
                    onClick={handleClick}
                />
            </div>
        </div>
    );
}

export default SuccessContent;

import api, { BodyData } from '../../../../utils/api';

export interface SubmitIncomeSourceBusinessOwnershipPayload extends BodyData {
    companyName: string;
    startDate: string;
    grossIncome: number;
    chamberOfCommerceNumber: string | null;
    grossIncomeRemark: string | null;
}

const submitIncomeDetailsBusinessOwnership = async (homeseekerId: string, sourceId: string, data: SubmitIncomeSourceBusinessOwnershipPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/screening/${homeseekerId}/income-verification/${sourceId}/save/business-ownership`, data);
}

export default submitIncomeDetailsBusinessOwnership;

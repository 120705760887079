import api from '../../utils/api';

export enum IdVerificationStatus {
    PENDING = 'pending',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
}

interface FindIdVerificationStatusResponse {
    status: IdVerificationStatus | null;
}

const findIdVerificationStatus = async (homeseekerId: string): Promise<FindIdVerificationStatusResponse> => {
    return await api.getWithoutAuth(`api/screening/${homeseekerId}/id-verification/status-check`);
}

export default findIdVerificationStatus;

const initialState = {
    data: [],
    selectedAgent: {
        '@id': '',
        '@type': '',
        createdAt: '',
        id: '',
        logins: [],
        loginsUris: [],
        loginsPopulated: [],
        name: '',
        parariusOfficeKey: '',
        phone: '',
        logo: '',
        city: '',
        url: '',
        postalCode: '',
        house: '',
        street: '',
        defaultEmail: '',
        defaultPhone: '',
        emailsForInquiries: [],
        leadsAgentIds: [],
        showHouseNumber: false,
        privacyPolicy: '',
        isEmailSecure: false,
        allowCommercialContactToHomeseekers: false,
        commercialContactType: 'NONE',
        hasActivatedScreeningService: false,
        zohoId: '',
        isActive: true
    },
    isLoading: false,
    error: false,
    errorMessage: '',
    code: 200,
};

function normalizeAgent(payload) {
    return {
        createdAt: payload.createdAt,
        id: payload.id,
        loginsUris: payload.logins,
        logins: [],
        loginsPopulated: payload.loginsPopulated || [],
        name: payload.name,
        parariusOfficeKey: payload.parariusOfficeKey,
        phone: payload.defaultPhone,
        logo: payload.logo,
        city: payload.city,
        url: payload.url,
        postalCode: payload.postalCode,
        houseNumber: payload.houseNumber,
        street: payload.street,
        defaultEmail: payload.defaultEmail,
        defaultPhone: payload.defaultPhone,
        emailsForInquiries: payload.emailsForInquiries,
        leadsAgentIds: payload.leadsAgentIds,
        showHouseNumber: payload.showHouseNumber,
        privacyPolicy: payload.privacyPolicy,
        isEmailSecure: payload.isEmailSecure,
        allowCommercialContactToHomeseekers: payload.allowCommercialContactToHomeseekers,
        commercialContactType: payload.commercialContactType,
        hasActivatedScreeningService: payload.hasActivatedScreeningService,
        zohoId: payload.zohoId,
        isActive: payload.isActive,
    };
}

export default function (state = initialState, action = {}) {
    const { payload, type } = action;
    switch (type) {
        case 'SET_AGENCIES': {
            return {
                ...state,
                data: payload.result.map((obj) => ({
                    name: obj.name,
                    id: obj.id,
                    monthlyDiscardedLeadsCount: obj.monthlyDiscardedLeadsCount,
                })),
            };
        }
        case 'SET_AGENT_DETAILS': {
            return {
                ...state,
                selectedAgent: {
                    ...normalizeAgent(payload),
                },
            };
        }
        case 'ADD_AGENT_LOGIN': {
            return {
                ...state,
                selectedAgent: {
                    ...state.selectedAgent,
                    loginsPopulated: [...state.selectedAgent.loginsPopulated, ...[payload.login]],
                    logins: [...state.selectedAgent.logins, ...[payload]],
                },
            };
        }
        case 'REMOVE_AGENT_LOGIN': {
            return {
                ...state,
                selectedAgent: {
                    ...state.selectedAgent,
                    loginsPopulated: state.selectedAgent.loginsPopulated.filter(
                        (login) => login !== payload.login
                    ),
                    logins: state.selectedAgent.logins.filter((login) => login.login !== payload.login),
                },
            };
        }

        default:
            return state;
    }
}

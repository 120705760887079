import styles from './FreelanceContent.module.css';
import Text from '../../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum, IncomeVerificationIncomeSourceTypeEnum } from '../../../../../enums';
import { Data, FormErrors } from '../../../../../molecules/form/Form';
import { useTranslation } from 'react-i18next';
import Label from '../../../../../atoms/label/Label';
import { InputField } from '../../../../../organisms/input-field';
import FormError from '../../../../../molecules/form/FormError';
import { useEffect } from 'react';
import { IncomeSourceFreelance } from '../../../../../../api/screening/income-verification/find-income-source-details/findIncomeSourceFreelance';
import DateInput from '../../../../../../components/atoms/dateInput/DateInput';
import moment, { Moment } from 'moment/moment';
import HighlightBlock from '../../../../../molecules/highlightBlock/HighlightBlock';
import { RadioInput } from '../../../../../atoms/radio-input';
import stringToBoolean from '../../../../../utils/stringToBoolean';
import { HtmlTextArea } from '../../../../../atoms/html-text-area';

interface FreelanceContentProps {
    storedData: IncomeSourceFreelance;
    formData: Data;
    formErrors: FormErrors;
    onChange: (data) => void;
}

interface ActualFormData {
    companyName: string;
    isCompanyLocatedInTheNetherlands: boolean;
    chamberOfCommerceNumber: string | null;
    startDateValue: Moment | null;
    grossIncome?: string;
    grossIncomeRemark: string | null;
}

const FreelanceContent = ({
    storedData,
    formData,
    formErrors,
    onChange,
}: FreelanceContentProps) => {
    const { t } = useTranslation();

    const startDate = formData['startDate']?.value ?? storedData.startDate;

    const actualFormData: ActualFormData = {
        companyName: formData['companyName']?.value as string ?? storedData.companyName,
        isCompanyLocatedInTheNetherlands: stringToBoolean(formData['isCompanyLocatedInTheNetherlands']?.value as string) ?? (!!storedData.chamberOfCommerceNumber) ?? false,
        chamberOfCommerceNumber: formData['chamberOfCommerceNumber']?.value as string ?? storedData.chamberOfCommerceNumber,
        startDateValue: startDate ? moment(startDate as string) : null,
        grossIncome: formData['grossIncome']?.value as string ?? storedData.grossIncome?.toString(),
        grossIncomeRemark: formData['grossIncomeRemark']?.value as string ?? storedData.grossIncomeRemark,
    };

    useEffect(() => {
        onChange({
            type: IncomeVerificationIncomeSourceTypeEnum.Freelance,
            companyName: formData['companyName']?.value as string,
            isCompanyLocatedInTheNetherlands: formData['isCompanyLocatedInTheNetherlands']?.value as string,
            chamberOfCommerceNumber: formData['chamberOfCommerceNumber']?.value as string,
            startDate: formData['startDate']?.value as string,
            grossIncome: Number(formData['grossIncome']?.value as string),
            grossIncomeRemark: formData['grossIncomeRemark']?.value as string,
        });
    }, [formData]);

    const renderGrossIncomeTitle = () => {
        const date = moment(startDate as string);
        const currentYear = moment().year();

        if (date.year() < currentYear) {
            return t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.lastYear.title', {year: (currentYear - 1)});
        }
        if (date.month() < 6) {
            return t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.currentYearFirstHalf.title', {year: currentYear});
        }

        return t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.currentYearSecondHalf.title', {year: currentYear});
    }

    return (
        <div className={styles.gridMd}>
            <Text
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Bold}
            >{t('screening.incomeVerification.provideIncomeDetails.freelance.title')}</Text>
            <div className={styles.gridXl}>
                <div className={styles.gridMd}>
                    <div className={styles.gridSm}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >{t('screening.incomeVerification.provideIncomeDetails.freelance.company.title')}</Text>
                        <Text
                            color={ColorEnum.Gray800}
                        >{t('screening.incomeVerification.provideIncomeDetails.freelance.company.text')}</Text>
                    </div>
                    <div>
                        <Label
                            htmlFor={'companyName'}
                            fontSize={FontSizeEnum.Sm}
                            required
                        >
                            {t('screening.incomeVerification.provideIncomeDetails.freelance.company.label.companyName')}
                        </Label>
                        <InputField
                            id='companyName'
                            name='companyName'
                            placeholder={t('screening.incomeVerification.provideIncomeDetails.freelance.company.placeholder.companyName')}
                            value={actualFormData.companyName}
                            maxlength={255}
                            required
                        />
                        <FormError errors={formErrors['companyName']}/>
                    </div>
                </div>
                <div className={styles.gridMd}>
                    <Text
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Semibold}
                    >{t('screening.incomeVerification.provideIncomeDetails.freelance.isCompanyLocatedInTheNetherlands.title')}</Text>
                    <RadioInput
                        name='isCompanyLocatedInTheNetherlands'
                        value={actualFormData.isCompanyLocatedInTheNetherlands.toString()}
                        isBoolean
                        required
                    />
                    {actualFormData.isCompanyLocatedInTheNetherlands && (
                        <div className={styles.gridMd}>
                            <Text
                                color={ColorEnum.Gray800}
                                weight={FontWeightEnum.Semibold}
                            >{t('screening.incomeVerification.provideIncomeDetails.freelance.chamberOfCommerceNumber.text')}</Text>
                            <div>
                                <Label
                                    htmlFor={'chamberOfCommerceNumber'}
                                    fontSize={FontSizeEnum.Sm}
                                    required
                                >
                                    {t('screening.incomeVerification.provideIncomeDetails.freelance.chamberOfCommerceNumber.input.label')}
                                </Label>
                                <InputField
                                    id='chamberOfCommerceNumber'
                                    type='text'
                                    name='chamberOfCommerceNumber'
                                    placeholder={t('screening.incomeVerification.provideIncomeDetails.freelance.chamberOfCommerceNumber.input.placeholder.text')}
                                    value={actualFormData.chamberOfCommerceNumber}
                                    minlength={8}
                                    maxlength={8}
                                    pattern={'^[0-9]{8}$'}
                                    required
                                />
                                <FormError errors={formErrors['chamberOfCommerceNumber']}/>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.gridMd}>
                    <Text
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Semibold}
                    >{t('screening.incomeVerification.provideIncomeDetails.freelance.startDate.title')}</Text>
                    <div>
                        <Label
                            htmlFor={'startDate'}
                            fontSize={FontSizeEnum.Sm}
                            required
                        >
                            {t('screening.incomeVerification.provideIncomeDetails.freelance.startDate.label.startDate')}
                        </Label>
                        <DateInput
                            className={styles.dateInput}
                            name='startDate'
                            maximumValue={moment()}
                            value={actualFormData.startDateValue}
                            fullWidth
                            required
                        />
                        <FormError errors={formErrors['startDate']}/>
                    </div>
                </div>
                {formData['startDate']?.value &&
                    <div className={styles.gridMd}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >
                            {renderGrossIncomeTitle()}
                        </Text>
                        <HighlightBlock type={'info'}>
                            <Text
                                size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray800}
                            >{t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.highlightText')}</Text>
                        </HighlightBlock>
                        <div>
                            <Label
                                htmlFor={'grossIncome'}
                                fontSize={FontSizeEnum.Sm}
                                required
                            >
                                {t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.input.label')}
                            </Label>
                            <InputField
                                placeholder={t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.input.placeholder')}
                                type='number'
                                id='grossIncome'
                                name='grossIncome'
                                value={actualFormData.grossIncome}
                                min='0'
                                max='999999999'
                                required
                                data-no-decimals
                            />
                            <FormError errors={formErrors['grossIncome']}/>
                        </div>
                        <div>
                            <Label
                                htmlFor={'grossIncomeRemark'}
                                fontSize={FontSizeEnum.Sm}
                            >
                                {t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.label.grossIncomeRemark')}
                            </Label>
                            <HtmlTextArea
                                id='grossIncomeRemark'
                                name='grossIncomeRemark'
                                placeholder={t('screening.incomeVerification.provideIncomeDetails.freelance.grossIncome.placeholder.grossIncomeRemark')}
                                value={actualFormData.grossIncomeRemark}
                                maxlength={1000}
                            />
                            <FormError errors={formErrors['grossIncomeRemark']}/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default FreelanceContent;

import { useTranslation } from 'react-i18next';
import styles from './HomeseekerApplicationSourceIcon.module.css';
import React from 'react';
import { Tooltip } from '../../atoms/tooltip';

interface IconProps {
    source: string;
}

const HomeseekerApplicationSourceIcon = ({
    source
}: IconProps) => {
    const { t } = useTranslation();

    if (!source) {
        return null;
    }

    const getIcon = () => {
        const icons = {
            'annotation_eazlee': 'https://cdn.auth0.com/avatars/ea.png',
            'annotation_fundalead': 'https://cdn.auth0.com/avatars/fu.png',
            'annotation_leadflowbase': 'https://cdn.auth0.com/avatars/lb.png',
            'annotation_ogonline': 'https://cdn.auth0.com/avatars/og.png',
            'annotation_parariusoffice': 'https://cdn.auth0.com/avatars/po.png',
            'annotation_rotsvast': 'https://cdn.auth0.com/avatars/rv.png',
            'leadflow_direct_invite': 'https://cdn.auth0.com/avatars/ld.png',
            'platform_huurwoningen': 'https://cdn.auth0.com/avatars/hu.png',
            'platform_pararius': 'https://cdn.auth0.com/avatars/pa.png',
            'platform_pararius_plus': 'https://cdn.auth0.com/avatars/pp.png',
            'unknown': 'https://cdn.auth0.com/avatars/un.png'
        };

        return <img className={styles.sourceAvatar} src={icons[source]} alt={`Source: ${source}`} />;
    };

    return (
        <div className={styles.sourceIconContainer}>
            <Tooltip icon={getIcon()}>
                <p className={styles.description}>{t(`candidate.source.${source}`)}</p>
            </Tooltip>
        </div>
    );
}

export default HomeseekerApplicationSourceIcon;

import Button from '../../buttons/button/Button';
import ButtonActionType from '../../buttons/button/logic/models/buttonActionType';
import { Trans, useTranslation } from 'react-i18next';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import Modal from '../../organisms/modal/Modal';
import ColorEnum from '../../../enums/colorEnum';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';
import Text from '../../typography/text/Text';
import CandidateModel from '../../../models/candidate/candidateModel';
import style from './InviteToViewingModal.module.css';
import DateInput from '../../atoms/dateInput/DateInput';
import TimeInput from '../../atoms/timeInput/TimeInput';
import React, { useEffect, useState } from 'react';
import InviteViewingModalModel from './model/inviteViewingModalModel';
import TextInput from '../../forms/textInput/TextInput';
import FormError from '../../banners/FormError';
import Checkbox from '../../forms/checkbox/Checkbox';
import NumberOfTimeslotsSubText from '../../../componentsNew/atoms/viewing-timeslots/NumberOfTimeslotsSubText';
import {HomeseekerSelector} from '../../../componentsNew/organisms/homeseeker-selector';
import { TextArea } from '../../../componentsNew/atoms/text-area';
import { Select } from '../../../componentsNew/organisms/select';
import Label from '../../../componentsNew/atoms/label/Label';

interface InviteToViewingModalProps {
    readonly listingId: string;
    readonly isOpen: boolean;
    readonly onCloseButtonClick: () => void;
    readonly selectedPeople: ReadonlyArray<CandidateModel>;
    readonly setSelectedPeople: React.Dispatch<React.SetStateAction<unknown[]>>;
    readonly street: string;
    readonly houseNumber: string;
    readonly address: string;
    readonly city: string;
    readonly onSubmitButtonClick: (model: InviteViewingModalModel) => void;
    readonly clear: boolean;
    readonly setClear: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function InviteToViewingModal(props: InviteToViewingModalProps) {
    const { t } = useTranslation()
    const [model, setModel] = useState(InviteViewingModalModel.create('', []))

    useEffect(() => {
        setModel(InviteViewingModalModel.create(
            t('candidate.action.invite.viewing', {
                address: props.address,
                city: props.city
            }),
            [...props.selectedPeople]
        ))
    }, [props.street, props.houseNumber, props.address, props.city])

    useEffect(() => {
        setModel(model.with({
            messageSubject: `${t('candidate.action.invite.viewing', {
                address: props.address,
                city: props.city
            })}`,
            messageRecipients: [...props.selectedPeople]
        }))
    }, [props.selectedPeople])

    useEffect(() => {
        if (props.clear) {
            setModel(InviteViewingModalModel.create('', []));
            props.setClear(false);
        }
    }, [props.clear]);

    function handleSubmitButtonClick() {
        props.onSubmitButtonClick(model);
        props.onCloseButtonClick();
    }

    function showInvitationDateTimeErrors() {
        return <>
            {model.isInvitationStartDateTimeInThePast() &&
                <FormError message={t('candidate.action.invite.error.startDateTimeInPast')}/>}
            {model.isInvitationStartDateTimeTheSameAsEndDateTime() &&
                <FormError message={t('candidate.action.invite.error.startAndEndDateTimeSame')}/>}
            {model.isInvitationStartDateTimeTooCloseToCurrentTime() &&
                <FormError message={t('candidate.action.invite.error.startDateTimeTooCloseToCurrentTime')}/>}
            {model.isInvitationEndDateTimeBeforeStartDateTime() &&
                <FormError message={t('candidate.action.invite.error.endDateTimeBeforeStartDateTime')}/>}
        </>
    }

    return (
        <Modal
            icon={'ri-calendar-line'}
            iconColor={ColorEnum.Primary600}
            iconBackgroundColor={ColorEnum.Primary100}
            flowyColor={ColorEnum.Primary100}
            isOpen={props.isOpen}
            onCloseButtonClick={() => props.onCloseButtonClick()}
        >
            <div className={style.titleContainer}>
                <Text
                    size={FontSizeEnum.Lg}
                    weight={FontWeightEnum.Medium}
                    color={ColorEnum.Gray800}
                >
                    {t('candidate.action.invite.title')}
                </Text>
            </div>
            <div className="flex flex-col gap-4">
                <HomeseekerSelector
                    transactionalListingId={props.listingId}
                    preselectedHomeseekers={model.getHomeseekerApplicationsForSelector()}
                    onChange={props.setSelectedPeople}
                />
                <div>
                    <Label>{t('candidate.action.invite.date')}</Label>
                    <div className={style.dateContainer}>
                        <DateInput
                            minimumValue={model.getMinimumSelectableInvitationDate()}
                            value={model.value.invitationDate}
                            onChange={(val) => setModel(prev => prev.updateInvitationDate(val.year(), val.month(), val.date()))}
                        />
                        <TimeInput
                            value={model.getInvitationStartTimeInputModel()}
                            onChange={(val) => setModel(prev => prev.updateInvitationStartDateTimeMaintainingTimeDiff(val))}
                        />
                        <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                            {t('candidate.action.invite.date.to')}
                        </Text>
                        <TimeInput
                            disabledUntil={model.getInvitationStartTimeInputModel().value.time}
                            value={model.getInvitationEndTimeInputModel()}
                            onChange={(val) => setModel(prev => prev.updateInvitationEndDateTime(val))}
                        />
                    </div>
                    {showInvitationDateTimeErrors()}
                </div>
                <div>
                    <Select
                        id="agency"
                        name="agency"
                        label={t('candidate.action.invite.viewing.pickableTimeslotsLabel')}
                        items={[null, 5, 10, 15, 20, 30, 60].map((m) => {
                            return {
                                label: m ? t('candidate.action.invite.viewing.minutes', {minutes: m}) : t('viewing.page.choose.timeslot.no.split'),
                                value: m ? `${m}` : null
                            }
                        })}
                        selected={model.value.timeslotDurationInMinutes}
                        onChange={(val) => setModel(prev => prev.updateTimeslotDuration(val.value))}
                    />
                    {(model.value.timeslotDurationInMinutes && Number.isInteger(model.getViewingDuration())) &&
                        <div className={style.formSubText}>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>
                                <NumberOfTimeslotsSubText
                                    number={Math.floor(model.getViewingDuration() / +model.value.timeslotDurationInMinutes)}
                                />
                            </Text>
                        </div>
                    }
                </div>
                <div className={style.checkboxDiv}>
                    <Checkbox
                        id="maxNumberOfHomeseekersPerTimeslot"
                        isChecked={model.value.hasMaxNumberOfHomeseekersPerTimeslot}
                        onChange={(e) => {
                            setModel((prev) =>
                                prev.with({
                                    hasMaxNumberOfHomeseekersPerTimeslot: (e.target as HTMLInputElement).checked,
                                    maxNumberOfHomeseekersPerTimeslot: (e.target as HTMLInputElement).checked
                                        ? prev.value.maxNumberOfHomeseekersPerTimeslot
                                        : ''
                                })
                            );
                        }}
                        label={
                            <span className={style.maxCandidatesText}>
                                {!model.value.timeslotDurationInMinutes
                                    ? t('candidate.action.invite.viewing.maxNumberOfCandidatesForViewing')
                                    : t('candidate.action.invite.viewing.maxNumberOfCandidatesForTimeslots')}
                            </span>
                        }
                    />

                    <input
                        type="number"
                        className={style.maxCandidatesInput}
                        min="1"
                        value={model.value.maxNumberOfHomeseekersPerTimeslot}
                        disabled={!model.value.hasMaxNumberOfHomeseekersPerTimeslot}
                        onChange={(e) =>
                            setModel((prev) =>
                                prev.with({maxNumberOfHomeseekersPerTimeslot: e.target.value})
                            )
                        }
                    />
                </div>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800} weight={FontWeightEnum.Regular}>
                    {t('candidate.action.invite.label')}
                </Text>
                <div>
                    <label className={style.subjectTitle}>
                        {t('candidate.action.invite.subject')}
                    </label>
                    <TextInput
                        name="subject"
                        value={model.value.messageSubject}
                        onChange={(e) => setModel(prev => prev.with({messageSubject: (e.target as HTMLInputElement).value}))}
                    />
                    {model.isMessageSubjectInvalid() &&
                        <FormError message={t('candidate.action.invite.error.subject')}/>}
                </div>
                <div className={style.checkboxDiv}>
                    <Checkbox
                        id="includeDateAndTimeInEmail"
                        label={
                            <div>
                                <span className={style.includeInEmailText}>
                                    {t('candidate.action.invite.date.includeInEmail')}
                                </span>

                                <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray500} weight={FontWeightEnum.Regular}>
                                    <Trans
                                        i18nKey="candidate.action.invite.date.includeInEmail.transText"
                                    />
                                </Text>

                                <div className={style.messageBodyWithTime}>
                                    <p>
                                        {t('candidate.action.invite.viewing.messageBodyWithTime', {
                                            date: model.isInvitationDateInvalid() ? model.value.invitationDate.format('dddd D MMMM YYYY') : t('candidate.action.invite.viewing.noDate'),
                                            startTime: model.isInvitationStartDateTimeInvalid() ? model.value.invitationStartDateTime.format('HH:mm') : t('candidate.action.invite.viewing.noTime'),
                                            endTime: model.isInvitationEndDateTimeInvalid() ? model.value.invitationEndDateTime.format('HH:mm') : t('candidate.action.invite.viewing.noTime')
                                        })}
                                    </p>
                                </div>
                            </div>
                        }
                        isChecked={model.value.includeDateAndTimeInEmail}
                        onChange={(e) =>
                            setModel((prev) =>
                                prev.with({includeDateAndTimeInEmail: (e.target as HTMLInputElement).checked})
                            )
                        }
                    />
                </div>

                <div data-testid="leadflow-editor">
                    <div className={style.buttonTitle}>
                        <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                            {t('candidate.action.invite.body')}
                        </Text>
                    </div>
                    <TextArea
                        value={model.value.messageBody}
                        onChange={(id, v) => setModel(prev => prev.with({messageBody: v}))}
                        id='inviteToViewingEditor'
                    />
                </div>
            </div>
            <div className={style.buttonContainer}>
                <Button
                    title={t('actions.cancel')}
                    onClick={() => props.onCloseButtonClick()}
                />
                <Button
                    buttonType={ButtonType.Primary}
                    isDisabled={!model.isSubmitButtonEnabled()}
                    onClick={() => handleSubmitButtonClick()}
                    buttonActionType={ButtonActionType.Submit}
                    title={t('viewing.invitation.send')}
                    icon="ri-mail-send-line"
                />
            </div>
        </Modal>
    )
}

import Text from '../../typography/text/Text'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import ColorEnum from '../../../enums/colorEnum'
import styles from './TextInput.module.css'

export interface TextInputProps {
    readonly required?: boolean;
    readonly name: string;
    readonly placeholder?: string;
    readonly testId?: string;
    readonly isDisabled?: boolean;
    readonly isMandatory?: boolean;
    readonly label?: string;
    readonly value?: string;
    readonly width?: number;
    readonly onChange?: (ev: React.ChangeEvent<unknown>) => void;
    readonly keepColorOnDisabled?: boolean;
    readonly type?: string;
    readonly pattern?: string;
    readonly minLength?: number;
    readonly maxLength?: number;
}

const TextInput = (props: TextInputProps) => {

    return (
        <div style={{ width: props.width ? props.width : 'auto' }}>
            {props.label &&
                <label htmlFor={props.name}>
                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Primary700}>
                        { props.label }
                        {props.isMandatory && ' *'}
                    </Text>
                </label>
            }

            <input
                placeholder={props.placeholder}
                data-testid={props?.testId}
                disabled={props.isDisabled}
                name={props.name}
                id={props.name}
                onChange={props.onChange}
                value={props.value ? props.value : ''}
                className={`${styles.inputContainer} ${props.keepColorOnDisabled ? styles.inputDisabled : ''}`}
                type={props.type ?? 'text'}
                pattern={props.pattern}
                minLength={props.minLength}
                maxLength={props.maxLength}
                required={props.required}
            />
        </div>
    )
}

export default TextInput

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Text from '../../componentsNew/atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../componentsNew/enums';
import ButtonNew from '../../componentsNew/molecules/button/Button';
import { useTranslation } from 'react-i18next';
import styles from './SettingsPage.module.css';
import { SidePanel } from '../../componentsNew/organisms/side-panel';
import { InputField } from '../../componentsNew/organisms/input-field';
import fetchMailingSettings from '../../api/agency/settings/fetchMailingSettings';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../redux/actions/ui/setNotification';
import HighlightBlock from '../../componentsNew/molecules/highlightBlock/HighlightBlock';

const EmailTemplatesContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ queryParams, ] = useSearchParams();

    const [ selectedAgencyId , setSelectedAgencyId ] = useState<string | null>(null);
    const [ fetchingMailingSettings , setFetchingMailingSettings ] = useState<boolean>(true);

    useEffect(() => {
        setSelectedAgencyId(queryParams.get('selectedAgencyId'));
    }, [queryParams]);

    useEffect(() => {
        if (selectedAgencyId) {
            tryFetchingMailingSettings(selectedAgencyId);
        }
    }, [selectedAgencyId]);


    const tryFetchingMailingSettings = async (selectedAgencyId: string) => {
        setFetchingMailingSettings(true);

        try {
            await fetchMailingSettings(selectedAgencyId);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('settingsPage.signature.failed')
            }));
        } finally {
            setFetchingMailingSettings(false);
        }
    }

    return (
        <>
            <div className={styles.formRow}>
                <HighlightBlock bottomMarginInPx={16}>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray800}
                    >
                        {t('settingsPage.signature.info')}
                    </Text>
                </HighlightBlock>
            </div>
            <div className={styles.contentSection}>
                <div className={styles.descriptionPanel}>
                    <Text
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Bold}
                        tag='h6'
                    >
                        {t('settingsPage.rejection.template.title')}
                    </Text>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray800}
                        tag='p'
                    >
                        {t('settingsPage.rejection.template.description')}
                    </Text>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray800}
                        tag='p'
                    >
                        {t('settingsPage.template.variable.description')}
                    </Text>
                    <ButtonNew variant="link" onClick={SidePanel.toggle}>
                        {t('sidepanel.variable.more.about')}
                    </ButtonNew>
                </div>
                <div className={styles.settingsPanel}>
                    <div className={styles.formRow}>
                        {fetchingMailingSettings
                            ?
                            'loading'
                            :
                            <>
                                {t('settingsPage.input.subject')}
                                <span className={styles.required}>*</span>
                                <InputField
                                    name='rejectionEmailTitle'
                                    value='test'
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailTemplatesContent;

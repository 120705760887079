import api from '../../../../utils/api';

export interface IncomeSourceBusinessOwnership {
    companyName: string,
    isCompanyLocatedInTheNetherlands: boolean,
    chamberOfCommerceNumber: string,
    startDate: string,
    grossIncome: number,
    grossIncomeRemark: string,
}

const findIncomeSourceBusinessOwnership = async (sourceId: string): Promise<IncomeSourceBusinessOwnership> => {
    return await api.getWithoutAuth(`api/screening/income-verification/details/business-ownership`, { sourceId });
}

export default findIncomeSourceBusinessOwnership;

import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from './SettingsPage.module.css';
import {useDispatch} from 'react-redux';
import {setNotification} from '../../redux/actions/ui/setNotification';
import Text from '../../componentsNew/atoms/typography/Text';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../componentsNew/enums';
import {InputField} from '../../componentsNew/organisms/input-field';
import fetchGeneralSettings from '../../api/agency/settings/fetchGeneralSettings';

const GeneralSettingsContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ queryParams, ] = useSearchParams();

    const [ selectedAgencyId , setSelectedAgencyId ] = useState<string | null>(null);
    const [ , setFetchingGeneralSettings ] = useState<boolean>(true);
    const [ privacyPolicyUrl, setPrivacyPolicyUrl ] = useState<string>('');
    const [ logoUrl, setLogoUrl ] = useState<string>('');

    useEffect(() => {
        setSelectedAgencyId(queryParams.get('selectedAgencyId'));
    }, [queryParams]);

    useEffect(() => {
        if (selectedAgencyId) {
            tryFetchingGeneralSettings(selectedAgencyId);
        }
    }, [selectedAgencyId]);


    const tryFetchingGeneralSettings = async (selectedAgencyId: string) => {
        setFetchingGeneralSettings(true);

        try {
            const generalSettings = await fetchGeneralSettings(selectedAgencyId);
            setPrivacyPolicyUrl(generalSettings.privacyPolicyUrl);
            setLogoUrl(generalSettings.logoUrl);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('settingsPage.general.failed.fetching')
            }));
        } finally {
            setFetchingGeneralSettings(false);
        }
    }

    return (
        <>
            <div className={styles.contentSection}>
                <div className={styles.descriptionPanel}>
                    <Text
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Bold}
                        tag='h6'
                    >
                        {t('settingsPage.general.privacy.policy')}
                    </Text>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray800}
                        tag='p'
                    >
                        {t('settingsPage.general.privacy.policy.description')}
                    </Text>
                </div>
                <div className={styles.settingsPanel}>
                    <InputField required value={privacyPolicyUrl} onChange={(state) => setPrivacyPolicyUrl(state.value)} />
                </div>
            </div>
            <div className={styles.contentSection}>
                <div className={styles.descriptionPanel}>
                    <Text
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Bold}
                        tag='h6'
                    >
                        {t('settingsPage.general.logo.url')}
                    </Text>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray800}
                        tag='p'
                    >
                        {t('settingsPage.general.logo.url.description')}
                    </Text>
                </div>
                <div className={styles.settingsPanel}>
                    <InputField required value={logoUrl} onChange={(state) => setLogoUrl(state.value)} />
                </div>
            </div>
        </>
    );
};

export default GeneralSettingsContent;

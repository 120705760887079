import React, {forwardRef, ReactNode} from 'react';
import styles from './Card.module.css';
import { ColorEnum } from '../../enums';
import { getColorCssVar } from '../../enums/ColorEnum';
import { classNames } from '../../utils';

interface CardProps {
    readonly className?: string;
    readonly children: ReactNode;
    readonly hasPadding?: boolean;
    readonly elevated?: boolean;
    readonly backgroundColor?: ColorEnum;
    readonly borderColor?: ColorEnum;
    readonly unselectable?: boolean;
    readonly onClickHandler?: () => void;
}

const Card = forwardRef<HTMLDivElement, CardProps>(({
    className,
    children,
    hasPadding = true,
    elevated = false,
    backgroundColor = ColorEnum.White,
    borderColor = ColorEnum.Gray100,
    unselectable = false,
    onClickHandler,
}, ref) => {
    return (
        <div
            ref={ref}
            className={classNames(className && className, styles.Card, hasPadding && styles.hasPadding, elevated && styles.elevated, borderColor && styles.border)}
            style={{ backgroundColor: getColorCssVar(backgroundColor), borderColor: getColorCssVar(borderColor) }}
            tabIndex={unselectable ? -1 : undefined}
            onClick={onClickHandler}
        >
            {children}
        </div>
    );
});

Card.displayName = 'Card';

export default Card;

import { ReactNode, useState } from 'react';
import styles from './RadioInput.module.css';
import { useTranslation } from 'react-i18next';
import Label from '../label/Label';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { classNames } from '../../utils';

export interface Option {
    label: ReactNode;
    value: string | null;
}

interface RadioInputBase {
    readonly className?: string;
    readonly name?: string;
    readonly label?: ReactNode;
    readonly value: string | null;
    readonly onChange?: (value: string | null) => void;
    readonly isBoolean?: boolean;
    readonly options?: Option[];
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly optionFontColor?: ColorEnum;
    readonly optionFontSize?: FontSizeEnum;
    readonly optionFontWeight?: FontWeightEnum;
}

interface RadioInputDefault extends RadioInputBase {
    readonly isBoolean?: false;
    readonly options: Option[];
}

interface RadioInputIsBoolean extends RadioInputBase {
    readonly isBoolean: true;
    readonly options?: never;
}

type RadioInputProps = RadioInputDefault | RadioInputIsBoolean;

const RadioInput = ({
    className,
    name,
    label,
    value,
    isBoolean,
    options,
    required = false,
    disabled = false,
    onChange,
    optionFontColor = ColorEnum.Gray800,
    optionFontSize = FontSizeEnum.Md,
    optionFontWeight = FontWeightEnum.Regular,
}: RadioInputProps) => {
    const { t } = useTranslation();

    const [ valueState, setValueState ] = useState<string | null>(value);

    const isRadioButtonChecked = (radioValue: string): boolean => {
        return radioValue === valueState;
    }

    const handleOnChange = (option: Option): void => {
        setValueState(option.value);
        onChange?.(option.value)
    }

    const radioButton = (option: Option, index: number): JSX.Element => {
        return (
            <div key={index} className={styles.inputContainer}>
                <div className={styles.radioButtonContainer}>
                    <input
                        className={classNames(styles.radioButtonInput, disabled && styles.disabled)}
                        type="radio"
                        id={option.value}
                        name={name}
                        value={option.value}
                        checked={isRadioButtonChecked(option.value)}
                        required={required}
                        onChange={() => handleOnChange(option)}
                    />
                </div>
                <Label
                    className={classNames(styles.label, disabled && styles.disabledText)}
                    htmlFor={option.value}
                    color={optionFontColor}
                    fontSize={optionFontSize}
                    fontWeight={optionFontWeight}
                >
                    {option.label}
                </Label>
            </div>
        );
    };

    const renderRadioButtons = (): JSX.Element[] => {
        let radioButtons: JSX.Element[] = [];

        if (isBoolean === true) {
            const isBooleanOptions: JSX.Element[] = [
                radioButton({ label: t('radio.input.false.label'), value: 'false' }, 1),
                radioButton({ label: t('radio.input.true.label'), value: 'true' }, 2),
            ];
            radioButtons = radioButtons.concat(isBooleanOptions);
        } else if (options && Array.isArray(options)) {
            radioButtons = options.map((option: Option, index: number) => radioButton(option, index));
        }

        return radioButtons;
    }

    return (
        <div className={classNames(styles.container, className && className, disabled && styles.disabled)}>
            {label &&
                <div>
                    <Label>{label}</Label>
                </div>
            }
            {renderRadioButtons()}
        </div>
    );
};

export default RadioInput;

export interface SplitFilename {
    name: string;
    extension: string;
}

const splitFilenameAndExtension = (filename: string): SplitFilename => {
    const lastDotIndex = filename.lastIndexOf('.');

    if (lastDotIndex !== -1 && lastDotIndex !== 0) {
        return {
            name: filename.slice(0, lastDotIndex),
            extension: filename.slice(lastDotIndex),
        }
    }

    return {
        name: filename,
        extension: '',
    };
}

export default splitFilenameAndExtension;

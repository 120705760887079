import React, {ReactElement, ReactNode} from 'react';
import styles from './FeaturedIcon.module.css';
import {classNames} from '../../utils';

interface FeaturedIconProps {
    className?: string;
    icon: ReactNode;
    size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
    type?: 'gray' | 'info' | 'warning' | 'error' | 'success';
}

const FeaturedIcon = ({
    className,
    icon,
    size = 'sm',
    type = 'gray',
}: FeaturedIconProps) => {
    const iconClone = () => {
        if (React.isValidElement(icon)) {
            const preExistingClassNames: string = icon.props.className || '';
            const additionalClassNames: string = styles[`${size}Icon`];
            const combinedClassNames: string = classNames(preExistingClassNames, additionalClassNames);

            return React.cloneElement(icon as ReactElement, { className: combinedClassNames });
        }
    }

    return (
        <div
            className={classNames(className && className, styles.iconContainer, styles[type], styles[size])}
        >
            {iconClone()}
        </div>
    );
};

export default FeaturedIcon;

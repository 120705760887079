import api, { BodyData } from '../../utils/api';

export interface assignTenantToListingPayload extends BodyData {
    homeseekerApplicationId?: string;
    transactionalListingId: string;
    shouldNotifyHomeseekers: boolean;
}

const assignTenantToListing = async (payload: assignTenantToListingPayload) => {
    return await api.postWithoutAuth('api/transactional-listing/tenant/archive', payload);
}

export default assignTenantToListing;

import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { setTokenToStore } from '../redux/actions/users/handleAuthToken';

import useAuth from '../hooks/useAuth';

import { growthbook } from './analytics';
import { getFeaturesFromGrowthBook } from './api';
import { GrowthBookProvider } from '@growthbook/growthbook-react';

import ErrorBoundary from './ErrorBoundary';

import Loading from '../components/empty-states/loading/Loading';
import Error from '../components/empty-states/Error';
import LeadflowWindow from '../LeadflowWindow';
import GeneralErrorPage from '../componentsNew/pages/errorPage/GeneralErrorPage';

const updateGrowthBookURL = () => {
    growthbook.setURL(window.location.href)
}

const BaseWrapper = ({ children }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [isGrowthBookLoading, setIsGrowthBookLoading] = useState(true);

    const { isLoading, error, isAuthenticated, getAccessTokenSilently, user } = useAuth();

    useEffect(() => {
        const getGrowthBookFeatures = async () => {
            try {
                const response = await getFeaturesFromGrowthBook();
                const { features } = response.data;
                growthbook.setPayload({features});
            } catch (e) {
                return;
            } finally {
                setIsGrowthBookLoading(false);
            }
        };

        getGrowthBookFeatures();

        growthbook.init({ streaming: true });

        window.addEventListener('CookiebotOnAccept', function () {
            document.dispatchEvent(new CustomEvent('growthbookpersist'));
        }, false);
    }, []);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const token = await getAccessTokenSilently();

                if (isAuthenticated) {
                    growthbook.setAttributes({
                        email: user?.email,
                        name: user?.name,
                        email_verified: user?.email_verified,
                        nickname: user?.nickname,
                        ui_env: (window as typeof globalThis)?.NODE_ENV,
                        origin: window?.origin,
                        language: window?.frames?.localStorage?.i18nextLng
                    });
                    dispatch(setTokenToStore(token));
                }
            } catch (e) {
                return
            }
        };

        getUserData();
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        updateGrowthBookURL();

        return () => {
            updateGrowthBookURL();
        };
    }, [location]);

    const persistGrowthbook = () => {
        if (LeadflowWindow.Cookiebot.consented) {
            document.dispatchEvent(new CustomEvent('growthbookpersist'));
        }
    };

    useEffect(() => {
        if (growthbook.ready) {
            if (!LeadflowWindow.Cookiebot || LeadflowWindow.Cookiebot.loaded === false) {
                window.addEventListener('CookiebotOnLoad', function () {
                    persistGrowthbook();
                }, false);
            } else {
                persistGrowthbook();
            }
        }
    }, [growthbook.ready]);

    if (isLoading || isGrowthBookLoading) {
        return <Loading />
    } else {
        if (error) {
            return (
                <Error
                    title={'Er is iets misgegaan'}
                    message={error.message}
                />
            );
        } else {
            return (
                <>
                    <GrowthBookProvider growthbook={growthbook}>
                        <ErrorBoundary fallback={<GeneralErrorPage/>}>
                            {children}
                        </ErrorBoundary>
                    </GrowthBookProvider>
                </>
            );
        }
    }
}

BaseWrapper.propTypes = {
    children: PropTypes.any.isRequired,
};

export default BaseWrapper;

import { Trans, useTranslation } from 'react-i18next';
import { ColorEnum, IncomeFrequencyEnum } from '../../enums';
import { Card } from '../../organisms/card';
import styles from './EarningsCalculator.module.css';
import { formatPrice } from '../../utils';

export interface EarningsCalculatorProps {
    grossIncome: number;
    grossIncomeFrequency: IncomeFrequencyEnum;
    bonusses: number;
    bonussesFrequency: IncomeFrequencyEnum;
    holidayAllowanceIncluded: boolean;
    hasThirteenthMonth: boolean;
}

const EarningsCalculator = ({
    grossIncome,
    grossIncomeFrequency,
    bonusses,
    bonussesFrequency,
    holidayAllowanceIncluded,
    hasThirteenthMonth,
}: EarningsCalculatorProps) => {
    const { t } = useTranslation();

    if (isNaN(grossIncome) || grossIncome > 999999999) {
        grossIncome = NaN;
    }

    if (isNaN(bonusses) || bonusses > 999999999) {
        bonusses = NaN;
    }

    const calculateMonthlyValue = (value, frequency) => {
        const factors = { weekly: 52 / 12, monthly: 1, annual: 1 / 12 };
        return value * (factors[frequency] || 0);
    }

    const calculateAnnualIncome = (monthlyIncome) => {
        return monthlyIncome * 12;
    }

    const baseMonthlyIncome = calculateMonthlyValue(grossIncome, grossIncomeFrequency);
    const monthlyBonusses = calculateMonthlyValue(bonusses, bonussesFrequency);
    const monthlyHolidayAllowance = (!holidayAllowanceIncluded ? (baseMonthlyIncome * 1.08 - baseMonthlyIncome) : 0);
    const monthlyThirteenthMonth = (hasThirteenthMonth ? (baseMonthlyIncome / 12) : 0);

    const monthlyIncome = baseMonthlyIncome + monthlyBonusses + monthlyHolidayAllowance + monthlyThirteenthMonth;
    const annualIncome = calculateAnnualIncome(monthlyIncome);

    return (
        <Card
            backgroundColor={ColorEnum.Gray50}
            className={styles.card}
            hasPadding={false}
        >
            <div>
                <strong>{t('screening.incomeVerification.provideIncomeDetails.total.income.calculation')}</strong>
            </div>
            <div>
                <Trans
                    i18nKey="screening.incomeVerification.provideIncomeDetails.total.income.calculation.monthly.income"
                    values={{
                        monthlyIncome: `${formatPrice(monthlyIncome)}`,
                    }}
                    components={{strong: <strong/>}}
                />
            </div>
            <div>
                <Trans
                    i18nKey="screening.incomeVerification.provideIncomeDetails.total.income.calculation.annual.income"
                    values={{
                        annualIncome: `${formatPrice(annualIncome)}`,
                    }}
                    components={{strong: <strong/>}}
                />
            </div>
        </Card>
    );
}

export default EarningsCalculator;

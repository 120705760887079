import api from '../../utils/api';

export interface FindIDVerificationRedirectSuccessResponse {
    redirect: string;
}

export interface FindIDVerificationRedirectErrorResponse {
    error: string;
}

export type FindIDVerificationRedirectResponse = FindIDVerificationRedirectSuccessResponse | FindIDVerificationRedirectErrorResponse;

const findIDVerificationRedirect = async (homeseekerId: string): Promise<FindIDVerificationRedirectResponse> => {
    return await api.getWithoutAuth(
        `api/screening/${homeseekerId}/id-verification/redirect`,
        undefined,
        {
            'Content-Type': 'application/json',
        },
    );
}

export default findIDVerificationRedirect;

import api, { BodyData } from '../../../../utils/api';

export interface SubmitIncomeSourceFreelancePayload extends BodyData {
    companyName: string;
    startDate: string;
    grossIncome: number;
    chamberOfCommerceNumber: string | null;
    grossIncomeRemark: string | null;
}

const submitIncomeDetailsFreelance = async (homeseekerId: string, sourceId: string, data: SubmitIncomeSourceFreelancePayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/screening/${homeseekerId}/income-verification/${sourceId}/save/freelance`, data);
}

export default submitIncomeDetailsFreelance;

import React, { ReactNode, useEffect, useRef, useState, ChangeEvent } from 'react';
import { classNames } from '../../utils';
import styles from './Select.module.css';
import { Dropdown } from '../dropdown';
import CheckLineIcon from '../../atoms/icons/CheckLineIcon';
import ButtonNew from '../../molecules/button/Button';
import ArrowDownSLineIcon from '../../atoms/icons/ArrowDownSLineIcon';
import LoadingSpinner from '../../atoms/loading-spinner';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { useTranslation } from 'react-i18next';
import dropdownStyles from '../dropdown/Dropdown.module.css';
import Label from '../../atoms/label/Label';
import { ButtonVariant } from '../../molecules/button/ButtonVariant';

export interface Item {
    label: string | ReactNode;
    value: string | null;
    selectable?: boolean;
    disabled?: boolean;
    scrollTo?: boolean;
    hidden?: boolean;
}

export type SelectVariant = 'secondary-destructive' | 'primary' | 'secondary-gray';

interface BaseSelectProps {
    className?: string;
    buttonClassName?: string;
    cursorPointer?: boolean;
    variant?: ButtonVariant | SelectVariant;
    items: Item[];
    isLoadingItems?: boolean;
    label?: ReactNode;
    name?: string;
    id?: string;
    placeholder?: string;
    iconBefore?: ReactNode;
    subText?: ReactNode;
    isOpen?: boolean;
    hasInputField?: boolean;
    fullWidth?: boolean;
    align?: 'left' | 'right';
    unselectable?: boolean;
    disabled?: boolean;
    onChange: (item: Item | null) => void;
    onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    renderCustomLabel?: () => ReactNode;
    renderCustomSelected?: (item: string | string[]) => ReactNode;
    renderDropdownHeader?: () => ReactNode;
    renderCustomOptionLabel?: (item: Item) => ReactNode;
    showArrowIcon?: boolean;
    required?: boolean;
}

interface DefaultSelectProps extends BaseSelectProps {
    multiple?: false;
    selected?: string | null;
}

interface MultiSelectProps extends BaseSelectProps {
    multiple: true;
    selected?: (string | null)[];
}

type SelectProps = DefaultSelectProps | MultiSelectProps;

export const findItemByValue = (items: Item[], value: Item['value']): undefined | Item => {
    return items.find(item => item.value === value);
}

const Select = ({
    className,
    buttonClassName,
    cursorPointer = false,
    variant = 'secondary-gray',
    items,
    isLoadingItems = false,
    label,
    name,
    id,
    placeholder,
    multiple = false,
    selected,
    iconBefore,
    subText,
    isOpen = false,
    hasInputField = false,
    fullWidth = false,
    align = 'left',
    unselectable = false,
    disabled = false,
    onChange,
    onInputChange,
    renderCustomLabel,
    renderCustomSelected,
    renderDropdownHeader,
    renderCustomOptionLabel,
    showArrowIcon = true,
    required = false,
}: SelectProps) => {
    const { t } = useTranslation();

    const selectRef = useRef<HTMLSelectElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLUListElement>(null);
    const selectDivRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [dropdownIsActive, setDropdownIsActive] = useState(isOpen);
    const [previousSelected, setPreviousSelected] = useState<string | string[] | null>(selected);

    const hasIconBeforeClassName = () => {
        return (iconBefore !== undefined) ? styles.hasIconBefore : '';
    }

    useEffect(() => {
        document.addEventListener('pointerdown', handleOutsideClick);
        document.addEventListener('focusin', handleOutsideFocus);

        return () => {
            document.removeEventListener('pointerdown', handleOutsideClick);
            document.removeEventListener('focusin', handleOutsideFocus);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        inputRef.current?.addEventListener('blur', () => handleBlur());

        return () => {
            inputRef.current?.removeEventListener('blur', () => handleBlur());
        };
    }, [inputRef.current]);

    useEffect(() => {
        buttonRef.current?.addEventListener('blur', () => handleBlur());

        return () => {
            buttonRef.current?.removeEventListener('blur', () => handleBlur());
        };
    }, [buttonRef.current]);

    useEffect(() => {
        if (selectRef.current && previousSelected !== selected) {
            const event = new Event('change', { bubbles: true });
            selectRef.current.dispatchEvent(event);
        }
        setPreviousSelected(selected);
    }, [selected]);

    useEffect(() => {

        if (dropdownIsActive === true) {
            inputRef.current?.focus();
            buttonRef.current?.focus();

            const scrollToElement: HTMLLIElement | null = itemsRef.current?.querySelector(`[data-scroll-to="true"]`);
            const selectedElement: HTMLLIElement | null = itemsRef.current?.querySelector(`.${styles.selected}`);

            scrollToItem(selectedElement ? selectedElement : scrollToElement);
        } else {
            buttonRef.current?.blur();
            inputRef.current?.blur();
        }
    }, [dropdownIsActive]);

    const scrollToItem = (element: HTMLLIElement | null) => {
        if (!dropdownIsActive || items.length === 0 || element === null) {
            return;
        }

        const selectDropdownElement: HTMLDivElement = dropdownRef.current.querySelector(`.${dropdownStyles.dropdownCard}`);
        const itemsListElement = itemsRef.current;

        if (selectDropdownElement.offsetHeight >= itemsListElement.offsetHeight) {
            return;
        }

        selectDropdownElement.scrollTop = element.offsetTop;
    };

    const handleResize = () => {
        const dropdownCard: HTMLDivElement = dropdownRef.current.querySelector(`.${dropdownStyles.dropdownCard}`);

        if (dropdownRef && dropdownCard) {
            if (window.innerHeight < 530) {
                dropdownRef.current.style.bottom = `110%`;
                dropdownRef.current.style.transform = `none`;
            } else {
                dropdownRef.current.style.bottom = `calc(-1 * var(--spacing-sm))`;
                dropdownRef.current.style.transform = `translateY(100%)`;
            }

            if (dropdownRef.current.getBoundingClientRect().bottom > window.innerHeight) {
                const oldHeight = parseInt(dropdownCard.style.maxHeight);

                dropdownCard.style.maxHeight = `${Math.floor((oldHeight - (dropdownRef.current.getBoundingClientRect().bottom - window.innerHeight))) - 20}px`;
            } else if((window.innerHeight - dropdownRef.current.getBoundingClientRect().bottom) > 30) {
                dropdownCard.style.maxHeight = '400px';
            }

            if (dropdownRef.current.getBoundingClientRect().top < 0) {
                const oldHeight = parseInt(dropdownCard.style.maxHeight);

                dropdownCard.style.maxHeight = `${Math.floor((oldHeight - (window.innerHeight - dropdownRef.current.getBoundingClientRect().bottom) - 40))}px`;
            }
        }
    }

    const handleOutsideClick = (e: PointerEvent) => {
        handleResize();
        if (!selectDivRef.current?.contains(e.target as Node)) {
            setDropdownIsActive(false);
        }
    };

    const handleBlur = (force = false) => {
        setTimeout(() => {
            if (
                (document.activeElement.tagName !== 'BODY' && !selectDivRef.current?.contains(document.activeElement))
                || force === true
            ) {
                const highlightedElement: HTMLLIElement | null = itemsRef.current?.querySelector(`.${styles.highlighted}`) ?? null;

                if (highlightedElement) {
                    onChange(findItemByValue(items, highlightedElement.getAttribute('data-value')));
                }

                setDropdownIsActive(false);
            }
        }, 0);
    }

    const handleOutsideFocus = () => {
        if (selectDivRef.current?.contains(document.activeElement)) {
            handleResize();
            setDropdownIsActive(true);
        } else {
            setDropdownIsActive(false);
        }
    }

    const isSelected = (item: Item): boolean => {
        return (
            Array.isArray(selected)
            && !!selected?.includes(item.value)
        ) || (
            !Array.isArray(selected)
            && selected === item.value
        );
    }

    const renderHiddenSelect = (): ReactNode => {
        return (
            <select
                ref={selectRef}
                id={id}
                name={name}
                multiple={multiple}
                value={selected}
                onChange={() => {/** */}}
                required={required}
                hidden
            >
                {placeholder && <option value=""></option>}
                {items.map((item, number) => {
                    return (
                        <option
                            key={number}
                            value={item.value}
                        >{item.value}</option>
                    )
                })}
            </select>
        )
    }

    const renderSelected = (): string | ReactNode => {
        if (!multiple && hasInputField) {
            return;
        }

        if (selected === undefined || selected?.length === 0) {
            if (placeholder) {
                return (
                    <div className={styles.Placeholder}>{placeholder}</div>
                );
            }

            return (
                <>&nbsp;</>
            );
        }

        if (Array.isArray(selected)) {
            return items.filter((item, index) => item.value === selected[index])
                .map(item => item.label ? item.label : item.value)
                .join(', ');
        }

        return items.find((item) => item.value === selected)?.label;
    }

    const handleSelectClick = (): void => {
        if (inputRef.current) {
            inputRef.current.select();
            handleResize();
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const inputValue = e.target.value;

        const itemsRefChildren = itemsRef.current.children;

        let foundMatch = false;

        for (let i = 0; i < itemsRefChildren.length; i++) {
            const item: HTMLLIElement = (itemsRefChildren[i] as HTMLLIElement);

            if (inputValue !== '' &&
                (
                    item.textContent?.toLowerCase().includes(inputValue.toLowerCase())
                    || item.getAttribute('data-value')?.toLowerCase().includes(inputValue.toLowerCase())
                )
                && !foundMatch
            ) {
                item.classList.add(styles.highlighted)
                scrollToItem(item);
                foundMatch = true;
            } else {
                item.classList.remove(styles.highlighted);
            }
        }

        onChange(null);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>): void {
        const currentItemRef = itemsRef.current;

        if (!currentItemRef) {
            return;
        }

        const highlightedElement: HTMLLIElement | null = currentItemRef.querySelector(`.${styles.highlighted}`);

        if (e.key === 'Enter' || e.key === 'Escape') {
            e.preventDefault();

            if (highlightedElement === null) {
                handleBlur(true);
            } else {
                onChange(findItemByValue(items, highlightedElement.getAttribute('data-value')));
                handleBlur(true);
            }
        }

        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault();
            handleArrowsKeyDown(e, highlightedElement);
        }
    }

    function handleArrowsKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>, highlightedElement: HTMLLIElement | null): void {
        if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
            return;
        }

        if (selected && highlightedElement === null) {
            highlightedElement = itemsRef.current.querySelector(`.${styles.selected}`);
        }

        let item = null;

        if (highlightedElement === null) {
            item = Array.from(itemsRef.current.children).find(
                (li) => li.tagName === 'LI' && !li.classList.contains(styles.disabled)
            ) as HTMLLIElement | null;

            if (item === null) {
                return;
            }

            if (!multiple) {
                if (inputRef.current) {
                    inputRef.current.value = item.textContent as string;
                }
            }

            item.classList.add(styles.highlighted)
            scrollToItem(item);

            return;
        }

        const nextItem: HTMLLIElement | null = highlightedElement.nextElementSibling as HTMLLIElement | null;
        const previousItem: HTMLLIElement | null = highlightedElement.previousElementSibling as HTMLLIElement | null;
        item = nextItem;

        if (e.key === 'ArrowDown') {
            item = nextItem;
        }

        if (e.key === 'ArrowUp') {
            item = previousItem;
        }

        if (item === null) {
            return;
        }

        if (item.classList.contains(styles.disabled)) {
            return;
        }

        highlightedElement.classList.remove(styles.highlighted);
        if (!multiple) {
            if (inputRef.current) {
                inputRef.current.value = item.textContent as string;
            }
        }

        item.classList.add(styles.highlighted)
        scrollToItem(item);
    }

    const renderInput = (): ReactNode => {
        if (!hasInputField) {
            return;
        }
        if (multiple) {
            return (
                <div className={classNames(styles.SelectInput, disabled && styles.disabled)}>
                    <input
                        ref={inputRef}
                        className={styles.input}
                        placeholder={placeholder}
                        onChange={onInputChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                </div>
            );
        }

        return (
            <div className={classNames(styles.SelectInput, disabled && styles.disabled)}>
                <input
                    ref={inputRef}
                    className={classNames(styles.input, disabled && styles.disabled)}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                />
            </div>
        );
    }

    const handleIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setDropdownIsActive(!dropdownIsActive);
    }

    const renderSelect = (): ReactNode => {
        if (renderCustomSelected || hasInputField) {
            return (
                <div
                    className={classNames(styles.CustomButton, variant && styles[variant], cursorPointer && styles.cursorPointer, disabled && styles.disabled)}
                    onClick={handleSelectClick}
                >
                    <div className={classNames(styles.SelectWrapper, hasIconBeforeClassName())}>
                        {iconBefore &&
                            <div className={styles.iconBeforeWrapper}>
                                {iconBefore}
                            </div>
                        }
                        {renderCustomSelected ? renderCustomSelected?.(selected) : renderSelected()}
                        {renderInput()}
                    </div>
                    {showArrowIcon &&
                        <div className={styles.iconAfter} onClick={handleIconClick}>
                            <ArrowDownSLineIcon className={styles.arrowDownIcon}/>
                        </div>
                    }
                </div>
            );
        }

        return (
            <ButtonNew
                className={classNames(buttonClassName && buttonClassName, styles.Button, disabled && styles.disabled)}
                ref={buttonRef}
                iconBefore={iconBefore}
                iconAfter={
                    showArrowIcon && (
                        <ArrowDownSLineIcon className={styles.arrowDownIcon} />
                    )
                }
                variant={variant}
                onClick={handleSelectClick}
                onKeyDown={(e) => handleKeyDown(e)}
            >
                <div className={styles.SelectWrapper}>
                    {renderSelected()}
                </div>
            </ButtonNew>
        )
    }

    const handleLabelClick = () => {
        setDropdownIsActive(true);
        handleResize();
    }

    const handleItemClick = (e: React.MouseEvent, item?: Item | null) =>  {
        e.preventDefault();

        if (item === null || item === undefined) {
            onChange(null);
        }

        if (item.selectable === false || item.disabled) {
            return;
        }

        if (multiple) {
            e.stopPropagation();
        } else {
            setDropdownIsActive(false);
            if (hasInputField) {
                const htmlitem = Array.from(itemsRef.current.children).find(
                    (li) => li.tagName === 'LI' && !li.classList.contains(styles.disabled) && li.getAttribute('data-value') === item.value
                ) as HTMLLIElement | null;

                if (item === null) {
                    return;
                }

                if (!multiple) {
                    if (inputRef.current) {
                        inputRef.current.value = htmlitem.textContent as string;
                    }
                }
                handleBlur(true)
                onChange(item);
            }
        }

        onChange(item);
    }

    const renderOptionLabel = (item: Item): ReactNode => {
        if (renderCustomOptionLabel) {
            return renderCustomOptionLabel(item);
        }

        return item.label;
    }

    const renderOption = (item: Item, index: number): ReactNode => {
        const optionClassNames = classNames(
            styles.item,
            isSelected(item) && styles.selected,
            item.selectable === false ? styles.unselectable : '',
            item.disabled === true ? styles.disabled : '',
            item.hidden === true ? styles.hidden : ''
        );

        return (
            <li
                key={index}
                className={optionClassNames}
                onClick={(e) => handleItemClick(e, item)}
                data-scroll-to={item.scrollTo}
                data-value={item.value}
            >
                {renderOptionLabel(item)}
                {item.selectable !== false &&
                    <CheckLineIcon className={styles.checkLineIcon}/>
                }
            </li>
        )
    }

    const renderOptions = (): ReactNode => {
        return (
            <div>
                {(!isLoadingItems && (renderDropdownHeader !== undefined)) &&
                    <div className={styles.DropdownHeader}>{renderDropdownHeader()}</div>
                }
                <ul
                    ref={itemsRef}
                    className={styles.items}
                >
                    {isLoadingItems ? (
                        <li className={classNames(styles.item, styles.loadingItem)}>
                            <LoadingSpinner/>
                        </li>
                    ) : (
                        <>
                            {items.map(renderOption)}
                        </>
                    )}
                </ul>
            </div>
        )
    }

    const renderLabel = (): ReactNode => {
        if (renderCustomLabel) {
            return (
                <div
                    onClick={handleLabelClick}
                >{renderCustomLabel()}</div>
            )
        }

        if (label === undefined) {
            return <></>;
        }

        return (
            <Label
                htmlFor={id}
                onClick={handleLabelClick}
            >
                {label}
            </Label>
        );
    }

    return (
        <>
            <div
                className={classNames(className && className, styles.Select, dropdownIsActive && styles.isOpen, fullWidth && styles.fullWidth)}
                ref={selectDivRef}
            >
                {renderLabel()}
                <div
                    className={classNames(styles.SelectContainer, disabled && styles.disable)}
                >
                    {renderHiddenSelect()}
                    {renderSelect()}
                    <Dropdown
                        ref={dropdownRef}
                        fullWidth={fullWidth}
                        isActive={dropdownIsActive}
                        align={align}
                        unselectable={unselectable}
                    >
                        {renderOptions()}
                    </Dropdown>
                </div>
                {subText &&
                    <Text
                        tag="p"
                        color={ColorEnum.Gray500}
                        size={FontSizeEnum.Sm}
                        weight={FontWeightEnum.Light}
                    >
                        {t('candidate.action.invite.viewing.homeseekerSelector.subText')}
                    </Text>
                }
            </div>
        </>
    );
};

export default Select;

import {ReactNode, useEffect, useRef, useState} from 'react';
import styles from './Tooltip.module.css';
import {classNames} from '../../utils';

type VerticalPosition = 'top' | 'bottom';

type HorizontalPosition = 'middle' | 'left' | 'right';

export type TooltipSize = 'sm' | 'md' | 'lg' | 'auto';

interface TooltipProps {
    readonly size?: TooltipSize;
    readonly icon: ReactNode;
    readonly children: ReactNode | string;
}

const Tooltip = ({
    size = 'auto',
    icon,
    children,
}: TooltipProps) => {
    const anchorRef = useRef(null);
    const tooltipRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipClassNames, tooltipSetClassNames] = useState(classNames(styles.tooltip, styles.top));

    const calcTooltipVerticalPosition = (anchorRect: DOMRect, tooltipRect: DOMRect): VerticalPosition => {
        const windowHeight = window.innerHeight;
        const tooltipOffsetY = 12;

        const spaceTop = anchorRect.top - tooltipOffsetY;
        if (spaceTop >= tooltipRect.height) {
            return 'top';
        }

        const spaceBottom = windowHeight - anchorRect.bottom - tooltipOffsetY;
        if (spaceBottom >= tooltipRect.height || spaceBottom > spaceTop) {
            return 'bottom';
        }

        return 'top';
    }

    const calcTooltipHorizontalPosition = (anchorRect: DOMRect, tooltipRect: DOMRect): HorizontalPosition => {
        const windowWidth = window.innerWidth;

        const spaceLeft = anchorRect.left + anchorRect.width/2;
        const spaceRight = windowWidth - (anchorRect.right + anchorRect.width/2);

        if (spaceLeft < tooltipRect.width/2) {
            return 'right';
        }

        if (spaceRight < tooltipRect.width/2) {
            return 'left';
        }

        return 'middle';
    }

    const updateTooltipClassNames = () => {
        const anchorRect: DOMRect = anchorRef?.current?.getBoundingClientRect();
        const tooltipRect: DOMRect = tooltipRef?.current?.getBoundingClientRect();

        const verticalPosition: VerticalPosition = calcTooltipVerticalPosition(anchorRect, tooltipRect);
        const horizontalPosition: HorizontalPosition = calcTooltipHorizontalPosition(anchorRect, tooltipRect);

        const newTooltipClassNames = classNames(
            styles.tooltip,
            styles[size],
            styles[verticalPosition],
            horizontalPosition !== 'middle' && styles[horizontalPosition],
            showTooltip && styles.show,
        );

        tooltipSetClassNames(newTooltipClassNames);
    };

    useEffect(() => {
        updateTooltipClassNames();
    }, [showTooltip]);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    const openTooltip = () => {
        setShowTooltip(true);
    };

    const closeTooltip = () => {
        setShowTooltip(false);
    };

    return (
        <div ref={anchorRef} className={styles.tooltipContainer}>
            <div
                className={styles.iconContainer}
                onTouchEnd={toggleTooltip}
                onMouseEnter={openTooltip}
                onMouseLeave={closeTooltip}
            >
                {icon}
            </div>
            <div
                ref={tooltipRef}
                className={tooltipClassNames}
            >
                {children}
            </div>
        </div>
    );
};

export default Tooltip;

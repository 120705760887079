import IncomeVerificationIncomeSourceTypeEnum from '../../../../componentsNew/enums/screening/IncomeVerificationIncomeSourceTypeEnum';
import submitIncomeDetailsBusinessOwnership, { SubmitIncomeSourceBusinessOwnershipPayload } from './submitIncomeDetailsBusinessOwnership';
import submitIncomeDetailsEmployment, { SubmitIncomeSourceEmploymentPayload } from './submitIncomeDetailsEmployment';
import submitIncomeDetailsFreelance, { SubmitIncomeSourceFreelancePayload } from './submitIncomeDetailsFreelance';

export type SubmitIncomeDetailsPayload = SubmitIncomeSourceEmploymentPayload | SubmitIncomeSourceFreelancePayload | SubmitIncomeSourceBusinessOwnershipPayload;

const submitIncomeDetails = async (type: IncomeVerificationIncomeSourceTypeEnum, homeseekerId: string, sourceId: string, data: SubmitIncomeDetailsPayload): Promise<unknown> => {
    switch (type) {
        case IncomeVerificationIncomeSourceTypeEnum.Employment:
            return await submitIncomeDetailsEmployment(homeseekerId, sourceId, data as SubmitIncomeSourceEmploymentPayload);
        case IncomeVerificationIncomeSourceTypeEnum.Freelance:
            return await submitIncomeDetailsFreelance(homeseekerId, sourceId, data as SubmitIncomeSourceFreelancePayload);
        case IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership:
            return await submitIncomeDetailsBusinessOwnership(homeseekerId, sourceId, data as SubmitIncomeSourceBusinessOwnershipPayload);
        default:
            break;
    }
}

export default submitIncomeDetails;

import { MouseEvent } from 'react';
import styles from './AddIncomeButton.module.css';
import { FeaturedIcon } from '../../atoms/featured-icon';
import Text from '../../atoms/typography/Text';
import AddLineIcon from '../../atoms/icons/AddLineIcon';
import { FontWeightEnum } from '../../enums';
import { useTranslation } from 'react-i18next';

interface AddIncomeButtonProps {
    readonly onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    readonly disabled?: boolean;
}

const AddIncomeButton = ({
    onClick,
    disabled = false,
}: AddIncomeButtonProps) => {
    const { t } = useTranslation();

    return (
        <button
            className={styles.button}
            onClick={onClick}
            disabled={disabled}
        >
            <FeaturedIcon
                className={styles.featuredIcon}
                icon={<AddLineIcon />}
                size='md'
            />
            <Text weight={FontWeightEnum.Regular}>
                {t('addIncomeButton.text')}
            </Text>
        </button>
    );
};

export default AddIncomeButton;

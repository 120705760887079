import styles from './ContactRequestPage.module.css';
import Text from '../../components/typography/text/Text';
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum';
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../enums/colorEnum';
import { GUARANTOR, HOUSEHOLD_TYPE, SALUTATION } from '../../utils/constants';
import TextInput from '../../components/forms/textInput/TextInput';
import DateInput from '../../components/atoms/dateInput/DateInput';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import ContactRequestFormData from './ContactRequestFormData';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from '../../components/forms/checkbox/Checkbox';
import { LogoLeadflowPoweredBy } from '../../components/logo/LogoLeadflowPoweredBy';
import { TextArea } from '../../componentsNew/atoms/text-area';
import ButtonNew from '../../componentsNew/molecules/button/Button';
import Form from '../../componentsNew/molecules/form/Form';
import FormError from '../../componentsNew/molecules/form/FormError';
import HouseholdType from '../../components/candidateData/candidateProfileInfo/logic/models/householdType';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../redux/actions/ui/setNotification';
import submitContactRequestForm, { findListingByIdPayload } from '../../api/homeseeker/submitContactRequestForm';

interface ContactRequestFormProps {
    readonly contactRequestFormData: ContactRequestFormData;
    readonly setContactRequestFormData: React.Dispatch<React.SetStateAction<ContactRequestFormData>>;
    readonly handleSubmit: () => void;
    readonly householdClass: string;
    readonly agent: undefined | { name: string, privacyPolicy: string }
    readonly isSubmitButtonDisabled: boolean;
}

export default function ContactRequestForm(props: ContactRequestFormProps) {

    const { t, i18n } = useTranslation();
    const listingId = useParams().id;
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [, setIsFormValid] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [payload, setPayload] = useState<findListingByIdPayload>();


    function getHouseholdClass(): string {
        if (props.householdClass === '') {
            return styles.householdDefault + ' ' + props.householdClass;
        }
        return `${styles.householdDefault} ${props.householdClass} ${styles.formRow} ${styles.fullWidth}`;
    }

    function getIncomeOptions() {
        const options = [];
        for (let i = 0; i <= 19; i++) {
            const rangeStart = i * 500;
            const range = `€ ${rangeStart + (i === 0 ? 0 : 1)} - ${rangeStart + 500}`
            options.push(
                <option key={i + 1} value={rangeStart + 500}>
                    {range} {t('contactRequestPage.form.group.additional.income.perMonth')}
                </option>
            );
        }
        options.push(
            <option key={options.length + 1} value={options.length * 500 + 1}>
                € 10.000+ {t('contactRequestPage.form.group.additional.income.perMonth')}
            </option>
        );
        return options;
    }

    function getHouseholdOptions() {
        const options = [];
        for (let i = 1; i <= 10; i++) {
            options.push(
                <option key={i} value={i.toString()}>
                    {i}
                </option>
            );
        }
        return options;
    }

    useEffect(() => {
        setPayload({
            transactionalListingId: listingId,
            locale: i18n.language,
            salutation: formData['gender']?.value,
            fullName: formData['fullName']?.value,
            birthDate: formData['birthDate']?.value,
            age: formData['age']?.value,
            email: formData['email']?.value,
            phoneNumber: formData['phone']?.value,
            employment: formData['employment']?.value,
            income: parseInt(formData['income']?.value),
            guarantor: formData['guarantor']?.value,
            householdType: formData['householdType']?.value,
            household: formData['household']?.value,
            hasPets: (formData['pets']?.value) === 'true',
            rentalStartDate: formData['rentalStartDate']?.value,
            rentalPeriod: parseInt(formData['term']?.value),
            motivation: formData['motivation']?.value,
            dataSharingAccepted: (formData['dataSharingAccepted']?.value === 'true'),
            privacyPolicyAccepted: (formData['terms']?.value === 'true')
        });
    }, [formData]);

    const handleFormChange = (data, isValid, errors) => {
        setFormData(data);
        setIsFormValid(isValid);
        setFormErrors(errors);
    }

    const handleSubmit = async () => {
        try {
            await submitContactRequestForm(payload);
        } catch (error) {
            dispatch(setNotification(
                {
                    id: Date.now(),
                    message: t('contactRequestPage.form.validation.toast.failed'),
                    type: 'failed'
                }
            ));

            return;
        }

        props.handleSubmit();
    }

    return (
        <>
            <Form data-testid={'contactRequestForm'} onChange={handleFormChange} onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.personal.title')}</Text>
                    <div className={`${styles.formRow} ${styles.oneThirdTwoThirds}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.salutation.label')}</Text>
                            <select
                                id="gender"
                                name="gender"
                                value={formData['gender']?.value}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({ salutation: e.target.value }))}
                                className="mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                            >
                                <option
                                    value={SALUTATION.UNSET}>{t('contactRequestPage.form.group.personal.salutation.gender.UNSET')}</option>
                                <option
                                    value={SALUTATION.MALE}>{t('contactRequestPage.form.group.personal.salutation.gender.MALE')}</option>
                                <option
                                    value={SALUTATION.FEMALE}>{t('contactRequestPage.form.group.personal.salutation.gender.FEMALE')}</option>
                            </select>
                        </div>
                        <FormError errors={formErrors['gender']} />
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.fullName.label')}*</Text>
                            <TextInput
                                value={formData['fullName']?.value}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({ fullName: (e.target as HTMLInputElement).value }))}
                                name={'fullName'}
                                pattern="^[\p{L}]+(?:\s*-\s*[\p{L}]+)*(?:\s+[\p{L}]+(?:\s*-\s*[\p{L}]+)*)+$"
                                placeholder={t('contactRequestPage.form.group.personal.fullName.placeholder')}
                                required
                            />
                            <FormError errors={formErrors['fullName']} />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.twoThirdsOneThird}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.birthday.label')}</Text>
                            <DateInput
                                value={moment(formData['birthDate']?.value)}
                                maximumValue={moment()}
                                name="birthDate"
                                id={'birthday'}
                            />
                            <FormError errors={formErrors['birthDate']} />
                        </div>
                        <div>
                            <Text size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray800}>{t('contactRequestPage.form.group.personal.age.label')}</Text>
                            <TextInput
                                required
                                value={moment().diff(moment(formData['birthDate']?.value).format('yyyy-MM-DD'), 'year').toString()}
                                name={'age'}
                                isDisabled={true}
                            />
                        </div>
                        <FormError errors={formErrors['age']} />
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.email.label')}*</Text>
                            <TextInput
                                required
                                type='email'
                                value={formData['email']?.value}
                                name={'email'}
                                placeholder={t('contactRequestPage.form.group.personal.email.placeholder')}
                            />
                            <FormError errors={formErrors['email']} />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.personal.phone.label')}</Text>
                            <TextInput
                                value={formData['phone']?.value}
                                name={'phone'}
                                pattern="^(\+?[1-9]\d{9,14})|(0\d{9})$"
                                minLength={7}
                                maxLength={20}
                            />
                        </div>
                        <FormError errors={formErrors['phone']} />
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.additional.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.employment.label')}</Text>
                            <select
                                required
                                value={formData['employment']?.value}
                                id="employment"
                                name="employment"
                                className="mt-1 py-3 block w-full pl-3 pr-10 text-base border-gray-300
                                                        focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md
                                                        required:invalid:text-gray-500"
                            >
                                <option value="" disabled
                                    hidden>{t('contactRequestPage.form.group.additional.employment.NONE')}</option>
                                <option
                                    value="EMPLOYER">{t('contactRequestPage.form.group.additional.employment.EMPLOYED_BY_EMPLOYER')}</option>
                                <option
                                    value="ENTREPRENEUR">{t('contactRequestPage.form.group.additional.employment.ENTREPRENEUR')}</option>
                                <option
                                    value="STUDENT">{t('contactRequestPage.form.group.additional.employment.STUDENT')}</option>
                                <option
                                    value="RETIRED">{t('contactRequestPage.form.group.additional.employment.RETIRED')}</option>
                                <option
                                    value="NONE">{t('contactRequestPage.form.group.additional.employment.UNEMPLOYED')}</option>
                            </select>
                        </div>
                        <FormError errors={formErrors['employment']} />
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.income.label')}</Text>
                            <select
                                name="income"
                                id="income"
                                value={formData['income']?.value}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary
                                block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                required:invalid:text-gray-500"
                                required
                            >
                                <option value="" disabled
                                    hidden> {t('enriching.form.income.SELECT_INCOME')}</option>
                                <option value={0}>€ 0 {t('contactRequestPage.form.group.additional.income.perMonth')}</option>
                                {getIncomeOptions()}
                            </select>
                            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}><Trans
                                i18nKey="contactRequestPage.form.group.additional.income.info" /></Text>
                        </div>
                        <FormError errors={formErrors['income']} />
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.guarantor.label')}</Text>
                            <select
                                required
                                name="guarantor"
                                id="guarantor"
                                value={formData['guarantor']?.value}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full
                                                    shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500 truncate "
                            >
                                <option value={GUARANTOR.NO_GUARANTOR}>
                                    {t('enriching.form.guarantor.NO_GUARANTOR')}
                                </option>
                                <option value={GUARANTOR.IN_NETHERLANDS}>
                                    {t('enriching.form.guarantor.IN_NETHERLANDS')}
                                </option>
                                <option value={GUARANTOR.ABROAD}>
                                    {t('enriching.form.guarantor.ABROAD')}
                                </option>
                            </select>
                            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}><Trans
                                i18nKey="contactRequestPage.form.group.additional.guarantor.info" /></Text>
                        </div>
                        <FormError errors={formErrors['guarantor']} />
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.householdType.label')}</Text>
                            <select
                                required
                                name="householdType"
                                id="householdType"
                                value={formData['householdType']?.value}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({ householdType: (e.target.value as HouseholdType) }))}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md
                                                    required:invalid:text-gray-500"
                            >
                                <option value="" disabled
                                    hidden> {t('contactRequestPage.form.group.additional.householdType.SELECT_HOUSEHOLD')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.SINGLE}>{t('contactRequestPage.form.group.additional.householdType.SINGLE')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.WITH_PARTNER}>{t('contactRequestPage.form.group.additional.householdType.WITH_PARTNER')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.WITH_FAMILY}>{t('contactRequestPage.form.group.additional.householdType.WITH_FAMILY')}</option>
                                <option
                                    value={HOUSEHOLD_TYPE.WITH_FRIENDS}>{t('contactRequestPage.form.group.additional.householdType.WITH_FRIENDS')}</option>
                            </select>
                        </div>
                        <FormError errors={formErrors['householdType']} />
                    </div>
                    <div className={getHouseholdClass()}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.additional.household.label')}</Text>
                            <select
                                required
                                name="household"
                                id="household"
                                value={formData['household']?.value}
                                className="mt-1 py-3 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md required:invalid:text-gray-500"
                            >
                                <option value="" disabled hidden>
                                    {t('enriching.form.household.SELECT_MEMBERS')}
                                </option>
                                {getHouseholdOptions()}
                            </select>
                        </div>
                        <FormError errors={formErrors['household']} />
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <div>
                                <Text
                                    weight={FontWeightEnum.Medium}
                                    size={FontSizeEnum.Sm}
                                    color={ColorEnum.Gray700}
                                >
                                    {t('contactRequestPage.form.group.additional.pets.label')}
                                </Text>
                                <div className={`flex items-center ${styles.radioButtonWrapper}`}>
                                    <input
                                        id="pets-yes"
                                        name="pets"
                                        value="true"
                                        type="radio"
                                        className={`${styles.radioButton} focus:ring-primary text-primary border-gray-300`}
                                    />
                                    <label
                                        htmlFor="pets-yes"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        {t('contactRequestPage.form.group.additional.pets.yes')}
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        id="pets-no"
                                        name="pets"
                                        value="false"
                                        type="radio"
                                        className={`${styles.radioButton} focus:ring-primary text-primary border-gray-300`}
                                    />
                                    <label
                                        htmlFor="pets-no"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                        {t('contactRequestPage.form.group.additional.pets.no')}
                                    </label>
                                </div>
                                <FormError errors={formErrors['pets']} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.rentalInfo.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.rentalInfo.rentalStartDate.label')}</Text>
                            <DateInput
                                value={moment(formData['rentalStartDate']?.value)}
                                minimumValue={moment()}
                                name='rentalStartDate'
                                id={'rentalStartDate'}
                            />
                            <FormError errors={formErrors['rentalStartDate']} />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.label')}</Text>
                            <select
                                required
                                id="term"
                                name="term"
                                value={formData['term']?.value}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({ rentalPeriod: parseInt(e.target.value) }))}
                                className="mt-1 py-3 block w-full pl-3 pr-10 text-base border-gray-300
                                                        focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md
                                                        required:invalid:text-gray-500"
                            >
                                <option
                                    value="0">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.INDEFINITE')}</option>
                                <option
                                    value="3">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.ZERO_THREE')}</option>
                                <option
                                    value="6">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.THREE_SIX')}</option>
                                <option
                                    value="12">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.SIX_TWELVE')}</option>
                                <option
                                    value="24">{t('contactRequestPage.form.group.rentalInfo.rentalPeriod.YEAR')}</option>
                            </select>
                            <FormError errors={formErrors['term']} />
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}>{t('contactRequestPage.form.group.motivation.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Text weight={FontWeightEnum.Medium} size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray700}>{t('contactRequestPage.form.group.motivation.message.label')}</Text>
                            <div className={styles.motivationTextArea}>
                                <TextArea
                                    id='motivation'
                                    name='motivation'
                                    onChange={(id, val) => props.setContactRequestFormData(prev => prev.with({ motivation: val }))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <Text
                        weight={FontWeightEnum.Medium}
                        size={FontSizeEnum.Lg}
                        color={ColorEnum.Gray900}
                    >{t('contactRequestPage.form.group.terms.title')}</Text>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Checkbox
                                label={`${t('contactRequestPage.form.group.terms.sharing.label')} ${props.agent.name ?? ''}*`}
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({ dataSharingAccepted: e.target.checked }))}
                                id={'dataSharingAccepted'}
                                name='dataSharingAccepted'
                            />
                        </div>
                    </div>
                    <div className={`${styles.formRow} ${styles.fullWidth}`}>
                        <div>
                            <Checkbox
                                label={
                                    <span>
                                        {t('contactRequestPage.form.group.terms.privacy.label')} {props.agent.privacyPolicy &&
                                        <a
                                            target={'_blank'}
                                            href={props.agent.privacyPolicy}
                                            className="font-semibold text-primary underline"
                                            rel="noreferrer"
                                        >
                                            {t('enriching.form.privacy')}
                                        </a>}
                                    </span>
                                }
                                onChange={(e) => props.setContactRequestFormData(prev => prev.with({ privacyPolicyAccepted: e.target.checked }))}
                                id={'privacyPolicyAccepted'}
                                name='terms'
                                required
                            />
                            <FormError errors={formErrors['terms']} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={props.isSubmitButtonDisabled ? styles.submitButton : ''}>
                        <ButtonNew type='submit'>{t('contactRequestPage.form.group.submit.button')}</ButtonNew>
                    </div>
                </div>
                <div>
                    <hr className={styles.divider} />
                    <LogoLeadflowPoweredBy />
                </div>
            </Form>
        </>
    );

}

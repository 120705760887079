
import api, { BodyData } from '../../../../utils/api';

export interface SubmitIncomeSourceDocumentsFreelancePayload extends BodyData {
    annualStatement?: FileList,
    taxReturn?: FileList,
}

const submitIncomeDocumentsFreelance = async (homeseekerId: string, sourceId: string, data: SubmitIncomeSourceDocumentsFreelancePayload): Promise<unknown> => {
    return await api.postFormData(`api/screening/${homeseekerId}/income-verification/${sourceId}/save/freelance/documents`, data);
}

export default submitIncomeDocumentsFreelance;

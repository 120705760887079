import { Data, FormErrors } from '../../../../../molecules/form/Form';
import styles from './EmploymentContent.module.css'
import Text from '../../../../../atoms/typography/Text';
import { ColorEnum, FontWeightEnum } from '../../../../../enums';
import Label from '../../../../../atoms/label/Label';
import { FileUpload } from '../../../../../organisms/file-upload';
import { CheckboxInput } from '../../../../../molecules/checkbox-input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContractTypeEnum from '../../../../../enums/screening/ContractTypeEnum';
import { FindIncomeSourceEmploymentResponse } from '../../../../../../api/screening/income-verification/find-income-source-documents/findIncomeSourceEmployment';

interface EmploymentContentProps {
    storedData: FindIncomeSourceEmploymentResponse;
    formData: Data;
    formErrors: FormErrors;
    onChange: (data) => void;
    onDocumentDelete?: (name: string) => void;
}

const EmploymentContent = ({
    storedData,
    formData,
    formErrors,
    onChange,
    onDocumentDelete,
}: EmploymentContentProps) => {
    const { t } = useTranslation();

    const [allFilesRequired, setAllFilesRequired] = useState(true);

    const contractType = storedData.contractType as ContractTypeEnum;

    useEffect(() => {
        onChange({
            employmentContract: formData['employmentContract']?.value as FileList,
            employerStatement: formData['employerStatement']?.value as FileList,
            payslipOne: formData['payslipOne']?.value as FileList,
            payslipTwo: formData['payslipTwo']?.value as FileList,
            payslipThree: formData['payslipThree']?.value as FileList
        });
    }, [formData]);

    const handleFileUploadDelete = (name: string) => {
        onDocumentDelete?.(name)
    }

    return (
        <div className={styles.gridXl}>
            <div className={styles.gridMd}>
                <Text
                    color={ColorEnum.Gray800}
                    weight={FontWeightEnum.Bold}
                >{t('screening.incomeVerification.provideIncomeDetails.employment.title')}</Text>
                <Text>{t('screening.incomeVerification.provideDocuments.explanation')}</Text>
                <div>
                    <Label htmlFor={'employmentContract'} required={allFilesRequired}>
                        {t('screening.incomeVerification.provideDocuments.employmentContract')}
                    </Label>
                    <FileUpload
                        id='employmentContract'
                        name='employmentContract'
                        value={formData['employmentContract']?.value as FileList}
                        storedFileMetadata={storedData.documents['employmentContract']}
                        acceptedExtensions={['pdf']}
                        errors={formErrors['employmentContract']}
                        required={allFilesRequired}
                        onDelete={() => handleFileUploadDelete('employmentContract')}
                    ></FileUpload>
                </div>
                {contractType !== ContractTypeEnum.Indefinite && (
                    <div>
                        <Label htmlFor={'employerStatement'} required={allFilesRequired}>
                            {t('screening.incomeVerification.provideDocuments.employerStatement')}
                        </Label>
                        <FileUpload
                            id='employerStatement'
                            name='employerStatement'
                            value={formData['employerStatement']?.value as FileList}
                            storedFileMetadata={storedData.documents['employerStatement']}
                            acceptedExtensions={['pdf']}
                            errors={formErrors['employerStatement']}
                            required={allFilesRequired}
                            onDelete={() => handleFileUploadDelete('employerStatement')}
                        ></FileUpload>
                    </div>
                )}
                <div>
                    <Label htmlFor={'payslipOne'} required={allFilesRequired}>
                        {t('screening.incomeVerification.provideDocuments.payslipOne')}
                    </Label>
                    <FileUpload
                        id='payslipOne'
                        name='payslipOne'
                        value={formData['payslipOne']?.value as FileList}
                        storedFileMetadata={storedData.documents['payslipOne']}
                        acceptedExtensions={['pdf']}
                        errors={formErrors['payslipOne']}
                        required={allFilesRequired}
                        onDelete={() => handleFileUploadDelete('payslipOne')}
                    ></FileUpload>
                </div>
                <div>
                    <Label htmlFor={'payslipTwo'} required={allFilesRequired}>
                        {t('screening.incomeVerification.provideDocuments.payslipTwo')}
                    </Label>
                    <FileUpload
                        id='payslipTwo'
                        name='payslipTwo'
                        value={formData['payslipTwo']?.value as FileList}
                        storedFileMetadata={storedData.documents['payslipTwo']}
                        acceptedExtensions={['pdf']}
                        errors={formErrors['payslipTwo']}
                        required={allFilesRequired}
                        onDelete={() => handleFileUploadDelete('payslipTwo')}
                    ></FileUpload>
                </div>
                <div>
                    <Label htmlFor={'payslipThree'} required={allFilesRequired}>
                        {t('screening.incomeVerification.provideDocuments.payslipThree')}
                    </Label>
                    <FileUpload
                        id='payslipThree'
                        name='payslipThree'
                        value={formData['payslipThree']?.value as FileList}
                        storedFileMetadata={storedData.documents['payslipThree']}
                        acceptedExtensions={['pdf']}
                        errors={formErrors['payslipThree']}
                        required={allFilesRequired}
                        onDelete={() => handleFileUploadDelete('payslipThree')}
                    ></FileUpload>
                </div>
            </div>
            <CheckboxInput
                name='proceedWithoutAllDocuments'
                id='proceedWithoutAllDocuments'
                label={t('screening.incomeVerification.provideDocuments.proceedWithoutAllDocuments')}
                value='proceedWithoutAllDocuments'
                checked={!allFilesRequired}
                onChange={checked => setAllFilesRequired(!checked)}
            />
        </div>
    )
}

export default EmploymentContent;

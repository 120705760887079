import { useNavigate, useParams } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import Form, { Data, FormErrors } from '../../../../../molecules/form/Form';
import IncomeVerificationIncomeSourceTypeEnum from '../../../../../enums/screening/IncomeVerificationIncomeSourceTypeEnum';
import EmploymentContent from '../provide-documents/EmploymentContent';
import styles from './ProvideDocuments.module.css';
import Text from '../../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../../enums';
import * as DeprecatedColorEnum from '../../../../../../enums/colorEnum';
import screeningSteps, { Action } from '../../../../../organisms/screening-navigation/screeningSteps';
import { Card } from '../../../../../organisms/card';
import ScreeningNavigation from '../../../../../organisms/screening-navigation/ScreeningNavigation';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/ui/setNotification';
import SkeletonComponent from '../../../../../../components/SkeletonComponent';
import submitIncomeDocuments, { SubmitIncomeDocumentsPayload } from '../../../../../../api/screening/income-verification/submit-income-documents/submitIncomeDocuments';
import { useRoutePath } from '../../../../../utils/useRoutePath';
import findIncomeSourceDocuments, { FindIncomeSourceDocumentsResponse } from '../../../../../../api/screening/income-verification/find-income-source-documents/findIncomeSourceDocuments';
import Modal from '../../../../../../components/organisms/modal/Modal';
import ProcessRequestFeedback from '../../../../../molecules/process-request-feedback/ProcessRequestFeedback';
import ButtonNew from '../../../../../molecules/button/Button';
import { classNames } from '../../../../../utils';
import deleteIncomeSourceDocument from '../../../../../../api/screening/income-verification/delete-income-source-document/deleteIncomeSourceDocument';
import BusinessOwnershipContent from './BusinessOwnershipContent';
import FreelanceContent from './FreelanceContent';
import { FindIncomeSourceEmploymentResponse } from '../../../../../../api/screening/income-verification/find-income-source-documents/findIncomeSourceEmployment';
import { FindIncomeSourceBusinessOwnershipResponse } from '../../../../../../api/screening/income-verification/find-income-source-documents/findIncomeSourceBusinessOwnership';
import { FindIncomeSourceFreelanceResponse } from '../../../../../../api/screening/income-verification/find-income-source-documents/findIncomeSourceFreelance';

const ProvideDocuments = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const { homeseekerId, sourceId, type } = useParams();

    const [ storedData, setStoredData ] = useState<FindIncomeSourceDocumentsResponse|null>(null);
    const [ formData, setFormData ] = useState<Data>({});
    const [ formErrors, setFormErrors ] = useState<FormErrors>({});
    const [ isFetchingDocuments, setIsFetchingDocuments ] = useState<boolean>(true);
    const [ deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen ] = useState<boolean>(false);
    const [ isSubmittingDocuments, setIsSubmittingDocuments ] = useState<boolean>(false);
    const [ isSubmittingDocumentsSuccess, setIsSubmittingDocumentsSuccess ] = useState<boolean>(false);
    const [ payload, setPayload ] = useState<SubmitIncomeDocumentsPayload>(null);
    const [ incomeSourceDocumentNameToDelete, setIncomeSourceDocumentNameToDelete ] = useState<string>(null);
    const [ isDeletingIncomeSourceDocument, setIsDeletingIncomeSourceDocument ] = useState<boolean>(false);
    const [ isDeletingIncomeSourceDocumentSucces, setIsDeletingIncomeSourceDocumentSuccess ] = useState<boolean>(false);

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        try {
            const incomeSourceDocumentsResponse = await findIncomeSourceDocuments(type.replaceAll('-', ' ') as IncomeVerificationIncomeSourceTypeEnum, sourceId);
            setStoredData(incomeSourceDocumentsResponse);
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
            setStoredData(null);
        } finally {
            setIsFetchingDocuments(false);
        }
    }

    const handleDocumentDelete = (name: string) => {
        if (storedData.documents[name]) {
            setDeleteConfirmationModalIsOpen(true);
            setIncomeSourceDocumentNameToDelete(name);
        }
    }

    const renderDocumentsForm = (): ReactNode => {
        switch(type) {
            case IncomeVerificationIncomeSourceTypeEnum.Employment:
                return (
                    <EmploymentContent
                        storedData={storedData as FindIncomeSourceEmploymentResponse}
                        formData={formData}
                        formErrors={formErrors}
                        onChange={setPayload}
                        onDocumentDelete={handleDocumentDelete}
                    />
                );
            case IncomeVerificationIncomeSourceTypeEnum.Freelance:
                return (
                    <FreelanceContent
                        storedData={storedData as FindIncomeSourceFreelanceResponse}
                        formData={formData}
                        formErrors={formErrors}
                        onChange={setPayload}
                        onDocumentDelete={handleDocumentDelete}
                    />
                );
            case IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership:
                return (
                    <BusinessOwnershipContent
                        storedData={storedData as FindIncomeSourceBusinessOwnershipResponse}
                        formData={formData}
                        formErrors={formErrors}
                        onChange={setPayload}
                        onDocumentDelete={handleDocumentDelete}
                    />
                );
            default:
                return null;
        }
    }

    const handleFormDataChange = (data: Data, _isValid: boolean, errors: FormErrors) => {
        setFormData(data);
        setFormErrors(errors);
    }

    const handleClick = (action: Action) => {
        if (action === 'previous') {
            navigate(routePath('ScreeningIncomeVerificationProvideIncomeDetails', { homeseekerId, sourceId, type }));
        }
    }

    const deleteSelectedDocument = async () => {
        setIsDeletingIncomeSourceDocument(true);
        setIsDeletingIncomeSourceDocumentSuccess(false);

        const startTime = Date.now();

        try {
            await deleteIncomeSourceDocument(incomeSourceDocumentNameToDelete, sourceId);

            const filename = storedData.documents[incomeSourceDocumentNameToDelete].originalFileName;

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsDeletingIncomeSourceDocument(false);
            setIsDeletingIncomeSourceDocumentSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            setDeleteConfirmationModalIsOpen(false);

            await new Promise(resolve => setTimeout(resolve, 300));

            const documentKeyToDelete = incomeSourceDocumentNameToDelete;

            setIncomeSourceDocumentNameToDelete(null);

            const newDocuments = {} as FindIncomeSourceDocumentsResponse['documents'];

            for (const [key] of Object.entries(storedData.documents)) {
                if (key !== documentKeyToDelete) {
                    newDocuments[key] = storedData.documents[key];
                }
            }

            setStoredData({
                ...storedData,
                documents: newDocuments as unknown
            });

            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: (
                    <Trans
                        i18nKey="screening.incomeVerification.provideDocuments.deletion.modal.notification.success"
                        values={{ filename }}
                        components={{strong: <strong/>}}
                    />
                )
            }));
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('screening.incomeVerification.provideDocuments.deletion.modal.notification.failed')
            }));
        } finally {
            setIsDeletingIncomeSourceDocument(false);
            setIsDeletingIncomeSourceDocumentSuccess(false);
        }
    }

    const handleSubmit = async () => {
        setIsSubmittingDocuments(true);
        setIsSubmittingDocumentsSuccess(false);

        try {
            await submitIncomeDocuments(type as IncomeVerificationIncomeSourceTypeEnum, homeseekerId, sourceId, payload)

            navigate(routePath('ScreeningIncomeVerificationOverview', { homeseekerId }));
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingDocuments(false);
            setIsSubmittingDocumentsSuccess(false);
        }
    }

    if (isFetchingDocuments) {
        return (
            <div className={styles.contentContainer}>
                <div className={styles.gridLg}>
                    <Text
                        color={ColorEnum.Primary700}
                        weight={FontWeightEnum.Bold}
                        size={FontSizeEnum.DisplayXs}
                    >{t(screeningSteps.ScreeningIncomeVerificationProvideIncomeDetails.pageTitleTranslationKey)}</Text>
                    <Card elevated>
                        <div className={styles.gridMd}>
                            <SkeletonComponent height={24}/>
                            <div className={styles.gridSm}>
                                <SkeletonComponent height={24}/>
                                <SkeletonComponent height={72}/>
                            </div>
                        </div>
                    </Card>
                    <ScreeningNavigation
                        isSubmitting={isSubmittingDocuments}
                        isSuccessful={isSubmittingDocumentsSuccess}
                        disabled
                        onClick={handleClick}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <Form
                onChange={handleFormDataChange}
                onSubmit={handleSubmit}
            >
                <div className={styles.contentContainer}>
                    <div className={styles.gridLg}>
                        <Text
                            color={ColorEnum.Primary700}
                            weight={FontWeightEnum.Bold}
                            size={FontSizeEnum.DisplayXs}
                        >{t(screeningSteps.ScreeningIncomeVerificationProvideIncomeDetails.pageTitleTranslationKey)}</Text>
                        <Card elevated>
                            {renderDocumentsForm()}
                        </Card>
                        <ScreeningNavigation
                            isSubmitting={isSubmittingDocuments}
                            isSuccessful={isSubmittingDocumentsSuccess}
                            onClick={handleClick}
                        />
                    </div>
                </div>
            </Form>
            <Modal
                flowyColor={DeprecatedColorEnum.default.Error50}
                icon={'ri-delete-bin-line'}
                iconBackgroundColor={DeprecatedColorEnum.default.Error100}
                iconColor={DeprecatedColorEnum.default.Error700}
                isOpen={deleteConfirmationModalIsOpen}
                onCloseButtonClick={() => setDeleteConfirmationModalIsOpen(false)}
            >
                {incomeSourceDocumentNameToDelete &&
                    <div className={styles.gridMd}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Bold}
                            size={FontSizeEnum.Lg}
                            tag={'h2'}
                        >{t('screening.incomeVerification.provideDocuments.deletion.modal.title')}</Text>
                        <Text tag='p'>{t('screening.incomeVerification.provideDocuments.deletion.modal.p1')}</Text>
                        <Text tag='p'>
                            <Trans
                                i18nKey="screening.incomeVerification.provideDocuments.deletion.modal.p2"
                                values={{
                                    filename: storedData.documents[incomeSourceDocumentNameToDelete].originalFileName,
                                }}
                                components={{strong: <strong/>}}
                            />
                        </Text>
                        <div className={classNames(styles.footer, (!isDeletingIncomeSourceDocument && !isDeletingIncomeSourceDocumentSucces) && styles.noColumnGap)}>
                            <ProcessRequestFeedback
                                isSubmitting={isDeletingIncomeSourceDocument}
                                isSuccessful={isDeletingIncomeSourceDocumentSucces}
                            />
                            <div className={styles.buttonsContainer}>
                                <ButtonNew
                                    disabled={isDeletingIncomeSourceDocument || isDeletingIncomeSourceDocumentSucces}
                                    variant='secondary-gray'
                                    onClick={() => setDeleteConfirmationModalIsOpen(false)}
                                >{t('screening.incomeVerification.provideDocuments.deletion.modal.button.cancel')}</ButtonNew>
                                <ButtonNew
                                    disabled={isDeletingIncomeSourceDocument || isDeletingIncomeSourceDocumentSucces}
                                    variant='primary-destructive'
                                    onClick={() => deleteSelectedDocument()}
                                >{t('screening.incomeVerification.provideDocuments.deletion.modal.button.delete')}</ButtonNew>
                            </div>
                        </div>
                    </div>
                }
            </Modal>
        </>
    )
}

export default ProvideDocuments;

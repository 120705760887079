import React, { useEffect, useState } from 'react';
import EnrichingForm from '../../components/forms/EnrichingForm';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormSuccess from '../../components/empty-states/FormSuccess';
import { getEnrichingCandidateDetails } from '../../redux/actions/candidates/getCandidateDetails';

export default function EnrichingFormPage() {
    const { id, languageCode } = useParams();
    const { data } = useSelector((state) => state.candidates);
    const [showFormSuccess, setShowFormSuccess] = useState(false);
    const [pets, setPets] = useState(null);
    const dispatch = useDispatch();

    const hasSucceeded = () => {
        setShowFormSuccess(true);
    }

    useEffect(() => {
        dispatch(getEnrichingCandidateDetails(id));
    }, [showFormSuccess]);

    return (
        <>
            {showFormSuccess ?
                <FormSuccess /> :
                <EnrichingForm
                    data={data}
                    pets={pets}
                    setPets={setPets}
                    languageCode={languageCode}
                    hasSucceeded={hasSucceeded}
                />
            }
        </>
    );
}

import { ReactNode } from 'react';
import { Location, Pathname } from 'react-router-dom';
import AgentsOverview from './pages/admin/AgentsOverview';
import AgentDetails from './pages/admin/agents/AgentDetails';
import DiscardedLeadsPage from './pages/admin/discardedLeads/DiscardedLeadsPage';
import ListingsOverview from './pages/dashboard/listings/ListingsOverview';
import ListingDetails from './pages/dashboard/listings/ListingDetails';
import SettingsPage from './pages/settings/SettingsPage';
import ViewingAttendancePage from './componentsNew/pages/viewingAttendancePage/ViewingAttendancePage';
import EnrichingFormPage from './pages/enriching-form/EnrichingFormPage';
import ContactRequestPage from './pages/contactRequestPage/ContactRequestPage';
import CookieDeclarationPage from './pages/cookies/CookieDeclarationPage';
import EmailAddressConfirmationPage from './pages/emailAddressConfirmation/EmailAddressConfirmationPage';
import ViewingInvitiationAcceptedPage from './componentsNew/pages/viewingInvitationAccepted/ViewingInvitiationAcceptedPage';
import ViewingInvitationDeclinedPage from './componentsNew/pages/viewingInvitationDeclined/ViewingInvitationDeclinedPage';
import ScheduleViewingPage from './componentsNew/pages/scheduleViewing/ScheduleViewingPage';
import LoginPage from './pages/login/LoginPage';
import LogoutPage from './pages/LogoutPage';
import SSOLoginPage from './pages/login/SSOLoginPage';
import MarketingMessagesSubscribePage from './pages/marketingMessagesSubscribe/MarketingMessagesSubscribePage';
import MarketingMessagesUnsubscribePage from './pages/marketingMessagesUnsubscribe/MarketingMessagesUnsubscribePage';
import ScreeningPage from './componentsNew/pages/screening/ScreeningPage';
import ScreeningIntroductionContent from './componentsNew/pages/screening/pageContent/ScreeningIntroductionContent';
import ScreeningHouseholdContent from './componentsNew/pages/screening/pageContent/ScreeningHouseholdContent';
import ScreeningCoTenantsContent from './componentsNew/pages/screening/pageContent/ScreeningCoTenantsContent';
import ParariusOfficeListingIdRedirect from './componentsNew/pages/listingDetail/ParariusOfficeListingIdRedirect';
import { IntroductionContent, PendingContent, SuccessContent, FailureContent, IdAnalyzerUnavailableContent, OutcomeContent } from './componentsNew/pages/screening/pageContent/id-verification';
import { IncomeSourceCategoryContent, IncomeSourceTypeContent, OverviewContent } from './componentsNew/pages/screening/pageContent/income-verification';
import ProvideDocuments from './componentsNew/pages/screening/pageContent/income-verification/provide-documents/ProvideDocuments';
import { MergeDuplicateListings } from './componentsNew/pages/mergeDuplicateListings/MergeDuplicateListings';
import ProvideIncomeDetails from './componentsNew/pages/screening/pageContent/income-verification/provide-income-details/ProvideIncomeDetails';
import IncomeSourceSelectionContent from './componentsNew/pages/screening/pageContent/income-verification/IncomeSourceSelectionContent';
import EmailTemplatesContent from './pages/settings/EmailTemplatesContent';
import EmailNotificationsContent from './pages/settings/EmailNotificationsContent';
import SettingsPageNew from './pages/settings/SettingsPageNew';
import GeneralSettingsContent from './pages/settings/GeneralSettingsContent';

export enum RouteAuthentication {
    Authenticated = 'authenticated',
    Guest = 'guest',
    Unauthenticated = 'unauthenticated',
}

export interface RouteBase {
    path?: string;
    name?: string;
    component?: () => ReactNode;
    childRoutes?: Route[];
    params?: Record<string, string>;
}

interface RouteWithoutChildren extends RouteBase {
    path: string;
    component: () => ReactNode;
    childRoutes: never;
}

interface RouteWithChildren extends RouteBase {
    path?: string;
    childRoutes?: Route[];
}

export type Route = RouteWithoutChildren | RouteWithChildren;

export interface Routes {
    authenticatedRoutes: Route[];
    guestRoutes: Route[];
    unauthenticatedRoutes: Route[];
}

const routes: Routes = {
    authenticatedRoutes: [
        {path: '/admin', component: AgentsOverview},
        {path: '/admin/agents/:id', component: AgentDetails},
        {path: '/dubbele-advertenties', component: MergeDuplicateListings},
        {path: '/admin/onverwerkbare-leads', component: DiscardedLeadsPage},
        {path: '/admin/onverwerkbare-leads/:agentId', component: DiscardedLeadsPage},
        {path: '/dashboard/advertenties', name: 'ListingOverview', component: ListingsOverview},
        {path: '/dashboard/advertenties/:id', name: 'ListingDetails', component: ListingDetails},
        {path: '/dashboard/advertenties/:id/:status', component: ListingDetails},
        {path: '/dashboard/listings', component: ListingsOverview},
        {path: '/dashboard/listings/:id', component: ListingDetails},
        {path: '/instellingen', name: 'Settings', component: SettingsPage},
        {path: '/settings', component: SettingsPage},
        {
            component: SettingsPageNew,
            childRoutes: [
                {
                    path: '/instellingen/algemeen',
                    name: 'SettingsGeneral',
                    component: GeneralSettingsContent,
                },
                {
                    path: '/instellingen/email-templates',
                    name: 'SettingsEmailTemplates',
                    component: EmailTemplatesContent,
                },
                {
                    path: '/instellingen/email-notifications',
                    name: 'SettingsEmailNotifications',
                    component: EmailNotificationsContent,
                }
            ]
        },
        {path: '/listing/:listingId/viewings/:viewingId/overview', component: ViewingAttendancePage},
        {path: '/listing/:listingId/viewings/:viewingId/overview/:candidateId', component: ViewingAttendancePage},
        {path: '/office-listing/:officeId', component: ParariusOfficeListingIdRedirect},
        {path: '/logout', component: LogoutPage},
    ],
    guestRoutes: [
        {path: '/login', name: 'LoginPage', component: LoginPage},
        {path: '/sso-login', component: SSOLoginPage},
    ],
    unauthenticatedRoutes: [
        {path: '/applicant-form/:id', component: EnrichingFormPage},
        {path: '/applicant-form/:id/:languageCode', component: EnrichingFormPage},
        {path: '/contact-request/:id', component: ContactRequestPage},
        {path: '/contact-verzoek/:id', component: ContactRequestPage},
        {path: '/cookies', component: CookieDeclarationPage},
        {path: '/email-address-confirmation/:id', component: EmailAddressConfirmationPage},
        {path: '/email-address-confirmation/:id/:listingCity', component: EmailAddressConfirmationPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/accepted', component: ViewingInvitiationAcceptedPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/declined', component: ViewingInvitationDeclinedPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/schedule', component: ScheduleViewingPage},
        {path: '/subscribe-to-marketing-messages/:id', component: MarketingMessagesSubscribePage},
        {path: '/subscribe-to-marketing-messages/:id/:listingCity', component: MarketingMessagesSubscribePage},
        {path: '/unsubscribe-from-marketing-messages/:id', component: MarketingMessagesUnsubscribePage},
        {
            component: ScreeningPage,
            childRoutes: [
                {
                    path: '/:lang/screening/:verificationRequestAuthorisationId/introduction',
                    name: 'ScreeningIntroduction',
                    component: ScreeningIntroductionContent
                },
                {
                    path: '/:lang/screening/:verificationRequestAuthorisationId/household',
                    name: 'ScreeningHousehold',
                    component: ScreeningHouseholdContent
                },
                {
                    path: '/:lang/screening/:verificationRequestAuthorisationId/co-tenants',
                    name: 'ScreeningCoTenants',
                    component: ScreeningCoTenantsContent
                },
                {
                    path: '/:lang/screening/:homeseekerId/id-verification/introduction',
                    name: 'ScreeningIdVerificationIntroduction',
                    component: IntroductionContent
                },
                {
                    path: '/:lang/screening/:homeseekerId/id-verification/pending',
                    name: 'ScreeningIdVerificationPending',
                    component: PendingContent
                },
                {
                    path: '/:lang/screening/:homeseekerId/id-verification/success',
                    name: 'ScreeningIdVerificationSuccess',
                    component: SuccessContent
                },
                {
                    path: '/:lang/screening/:homeseekerId/id-verification/failure',
                    name: 'ScreeningIdVerificationFailure',
                    component: FailureContent
                },
                {
                    path: '/:lang/screening/:homeseekerId/id-verification/id-analyzer-unavailable',
                    name: 'ScreeningIdVerificationIDAnalyzerUnavailable',
                    component: IdAnalyzerUnavailableContent
                },
                {
                    path: '/:lang/screening/:homeseekerId/income-verification/overview',
                    name: 'ScreeningIncomeVerificationOverview',
                    component: OverviewContent
                },
                {
                    component: IncomeSourceSelectionContent,
                    childRoutes: [
                        {
                            path: '/:lang/screening/:homeseekerId/income-verification/provide-income-type',
                            name: 'ScreeningIncomeVerificationIncomeSourceCategory',
                            component: IncomeSourceCategoryContent
                        },
                        {
                            path: '/:lang/screening/:homeseekerId/income-verification/provide-income-type/:category',
                            name: 'ScreeningIncomeVerificationIncomeSourceType',
                            component: IncomeSourceTypeContent
                        },
                    ]
                },
                {
                    path: '/:lang/screening/:homeseekerId/income-verification/:sourceId/provide-income-details/:type',
                    name: 'ScreeningIncomeVerificationProvideIncomeDetails',
                    component: ProvideIncomeDetails
                },
                {
                    path: '/:lang/screening/:homeseekerId/income-verification/:sourceId/provide-documents/:type',
                    name: 'ScreeningIncomeVerificationProvideDocuments',
                    component: ProvideDocuments
                },

            ]
        },
        {
            path: '/screening/id-verification/outcome',
            name: 'ScreeningIdVerificationOutcome',
            component: OutcomeContent
        },
    ]
};

export const isAuthenticatedRoute = (pathname: Pathname) => {
    return !!routeByPathname(pathname, routes.authenticatedRoutes);
}

export const isGuestRoute = (pathname: Pathname) => {
    return !!routeByPathname(pathname, routes.guestRoutes);
}

export const isUnauthenticatedRoute = (pathname: Pathname) => {
    return !!routeByPathname(pathname, routes.unauthenticatedRoutes);
}

export const findRouteAuthentication = (pathname: Pathname): RouteAuthentication | null => {
    if (isAuthenticatedRoute(pathname)) {
        return RouteAuthentication.Authenticated;
    }
    if (isGuestRoute(pathname)) {
        return RouteAuthentication.Guest;
    }
    if (isUnauthenticatedRoute(pathname)) {
        return RouteAuthentication.Unauthenticated;
    }

    return null;
}

export const findRouteByName = (name: string | undefined, thisRoutes: Route[] = []): Route | null => {
    if (thisRoutes.length === 0) {
        thisRoutes = [...routes.authenticatedRoutes, ...routes.guestRoutes, ...routes.unauthenticatedRoutes];
    }

    if (name === undefined) {
        return null;
    }

    for (const route of thisRoutes) {
        if (route.name === name) return route;

        if (route.childRoutes) {
            const result = findRouteByName(name, route.childRoutes);
            if (result) return result;
        }
    }

    return null;
};

export const isCurrentRoute = (location: Location, name: string): boolean => {
    const route = findRouteByName(name);

    if (!route) {
        return false;
    }

    const routeRegex = new RegExp(`^${route.path.replace(/:\w+/g, '[^/]+')}$`);

    return routeRegex.test(location.pathname);
};

export const routeByPathname = (pathname: Pathname, thisRoutes: Route[] = []): Route | null => {
    if (thisRoutes.length === 0) {
        thisRoutes = [...routes.authenticatedRoutes, ...routes.guestRoutes, ...routes.unauthenticatedRoutes];
    }

    for (const route of thisRoutes) {
        const paramNames: string[] = [];

        if (route.childRoutes) {
            const result = routeByPathname(pathname, route.childRoutes);
            if (result) {
                return result;
            }
        }

        if (!route.path) {
            continue;
        }

        const routeRegex = new RegExp(
            `^${route.path.replace(/:\w+/g, (match) => {
                paramNames.push(match.substring(1));
                return `([^/]+)`;
            })}$`
        );

        const match = routeRegex.exec(pathname);
        if (match) {
            const params = paramNames.reduce<Record<string, string>>((acc, paramName, index) => {
                acc[paramName] = match[index + 1];
                return acc;
            }, {});

            return {
                ...route,
                params
            };
        }
    }

    return null;
};

export default routes;

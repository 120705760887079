import { IncomeVerificationIncomeSourceTypeEnum } from '../../../componentsNew/enums';
import api from '../../../utils/api';

interface IncomeSourceBase {
    id: string;
    type: IncomeVerificationIncomeSourceTypeEnum;
}

interface IncomeSourceEmployment extends IncomeSourceBase {
    income: number;
}

export type IncomeSource = IncomeSourceEmployment;

const findIncomeSources = async (homeseekerId: string): Promise<IncomeSource[]> => {
    return await api.getWithoutAuth(`api/screening/${homeseekerId}/income-verification/overview`);
}

export default findIncomeSources;

import IncomeVerificationIncomeSourceEmploymentDocumentEnum from '../../../../componentsNew/enums/screening/IncomeVerificationIncomeSourceEmploymentDocumentEnum';
import IncomeVerificationIncomeSourceFreelanceDocumentEnum from '../../../../componentsNew/enums/screening/IncomeVerificationIncomeSourceFreelanceDocumentEnum';
import deleteIncomeSourceEmploymentDocumentEmploymentContract from './deleteIncomeSourceEmployment';
import deleteIncomeSourceEmploymentDocumentEmployerStatement from './deleteIncomeSourceEmploymentDocumentEmployerStatement';
import deleteIncomeSourceEmploymentDocumentPayslipOne from './deleteIncomeSourceEmploymentDocumentPayslipOne';
import deleteIncomeSourceEmploymentDocumentPayslipThree from './deleteIncomeSourceEmploymentDocumentPayslipThree';
import deleteIncomeSourceEmploymentDocumentPayslipTwo from './deleteIncomeSourceEmploymentDocumentPayslipTwo';
import deleteIncomeSourceFreelanceDocumentAnnualStatement from './deleteIncomeSourceFreelanceDocumentAnnualStatement';
import deleteIncomeSourceFreelanceDocumentCertifiedChamberOfCommerceExtract from './deleteIncomeSourceFreelanceDocumentCertifiedChamberOfCommerceExtract';
import deleteIncomeSourceFreelanceDocumentTaxReturn from './deleteIncomeSourceFreelanceDocumentTaxReturn';

const deleteIncomeSourceDocument = async (name: string, sourceId: string): Promise<unknown> => {
    switch (name) {
        case IncomeVerificationIncomeSourceEmploymentDocumentEnum.EmploymentContract:
            return await deleteIncomeSourceEmploymentDocumentEmploymentContract(sourceId);
        case IncomeVerificationIncomeSourceEmploymentDocumentEnum.EmployerStatement:
            return await deleteIncomeSourceEmploymentDocumentEmployerStatement(sourceId);
        case IncomeVerificationIncomeSourceEmploymentDocumentEnum.PayslipOne:
            return await deleteIncomeSourceEmploymentDocumentPayslipOne(sourceId);
        case IncomeVerificationIncomeSourceEmploymentDocumentEnum.PayslipTwo:
            return await deleteIncomeSourceEmploymentDocumentPayslipTwo(sourceId);
        case IncomeVerificationIncomeSourceEmploymentDocumentEnum.PayslipThree:
            return await deleteIncomeSourceEmploymentDocumentPayslipThree(sourceId);
        case IncomeVerificationIncomeSourceFreelanceDocumentEnum.AnnualStatement:
            return await deleteIncomeSourceFreelanceDocumentAnnualStatement(sourceId);
        case IncomeVerificationIncomeSourceFreelanceDocumentEnum.CertifiedChamberOfCommerceExtract:
            return await deleteIncomeSourceFreelanceDocumentCertifiedChamberOfCommerceExtract(sourceId);
        case IncomeVerificationIncomeSourceFreelanceDocumentEnum.TaxReturn:
            return await deleteIncomeSourceFreelanceDocumentTaxReturn(sourceId);
        default:
            break;
    }
}

export default deleteIncomeSourceDocument;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import LayoutLandingPage from '../../components/generics/LayoutLandingPage';
import styles from './ContactRequestPage.module.css';
import Logo from '../../components/logo/Logo';
import LanguageSwitchButtons from '../../components/locales/LanguageSwitchButtons';
import { useTranslation } from 'react-i18next';
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../enums/colorEnum';
import Title from '../../components/typography/title/Title';
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum';
import Text from '../../components/typography/text/Text';
import ContactRequestFormData from './ContactRequestFormData';
import ContactRequestForm from './ContactRequestForm';
import { HOUSEHOLD_TYPE } from '../../utils/constants';
import ListingCard from '../../components/listings/ListingCard';
import { useParams } from 'react-router-dom';
import FormSuccess from '../../components/empty-states/FormSuccess';
import { findListingById } from '../../api/transactional-listing/findListingById';
import { setNotification } from '../../redux/actions/ui/setNotification';
import { useDispatch } from 'react-redux';

export default function ContactRequestPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const listingId = useParams().id;

    const [contactRequestFormData, setContactRequestFormData] = useState(ContactRequestFormData.create());
    const [householdClass, setHouseholdClass] = useState('');
    const [agent, setAgent] = useState({
        name: '',
        logo: '',
        privacyPolicy: ''
    });
    const [listing, setListing] = useState({});
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [isFromSubmitSuccess, setIsFormSubmitSuccess] = useState(false);

    const fetchListingData = async () => {
        try {
            const responseData = await findListingById(listingId);
    
            setListing({
                street: responseData.listing.street,
                show_house_number: responseData.listing.showHouseNumber,
                house_number: responseData.listing.houseNumber,
                postal_code: responseData.listing.postalCode,
                city: responseData.listing.city,
                image_url: responseData.listing.imageUrl,
                price: responseData.listing.price / 100,
                surface: responseData.listing.surface,
                rooms: responseData.listing.rooms,
            });
    
            setAgent({
                name: responseData.agent.name,
                logo: responseData.agent.logo,
                privacyPolicy: responseData.agent.privacyPolicy,
            });   
        } catch (error) {
            dispatch(setNotification(
                {
                    id: Date.now(),
                    message: t('contactRequestPage.form.validation.toast.failed'),
                    type: 'failed'
                }
            ));
        }
    }

    useEffect(() => {
        fetchListingData();
    }, []);

    useEffect(() => {
        if (contactRequestFormData.value.birthDate) {
            let age = null

            if (contactRequestFormData.value.birthDate && moment(contactRequestFormData.value.birthDate, 'YYYY-MM-DD', true).isValid()) {
                age = moment().diff(contactRequestFormData.value.birthDate, 'years')
            }

            setContactRequestFormData(prev => prev.with({ age }))
        }
    }, [contactRequestFormData.value.birthDate]);

    useEffect(() => {
        if (contactRequestFormData.value.householdType === HOUSEHOLD_TYPE.SINGLE) {
            setContactRequestFormData(prev => prev.with({ household: 1 }))
            setHouseholdClass('');
        } else if (contactRequestFormData.value.householdType === HOUSEHOLD_TYPE.WITH_PARTNER) {
            setContactRequestFormData(prev => prev.with({ household: 2 }))
            setHouseholdClass('');
        } else if (contactRequestFormData.value.householdType !== null) {
            setContactRequestFormData(prev => prev.with({ household: 1 }))
            setHouseholdClass(styles.showFormField);
        } else {
            setHouseholdClass('')
        }
    }, [contactRequestFormData.value.householdType]);

    const handleSubmit = () => {
        setIsSubmitButtonDisabled(true);
        setIsFormSubmitSuccess(true);
    };

    return (
        <>
            {isFromSubmitSuccess ?
                <FormSuccess />
                :
                <LayoutLandingPage>
                    <div className={styles.header}>
                        <Logo />
                        <LanguageSwitchButtons />
                    </div>
                    <hr className={styles.divider} />
                    <div className={styles.gridContainer}>
                        <div className={styles.sidebar}>
                            <div className={styles.content}>
                                <Title
                                    size={FontSizeEnum.Md}
                                    weight={FontWeightEnum.Semibold}
                                    color={ColorEnum.Primary700}
                                >
                                    {t('contactRequestPage.title')}
                                </Title>
                                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                                    {t('contactRequestPage.description')}
                                </Text>
                                <ListingCard agent={agent} listing={listing} className='col-span-6' />
                            </div>
                        </div>
                        <div className={styles.mainContent}>
                            <ContactRequestForm
                                isSubmitButtonDisabled={isSubmitButtonDisabled}
                                householdClass={householdClass}
                                contactRequestFormData={contactRequestFormData}
                                setContactRequestFormData={setContactRequestFormData}
                                handleSubmit={handleSubmit}
                                agent={agent}
                            />
                        </div>
                    </div>
                </LayoutLandingPage>
            }
        </>
    );

}

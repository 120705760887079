import styles from './IncomeSourceSelectionContent.module.css';
import { useTranslation } from 'react-i18next';
import { RadioInput } from '../../../../atoms/radio-input';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Option } from '../../../../atoms/radio-input/RadioInput';
import { slugify } from '../../../../utils';
import incomeTypes from './incomeTypes';

const IncomeSourceTypeContent = () => {
    const { t } = useTranslation();
    const { category } = useParams();

    const [ selectedIncomeSourceType, setSelectedIncomeSourceType ] = useState<string | null>(null);

    const typeOptions: Option[] = incomeTypes[category]?.options.map((type): Option => {
        return {
            label: t(`screening.incomeVerification.incomeSourceTypeContent.type.options.${type}`),
            value: slugify(type)
        }
    });

    return (
        <RadioInput
            className={styles.categoryOptions}
            name='incomeSourceType'
            value={selectedIncomeSourceType}
            options={typeOptions}
            onChange={setSelectedIncomeSourceType}
            required
        />
    )
}

export default IncomeSourceTypeContent;

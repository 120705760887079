import { ReactNode } from 'react';
import styles  from './HorizontalNavigationTabs.module.css';

interface HorizontalNavigationTabsProps {
    readonly children: ReactNode
}

export default function HorizontalNavigationTabs(props: HorizontalNavigationTabsProps) {
    return (
        <>
            <div className={styles.tabsContainer}>
                {props.children}
            </div>
        </>
    );
}

import api from '../../../../utils/api';

export interface EmploymentDocument {
    fileSize: string;
    originalFileName: string;
}

export interface EmploymentDocuments {
    employmentContract?: EmploymentDocument;
    employerStatement?: EmploymentDocument;
    payslipOne?: EmploymentDocument;
    payslipTwo?: EmploymentDocument;
    payslipThree?: EmploymentDocument;
}

export interface FindIncomeSourceEmploymentResponse {
    contractType: string | null;
    documents: EmploymentDocuments;
}

const findIncomeSourceEmployment = async (sourceId: string): Promise<FindIncomeSourceEmploymentResponse> => {
    return await api.getWithoutAuth(`api/screening/income-verification/${sourceId}/documents/employment`);
}

export default findIncomeSourceEmployment;

import { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './ColorSelector.module.css';
import toolbarStyles from './Toolbar.module.css';
import { classNames } from '../../utils';
import { Dropdown } from '../../organisms/dropdown';
import CheckLineIcon from '../icons/CheckLineIcon';
import FontColorIcon from '../icons/FontColorIcon';
import { EmitterSource } from 'quill';
import { Delta } from 'quill/core';
import BlurOffIcon from '../icons/BlurOffIcon';
import { useTranslation } from 'react-i18next';

interface ColorSelectorProps {
    format: (name: string, value: unknown, source?: EmitterSource) => Delta | null,
    selectedColor?: string;
}

const ColorSelector = ({
    format,
    selectedColor,
}: ColorSelectorProps) => {
    const { t } = useTranslation();

    const colorRef = useRef<HTMLDivElement>(null);

    const [colorDropdownIsActive, setColorDropdownIsActive] = useState(false);

    const colors: string[] = [
        // Gray
        '#f2f2f2', '#d9d9d9', '#808080', '#595959', '#333333',
        // Red
        '#ffcccc', '#ff9999', '#ff0000', '#cc0000', '#990000',
        // Orange
        '#ffe0cc', '#ffb366', '#ff6600', '#cc5200', '#993d00',
        // Yellow
        '#ffffcc', '#ffff99', '#ffcc00', '#cc9900', '#996600',
        // Green
        '#ccffcc', '#99ff99', '#00cc00', '#009900', '#006600',
        // Blue
        '#cce0ff', '#99c2ff', '#0066ff', '#0047b3', '#003380',
        // Purple
        '#e0ccff', '#c299ff', '#6600cc', '#4d0099', '#330066',
        // Pink
        '#ffccff', '#ff99ff', '#ff00ff', '#cc00cc', '#990099',
    ];

    useEffect(() => {
        document.addEventListener('pointerdown', handleOutsideColorClick);

        return () => {
            document.removeEventListener('pointerdown', handleOutsideColorClick);
        };
    }, []);

    const handleOutsideColorClick = (e: PointerEvent) => {
        if (colorRef.current && !colorRef.current.contains(e.target as Node)) {
            setColorDropdownIsActive(false);
        }
    };

    const handleButtonColorClick = () => {
        setColorDropdownIsActive(true);
    }

    const setColor = (color: false | string) => {
        format('color', color);
        setColorDropdownIsActive(false);
    }

    const handleColorClick = (color: string) => {
        setColor(color);
    }

    const resetColor = () => {
        setColor(false);
    }

    const renderColorBox = (color: string, index?: number): ReactNode => {
        return (
            <div
                key={index}
                className={styles.colorBox}
                style={{ backgroundColor: color }}
                onClick={() => handleColorClick(color)}
            >
                {color === selectedColor &&
                    <CheckLineIcon className={styles.colorCheckIcon}/>
                }
            </div>
        )
    }

    return (
        <div
            ref={colorRef}
            className={styles.ColorSelector}
        >
            <button
                type='button'
                className={classNames(toolbarStyles.formatButton, selectedColor && toolbarStyles.selected)}
                onClick={handleButtonColorClick}
            >
                <FontColorIcon selectedColor={selectedColor}/>
            </button>
            <Dropdown
                isActive={colorDropdownIsActive}
            >
                <div className={styles.colorBoxesContainer}>
                    <div>
                        <button
                            type='button'
                            className={styles.resetColorButton}
                            onClick={resetColor}
                        ><BlurOffIcon className={styles.resetColorIcon}/> { t('recover')}</button>
                    </div>
                    <div className={styles.colorBoxes}>
                        {colors.map(renderColorBox)}
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}

export default ColorSelector;

import styles from './FailureContent.module.css';
import { Card } from '../../../../organisms/card';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import { Trans, useTranslation } from 'react-i18next';
import screeningSteps, {Action} from '../../../../organisms/screening-navigation/screeningSteps';
import Divider from '../../../../atoms/divider';
import { useNavigate, useParams } from 'react-router-dom';
import CloseCircleFillIcon from '../../../../atoms/icons/CloseCircleFillIcon';
import PassportLineIcon from '../../../../atoms/icons/PassportLineIcon';
import { FeaturedIconWithText } from '../../../../molecules/featured-icon-with-text';
import CameraLineIcon from '../../../../atoms/icons/CameraLineIcon';
import EmotionUnhappyLineIcon from '../../../../atoms/icons/EmotionUnhappyLineIcon';
import { classNames } from '../../../../utils';
import React from 'react';
import { useRoutePath } from '../../../../utils/useRoutePath';
import ScreeningNavigation from '../../../../organisms/screening-navigation/ScreeningNavigation';

const FailureContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const { homeseekerId } = useParams();

    const handleClick = (action: Action) => {
        if (action === 'next') {
            navigate(routePath('ScreeningIncomeVerificationOverview', { homeseekerId }));
        }
        if (action === 'previous') {
            navigate(routePath('ScreeningIdVerificationIntroduction', { homeseekerId }));
        }
    }

    return (
        <div className={styles.contentContainer}>
            <div className={styles.gridLg}>
                <Text
                    color={ColorEnum.Primary700}
                    weight={FontWeightEnum.Bold}
                    size={FontSizeEnum.DisplayXs}
                >{t(screeningSteps.ScreeningIdVerificationFailure.pageTitleTranslationKey)}</Text>
                <Card elevated>
                    <div className={styles.gridMd}>
                        <div className={styles.iconContainer}>
                            <CloseCircleFillIcon className={styles.closeIcon} />
                        </div>
                        <Text
                            size={FontSizeEnum.DisplayXs}
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Primary700}
                        >{t('screening.idVerification.failure.header')}</Text>
                        <Divider />
                        <div className={classNames(styles.textLeft, styles.gridMd)}>
                            <Text color={ColorEnum.Gray800}
                            >{t('screening.idVerification.failure.text')}</Text>
                            <div className={styles.gridSm}>
                                <FeaturedIconWithText
                                    type='error'
                                    icon={<CameraLineIcon />}
                                    text={t('screening.idVerification.failure.camera.text')}
                                    fontSize={FontSizeEnum.Md}
                                    fontWeight={FontWeightEnum.Bold}
                                />
                                <Text
                                    className={styles.unorderedList}
                                    tag='ul'
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray800}
                                >
                                    <li>{t('screening.idVerification.failure.camera.point.one')}</li>
                                    <li>{t('screening.idVerification.failure.camera.point.two')}</li>
                                </Text>
                            </div>
                            <div className={styles.gridSm}>
                                <FeaturedIconWithText
                                    type='error'
                                    icon={<PassportLineIcon />}
                                    text={t('screening.idVerification.failure.passport.text')}
                                    fontSize={FontSizeEnum.Md}
                                    fontWeight={FontWeightEnum.Bold}
                                />
                                <Text
                                    className={styles.unorderedList}
                                    tag='ul'
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray800}
                                >
                                    <li>{t('screening.idVerification.failure.passport.point.one')}</li>
                                    <li>{t('screening.idVerification.failure.passport.point.two')}</li>
                                </Text>
                            </div>
                            <div className={styles.gridSm}>
                                <FeaturedIconWithText
                                    type='error'
                                    icon={<EmotionUnhappyLineIcon />}
                                    text={t('screening.idVerification.failure.selfie.text')}
                                    fontSize={FontSizeEnum.Md}
                                    fontWeight={FontWeightEnum.Bold}
                                />
                                <Text
                                    className={styles.unorderedList}
                                    tag='ul'
                                    size={FontSizeEnum.Md}
                                    color={ColorEnum.Gray800}
                                >
                                    <li>{t('screening.idVerification.failure.selfie.point.one')}</li>
                                    <li>{t('screening.idVerification.failure.selfie.point.two')}</li>
                                    <li>{t('screening.idVerification.failure.selfie.point.three')}</li>
                                    <li>{t('screening.idVerification.failure.selfie.point.four')}</li>
                                </Text>
                            </div>
                            <Text color={ColorEnum.Gray800}>
                                <Trans
                                    i18nKey='screening.idVerification.failure.subtext'
                                    components={{Link: <a style={{color: 'var(--primary-500)'}} />}}
                                />
                            </Text>
                        </div>
                    </div>
                </Card>
                <ScreeningNavigation
                    onClick={handleClick}
                />
            </div>
        </div>
    );
}

export default FailureContent;

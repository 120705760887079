import { ChangeEvent, DragEvent, Fragment, KeyboardEvent, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import formatBytes from '../../utils/formatBytes';
import styles from './FileUpload.module.css';
import { FeaturedIcon } from '../../atoms/featured-icon';
import FileLineIcon from '../../atoms/icons/FileLineIcon';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import DeleteBinIcon from '../../atoms/icons/DeleteBinIcon';
import ButtonNew from '../../molecules/button/Button';
import CheckCircleFillIcon from '../../atoms/icons/CheckCircleFillIcon';
import { classNames, splitFilenameAndExtension } from '../../utils';
import FormError from '../../molecules/form/FormError';
import { FormError as FormErrorType } from '../../molecules/form/Form';
import { useTranslation } from 'react-i18next';

export type AcceptedExtension = 'png' | 'jpg' | 'jpeg' | 'pdf';

export type FileMetadata = {
    fileSize: string;
    originalFileName: string;
};

interface FileUploadProps {
    id?: string;
    name?: string;
    value?: FileList;
    storedFileMetadata?: FileMetadata;
    required?: boolean;
    disabled?: boolean;
    acceptedExtensions?: AcceptedExtension[];
    maxFileSizeInMbs?: number;
    errors: FormErrorType[];
    onDelete?: () => void;
}

const FileUpload = ({
    id,
    name,
    value,
    storedFileMetadata,
    required,
    disabled,
    acceptedExtensions,
    maxFileSizeInMbs = 10,
    errors = [],
    onDelete,
}: FileUploadProps) => {
    const { t } = useTranslation();

    const intervalRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const dragCounter = useRef(0);
    const animationRef = useRef<number | null>(null);

    const [ selectedFiles, setSelectedFiles ] = useState<FileList | null>(null);
    const [ uploadPercentage, setUploadPercentage ] = useState<number | null>(null);
    const [ uploadWidthPercentage, setUploadWidthPercentage ] = useState<number>(0);
    const [ isDragging, setIsDragging ] = useState(false);

    const maxFileSizeInBytes = maxFileSizeInMbs * 1000000;
    const textColor = disabled ? ColorEnum.Gray400 : errors.length ? ColorEnum.Error600 : ColorEnum.Gray500;

    const areFilesSame = (currentFiles: FileList) => {
        if (!currentFiles || currentFiles.length !== value.length) return false;

        return Array.from(currentFiles).every((file, index) => {
            return (file.name === value[index].name) && (file.size === value[index].size);
        });
    };

    useEffect(() => {
        if (!inputRef.current || !value) {
            return;
        }

        if (areFilesSame(inputRef.current.files)) {
            return;
        }

        const dataTransfer = new DataTransfer();

        Array.from(value).forEach(file => dataTransfer.items.add(file));
        inputRef.current.files = dataTransfer.files;

        inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }, [value]);

    const randomlySetUploadPercentage = () => {
        setUploadPercentage((prev) => {
            if (prev === 100) {
                clearInterval(intervalRef.current);
            }

            const increment = Math.floor(Math.random() * 99) + 1 + prev;
            return Math.min(prev + increment, 100);
        });
    };

    useEffect(() => {
        if (selectedFiles == null || Array.from(selectedFiles).length === 0) {
            setUploadPercentage(null);
            setUploadWidthPercentage(0);

            return;
        }

        randomlySetUploadPercentage();

        intervalRef.current = setInterval(() => {
            randomlySetUploadPercentage();
        }, Math.floor(Math.random() * 600) + 300);

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [selectedFiles]);

    useEffect(() => {
        if (uploadPercentage === null) return;

        let startTime: number | null = null;

        const animateWidth = (timestamp: number) => {
            if (!startTime) startTime = timestamp;

            const elapsedTime = timestamp - startTime;
            const newWidth = Math.round(Math.min(
                uploadWidthPercentage + (uploadPercentage - uploadWidthPercentage) * (elapsedTime / 200),
                uploadPercentage
            ) * 10) / 10;

            if (newWidth !== uploadWidthPercentage) {
                setUploadWidthPercentage(newWidth);
            }

            if (uploadPercentage !== uploadWidthPercentage) {
                animationRef.current = requestAnimationFrame(animateWidth);
            }
        };

        animationRef.current = requestAnimationFrame(animateWidth);

        return () => {
            cancelAnimationFrame(animationRef.current);
        };
    }, [uploadPercentage, uploadWidthPercentage]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUploadPercentage(null);
        setUploadWidthPercentage(0);
        setSelectedFiles(event.target.files);
    };

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        divRef.current?.focus();
        inputRef.current?.click();
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (disabled) {
            return;
        }

        if (e.key === 'Enter') {
            e.preventDefault();
            inputRef.current?.click();
        }

        if (e.key === 'Escape') {
            e.preventDefault();
            divRef.current?.blur();
        }
    };

    const dispatchInputChangeEvent = () => {
        const event = new Event('change', { bubbles: true });
        inputRef.current.dispatchEvent(event);
    }

    const handleDeleteClick = (e: MouseEvent) => {
        if (!inputRef.current) {
            return;
        }

        e.stopPropagation();
        inputRef.current.value = '';
        setSelectedFiles(null);

        const dataTransfer = new DataTransfer();
        inputRef.current.files = dataTransfer.files;

        dispatchInputChangeEvent();

        onDelete?.();
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        dragCounter.current += 1;
        setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        dragCounter.current -= 1;
        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        dragCounter.current = 0;
        setIsDragging(false);

        if (e.dataTransfer.files.length > 0) {
            const dataTransfer = new DataTransfer();
            Array.from(e.dataTransfer.files).forEach((file) => dataTransfer.items.add(file));

            inputRef.current.files = dataTransfer.files;

            dispatchInputChangeEvent();

            return;
        }
    };

    const formattedFilename = (filename: string): ReactNode => {
        const slittedFilename = splitFilenameAndExtension(filename);
        let name = slittedFilename.name;
        let extension = slittedFilename.extension;
        let lastFourCharacters = '';

        if (filename.length > 12) {
            lastFourCharacters = name.slice(-4);
            name = name.slice(0, -4);
            extension = lastFourCharacters + extension;
        }

        return (
            <div className={styles.filename}>
                <div className={styles.name}>{name}</div>
                <div>{extension}</div>
            </div>
        );
    };

    const bulletSeparatedList = (list: ReactNode[]): ReactNode => {
        return (
            <Text
                className={styles.additionalFileDetails}
                color={textColor}
                size={FontSizeEnum.Sm}
                weight={FontWeightEnum.Light}
            >
                {list.map((item, index) => (
                    <Fragment key={index}>
                        <span>{item}</span>
                        {list.length > 1 && index < list.length - 1 &&
                            <span>•</span>
                        }
                    </Fragment>
                ))}
            </Text>
        );
    };

    const renderFileUploadRequirements = (): ReactNode => {
        const requirements = [];

        if (acceptedExtensions.length > 0) {
            requirements.push(acceptedExtensions?.map(ext => `${ext.toUpperCase()}`).join(', '));
        }

        if (maxFileSizeInBytes) {
            requirements.push(
                t('file.input.max.fileSize', { fileSize: formatBytes(maxFileSizeInBytes) })
            );
        }

        if (requirements.length < 1) {
            return null;
        }

        return (
            <div className={styles.fileUploadInformationWrapper}>
                <Text
                    color={textColor}
                    size={FontSizeEnum.Sm}
                    weight={FontWeightEnum.Regular}
                >{t('file.input.text')}</Text>
                {bulletSeparatedList(requirements)}
            </div>
        );
    };

    const renderStoredFile = (): ReactNode => {
        return (
            <>
                <div className={styles.fileUploadInformationWrapper}>
                    <Text
                        color={ColorEnum.Gray700}
                        size={FontSizeEnum.Sm}
                        weight={FontWeightEnum.Regular}
                    >{storedFileMetadata.originalFileName}</Text>
                    <Text
                        className={styles.additionalFileDetails}
                        color={ColorEnum.Gray500}
                        size={FontSizeEnum.Sm}
                        weight={FontWeightEnum.Light}
                    >
                        <span>{formatBytes(parseInt(storedFileMetadata.fileSize))}</span>
                    </Text>
                </div>
                <div>
                    <ButtonNew
                        className={styles.deleteButton}
                        iconBefore={<DeleteBinIcon />}
                        size='sm'
                        variant='empty'
                        onClick={handleDeleteClick}
                    ></ButtonNew>
                </div>
            </>
        );
    };

    const renderSelectedFile = (): ReactNode => {
        return (
            <>
                <div className={styles.fileUploadInformationWrapper}>
                    <Text
                        color={ColorEnum.Gray700}
                        size={FontSizeEnum.Sm}
                        weight={FontWeightEnum.Regular}
                    >{formattedFilename(selectedFiles[0].name)}</Text>
                    <Text
                        className={styles.additionalFileDetails}
                        color={ColorEnum.Gray500}
                        size={FontSizeEnum.Sm}
                        weight={FontWeightEnum.Light}
                    >
                        <span>{formatBytes(selectedFiles[0].size)}</span>
                        <span>•</span>
                        <span>{t('file.input.uploadPercentage', { uploadPercentage: Math.round(uploadPercentage) })}</span>
                        {(uploadPercentage == 100) &&
                            <CheckCircleFillIcon className={styles.uploadedCheckIcon}/>
                        }
                    </Text>
                </div>
                <div>
                    <ButtonNew
                        className={styles.deleteButton}
                        iconBefore={<DeleteBinIcon />}
                        size='sm'
                        variant='empty'
                        onClick={handleDeleteClick}
                    ></ButtonNew>
                </div>
            </>
        );
    };

    const fileUploadAreaClassNames = classNames(
        styles.fileUploadArea,
        (uploadWidthPercentage > 0 && uploadWidthPercentage < 100) && styles.uploading,
        uploadWidthPercentage === 100 && styles.uploaded,
        isDragging && styles.dragging,
        disabled && styles.disabled,
        (errors.length > 0) && styles.error
    );

    return (
        <div className={classNames(styles.fileUpload, errors.length > 0 && styles.hasErrors)}>
            <input
                ref={inputRef}
                id={id}
                name={name}
                type="file"
                required={storedFileMetadata ? false : required}
                hidden
                accept={acceptedExtensions?.map(ext => `.${ext}`).join(',')}
                onChange={handleFileChange}
                data-max-file-size-in-bytes={maxFileSizeInBytes}
            />
            <div
                ref={divRef}
                className={fileUploadAreaClassNames}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                tabIndex={0}
            >
                <div
                    className={styles.uploadProgress}
                    style={{ width: `${uploadWidthPercentage}%` }}
                ></div>
                <div className={styles.fileUploadAreaContainer}>
                    <div className={styles.featuredIconContainer}>
                        <FeaturedIcon
                            className={classNames(styles.featuredIcon, storedFileMetadata && styles.storedFileExists)}
                            icon={<FileLineIcon />}
                        />
                    </div>
                    <div className={styles.fileUploadInformation}>
                        {selectedFiles && selectedFiles[0] ?
                            renderSelectedFile()
                            :
                            storedFileMetadata ?
                                renderStoredFile()
                                :
                                renderFileUploadRequirements()
                        }
                    </div>
                </div>
            </div>
            {errors.length > 0 &&
                <FormError errors={errors} />
            }
        </div>
    );
};

export default FileUpload;

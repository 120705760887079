import api, { BodyData } from '../../utils/api';

interface CoTenant extends BodyData {
    name: string;
    email: string;
}
export interface CoTenantsPayload extends BodyData {
    screeningVerificationRequestAuthorisationId: string;
    coTenants: CoTenant[];
}

export interface SubmitCoTenantsResponse {
    homeseekerId: string;
}

const submitCoTenants = async (data: CoTenantsPayload): Promise<SubmitCoTenantsResponse> => {
    const response = await api.postWithoutAuth(`api/screening/verification-request/co-tenants/save`, data);

    return response.json();
}

export default submitCoTenants;

import { useEffect, useState } from 'react';

export type Device = 'mobile' | 'desktop';

const useDeviceDetection = (): Device => {
    const [device, setDevice] = useState<Device>('desktop');

    useEffect(() => {
        const handleDeviceDetection = () => {
            const userAgent = navigator.userAgent;

            const isMobileOrTablet = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/g.test(userAgent);

            if (isMobileOrTablet) {
                setDevice('mobile');
            } else {
                setDevice('desktop');
            }
        };

        handleDeviceDetection();

        window.addEventListener('resize', handleDeviceDetection);
        window.addEventListener('orientationchange', handleDeviceDetection);

        return () => {
            window.removeEventListener('resize', handleDeviceDetection);
            window.removeEventListener('orientationchange', handleDeviceDetection);
        };
    }, []);

    return device;
}

export default useDeviceDetection;

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../atoms/loading-spinner';
import findListingIdByOfficeId from '../../../api/transactional-listing/findListingIdByOfficeId';
import ParariusOfficeListingNotFoundErrorPage from '../errorPage/ParariusOfficeListingNotFoundErrorPage';
import { useRoutePath } from '../../utils/useRoutePath';
import { TopHeader } from '../../organisms/top-header';
import { ColorEnum } from '../../enums';
import { PageContainer } from '../../atoms/page-container';
import { Main } from '../../organisms/main';
import {useTranslation} from 'react-i18next';

const ParariusOfficeListingIdRedirect = () => {
    const { officeId } = useParams();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const { t } = useTranslation();

    const [ notFound, setNotFound ] = useState(false);

    useEffect(() => {
        fetchListingIdByOfficeId();
    }, [officeId, navigate]);

    const fetchListingIdByOfficeId = async () => {
        try {
            const response = await findListingIdByOfficeId(officeId);
            if (response.listingId) {
                navigate(routePath('ListingDetails', {id: response.listingId}));
            } else {
                setNotFound(true);
            }
        } catch {
            setNotFound(true);
        }
    }

    if (notFound) {
        return (
            <ParariusOfficeListingNotFoundErrorPage officeId={officeId}/>
        );
    }

    return (
        <PageContainer
            backgroundColor={ColorEnum.Gray50}
        >
            <TopHeader/>
            <Main>
                <LoadingSpinner/> {t('parariusOfficeListingIdRedirect.loading.title')}
            </Main>
        </PageContainer>
    );
}

export default ParariusOfficeListingIdRedirect

import IncomeVerificationIncomeSourceTypeEnum from '../../../../componentsNew/enums/screening/IncomeVerificationIncomeSourceTypeEnum';
import submitIncomeDocumentsBusinessOwnership from './submitIncomeDocumentsBusinessOwnership';
import submitIncomeDocumentsFreelance from './submitIncomeDocumentsFreelance';
import { SubmitIncomeSourceDocumentsBusinessOwnershipPayload } from './submitIncomeDocumentsBusinessOwnership';
import submitIncomeDocumentsEmployment, { SubmitIncomeSourceDocumentsEmploymentPayload } from './submitIncomeDocumentsEmployment';
import { SubmitIncomeSourceDocumentsFreelancePayload } from './submitIncomeDocumentsFreelance';

export type SubmitIncomeDocumentsPayload = SubmitIncomeSourceDocumentsEmploymentPayload | SubmitIncomeSourceDocumentsFreelancePayload | SubmitIncomeSourceDocumentsBusinessOwnershipPayload

const submitIncomeDocuments = async (type: IncomeVerificationIncomeSourceTypeEnum, homeseekerId: string, sourceId: string, data: SubmitIncomeDocumentsPayload): Promise<unknown> => {
    switch (type) {
        case IncomeVerificationIncomeSourceTypeEnum.Employment:
            return await submitIncomeDocumentsEmployment(homeseekerId, sourceId, data as SubmitIncomeSourceDocumentsEmploymentPayload);
        case IncomeVerificationIncomeSourceTypeEnum.Freelance:
            return await submitIncomeDocumentsFreelance(homeseekerId, sourceId, data as SubmitIncomeSourceDocumentsFreelancePayload);
        case IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership:
            return await submitIncomeDocumentsBusinessOwnership(homeseekerId, sourceId, data as SubmitIncomeSourceDocumentsBusinessOwnershipPayload);
        default:
            break;
    }
}

export default submitIncomeDocuments;

import { MouseEvent, ReactNode } from 'react';
import styles from './Label.module.css';
import { classNames } from '../../utils';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { getColorCssVar } from '../../enums/ColorEnum'
import { fontSizeEnumAsCssVar } from '../../enums/FontSizeEnum'
import { fontWeightEnumAsCssVar } from '../../enums/FontWeightEnum'
import { Tooltip, TooltipSize } from '../tooltip';

interface BaseLabelProps {
    readonly className?: string;
    readonly htmlFor?: string;
    readonly children: ReactNode;
    readonly required?: boolean;
    readonly onClick?: (e: MouseEvent<HTMLLabelElement>) => void;
    readonly color?: ColorEnum;
    readonly fontSize?: FontSizeEnum;
    readonly fontWeight?: FontWeightEnum;
    readonly tooltip?: boolean;
    readonly tooltipSize?: TooltipSize;
    readonly tooltipIcon?: ReactNode;
    readonly tooltipContent?: ReactNode | string;
}

interface LabelDefaultProps extends BaseLabelProps {
    readonly tooltip?: false;
}

interface LabelWithTooltipProps extends BaseLabelProps {
    readonly tooltip: true;
    readonly tooltipIcon: ReactNode;
    readonly tooltipContent: ReactNode | string;
}

export type LabelProps = LabelDefaultProps | LabelWithTooltipProps;

const Label = ({
    className,
    htmlFor,
    children,
    required,
    onClick,
    color = ColorEnum.Gray800,
    fontSize = FontSizeEnum.Md,
    fontWeight = FontWeightEnum.Regular,
    tooltip = false,
    tooltipSize,
    tooltipIcon,
    tooltipContent,
}: LabelProps) => {
    const style = {
        color: getColorCssVar(color),
        fontSize: fontSizeEnumAsCssVar(fontSize),
        fontWeight: fontWeightEnumAsCssVar(fontWeight)
    };

    return (
        <div className={styles.container}>
            <label
                style={style}
                htmlFor={htmlFor}
                className={classNames(className && className, styles.label)}
                onClick={onClick}
            >
                {children}
                {required &&
                    <span className={styles.required}>*</span>
                }
            </label>
            {tooltip &&
                <div className={styles.tooltipContainer}>
                    <Tooltip size={tooltipSize} icon={tooltipIcon}>
                        {tooltipContent}
                    </Tooltip>
                </div>
            }
        </div>
    )
}

export default Label

import { useLocation } from 'react-router-dom';
import { routeByPathname } from '../../../routes';
import ButtonNew from '../../molecules/button/Button';
import styles from './ScreeningNavigation.module.css';
import screeningSteps, { Action, IconType } from './screeningSteps';
import SkeletonComponent from '../../../components/SkeletonComponent';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import { ReactNode } from 'react';
import ExternalLinkLineIcon from '../../atoms/icons/ExternalLinkLineIcon';
import ArrowRightSLineIcon from '../../atoms/icons/ArrowRightSLineIcon';
import useDeviceDetection from '../../../utils/useDeviceDetection';
import ProcessRequestFeedback from '../../molecules/process-request-feedback/ProcessRequestFeedback';

interface ScreeningNavigationProps {
    isSubmitting?: boolean;
    isSuccessful?: boolean;
    disabled?: boolean;
    onClick?: (action: string) => void;
}

const ScreeningNavigation = ({
    isSubmitting = false,
    isSuccessful = false,
    disabled = false,
    onClick,
}: ScreeningNavigationProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const device = useDeviceDetection();

    const currentRoute = routeByPathname(location.pathname);
    const currentStep = currentRoute ? screeningSteps[currentRoute.name] : null;

    if (currentStep === null) {
        return (
            <SkeletonComponent height={56} />
        );
    }

    const renderIcon = (iconName: IconType) => {
        switch (iconName) {
            case 'ExternalLinkLineIcon':
                return <ExternalLinkLineIcon />;
            case 'ArrowRightSLineIcon':
                return <ArrowRightSLineIcon />;
            default:
                return null;
        }
    }

    const handleClick = (action: Action) => {
        if (action !== 'submit') {
            onClick?.(action);
        }
    }

    const renderButtons = (): ReactNode => {
        if (currentStep?.buttons === undefined) {
            return null;
        }

        return currentStep.buttons.map((button, index) => {
            if (
                (button.onlyShowOnDevice === 'mobile' && device !== 'mobile')
                || (button.onlyShowOnDevice === 'desktop' && device !== 'desktop')
            ) {
                return null;
            }

            return (
                <ButtonNew
                    key={index}
                    variant={button.variant}
                    testName={button.action}
                    iconBefore={button.iconBefore && renderIcon(button.iconBefore)}
                    iconAfter={button.iconAfter && renderIcon(button.iconAfter)}
                    type={button.action === 'submit' ? 'submit' : 'button'}
                    onClick={() => handleClick(button.action)}
                    disabled={isSubmitting || isSuccessful || disabled}
                >{t(button.translationKey)}</ButtonNew>
            );
        });
    }

    return (
        <div className={classNames(styles.screeningNavigation, (!isSubmitting && !isSuccessful) && styles.noColumnGap)}>
            <ProcessRequestFeedback
                isSubmitting={isSubmitting}
                isSuccessful={isSuccessful}
            />
            <div className={styles.buttonsContainer}>
                {renderButtons()}
            </div>
        </div>
    );
}

export default ScreeningNavigation;

import api, { BodyData } from '../../utils/api';

export interface findListingByIdPayload extends BodyData {
    transactionalListingId: string;
    salutation: string;
    fullName: string;
    locale: string;
    birthDate: string;
    age: number;
    email: string;
    phoneNumber: string;
    employment: string;
    income: number;
    guarantor: string;
    householdType: string;
    household: string;
    hasPets: boolean;
    rentalStartDate: string;
    rentalPeriod: number;
    motivation: string;
    dataSharingAccepted: boolean;
    privacyPolicyAccepted: boolean;
}

const submitContactRequestForm = async (payload: findListingByIdPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/create-enriched-homeseeker-application`, payload);
}

export default submitContactRequestForm;

import api from '../../utils/api';

export interface DryRunResponse {
    sourceListing: Listing|null;
    targetListing: Listing|null;
    conflicts: [];
}

export interface Listing {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    price: number|null;
    surface: number|null;
    rooms: number|null;
}

export interface Priority {
    [email: string] : string;
}

export const dryRunDeduplication = async (sourceListing: string, targetListing: string): Promise<DryRunResponse> => {
    return await api.get(`api/transactional-listings/dry-run-merge/${sourceListing}/${targetListing}`);
};

export const mergeDuplicateListings = async (sourceListing: string, targetListing: string, priorities: Priority[]): Promise<void> => {
    await api.postWithTemplate(`api/transactional-listings/merge/${sourceListing}/${targetListing}`, {priorities});
}

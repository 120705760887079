import React, { ReactNode } from 'react';
import styles from './Badge.module.css';
import { classNames } from '../../utils';

interface BadgeProps {
    label: string | number;
    before?: ReactNode;
    after?: ReactNode;
    className?: string;
}

const Badge = ({
    label,
    before,
    after,
    className
}: BadgeProps) => {
    return (
        <span className={classNames(styles.Badge, className && className)}>
            {before}
            <span className={styles.label}>
                {label}
            </span>
            {after}
        </span>
    );
}


export default Badge;

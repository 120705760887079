import { useEffect, useState } from 'react';
import styles from './Toolbar.module.css';
import { classNames } from '../../utils';
import BoldIcon from '../icons/BoldIcon';
import ItalicIcon from '../icons/ItalicIcon';
import StriketroughIcon from '../icons/StriketroughIcon';
import UnderlineIcon from '../icons/UnderlineIcon';
import DoubleQuotesRIcon from '../icons/DoubleQuotesRIcon';
import ListOrderedIcon from '../icons/ListOrderedIcon';
import ListUnOrderedIcon from '../icons/ListUnorderedIcon';
import IndentDecreaseIcon from '../icons/IndentDecreaseIcon';
import IndentIncreaseIcon from '../icons/IndentIncreaseIcon';
import LinkIcon from '../icons/LinkIcon';
import ImageLineIcon from '../icons/ImageLineIcon';
import VariablesSelector from './VariablesSelector';
import ColorSelector from './ColorSelector';
import Quill, { EmitterSource, Range } from 'quill';
import { Delta } from 'quill/core';
import HeadingSelector from './HeadingSelector';

interface ToolbarProps {
    quill: Quill | null,
    variables?: string[];
    image?: boolean;
    maxIndent?: number;
    isLinkTooltipVisible: boolean;
    onLinkClick: () => void;
}

const Toolbar = ({
    quill,
    variables,
    image = false,
    maxIndent = 4,
    isLinkTooltipVisible = false,
    onLinkClick,
}: ToolbarProps) => {
    const [currentFormats, setCurrentFormats] = useState({});
    const [currentIndent, setCurrentIndent] = useState<number>(0);

    useEffect(() => {
        if (!quill) {
            return;
        }

        quill.on(Quill.events.SELECTION_CHANGE, updateFormats);
        quill.on(Quill.events.TEXT_CHANGE, updateFormats);

        return () => {
            quill.off(Quill.events.SELECTION_CHANGE, updateFormats);
            quill.off(Quill.events.TEXT_CHANGE, updateFormats);
        };
    }, [quill]);

    useEffect(() => {
        setCurrentIndent(currentFormats['indent'] ? (currentFormats['indent'] as number) : 0);
    }, [currentFormats]);

    const updateFormats = (range?: Range) => {
        if (range === undefined) {
            range = quill.getSelection();
        }

        if (!range) {
            return;
        }

        setCurrentFormats(quill.getFormat(range));
    }

    const format = (name: string, value: unknown, source?: EmitterSource): Delta | null => {
        const delta = quill.format(name, value, source);

        updateFormats();

        return delta;
    }

    const toggleFormat = (name: string, source?: EmitterSource): Delta | null => {
        return format(name, !currentFormats[name], source);
    }

    const toggleListFormat = (value: string, source?: EmitterSource): Delta | null => {
        const type = currentFormats['list'] !== value ? value : false;

        return format('list', type, source);
    }

    const handleIndentDecreaseClick = () => {
        if (currentIndent === 0) {
            return;
        }

        format('indent', currentIndent - 1);
    }

    const handleIndentIncreaseClick = () => {
        if (currentIndent >= maxIndent) {
            return;
        }

        format('indent', currentIndent + 1);
    }

    const handleImageChange = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.addEventListener('change', function () {
            if (input.files && input.files[0]) {
                const file = input.files[0];

                const reader = new FileReader();
                reader.onload = function (e) {
                    const range = quill.getSelection();
                    quill.insertEmbed(range.index, 'image', e.target.result);
                };
                reader.readAsDataURL(file);
            }
        });

        input.click();
    }

    const handleLinkClick = () => {
        if (quill.getSelection()?.length > 0) {
            onLinkClick();
        }
    }

    const handleImageClick = () => {
        if (quill.getSelection() !== null) {
            handleImageChange();
        }
    }

    return (
        <div className={styles.Toolbar}>
            <div>
                <HeadingSelector
                    format={format}
                    selectedHeading={currentFormats['custom-header']}
                />
            </div>
            <div className={styles.verticalDivider}></div>
            <div>
                <button
                    type='button'
                    className={classNames(styles.formatButton, 'bold' in currentFormats && styles.selected)}
                    onClick={() => toggleFormat('bold')}
                >
                    <BoldIcon/>
                </button>
                <button
                    type='button'
                    className={classNames(styles.formatButton, 'italic' in currentFormats && styles.selected)}
                    onClick={() => toggleFormat('italic')}
                >
                    <ItalicIcon/>
                </button>
                <button
                    type='button'
                    className={classNames(styles.formatButton, 'underline' in currentFormats && styles.selected)}
                    onClick={() => toggleFormat('underline')}
                >
                    <UnderlineIcon/>
                </button>
                <button
                    type='button'
                    className={classNames(styles.formatButton, 'strike' in currentFormats && styles.selected)}
                    onClick={() => toggleFormat('strike')}
                >
                    <StriketroughIcon/>
                </button>
                <ColorSelector
                    format={format}
                    selectedColor={currentFormats['color']}
                />
            </div>
            <div>
                <button
                    type='button'
                    className={classNames(styles.formatButton, 'blockquote' in currentFormats && styles.selected)}
                    onClick={() => toggleFormat('blockquote')}
                >
                    <DoubleQuotesRIcon/>
                </button>
                <button
                    type='button'
                    className={classNames(styles.formatButton, currentFormats['list'] === 'ordered' && styles.selected)}
                    onClick={() => toggleListFormat('ordered')}
                >
                    <ListOrderedIcon/>
                </button>
                <button
                    type='button'
                    className={classNames(styles.formatButton, currentFormats['list'] === 'bullet' && styles.selected)}
                    onClick={() => toggleListFormat('bullet')}
                >
                    <ListUnOrderedIcon/>
                </button>
            </div>
            <div>
                <button
                    type='button'
                    className={classNames(styles.formatButton)}
                    onClick={handleIndentDecreaseClick}
                    disabled={currentIndent === 0}
                >
                    <IndentDecreaseIcon/>
                </button>
                <button
                    type='button'
                    className={classNames(styles.formatButton)}
                    onClick={handleIndentIncreaseClick}
                    disabled={currentIndent >= maxIndent}
                >
                    <IndentIncreaseIcon/>
                </button>
            </div>
            <div>
                <button
                    type='button'
                    className={classNames(styles.formatButton, ('custom-link' in currentFormats || isLinkTooltipVisible) && styles.selected)}
                    onClick={handleLinkClick}
                >
                    <LinkIcon/>
                </button>
            </div>
            {image &&
                <div>
                    <button
                        type='button'
                        className={classNames(styles.formatButton)}
                        onClick={handleImageClick}
                    >
                        <ImageLineIcon/>
                    </button>
                </div>
            }
            {variables &&
                <>
                    <div className={styles.verticalDivider}></div>
                    <div>
                        <VariablesSelector
                            quill={quill}
                            buttonClassName={styles.toolbarSelect}
                            variables={variables}
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default Toolbar;

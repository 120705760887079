import TopNavigation from '../../../components/navigation/TopNavigation';
import React from 'react';
import ErrorContent from '../../organisms/ErrorComponent/ErrorContent';
import { useTranslation } from 'react-i18next';
import styles from './ParariusOfficeListingNotFoundErrorPage.module.css'
import Text from '../../../components/typography/text/Text';
import FontSizeEnum from '../../../components/typography/enums/fontSizeEnum';
import FontWeightEnum from '../../../components/typography/enums/fontWeightEnum';

interface ParariusOfficeListingNotFoundErrorPageProps {
    readonly officeId: string;
}

const ParariusOfficeListingNotFoundErrorPage = (props: ParariusOfficeListingNotFoundErrorPageProps) => {
    const { t } = useTranslation();

    const redirectInNewTab = (url: string) => {
        window.open(url, '_blank')?.focus();
    }

    return (
        <>
            <TopNavigation/>
            <ErrorContent
                title={t('errorPage.parariusOfficeListingNotFound.title')}
                buttonText={t('errorPage.parariusOfficeListingNotFound.toHome')}
                buttonTwoText={t('errorPage.parariusOfficeListingNotFound.toParariusOffice')}
                buttonTwoOnClick={() => redirectInNewTab(`https://crm.parariusoffice.nl/dossiers/${props.officeId}/publications`)}
            >
                <Text size={FontSizeEnum.Md} weight={FontWeightEnum.Bold} >{t('errorPage.parariusOfficeListingNotFound.description.list.title')}</Text>
                <ul className={styles.list} >
                    <li>{t('errorPage.parariusOfficeListingNotFound.description.list.1')}</li>
                </ul>
                <div className={styles.boldList}>
                    <Text size={FontSizeEnum.Md} weight={FontWeightEnum.Bold}>{t('errorPage.parariusOfficeListingNotFound.description.list2.title')}</Text>
                    <ul className={styles.list} >
                        <li>{t('errorPage.parariusOfficeListingNotFound.description.list2.1')}</li>
                        <li>{t('errorPage.parariusOfficeListingNotFound.description.list2.2')}</li>
                    </ul>
                </div>
            </ErrorContent>
        </>
    )
}

export default ParariusOfficeListingNotFoundErrorPage;

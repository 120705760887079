import Status from './Status';
import {useTranslation} from 'react-i18next';
import styles from './HomeseekerStatusBadge.module.css';
import React from 'react';

interface BadgeProps {
    status: Status;
}

const HomeseekerStatusBadge = ({
    status
}: BadgeProps) => {
    const { t } = useTranslation();

    function getData(status: Status) {
        switch (status) {
            case Status.New:
                return {label: t('candidate.status.none'), badgeClass: styles.New};
            case Status.BeingAssessed:
                return {label: t('candidate.status.assessment'), badgeClass: styles.BeingAssessed};
            case Status.OnWaitingList:
                return {label: t('candidate.status.waitinglist'), badgeClass: styles.OnWaitingList};
            case Status.Rejected:
                return {label: t('candidate.status.rejected'), badgeClass: styles.Rejected};
            case Status.Approved:
                return {label: t('candidate.status.suitable'), badgeClass: styles.Approved};
            case Status.InvitedToViewing:
                return {label: t('candidate.status.invitation.sent'), badgeClass: styles.InvitedToViewing};
            case Status.AcceptedInvitationToViewing:
                return {label: t('candidate.status.invitation.confirmed'), badgeClass: styles.AcceptedInvitationToViewing};
            case Status.DeclinedInvitationToViewing:
                return {label: t('candidate.status.invitation.rejected'), badgeClass: styles.DeclinedInvitationToViewing};
            case Status.ReceivedExtraInfoForm:
                return {label: t('candidate.status.extraInfo.requested'), badgeClass: styles.ReceivedExtraInfoForm};
            case Status.FilledInExtraInfoForm:
                return {label: t('candidate.status.extraInfo.sent'), badgeClass: styles.FilledInExtraInfoForm};
            case Status.ScreeningSent:
                return {label: t('candidate.status.screening.sent'), badgeClass: styles.ScreeningSent};
            case Status.ScreeningSentWithCoTenants:
                return {label: t('candidate.status.screenings.sent'), badgeClass: styles.ScreeningSentWithCoTenants};
            case Status.ScreeningNotCompleted:
                return {label: t('candidate.status.screenings.notCompleted'), badgeClass: styles.ScreeningNotCompleted};
            case Status.ScreeningNotCompletedWithCoTenants:
                return {label: t('candidate.status.screening.notCompleted'), badgeClass: styles.ScreeningNotCompletedWithCoTenants};
            case Status.IsListingTenant:
                return {label: t('candidate.status.tenant'), badgeClass: styles.IsListingTenant};
            default:
                return {label: 'unknown', badgeClass: ''};
        }
    }

    const {label, badgeClass} = getData(status);

    return (
        <span className={`${styles.Badge} ${badgeClass}`}>
            <span className={styles.label}>
                {label}
            </span>
        </span>
    );
}


export default HomeseekerStatusBadge;

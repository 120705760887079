import AnnouncementPageFrame from '../../components/announcementPageFrame/AnnouncementPageFrame';
import SuccessPageFragment from './fragments/success/SuccessPageFragment';
import ConfirmationPageFragment from './fragments/confirmation/ConfirmationPageFragment';
import ErrorPageFragment from '../../components/errorPageFragment/ErrorPageFragment';
import {useEffect, useRef, useState} from 'react';
import LoadStateEnum from '../../enums/loadStateEnum';
import {useParams} from 'react-router-dom';
import Loading from '../../components/empty-states/loading/Loading';
import MarketingSubscribeSuccessPageFragment from '../marketingMessagesSubscribe/fragments/success/SuccessPageFragment';
import { growthbook } from '../../utils/analytics';
import EmailAddressConfirmationService from '../../services/emailAddressConfirmationService';
import optInToMarketingMessages from '../../api/homeseeker/optInToMarketingMessages';

export default function EmailAddressConfirmationPage() {
    const [loadState, setLoadState] = useState<LoadStateEnum | undefined>();
    const {id, listingCity} = useParams();
    const [isMarketingMessagesSubscriptionConfirmed, setIsMarketingMessagesSubscriptionConfirmed] = useState(false);
    const [isCombinedOptIn, setIsCombinedOptIn] = useState(false);
    const [isParariusPlusOptIn, setIsParariusPlusOptIn] = useState(false);

    const emailAddressConfirmationService = useRef(new EmailAddressConfirmationService());
    const [showOptInVersion, setShowOptInVersion] = useState(0);

    useEffect(() => {
        growthbook.setAttributes({
            ...growthbook.getAttributes(),
            id,
        });
    }, [id])

    useEffect(() => {
        switch (showOptInVersion) {
            case 0:
                setIsMarketingMessagesSubscriptionConfirmed(true);
                setIsCombinedOptIn(false);
                setIsParariusPlusOptIn(false);
                break;
            case 1:
                setIsMarketingMessagesSubscriptionConfirmed(false);
                setIsCombinedOptIn(true);
                setIsParariusPlusOptIn(false);
                break;
            default:
                setIsMarketingMessagesSubscriptionConfirmed(true);
                setIsCombinedOptIn(false);
                setIsParariusPlusOptIn(true);
                break;
        }
    }, [showOptInVersion]);

    useEffect(() => {
        if (growthbook.ready) {
            setShowOptInVersion(growthbook.evalFeature('a-b-test-pararius-plus-opt-in-2').value ?? showOptInVersion);
        }
    }, [growthbook.ready]);

    async function handleConfirmationSubmitButtonClick() {
        await confirmEmailAddress();
        await subscribeToMarketingMessages();
    }

    async function confirmEmailAddress() {
        setLoadState(LoadStateEnum.Loading);
        try {
            await emailAddressConfirmationService.current.confirmHomeseekerEmail(id)
            setLoadState(LoadStateEnum.Success);
        } catch(e) {
            setLoadState(LoadStateEnum.Error);
        }
    }

    async function subscribeToMarketingMessages() {
        setLoadState(LoadStateEnum.Loading);
        try {
            const huurwoningen = isMarketingMessagesSubscriptionConfirmed === true || isCombinedOptIn === true;
            const parariusPlus = isParariusPlusOptIn === true || isCombinedOptIn === true;

            if (huurwoningen || parariusPlus) {
                await optInToMarketingMessages({
                    homeseekerId: id,
                    huurwoningen,
                    parariusPlus
                });
            }

            setLoadState(LoadStateEnum.Success);
        } catch (e) {
            setLoadState(LoadStateEnum.Error);
        }
    }


    return (
        <>
            {loadState === LoadStateEnum.Loading ?
                <Loading/> :
                <AnnouncementPageFrame>
                    {loadState === undefined &&
                        <ConfirmationPageFragment
                            onError={() => setLoadState(LoadStateEnum.Error)}
                            service={emailAddressConfirmationService.current}
                            homeseekerId={id}
                            isMarketingMessagesSubscriptionConfirmed={isMarketingMessagesSubscriptionConfirmed}
                            isCombinedOptIn={isCombinedOptIn}
                            isParariusPlusOptIn={isParariusPlusOptIn}
                            onIsMarketingMessagesSubscriptionConfirmedChange={setIsMarketingMessagesSubscriptionConfirmed}
                            onIsCombinedOptInChange={setIsCombinedOptIn}
                            onIsParariusPlusOptInChange={setIsParariusPlusOptIn}
                            onSubmitButtonClick={handleConfirmationSubmitButtonClick}
                            showOptInVersion={showOptInVersion}
                        />
                    }
                    {loadState === LoadStateEnum.Success &&
                        (isMarketingMessagesSubscriptionConfirmed === true ? <MarketingSubscribeSuccessPageFragment listingCity={listingCity} /> : <SuccessPageFragment/>)
                    }
                    {loadState === LoadStateEnum.Error && <ErrorPageFragment/>}
                </AnnouncementPageFrame>
            }
        </>
    )
}

import api, {BodyData} from '../../utils/api';

interface OptInToMarketingMessagesPayload extends BodyData {
    homeseekerId: string;
    huurwoningen: boolean;
    parariusPlus: boolean;
}

const optInToMarketingMessages = async (payload: OptInToMarketingMessagesPayload): Promise<unknown> => {
    return await api.postWithoutAuth('api/subscribe-homeseeker-to-marketing-messages', payload)
}

export default optInToMarketingMessages;

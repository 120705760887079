import { findRouteByName } from '../../routes';
import { languageContext } from '../providers/LanguageProvider';
import { useLocation } from 'react-router-dom';

export const useRoutePath = () => {
    const language = languageContext();
    const location = useLocation();

    return (name: string, params: Record<string, string | number> = {}): string => {
        const route = findRouteByName(name);

        if (!route) {
            console.error('Route not found:', name, 'with params:', params, ' aborting route path generation');

            return location.pathname;
        }

        let finalParams = { ...params };

        if (params.lang === undefined) {
            finalParams = {
                ...params,
                lang: language.code
            };
        }

        return Object.keys(finalParams).reduce(
            (path, param) => path.replace(`:${param}`, String(finalParams[param])),
            route.path
        );
    };
};

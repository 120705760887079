import TopNavigation from '../../../components/navigation/TopNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {getAgentDetails} from '../../../redux/actions/agents/getAgentDetails'
import { createAgentWithLogins } from '../../../redux/actions/agents/createAgentWithLogins'
import { updateAgentWithLogins } from '../../../redux/actions/agents/updateAgentWithLogins'
import AgentDetailsForm from '../../../components/forms/agentDetails/AgentDetailsForm'
import HighlightBlock from '../../../componentsNew/molecules/highlightBlock/HighlightBlock';
import {Card} from '../../../componentsNew/organisms/card';
import Typography from '../../../componentsNew/atoms/typography/Typography';
import Text from '../../../componentsNew/atoms/typography/Typography';
import {ColorEnum} from '../../../componentsNew/enums';
import styles from './AgentDetails.module.css';

type Agent = {
    id: number;
    name: string;
    role: string;
    logins: { login: string }[];
    leadsAgentIds: string[];
    loginsPopulated: string[];
    emailsForInquiries: string[];
    defaultPhone: string;
    street: string;
    houseNumber: string;
    city: string;
    postalCode: string;
    logo: string;
    defaultEmail: string;
    isEmailSecure: boolean;
    privacyPolicy: string;
    showHouseNumber: boolean;
    parariusOfficeKey: string;
    allowCommercialContactToHomeseekers: boolean;
    commercialContactType: string;
    zohoId: string;
    hasActivatedScreeningService: boolean;
    url: string;
    isActive: boolean;
};

function AgentDetails() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { token } = useSelector((state: { users: { token: string } }) => state.users);
    const agentData = useSelector((state: { agents: { selectedAgent: Agent } }) => state.agents.selectedAgent);

    const navigate = useNavigate()

    const fetchAgentOnLoad = () => {
        if (id !== 'new') dispatch(getAgentDetails(token, id, { pagination: false }))
    }

    useEffect(() => {
        if (token) fetchAgentOnLoad()
    }, [dispatch, token])

    function computeLoginUpdateOperations(oldLoginsState, newLoginsState) {
        const loginsToDelete = oldLoginsState.filter(login => {
            return newLoginsState.indexOf(login.login) === -1
        })

        const oldLoginsStateAddr = oldLoginsState.map(login => login.login)

        const loginsToCreate = newLoginsState.filter(login => {
            return oldLoginsStateAddr.indexOf(login) === -1
        })

        loginsToCreate.forEach(loginAddr => {
            if (loginsToDelete.indexOf(loginAddr) > -1) {
                loginsToDelete.splice(loginsToDelete.indexOf(loginAddr), 1)
                loginsToCreate.splice(loginsToCreate.indexOf(loginAddr), 1)
            }
        })

        return { toAdd: loginsToCreate, toDelete: loginsToDelete }
    }

    function onFormSubmit(data) {

        const loginOperations = computeLoginUpdateOperations(agentData?.logins || [], data.loginsPopulated)

        const adaptedData = {
            url: data.url,
            city: data.city,
            logo: data.logo,
            houseNumber: data.houseNumber,
            street: data.street,
            postalCode: data.postalCode,
            defaultEmail: data.defaultEmail,
            defaultPhone: data.defaultPhone,
            isEmailSecure: data.isEmailSecure,
            privacyPolicy: data.privacyPolicy,
            showHouseNumber: data.showHouseNumber,
            emailsForInquiries: data.inquiriesAddresses,
            name: data.name,
            parariusOfficeKey: data.parariusOfficeKey,
            allowCommercialContactToHomeseekers: data.allowCommercialContactToHomeseekers,
            commercialContactType: data.commercialContactType,
            zohoId: data.zohoId
        }

        const redirect = () => {
            navigate('/admin', {
                replace: true // So that the store is updated when going back to the overview...
            })
        };

        if (id === 'new') {
            createAgentWithLogins(adaptedData, loginOperations.toAdd).then(redirect);
        } else {
            updateAgentWithLogins(token, agentData.id, adaptedData, loginOperations.toAdd, loginOperations.toDelete, dispatch).then(redirect);
        }
    }

    function title() {
        if (!agentData || !agentData.name) {
            return 'Settings van Nieuwe Makelaar';
        }

        return 'Settings van ' + agentData.name;
    }

    function renderParariusOfficeSummary() {
        return <Card
            className={styles.parariusOfficeSummary}
            hasPadding={true}
            backgroundColor={ColorEnum.Gray100}
        >
            <Typography type={'h6'}>Gegevens uit Pararius Office/Gebruikersbeheer</Typography>
            <Text type={'p'}>
                Zoho ID: {agentData.zohoId}<br />
                Naam kantoor: {agentData.name}<br />
                Adres: {agentData.street} {agentData.houseNumber}, {agentData.postalCode} {agentData.city}<br />
                Telefoonnummer: {agentData.defaultPhone}<br />
                E-mailadres: {agentData.defaultEmail}<br />
                Website: {agentData.url ? agentData.url : 'Geen website bekend'}<br />
                Pararius Office Key: {agentData.parariusOfficeKey ? agentData.parariusOfficeKey : 'Geen Pararius Office API key bekend'}<br />
            </Text>
        </Card>
    }

    return (
        <>
            <TopNavigation />
            <div className="min-h-full">
                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <h1 className="text-3xl font-bold mb-6 ">{title()}</h1>
                            {!agentData.isActive && <HighlightBlock type={'warning'}>Makelaar is offboarded vanuit Pararius Office</HighlightBlock>}
                            {renderParariusOfficeSummary()}
                            <AgentDetailsForm agentData={agentData} onSubmitHandler={onFormSubmit} />
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AgentDetails


import api, { BodyData } from '../../../../utils/api';

export interface SubmitIncomeSourceDocumentsBusinessOwnershipPayload extends BodyData {
    annualStatement?: FileList,
}

const submitIncomeDocumentsBusinessOwnership = async (homeseekerId: string, sourceId: string, data: SubmitIncomeSourceDocumentsBusinessOwnershipPayload): Promise<unknown> => {
    return await api.postFormData(`api/screening/${homeseekerId}/income-verification/${sourceId}/save/business-ownership/documents`, data);
}

export default submitIncomeDocumentsBusinessOwnership;

import { Trans, useTranslation } from 'react-i18next';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum as ColorEnumNew, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import ColorEnum from '../../../../../enums/colorEnum';
import styles from './OverviewContent.module.css';
import screeningSteps, { Action } from '../../../../organisms/screening-navigation/screeningSteps';
import { Card } from '../../../../organisms/card';
import ScreeningNavigation from '../../../../organisms/screening-navigation/ScreeningNavigation';
import { useNavigate, useParams } from 'react-router-dom';
import { useRoutePath } from '../../../../utils/useRoutePath';
import { AddIncomeButton } from '../../../../molecules/add-income-button';
import { IncomeSourceCard } from '../../../../organisms/income-source-card';
import IncomeVerificationIncomeSourceTypeEnum from '../../../../enums/screening/IncomeVerificationIncomeSourceTypeEnum';
import { useEffect, useState } from 'react';
import findIncomeSources, { IncomeSource } from '../../../../../api/screening/income-verification/findIncomeSources';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../redux/actions/ui/setNotification';
import SkeletonComponent from '../../../../../components/SkeletonComponent';
import Modal from '../../../../../components/organisms/modal/Modal';
import { classNames, formatPrice } from '../../../../utils';
import ButtonNew from '../../../../molecules/button/Button';
import ProcessRequestFeedback from '../../../../molecules/process-request-feedback/ProcessRequestFeedback';
import deleteIncomeVerificationSource from '../../../../../api/screening/income-verification/delete-income-source/deleteIncomeVerificationSource';

const OverviewContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const { homeseekerId } = useParams();
    const dispatch = useDispatch();

    const [ incomeSources, setIncomeSources ] = useState([]);
    const [ isFetchingIncomeSources, setIsFetchingIncomeSources ] = useState<boolean>(false);
    const [ deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen ] = useState<boolean>(false);
    const [ incomeSourceToDelete, setIncomeSourceToDelete ] = useState<IncomeSource>(null);
    const [ deletedIncomeSourceId, setDeletedIncomeSourceId ] = useState<string>(null);
    const [ isDeletingIncomeVerificationSource, setIsDeletingIncomeVerificationSource ] = useState<boolean>(false);
    const [ isDeletingIncomeVerificationSourceSuccess, setIsDeletingIncomeVerificationSourceSuccess ] = useState<boolean>(false);

    useEffect(() => {
        fetchIncomeSources();
    }, []);

    const fetchIncomeSources = async () => {
        setIsFetchingIncomeSources(true);

        try {
            const response = await findIncomeSources(homeseekerId);

            setIncomeSources(response);
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingIncomeSources(false);
        }
    }

    const handleAddIncomeClick = () => {
        navigate(routePath('ScreeningIncomeVerificationIncomeSourceCategory', { homeseekerId }));
    }

    const handleEditIncomeClick = (incomeSource: IncomeSource) => {
        navigate(routePath('ScreeningIncomeVerificationProvideIncomeDetails', { homeseekerId, sourceId: incomeSource.id, type: incomeSource.type }));
    }

    const deleteSelectedIncome = async () => {
        setIsDeletingIncomeVerificationSource(true);
        setIsDeletingIncomeVerificationSourceSuccess(false);

        const startTime = Date.now();

        try {
            await deleteIncomeVerificationSource(incomeSourceToDelete.id);

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsDeletingIncomeVerificationSource(false);
            setIsDeletingIncomeVerificationSourceSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            setDeleteConfirmationModalIsOpen(false);

            await new Promise(resolve => setTimeout(resolve, 300));

            setDeletedIncomeSourceId(incomeSourceToDelete.id);
            setIncomeSourceToDelete(null);

            setIncomeSources(incomeSources.filter((incomeSource: IncomeSource) => incomeSource.id !== incomeSourceToDelete.id));

            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: t('screening.incomeVerification.overview.deletion.modal.notification.success', { type: t(`incomeSourceCard.incomeType.${incomeSourceToDelete.type}`) })
            }));
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('screening.incomeVerification.overview.deletion.modal.notification.failed')
            }));
        } finally {
            setIsDeletingIncomeVerificationSource(false);
            setIsDeletingIncomeVerificationSourceSuccess(false);
        }
    }

    const handleDeleteIncomeClick = (incomeSource: IncomeSource) => {
        setDeleteConfirmationModalIsOpen(true);
        setIncomeSourceToDelete(incomeSource);
    }

    const handleNavigationClick = (action: Action) => {
        if (action === 'skip') {
            navigate(routePath('ScreeningAdditionalInformation', { homeseekerId }));
        }
    }

    return (
        <div className={styles.contentContainer}>
            <div className={styles.gridLg}>
                <Text
                    color={ColorEnumNew.Primary700}
                    weight={FontWeightEnum.Bold}
                    size={FontSizeEnum.DisplayXs}
                >{t(screeningSteps.ScreeningIncomeVerificationProvideIncomeDetails.pageTitleTranslationKey)}</Text>
                <Card elevated>
                    <div className={styles.gridMd}>
                        {isFetchingIncomeSources ? (
                            <>
                                <SkeletonComponent height={24}/>
                                <SkeletonComponent height={114}/>
                            </>
                        ) : (
                            <>
                                {(incomeSources.length > 0) ? (
                                    <>
                                        <Text
                                            weight={FontWeightEnum.Semibold}
                                        >{t('screening.incomeVerification.overview.title')}</Text>
                                        <Text
                                            weight={FontWeightEnum.Regular}
                                        >{t('screening.incomeVerification.overview.description')}</Text>
                                    </>
                                ): (
                                    <Text
                                        weight={FontWeightEnum.Semibold}
                                    >{t('screening.incomeVerification.overview.title.noData')}</Text>
                                )}
                                {incomeSources.map((incomeSource: IncomeSource, index: number) => (
                                    <IncomeSourceCard
                                        key={index}
                                        incomeType={incomeSource.type as IncomeVerificationIncomeSourceTypeEnum}
                                        grossIncome={incomeSource.income}
                                        isDeleting={deletedIncomeSourceId === incomeSource.id}
                                        onEditClick={() => handleEditIncomeClick(incomeSource)}
                                        onDeleteClick={() => handleDeleteIncomeClick(incomeSource)}
                                    />
                                ))}
                                <AddIncomeButton
                                    onClick={handleAddIncomeClick}
                                />
                            </>
                        )}
                    </div>
                </Card>
                <ScreeningNavigation
                    onClick={handleNavigationClick}
                />
            </div>
            <Modal
                flowyColor={ColorEnum.Error50}
                icon={'ri-delete-bin-line'}
                iconBackgroundColor={ColorEnum.Error100}
                iconColor={ColorEnum.Error700}
                isOpen={deleteConfirmationModalIsOpen}
                onCloseButtonClick={() => setDeleteConfirmationModalIsOpen(false)}
            >
                {incomeSourceToDelete &&
                    <div className={styles.gridMd}>
                        <Text
                            color={ColorEnumNew.Gray800}
                            weight={FontWeightEnum.Bold}
                            size={FontSizeEnum.Lg}
                            tag={'h2'}
                        >{t('screening.incomeVerification.overview.deletion.modal.title', { type: t(`incomeSourceCard.incomeType.${incomeSourceToDelete.type}`) })}</Text>
                        <Text tag='p'>{t('screening.incomeVerification.overview.deletion.modal.p1')}</Text>
                        <Text tag='p'>
                            <Trans
                                i18nKey="screening.incomeVerification.overview.deletion.modal.p2"
                                values={{
                                    type: t(`incomeSourceCard.incomeType.${incomeSourceToDelete.type}`),
                                    price: formatPrice(incomeSourceToDelete.income)
                                }}
                                components={{strong: <strong/>}}
                            />
                        </Text>
                        <div className={classNames(styles.footer, (!isDeletingIncomeVerificationSource && !isDeletingIncomeVerificationSourceSuccess) && styles.noColumnGap)}>
                            <ProcessRequestFeedback
                                isSubmitting={isDeletingIncomeVerificationSource}
                                isSuccessful={isDeletingIncomeVerificationSourceSuccess}
                            />
                            <div className={styles.buttonsContainer}>
                                <ButtonNew
                                    disabled={isDeletingIncomeVerificationSource || isDeletingIncomeVerificationSourceSuccess}
                                    variant='secondary-gray'
                                    onClick={() => setDeleteConfirmationModalIsOpen(false)}
                                >{t('screening.incomeVerification.overview.deletion.modal.button.cancel')}</ButtonNew>
                                <ButtonNew
                                    disabled={isDeletingIncomeVerificationSource || isDeletingIncomeVerificationSourceSuccess}
                                    variant='primary-destructive'
                                    onClick={() => deleteSelectedIncome()}
                                >{t('screening.incomeVerification.overview.deletion.modal.button.delete')}</ButtonNew>
                            </div>
                        </div>
                    </div>
                }
            </Modal>
        </div>
    );
}

export default OverviewContent;

import CandidateStatus from '../enums/candidateStatus'
import CandidateModel from '../models/candidate/candidateModel'
import { stringToEnum } from '../utils/enumType'
import CandidateScreeningRequestStatusEnum from '../enums/candidateScreeningRequestStatusEnum';
import CandidateScreeningRequestOutcomeEnum from '../enums/candidateScreeningRequestOutcomeEnum'
import ViewingModel from '../models/candidate/viewingModel'
import GenderEnum from '../enums/genderEnum'
import MessageModel from '../models/candidate/messageModel'
import NoteModel from '../models/candidate/noteModel'

export interface CandidateJSON {
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly phone: string,
    readonly locale: string,
    readonly guarantor: string,
    readonly employment: string,
    readonly gender: Readonly<GenderEnum>,
    readonly income: number,
    readonly household: string,
    readonly householdType: string,
    readonly pets: boolean,
    readonly term: number,
    readonly availableSince: string,
    readonly extraInfoFormLastSentOn: string | undefined,
    readonly birthday: string,
    readonly messages: ReadonlyArray<MessageModel>,
    readonly notes: ReadonlyArray<NoteModel>,
    readonly status: string,
    readonly transactionalListingId: string,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly fullName: string,
    readonly hasAcceptedAgentPrivacyPolicy: boolean,
    readonly screeningRequestId: string,
    readonly screeningRequestStatus: string,
    readonly screeningRequestRating: number,
    readonly screeningRequestOutcome: string,
    readonly isListingTenant: boolean,
    readonly hasCoTenants: boolean,
    readonly hasCoTenantsWithInvitedScreeningRequests: boolean,
    readonly viewings: ReadonlyArray<ViewingModel> | null,
    readonly upcomingOrMostRecentViewing: ViewingModel | null,
    readonly source: string | null
}

export default class CandidateJsonToModelConverter {

    public static convert(obj: CandidateJSON): CandidateModel {
        return new CandidateModel(
            obj.id,
            obj.firstName,
            obj.lastName,
            obj.email,
            obj.phone,
            obj.locale,
            obj.guarantor,
            obj.employment,
            obj.gender,
            obj.income,
            obj.household,
            obj.householdType,
            obj.pets,
            obj.term,
            obj.availableSince,
            obj.extraInfoFormLastSentOn,
            obj.birthday,
            obj.messages,
            obj.notes,
            obj.viewings,
            stringToEnum(obj.status, CandidateStatus),
            obj.transactionalListingId,
            obj.createdAt,
            obj.updatedAt,
            obj.fullName,
            obj.hasAcceptedAgentPrivacyPolicy,
            obj.screeningRequestId,
            obj.screeningRequestStatus ? stringToEnum(obj.screeningRequestStatus, CandidateScreeningRequestStatusEnum) : null,
            obj.screeningRequestRating,
            obj.screeningRequestOutcome ? stringToEnum(obj.screeningRequestOutcome, CandidateScreeningRequestOutcomeEnum) : null,
            obj.isListingTenant,
            obj.hasCoTenants,
            obj.hasCoTenantsWithInvitedScreeningRequests,
            obj.upcomingOrMostRecentViewing,
            obj.source
        )
    }
}


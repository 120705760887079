import { IncomeFrequencyEnum } from '../../../../componentsNew/enums';
import api, { BodyData } from '../../../../utils/api';

export interface SubmitIncomeSourceEmploymentPayload extends BodyData {
    employer: string,
    contractType: string,
    startDate: string,
    endDate?: string,
    grossIncome?: number,
    incomeFrequency?: IncomeFrequencyEnum;
    holidayAllowanceIncluded?: boolean;
    thirteenthMonthPayIncluded?: boolean;
    thirtyPercentFacilityApplicable?: boolean;
    additionalIncome?: number;
    additionalIncomeFrequency?: IncomeFrequencyEnum;
    additionalIncomeComment?: string;
}

const submitIncomeDetailsEmployment = async (homeseekerId: string, sourceId: string, data: SubmitIncomeSourceEmploymentPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/screening/${homeseekerId}/income-verification/${sourceId}/save/employment`, data);
}

export default submitIncomeDetailsEmployment;

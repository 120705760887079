import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../enums';
import { Card } from '../../../organisms/card';
import styles from './ScreeningHouseholdContent.module.css';
import { useTranslation } from 'react-i18next';
import Text from '../../../atoms/typography/Text'
import HighlightBlock from '../../../molecules/highlightBlock/HighlightBlock';
import IncrementalNumberInput from '../../../molecules/incremental-number-input/IncrementalNumberInput';
import { RadioInput } from '../../../atoms/radio-input';
import React, { useEffect, useState } from 'react';
import ButtonNew from '../../../molecules/button/Button';
import AddLineIcon from '../../../atoms/icons/AddLineIcon';
import PetSelector from '../../../organisms/pet-selector/PetSelector';
import { setNotification } from '../../../../redux/actions/ui/setNotification';
import { useNavigate, useParams } from 'react-router-dom';
import submitScreeningHouseholdCompositionRequest, { submitScreeningHouseholdCompositionPayload } from '../../../../api/screening/submitScreeningHouseholdComposition';
import { useDispatch } from 'react-redux';
import findScreeningHouseholdComposition, { HouseholdComposition } from '../../../../api/screening/findScreeningHouseholdComposition';
import SkeletonComponent from '../../../../components/SkeletonComponent';
import ScreeningNavigation from '../../../organisms/screening-navigation/ScreeningNavigation';
import Form, { Data, FormErrors } from '../../../molecules/form/Form';
import FormError from '../../../molecules/form/FormError';
import screeningSteps from '../../../organisms/screening-navigation/screeningSteps';
import { useRoutePath } from '../../../utils/useRoutePath';

const ScreeningHouseholdContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const routePath = useRoutePath();

    const [hasPets, setHasPets] = useState(null);
    const [ isFetchingHouseholdComposition, setIsFetchingHouseholdComposition ] = useState<boolean>(true);
    const [ , setHouseholdComposition ] = useState<HouseholdComposition>(null);
    const { verificationRequestAuthorisationId } = useParams();
    const [ isSubmittingHouseholdComposition, setIsSubmittingHouseholdComposition ] = useState<boolean>(false);
    const [ isSubmittingHouseholdCompositionSuccess, setIsSubmittingHouseholdCompositionSuccess ] = useState<boolean>(false);

    const [, setIsFormValid] = useState<boolean>(false);
    const [formData, setFormData] = useState<Data>({});
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    const [payload, setPayload] = useState<submitScreeningHouseholdCompositionPayload>({
        screeningVerificationRequestAuthorisationId: verificationRequestAuthorisationId,
        amountOfAdults: 1,
        amountOfChildren: 0,
        pets: []
    });

    useEffect(() => {
        fetchScreeningHouseholdComposition();
    }, []);

    const handleAmountOfAdultsChange = (value: number) => {
        const formDataClone = formData;
        formDataClone['amountOfAdults'].value = value.toString();
        setFormData({...formData, ...formDataClone});

        setPayload(prevPayload => {
            return {
                ...prevPayload,
                amountOfAdults: value
            }
        })
    }

    const handleAmountOfChildrenChange = (value: number) => {
        const formDataClone = formData;
        formDataClone['amountOfChildren'].value = value.toString();
        setFormData({...formData, ...formDataClone});

        setPayload(prevPayload => {
            return {
                ...prevPayload,
                amountOfChildren: value
            }
        })
    }

    const addRow = () => {
        if (payload.pets.length < 50) {
            setPayload(prevPayload => ({
                ...prevPayload,
                pets: [...prevPayload.pets, { species: 'cat', amount: 1 }]
            }));
        }
    };

    const handleHasPetsChange = (value: string | null) => {
        setHasPets(value);
        if (value === 'false') {
            setPayload({
                ...payload,
                pets: []
            });
        } else {
            setPayload({
                ...payload,
                pets: [{ species: 'cat', amount: 1 }]
            });
        }
    }

    const handlePetsChange = (index: number, species?: string, amount?: number) => {
        const updatedPets = [...payload.pets];

        if (species !== undefined) {
            updatedPets[index] = { species: species, amount: updatedPets[index].amount };
        }

        if (amount !== undefined) {
            updatedPets[index] = {species: updatedPets[index].species, amount: amount};
        }

        setPayload({
            ...payload,
            pets: updatedPets
        });
    }

    const handleDelete = (index: number) => {
        const updatedPets = [...payload.pets];

        updatedPets.splice(index, 1);

        setPayload({
            ...payload,
            pets: updatedPets
        });
    };

    const handleSubmit = async () => {
        setIsSubmittingHouseholdCompositionSuccess(false);
        setIsSubmittingHouseholdComposition(true);

        const startTime = Date.now();

        try {
            const reseponse = await submitScreeningHouseholdCompositionRequest(payload);
            const homeseekerId = reseponse.homeseekerId;

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsSubmittingHouseholdComposition(false);
            setIsSubmittingHouseholdCompositionSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            if (payload.amountOfAdults > 1) {
                navigate(routePath('ScreeningCoTenants', { verificationRequestAuthorisationId }));
            } else {
                navigate(routePath('ScreeningIdVerificationIntroduction', { homeseekerId }));
            }
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingHouseholdComposition(false);
            setIsSubmittingHouseholdCompositionSuccess(false);
        }
    }

    const fetchScreeningHouseholdComposition = async () => {
        try {
            setIsFetchingHouseholdComposition(true);

            const response = await findScreeningHouseholdComposition(verificationRequestAuthorisationId);
            const householdCompositionRef = response.householdComposition;

            setHouseholdComposition(householdCompositionRef);

            const pets = householdCompositionRef.pets.map((pet, index) => ({
                species: pet.species,
                amount: pet.amount,
                index
            }));

            if (householdCompositionRef.adults !== null) {
                setHasPets(`${householdCompositionRef.pets.length > 0}`)
            }

            setPayload({
                ...payload,
                amountOfAdults: householdCompositionRef.adults ?? 1,
                amountOfChildren: householdCompositionRef.children ?? 0,
                pets
            });
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingHouseholdComposition(false);
        }
    }

    const handleFormDataChange = (data: Data, isValid: boolean, errors: FormErrors) => {
        setFormData(data);
        setIsFormValid(isValid);
        setFormErrors(errors);
    }

    if (isFetchingHouseholdComposition) {
        return (
            <div className={styles.contentContainer}>
                <div className={styles.gridLg}>
                    <SkeletonComponent height={30} width={200}/>
                    <SkeletonComponent height={58}/>
                    <SkeletonComponent height={200}/>
                    <div className={styles.loadingButtonContainer}>
                        <SkeletonComponent height={42} width={100}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Form
            className={styles.contentContainer}
            onChange={handleFormDataChange}
            onSubmit={handleSubmit}
        >
            <input name="screeningVerificationRequestAuthorisationId" defaultValue={verificationRequestAuthorisationId} hidden/>
            <div className={styles.gridLg}>
                <Text
                    color={ColorEnum.Primary700}
                    weight={FontWeightEnum.Bold}
                    size={FontSizeEnum.DisplayXs}
                >{t(screeningSteps.ScreeningHousehold.pageTitleTranslationKey)}</Text>
                <HighlightBlock type='info'>{t('screening.household.info')}</HighlightBlock>
                <Card elevated>
                    <div className={styles.gridMd}>
                        <Text color={ColorEnum.Gray800} html={t('screening.household.text')}></Text>
                        <div className={styles.humanCompositionContainer}>
                            <div className={styles.gridSm}>
                                <IncrementalNumberInput
                                    name='amountOfAdults'
                                    label={t('screening.household.amountOfAdults.label')}
                                    min={1}
                                    max={50}
                                    value={Number(formData['amountOfAdults']?.value ?? payload.amountOfAdults ?? 1)}
                                    onChange={handleAmountOfAdultsChange}
                                />
                                <IncrementalNumberInput
                                    name='amountOfChildren'
                                    label={t('screening.household.amountOfChildren.label')}
                                    min={0}
                                    max={50}
                                    value={Number(formData['amountOfChildren']?.value ?? payload.amountOfChildren ?? 0)}
                                    onChange={handleAmountOfChildrenChange}
                                />
                            </div>
                        </div>
                        <RadioInput
                            name='hasPets'
                            label={t('screening.household.hasPets.label')}
                            value={hasPets}
                            onChange={handleHasPetsChange}
                            isBoolean
                            required
                        />
                        <FormError errors={formErrors['hasPets']} />
                        {hasPets === 'true' && (
                            <div className={styles.gridMd}>
                                <div>
                                    <Text
                                        color={ColorEnum.Gray800}
                                        weight={FontWeightEnum.Regular}
                                        html={t('screening.household.amountOfPets.text')}
                                    ></Text>
                                </div>
                                <div className={styles.petCompositionContainer}>
                                    <div className={styles.gridSm}>
                                        {payload.pets.map((pet, index) => (
                                            <div key={index}>
                                                <PetSelector
                                                    index={index}
                                                    name='pets'
                                                    value={pet.species}
                                                    amount={pet.amount}
                                                    label={t('screening.household.amountOfPets.other.text')}
                                                    inputFieldPlaceholder={t('screening.household.listItem.other')}
                                                    maxLengthInputFieldValue={100}
                                                    minIncrementalNumberInputValue={1}
                                                    maxIncrementalNumberInputValue={50}
                                                    onChange={handlePetsChange}
                                                    isDeletable={payload.pets.length > 1}
                                                    onDelete={() => handleDelete(index)}
                                                />
                                                <FormError errors={formErrors[`petsSpecies-${index}`]} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={styles.inputGrid}>
                                    {payload.pets.length < 50 &&
                                        <ButtonNew
                                            className={styles.buttonMargin}
                                            variant="link-gray"
                                            onClick={addRow}
                                            iconBefore={<AddLineIcon/>}
                                        >{t('screening.household.addPets')}</ButtonNew>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </Card>
                <ScreeningNavigation
                    isSubmitting={isSubmittingHouseholdComposition}
                    isSuccessful={isSubmittingHouseholdCompositionSuccess}
                />
            </div>
        </Form>
    );
}

export default ScreeningHouseholdContent;

import { ReactNode } from 'react';
import styles from './FeaturedIconWithText.module.css';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import Text from '../../atoms/typography/Text';
import { FeaturedIcon } from '../../atoms/featured-icon';

interface FeaturedIconWithTextProps {
    readonly type?: 'gray' | 'info' | 'warning' | 'error' | 'success';
    readonly icon: ReactNode;
    readonly text: string;
    readonly fontSize?: FontSizeEnum;
    readonly fontWeight?: FontWeightEnum;
}

const FeaturedIconWithText = ({
    type = 'info',
    icon,
    text,
    fontSize = FontSizeEnum.Md,
    fontWeight = FontWeightEnum.Light,
}: FeaturedIconWithTextProps) => {
    return (
        <div className={styles.feedbackContainer}>
            <FeaturedIcon icon={icon} type={type} />
            <Text
                size={fontSize}
                weight={fontWeight}
                color={ColorEnum.Gray800}
            >{text}</Text>
        </div>
    );
};

export default FeaturedIconWithText;

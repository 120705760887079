/* eslint-disable */
const LeadflowWindow = {
    ps: (window as any).ps,
    Cookiebot: (window as any).Cookiebot,
    dataLayer: (window as any).dataLayer,
    NODE_ENV: (window as any).NODE_ENV,
    REACT_APP_AUTH_AUDIENCE: (window as any).REACT_APP_AUTH_AUDIENCE,
    REACT_APP_AUTH_CLIENT_ID: (window as any).REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_AUTH_DOMAIN: (window as any).REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_SCOPE: (window as any).REACT_APP_AUTH_SCOPE,
    REACT_APP_HOTJAR_SITE_ID: (window as any).REACT_APP_HOTJAR_SITE_ID,
    REACT_APP_HOTJAR_VERSION: (window as any).REACT_APP_HOTJAR_VERSION,
    REACT_APP_IS_AUTH_IN_TEST_MODE: (window as any).REACT_APP_IS_AUTH_IN_TEST_MODE,
    REACT_APP_LEADFLOW_API: (window as any).REACT_APP_LEADFLOW_API,
    REACT_APP_SENTRY_DSN: (window as any).REACT_APP_SENTRY_DSN,
};

export default LeadflowWindow;

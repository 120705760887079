import React, { useEffect, useState } from 'react';
import styles from './QRCode.module.css';
import { default as QRCodeModule } from 'qrcode';

type QrCodeProps = {
    url: string;
    alt?: string;
};

const QRCode = ({
    url,
    alt = 'QR Code',
}: QrCodeProps) => {
    const [generatedUrl, setGeneratedUrl] = useState('');

    useEffect(() => {
        generateQRCode();
    }, []);

    const generateQRCode = async () => {
        try {
            const qrCodeDataUrl = await QRCodeModule.toDataURL(
                url,
                {
                    color: {dark: '#2C364F', light: '#ffffff'}
                }
            );
            setGeneratedUrl(qrCodeDataUrl);
        } catch (err) {
            console.error('Failed to generate QR Code:', err);
            // TODO: Add error handling
        }
    };

    return (
        <div className={styles.container}>
            {generatedUrl && <img className={styles.image} src={generatedUrl} alt={alt}/>}
        </div>
    );
};

export default QRCode;

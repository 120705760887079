import api, { BodyData } from '../../utils/api';

export interface SubmitHomeseekerApplicationPayload extends BodyData {
    homeseekerApplicationId: string;
    birthday: string;
    availableSince: string;
    employment: string;
    firstName: string;
    gender: string;
    guarantor: string;
    household: string;
    householdType: string;
    income: number;
    pets: boolean;
    phone: string;
    term: number;
    terms: boolean;
    hasAcceptedAgentPrivacyPolicy: boolean;
    locale: string;
}

const submitHomeseekerApplication = async (payload: SubmitHomeseekerApplicationPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/homeseeker/application/${payload.homeseekerApplicationId}/enrich`, payload);
}

export default submitHomeseekerApplication;

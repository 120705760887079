import React, { ReactNode } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../redux/actions/ui/removeNotification';
import styles from './BasicNotification.module.css'

export enum NotificationTypes {
    FAILED = 'failed',
    FAILED_DARK = 'failed_dark',
    SUCCESS = 'success',
}

interface BasicNotificationProps {
    id: string
    type: NotificationTypes
    message: ReactNode
}

const BasicNotification = ({
    id,
    type = NotificationTypes.FAILED,
    message,
}: BasicNotificationProps) => {
    const dispatch = useDispatch();

    setTimeout(() => {
        dispatch(removeNotification(id));
    }, 8000);

    const getClassname = (type) => {
        switch(type) {
            case NotificationTypes.FAILED_DARK:
                return styles.notificationFailedDark;
            case NotificationTypes.FAILED:
                return styles.notificationFailed;
            case NotificationTypes.SUCCESS:
                return styles.notificationSuccess;
        }
    }

    const getCloseButtonClassNames = (type) => {
        switch(type) {
            case NotificationTypes.FAILED_DARK:
                return styles.closeButtonFailedDark;
            case NotificationTypes.FAILED:
                return styles.closeButtonFailed;
            case NotificationTypes.SUCCESS:
                return styles.closeButtonSuccess;
        }
    }

    return (
        <div
            className="w-full flex flex-col items-start justify-center"
        >
            <div
                className={`${styles.notification} ${getClassname(type)}`}
            >
                <div className={styles.notificationContent}>
                    <div className={styles.iconContainer}>
                        {type === NotificationTypes.SUCCESS ? (
                            <CheckCircleIcon className={styles.iconSuccess} aria-hidden="true" />
                        ) : (
                            <ExclamationCircleIcon className={(type === NotificationTypes.FAILED_DARK) ? styles.iconFailedDark : styles.iconFailed} aria-hidden="true" />
                        )}
                    </div>
                    <div className={styles.messageContainer}>
                        <p className={styles.message}>{message}</p>
                    </div>
                    <div className={styles.closeButtonContainer}>
                        <button
                            type="button"
                            className={`${styles.closeButton} ${getCloseButtonClassNames(type)}`}
                            onClick={() => dispatch(removeNotification(id))}
                        >
                            <span className="sr-only">Close</span>
                            <XIcon className={styles.closeIcon} aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BasicNotification;

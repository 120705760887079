import api from '../../../utils/api';

export interface GeneralSettings {
    readonly agencyId: string;
    readonly privacyPolicyUrl: string;
    readonly logoUrl: string
}

export interface GeneralSettingsResponse {
    readonly generalSettings: GeneralSettings
}

const fetchGeneralSettings = async (selectedAgencyId: string): Promise<GeneralSettings> => {
    const response: GeneralSettingsResponse = await api.get(`api/agency/${selectedAgencyId}/settings/general`);

    return response.generalSettings;
};

export default fetchGeneralSettings;

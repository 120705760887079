import api from '../../../utils/api'
import {LISTING_ACTIONS, UI_ACTIONS} from '../../../utils/constants'

let lastFetchedParams = null;

export const getAllCandidates = (token, id, params, ignoreCache = false) => dispatch => {
    if (!ignoreCache && lastFetchedParams !== null && lastFetchedParams === id + JSON.stringify(params)) {
        return;
    }

    if(params.status === 'all'){
        params.status = null;
    }

    dispatch({type: UI_ACTIONS.START_LOADING});
    api.getJsonLd('api/get-homeseeker-applications', {
        ...params,
        transactionalListingId: id,
    })
        .then((response) => {
            dispatch({
                type: LISTING_ACTIONS.SET_CANDIDATE_COUNT_PER_SELECTED_LISTING,
                payload: response
            });
            lastFetchedParams = id + JSON.stringify(params);
        })
        .catch((e) => {
            dispatch({type: UI_ACTIONS.STOP_LOADING});
            console.error(e);
        })
        .finally(() => dispatch({type: UI_ACTIONS.STOP_LOADING}));
}

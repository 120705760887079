import isNumber from './isNumber';

const formatPrice = (price?: number, showCurrency = true, round = true): string => {
    if (!isNumber(price)) {
        return '-';
    }

    const currency = new Intl.NumberFormat('nl-NL', {
        maximumFractionDigits: round ? 0 : 2,
    });

    let result = currency.format(price);

    if (showCurrency) {
        result = `€${result}`;
    }

    return result;
}

export default formatPrice;

import styles from './ProvideIncomeDetails.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import EmploymentContent from './EmploymentContent';
import { ReactNode, useEffect, useState } from 'react';
import Form, { Data, FormErrors } from '../../../../../molecules/form/Form';
import Text from '../../../../../atoms/typography/Text';
import { Card } from '../../../../../organisms/card';
import ScreeningNavigation from '../../../../../organisms/screening-navigation/ScreeningNavigation';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../../enums';
import screeningSteps, { Action } from '../../../../../organisms/screening-navigation/screeningSteps';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../redux/actions/ui/setNotification';
import { useRoutePath } from '../../../../../utils/useRoutePath';
import submitIncomeDetails, { SubmitIncomeDetailsPayload } from '../../../../../../api/screening/income-verification/submit-income-details/submitIncomeDetails';
import IncomeVerificationIncomeSourceTypeEnum from '../../../../../enums/screening/IncomeVerificationIncomeSourceTypeEnum';
import SkeletonComponent from '../../../../../../components/SkeletonComponent';
import findIncomeSourceDetails, { IncomeSource } from '../../../../../../api/screening/income-verification/find-income-source-details/findIncomeSourceDetails';
import FreelanceContent from './FreelanceContent';
import BusinessOwnershipContent from './BusinessOwnershipContent';
import { IncomeSourceEmployment } from '../../../../../../api/screening/income-verification/find-income-source-details/findIncomeSourceEmployment';
import { IncomeSourceFreelance } from '../../../../../../api/screening/income-verification/find-income-source-details/findIncomeSourceFreelance';
import { IncomeSourceBusinessOwnership } from '../../../../../../api/screening/income-verification/find-income-source-details/findIncomeSourceBusinessOwnership';

const ProvideIncomeDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const { homeseekerId, sourceId, type } = useParams();

    const [ storedData, setStoredData ] = useState<IncomeSource>(null);
    const [ formData, setFormData ] = useState<Data>({});
    const [ formErrors, setFormErrors ] = useState<FormErrors>({});
    const [ isFetchingIncomeSourceDetails, setIsFetchingIncomeSourceDetails ] = useState<boolean>(true);
    const [ isSubmittingIncomeDetails, setIsSubmittingIncomeDetails ] = useState<boolean>(false);
    const [ isSubmittingIncomeDetailsSuccess, setIsSubmittingIncomeDetailsSuccess ] = useState<boolean>(false);
    const [ payload, setPayload ] = useState<SubmitIncomeDetailsPayload>(null);

    useEffect(() => {
        fetchIncomeSource();
    }, []);

    const fetchIncomeSource = async () => {
        try {
            const response = await findIncomeSourceDetails(type.replaceAll('-', ' ') as IncomeVerificationIncomeSourceTypeEnum, sourceId);

            setStoredData(response);
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingIncomeSourceDetails(false);
        }
    }

    const renderIncomeSourceForm = (): ReactNode => {
        switch(type.replaceAll('-', ' ')) {
            case IncomeVerificationIncomeSourceTypeEnum.Employment:
                return <EmploymentContent storedData={storedData as IncomeSourceEmployment} formData={formData} formErrors={formErrors} onChange={setPayload}/>;
            case IncomeVerificationIncomeSourceTypeEnum.Freelance:
                return <FreelanceContent storedData={storedData as IncomeSourceFreelance} formData={formData} formErrors={formErrors} onChange={setPayload}/>;
            case IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership:
                return <BusinessOwnershipContent storedData={storedData as IncomeSourceBusinessOwnership} formData={formData} formErrors={formErrors} onChange={setPayload}/>;
            default:
        }
    }

    const handleFormDataChange = (data: Data, _isValid: boolean, errors: FormErrors) => {
        setFormData(data);
        setFormErrors(errors);
    }

    const handleSubmit = async () => {
        setIsSubmittingIncomeDetailsSuccess(false);
        setIsSubmittingIncomeDetails(true);

        const startTime = Date.now();

        try {
            await submitIncomeDetails(type as IncomeVerificationIncomeSourceTypeEnum, homeseekerId, sourceId, payload);

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsSubmittingIncomeDetails(false);
            setIsSubmittingIncomeDetailsSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            navigate(routePath('ScreeningIncomeVerificationProvideDocuments', { homeseekerId, sourceId, type }));
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingIncomeDetails(false);
            setIsSubmittingIncomeDetailsSuccess(false);
        }
    }

    const handleNavigationClick = (action: Action) => {
        if (action === 'previous') {
            navigate(routePath('ScreeningIncomeVerificationOverview', { homeseekerId }));
        }
    }

    return (
        <Form
            onChange={handleFormDataChange}
            onSubmit={handleSubmit}
        >
            <div className={styles.contentContainer}>
                <div className={styles.gridLg}>
                    <Text
                        color={ColorEnum.Primary700}
                        weight={FontWeightEnum.Bold}
                        size={FontSizeEnum.DisplayXs}
                    >{t(screeningSteps.ScreeningIncomeVerificationProvideIncomeDetails.pageTitleTranslationKey)}</Text>
                    <Card elevated>
                        {isFetchingIncomeSourceDetails ? (
                            <>
                                <div className={styles.gridMd}>
                                    <SkeletonComponent height={24}/>
                                    <div className={styles.gridSm}>
                                        <SkeletonComponent height={24}/>
                                        <SkeletonComponent height={72}/>
                                    </div>
                                </div>
                            </>
                        ) :
                            renderIncomeSourceForm()
                        }
                    </Card>
                    <ScreeningNavigation
                        onClick={handleNavigationClick}
                        isSubmitting={isSubmittingIncomeDetails}
                        isSuccessful={isSubmittingIncomeDetailsSuccess}
                    />
                </div>
            </div>
        </Form>
    );
}

export default ProvideIncomeDetails;

import { useTranslation } from 'react-i18next';
import CheckCircleLineIcon from '../../atoms/icons/CheckCircleLineIcon';
import LoadingSpinner from '../../atoms/loading-spinner';
import { classNames } from '../../utils';
import styles from './ProcessRequestFeedback.module.css';

interface ProcessRequestFeedbackProps {
    isSubmitting?: boolean;
    isSuccessful?: boolean;
}

const ProcessRequestFeedback = ({
    isSubmitting = false,
    isSuccessful = false,
}: ProcessRequestFeedbackProps) => {
    const { t } = useTranslation();

    return (
        <div className={
            classNames(
                styles.loadingContainer,
                isSubmitting && styles.isSubmitting,
                isSuccessful && styles.isSuccessful,
                !isSubmitting && !isSuccessful && styles.zeroWidth
            )
        }>
            <div className={classNames(styles.loadingItem, styles.savingText)}>
                <LoadingSpinner className={styles.loadingIcon} /> {t('screening.saving')}
            </div>
            <div className={classNames(styles.loadingItem, styles.savingSuccessText)}>
                <CheckCircleLineIcon className={styles.loadingIcon} /> {t('screening.saving.success')}
            </div>
        </div>
    )
}

export default ProcessRequestFeedback;

import api, { BodyData } from '../../utils/api'

export interface startVerificationRequestPayload extends BodyData {
    verificationRequestAuthorisationId: string;
}

export interface StartVerificationRequestResponse {
    homeseekerId: string;
}

const startVerificationRequest = async (data: startVerificationRequestPayload): Promise<StartVerificationRequestResponse> => {
    const response = await api.postWithoutAuth(`api/screening/start-verification-request`, data);

    return response.json();
}

export default startVerificationRequest;

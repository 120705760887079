import IncomeVerificationIncomeSourceTypeEnum from '../../../../enums/screening/IncomeVerificationIncomeSourceTypeEnum';

export interface IncomeType {
    category: string;
    options: string[];
}

const incomeTypes: {[key: string]: IncomeType} = {
    employment: {
        category: 'employment',
        options: [
            IncomeVerificationIncomeSourceTypeEnum.Employment,
            IncomeVerificationIncomeSourceTypeEnum.Freelance,
            IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership
        ]
    },
}

export default incomeTypes;

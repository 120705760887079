import { IncomeVerificationIncomeSourceTypeEnum } from '../../../../componentsNew/enums';
import findIncomeSourceEmployment, { IncomeSourceEmployment } from './findIncomeSourceEmployment';
import findIncomeSourceFreelance, { IncomeSourceFreelance } from './findIncomeSourceFreelance';
import findIncomeSourceBusinessOwnership, { IncomeSourceBusinessOwnership } from './findIncomeSourceBusinessOwnership';

export type IncomeSource = IncomeSourceEmployment | IncomeSourceFreelance | IncomeSourceBusinessOwnership;

const findIncomeSourceDetails = async (type: IncomeVerificationIncomeSourceTypeEnum, sourceId: string): Promise<IncomeSource> => {
    switch (type) {
        case IncomeVerificationIncomeSourceTypeEnum.Employment:
            return await findIncomeSourceEmployment(sourceId);
        case IncomeVerificationIncomeSourceTypeEnum.Freelance:
            return await findIncomeSourceFreelance(sourceId);
        case IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership:
            return await findIncomeSourceBusinessOwnership(sourceId);
        default:
            break;
    }
}

export default findIncomeSourceDetails;

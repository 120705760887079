import api from '../../../../utils/api';

export interface FreelanceDocument {
    fileSize: string;
    originalFileName: string;
}

export interface FreelanceDocuments {
    annualStatement?: FreelanceDocument;
    certifiedChamberOfCommerceExtract?: FreelanceDocument;
    taxReturn?: FreelanceDocument;
}

export interface FindIncomeSourceFreelanceResponse {
    startDate: string | null;
    chamberOfCommerceNumber: string | null;
    documents: FreelanceDocuments;
}

const findIncomeSourceFreelance = async (sourceId: string): Promise<FindIncomeSourceFreelanceResponse> => {
    return await api.getWithoutAuth(`api/screening/income-verification/${sourceId}/documents/freelance`);
}

export default findIncomeSourceFreelance;

import { Data, FormErrors } from '../../../../../molecules/form/Form';
import styles from './BusinessOwnershipContent.module.css'
import Text from '../../../../../atoms/typography/Text';
import { ColorEnum, FontWeightEnum } from '../../../../../enums';
import Label from '../../../../../atoms/label/Label';
import { FileUpload } from '../../../../../organisms/file-upload';
import { CheckboxInput } from '../../../../../molecules/checkbox-input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FindIncomeSourceBusinessOwnershipResponse } from '../../../../../../api/screening/income-verification/find-income-source-documents/findIncomeSourceBusinessOwnership';
import moment from 'moment/moment';

interface BusinessOwnershipContentProps {
    storedData: FindIncomeSourceBusinessOwnershipResponse;
    formData: Data;
    formErrors: FormErrors;
    onChange: (data) => void;
    onDocumentDelete?: (name: string) => void;
}

const BusinessOwnershipContent = ({
    storedData,
    formData,
    formErrors,
    onChange,
    onDocumentDelete,
}: BusinessOwnershipContentProps) => {
    const { t } = useTranslation();

    const [allFilesRequired, setAllFilesRequired] = useState(true);

    const startDate = storedData?.startDate;

    useEffect(() => {
        onChange({
            annualStatement: formData['annualStatement']?.value as FileList,
        });
    }, [formData]);

    const handleFileUploadDelete = (name: string) => {
        onDocumentDelete?.(name)
    }

    const renderAnnualStatementLabel = () => {
        const date = moment(startDate);
        const currentYear = moment().year();

        if (date.year() < currentYear) {
            return t('screening.incomeVerification.provideIncomeDetails.businessOwnership.annualStatement.title.lastYear', {year: (currentYear - 1)});
        }

        if (date.month() < 6) {
            return t('screening.incomeVerification.provideIncomeDetails.businessOwnership.annualStatement.title.currentYearFirstHalf', {year: currentYear});
        }

        return t('screening.incomeVerification.provideIncomeDetails.businessOwnership.annualStatement.title.currentYearSecondHalf', {year: currentYear});
    }

    return (
        <div className={styles.gridXl}>
            <div className={styles.gridMd}>
                <Text
                    color={ColorEnum.Gray800}
                    weight={FontWeightEnum.Bold}
                >{t('screening.incomeVerification.provideIncomeDetails.businessOwnership.title')}</Text>
                <Text>{t('screening.incomeVerification.provideDocuments.explanation')}</Text>
                {storedData.chamberOfCommerceNumber &&
                    <div>
                        <Label
                            htmlFor={'certifiedChamberOfCommerceExtract'}
                            required={allFilesRequired}
                        >
                            {t('screening.incomeVerification.provideIncomeDetails.freelance.certifiedChamberOfCommerceExtract.label')}
                        </Label>
                        <FileUpload
                            id='certifiedChamberOfCommerceExtract'
                            name='certifiedChamberOfCommerceExtract'
                            value={formData['certifiedChamberOfCommerceExtract']?.value as FileList}
                            storedFileMetadata={storedData?.documents['certifiedChamberOfCommerceExtract']}
                            acceptedExtensions={['pdf']}
                            errors={formErrors['certifiedChamberOfCommerceExtract']}
                            required={allFilesRequired}
                            onDelete={() => handleFileUploadDelete('certifiedChamberOfCommerceExtract')}
                        ></FileUpload>
                    </div>
                }
                <div>
                    <Label htmlFor={'annualStatement'} required={allFilesRequired}>{renderAnnualStatementLabel()}</Label>
                    <FileUpload
                        id='annualStatement'
                        name='annualStatement'
                        value={formData['annualStatement']?.value as FileList}
                        storedFileMetadata={storedData?.documents['annualStatement']}
                        acceptedExtensions={['pdf']}
                        errors={formErrors['annualStatement']}
                        required={allFilesRequired}
                        onDelete={() => handleFileUploadDelete('annualStatement')}
                    ></FileUpload>
                </div>
                <div>
                    <Label
                        htmlFor={'taxReturn'}
                        required={allFilesRequired}
                    >
                        {t('screening.incomeVerification.provideIncomeDetails.businessOwnership.taxReturn.label')}
                    </Label>
                    <FileUpload
                        id='taxReturn'
                        name='taxReturn'
                        value={formData['taxReturn']?.value as FileList}
                        storedFileMetadata={storedData?.documents['taxReturn']}
                        acceptedExtensions={['pdf']}
                        errors={formErrors['taxReturn']}
                        required={allFilesRequired}
                        onDelete={() => handleFileUploadDelete('taxReturn')}
                    ></FileUpload>
                </div>
            </div>
            <CheckboxInput
                name='proceedWithoutAllDocuments'
                id='proceedWithoutAllDocuments'
                label={t('screening.incomeVerification.provideDocuments.proceedWithoutAllDocuments')}
                value='proceedWithoutAllDocuments'
                checked={!allFilesRequired}
                onChange={checked => setAllFilesRequired(!checked)}
            />
        </div>
    )
}

export default BusinessOwnershipContent;

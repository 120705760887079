import { useEffect, useState } from 'react';
import findIdVerificationOutcome from '../../../../../api/screening/findIdVerificationOutcome';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageContentNotFound from '../../../../organisms/page-content-not-found/PageContentNotFound';
import { IdVerificationStatus } from '../../../../../api/screening/findIdVerificationStatus';
import { PageContainer } from '../../../../atoms/page-container';
import { ColorEnum } from '../../../../enums';
import { TopHeader } from '../../../../organisms/top-header';
import { Main } from '../../../../organisms/main';
import { PageFooter } from '../../../../organisms/page-footer';
import SkeletonComponent from '../../../../../components/SkeletonComponent';
import styles from './OutcomeContent.module.css';
import { useRoutePath } from '../../../../utils/useRoutePath';

const OutcomeContent = () => {
    const navigate = useNavigate();
    const routePath = useRoutePath();

    const [ searchParams ] = useSearchParams();
    const [ fetchingIdVerificationOutcome, setFetchingIdVerificationOutcome ] = useState(true);
    const [ fetchingIdVerificationOutcomeSuccess, setFetchingIdVerificationOutcomeSuccess ] = useState(false);
    const [ fetchingIdVerificationOutcomeFailed, setFetchingIdVerificationOutcomeFailed ] = useState(false);

    const idAnalyzerReference = searchParams.get('reference');

    const fetchIdVerificationOutcome = async (isMounted: boolean) => {
        setFetchingIdVerificationOutcome(true);
        setFetchingIdVerificationOutcomeFailed(false);

        try {
            const response = await findIdVerificationOutcome({ idAnalyzerReference });
            if (isMounted) setFetchingIdVerificationOutcomeSuccess(true);

            let redirectRoute: string | null = null;

            if ('error' in response) {
                redirectRoute = 'ScreeningIdVerificationIDAnalyzerUnavailable';
            }

            if ('status' in response) {
                if (response.status === IdVerificationStatus.SUCCEEDED) {
                    redirectRoute = 'ScreeningIdVerificationSuccess';
                }
                if (response.status === IdVerificationStatus.FAILED) {
                    redirectRoute = 'ScreeningIdVerificationFailure';
                }
                if (response.status === null) {
                    redirectRoute = 'ScreeningIdVerificationIDAnalyzerUnavailable';
                }
            }

            if (redirectRoute !== null) {
                navigate(routePath(redirectRoute, { homeseekerId: response.homeseekerId }));
            }
        } catch (err) {
            console.error('Error fetching ID verification outcome', err);

            if (isMounted) {
                setFetchingIdVerificationOutcomeFailed(true);
                setFetchingIdVerificationOutcomeSuccess(false);
            }
        } finally {
            if (isMounted) setFetchingIdVerificationOutcome(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        if (!idAnalyzerReference) {
            setFetchingIdVerificationOutcomeSuccess(false);
            setFetchingIdVerificationOutcome(false);

            return;
        }

        fetchIdVerificationOutcome(isMounted);

        return () => {
            isMounted = false;
        };
    }, [idAnalyzerReference]);

    if (fetchingIdVerificationOutcome) return (
        <PageContainer
            backgroundColor={ColorEnum.Gray50}
        >
            <TopHeader/>
            <Main>
                <div className={styles.contentContainer}>
                    <div className={styles.gridLg}>
                        <SkeletonComponent height={30} width={200}/>
                        <SkeletonComponent height={200}/>
                        <div className={styles.buttonContainer}>
                            <SkeletonComponent height={42} width={100}/>
                        </div>
                    </div>
                </div>
            </Main>
            <PageFooter/>
        </PageContainer>
    );

    if (fetchingIdVerificationOutcomeFailed || !fetchingIdVerificationOutcomeSuccess) {
        return (
            <PageContentNotFound/>
        )
    }

    return <></>;
};

export default OutcomeContent;

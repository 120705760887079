import Text from '../typography/text/Text';
import FontSizeEnum from '../typography/enums/fontSizeEnum';
import styles from './NavigationTab.module.css';
import FontWeightEnum from '../typography/enums/fontWeightEnum';
import Badge from '../generics/badge/Badge';
import { classNames } from '../../componentsNew/utils';

interface NavigationTabProps {
    title: string;
    name: string;
    selectedName: string;
    icon?: string;
    badgeLabel?: string;
    onClick?: (name: string) => void;
}

export default function NavigationTab({
    title,
    name,
    selectedName,
    icon,
    badgeLabel,
    onClick,
}: NavigationTabProps) {
    const isSelected = name === selectedName;

    return (
        <>
            <div
                className={classNames(styles.tab, isSelected && styles.selected)}
                onClick={() => onClick?.(name)}
            >
                {icon &&
                    <i className={`${styles.icon} ${icon}`}></i>
                }
                <Text
                    size={FontSizeEnum.Sm}
                    weight={FontWeightEnum.Semibold}
                >{title}</Text>
                {badgeLabel &&
                    <Badge label={badgeLabel} />
                }
            </div>
        </>
    );
}

import TimeLineIcon from '../../atoms/icons/TimeLineIcon';
import UserLineIcon from '../../atoms/icons/UserLineIcon';
import UserFillIcon from '../../atoms/icons/UserFillIcon';
import IconWithText from '../../atoms/typography/IconWithText';
import { FontSizeEnum } from '../../enums';
import styles from './AdditionalViewingDetails.module.css';

interface AdditionalViewingDetailsProps {
    startTime: string;
    endTime: string;
    actualNumberOfAttendees: number;
    maximumNumberOfAttendees: number;
}

const AdditionalViewingDetails = ({
    startTime,
    endTime,
    actualNumberOfAttendees,
    maximumNumberOfAttendees,
}: AdditionalViewingDetailsProps) => {
    const renderIcon = (): JSX.Element => {
        if (actualNumberOfAttendees === 0) {
            return <UserLineIcon />;
        }
        return actualNumberOfAttendees === maximumNumberOfAttendees ? <UserFillIcon /> : <UserLineIcon />;
    }

    return (
        <div className={styles.additionalDetails}>
            <IconWithText
                icon={<TimeLineIcon/>}
                fontSize={FontSizeEnum.Sm}
            >{startTime} - {endTime}</IconWithText>
            <div className={styles.dotContainer}></div>
            <IconWithText
                icon={renderIcon()}
                fontSize={FontSizeEnum.Sm}
            >{actualNumberOfAttendees}{maximumNumberOfAttendees > 0 && `/${maximumNumberOfAttendees}`}</IconWithText>
        </div>
    );
}

export default AdditionalViewingDetails;

import { IncomeVerificationIncomeSourceTypeEnum } from '../../../../componentsNew/enums';
import findIncomeSourceBusinessOwnership, { FindIncomeSourceBusinessOwnershipResponse } from './findIncomeSourceBusinessOwnership';
import findIncomeSourceEmployment, { FindIncomeSourceEmploymentResponse } from './findIncomeSourceEmployment';
import findIncomeSourceFreelance, { FindIncomeSourceFreelanceResponse } from './findIncomeSourceFreelance';

export type FindIncomeSourceDocumentsResponse = FindIncomeSourceEmploymentResponse | FindIncomeSourceBusinessOwnershipResponse | FindIncomeSourceFreelanceResponse;

const findIncomeSourceDocuments = async (type: IncomeVerificationIncomeSourceTypeEnum, sourceId: string): Promise<FindIncomeSourceDocumentsResponse> => {
    switch (type) {
        case IncomeVerificationIncomeSourceTypeEnum.Employment:
            return await findIncomeSourceEmployment(sourceId);
        case IncomeVerificationIncomeSourceTypeEnum.Freelance:
            return await findIncomeSourceFreelance(sourceId);
        case IncomeVerificationIncomeSourceTypeEnum.BusinessOwnership:
            return await findIncomeSourceBusinessOwnership(sourceId);
        default:
            break;
    }
}

export default findIncomeSourceDocuments;

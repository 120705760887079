import React, { useEffect, useState } from 'react';
import { Select } from '../select';
import { useParams } from 'react-router-dom';
import styles from './LanguageSelect.module.css';
import DutchFlagIcon from '../../atoms/icons/flags/DutchFlagIcon';
import GreatBritainFlagIcon from '../../atoms/icons/flags/GreatBritain';
import { Item } from '../select/Select';
import { languageContext } from '../../providers/LanguageProvider';

interface LanguageSelectProps {
    align?: 'left' | 'right';
}

const LanguageSelect = ({
    align = 'left',
}: LanguageSelectProps) => {
    const { lang } = useParams();
    const language = languageContext();

    const [selectedItem, setSelectedItem] = useState(lang || language.code);

    const listItems = [
        {
            value: 'nl',
            label: (
                <div className={styles.label}>
                    <DutchFlagIcon/>
                    <span>Nederlands</span>
                </div>
            )
        },
        {
            value: 'en',
            label: (
                <div className={styles.label}>
                    <GreatBritainFlagIcon/>
                    <span>English</span>
                </div>
            )
        }
    ];

    useEffect(() => {
        language.change(selectedItem);
    }, [selectedItem])

    const handleLanguageChange = (item: Item) => {
        setSelectedItem(item.value);
    }

    return (
        <>
            <Select
                selected={selectedItem}
                items={listItems}
                onChange={handleLanguageChange}
                align={align}
            />
        </>
    );
};

export default LanguageSelect;

import moment from 'moment';
import { FormEvent, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { splitFilenameAndExtension } from '../../utils';
import formatBytes from '../../utils/formatBytes';
import { AcceptedExtension } from '../../organisms/file-upload/FileUpload';

export interface Data {
    [key: string]: {
        value: FileList | string | string[];
        isValid: boolean
    }
}

export interface FieldState {
    name: string;
    value: FileList | string;
}

export interface FormError {
    key: string;
    params?: {[key: string]: string | number};
}
export type FormErrors = {[key: string]: FormError[]};

interface FormFieldObject {
    element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    value: FileList | string | string[];
    isValid: boolean;
    errors: FormError[];
    showErrors: boolean;
}

interface FormContextType {
    errors: FormErrors;
    formData: Data;
    isFormValid: boolean;
}

interface FromProps {
    children: ReactNode;
    className?: string;
    method?: 'dialog' | 'get' | 'post' | 'DIALOG' | 'GET' | 'POST';
    action?: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    enctype?: 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain';
    onChange?: (data: Data, isValid: boolean, errors: FormErrors) => void;
    onSubmit?: () => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

const Form = ({
    children,
    className,
    method,
    action,
    target,
    enctype,
    onChange,
    onSubmit,
}: FromProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const formDataRef = useRef<Data>({});

    const [ formFieldObjects, setFormFieldObjects ] = useState<{[key: string]: FormFieldObject} | []>([]);
    const formFieldObjectsRef = useRef<{[key: string]: FormFieldObject} | []>([]);
    const [ formData, setFormData ] = useState<Data>({});
    const [ isFormValid, setIsFormValid ] = useState<boolean>(false);
    const [ showFormErrors, setShowFormErrors ] = useState<boolean>(false);
    const showFormErrorsRef = useRef<boolean>(false);
    const [ errors, setErrors ] = useState<FormErrors>({});

    const { t } = useTranslation('validation');

    useEffect(() => {
        showFormErrorsRef.current = false;
        updateFormFieldObjects();
    }, [location.pathname]);

    useEffect(() => {
        if (onChange) {
            onChange(formData, isFormValid, errors);
        }

        // Update form is valid state based on every field's isValid value
        setIsFormValid(Object.values(formData).every((field) => field.isValid));
    }, [formData, isFormValid, errors, onChange]);

    useEffect(() => {
        formFieldObjectsRef.current = formFieldObjects;
    }, [formFieldObjects]);

    useEffect(() => {
        showFormErrorsRef.current = showFormErrors;
    }, [showFormErrors]);

    useEffect(() => {
        const updatedErrors = {};

        for (const [key, field] of Object.entries(formData)) {
            // Only add errors when the new field's isValid state is false, but the previous state was true AND showFormErrors is true
            if (!formDataRef.current[key]?.isValid && (field.isValid === true) && showFormErrors) {
                let element = formRef.current.elements[key] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | RadioNodeList;

                if (element instanceof RadioNodeList) {
                    element = Array.from(element).find((input) => (input as HTMLInputElement).checked) as HTMLInputElement | undefined;
                }

                if (!element) {
                    continue;
                }

                updatedErrors[key] = getValidationTranslationKeys(element);
            }
        }

        setErrors((prev) => {
            return {
                ...prev,
                ...updatedErrors
            };
        });
    }, [formData]);

    /**
     * Retrieve translation key based on nameKey:
     * if a translation key exists with an exact match of the nameKey, that key will be used
     * if a translation key exists with a * at the end and it matches the nameKey partially, that key will be used
     * Otherwise it will return the key as is
     */
    const getNamedValidationTranslationKey = (key: string, nameKey: string): string => {
        if (t(`${key}${nameKey}`, { defaultValue: null })) {
            return `${key}${nameKey}`;
        } else if (t(`${key}${nameKey.replace(/\*$/, '')}`, { defaultValue: null })) {
            return `${key}${nameKey.replace(/\*$/, '')}`;
        } else {
            return key;
        }
    }

    /**
     * Get validation translation keys for a specific element
     *
     * It will return an array of translation keys based on the element's validity state.
     *
     * It's also possible to match or not-match other values in the form. This can be done by adding a data-match or data-not-match attribute to the element. It will look for elements in the form with that name attribute and compare the values.
     *
     * Currently supported validation keys are:
     * - valueMissing - attribute: required
     * - typeMismatch - attribute: type
     * - tooShort - attribute: minLength
     * - tooLong - attribute: maxLength
     * - rangeUnderflow - attribute: min
     * - rangeOverflow - attribute: max
     * - patternMismatch - attribute: pattern
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
     */
    const getValidationTranslationKeys = (element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): FormError[] => {
        const validity: ValidityState = element.validity;
        const validationTranslationKeys: FormError[] = [];
        const name: string = element.name;
        let nameKey = '';

        if (name !== '') {
            nameKey = `.${name.replace(/-\d+$/, '*')}`;
        }

        if (element.type === 'number') {
            if ('valueAsNumber' in element) {
                const numberValue = element['valueAsNumber'];

                if (isNaN(numberValue) || element.value.includes('e') || element.value.includes('+')) {
                    validationTranslationKeys.push({
                        key: getNamedValidationTranslationKey('typeMismatch.number', nameKey)
                    });
                }
            }
        }

        if (element instanceof HTMLInputElement && element.type === 'file') {
            const files = element.files;

            if (files.length > 0) {
                const acceptedExtensions = element.accept.replaceAll('.', '').split(',');

                for (const file of files) {
                    const fileExtension = splitFilenameAndExtension(file.name).extension.replace('.', '');

                    if (acceptedExtensions && fileExtension && !acceptedExtensions.includes(fileExtension as AcceptedExtension)) {
                        validationTranslationKeys.push({
                            key: getNamedValidationTranslationKey('file.typeError', nameKey),
                            params: {
                                extensions: acceptedExtensions.join(', '),
                                extension: acceptedExtensions[0]
                            }
                        });
                    }

                    const dataAttributeMaxFileSizeInBytes = element.dataset.maxFileSizeInBytes;

                    if (dataAttributeMaxFileSizeInBytes) {
                        const maxFileSizeInBytes = parseInt(dataAttributeMaxFileSizeInBytes);

                        if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
                            validationTranslationKeys.push({
                                key: getNamedValidationTranslationKey('file.sizeTooLarge', nameKey),
                                params: {
                                    maxSize: formatBytes(maxFileSizeInBytes)
                                }
                            });
                        }
                    }
                }
                if (files.length > 1 && !element.multiple) {
                    validationTranslationKeys.push({
                        key: getNamedValidationTranslationKey('file.multipleFilesNotAllowed', nameKey)
                    });
                }
            }
        }

        if (validity.valueMissing && (!validationTranslationKeys.find((err) => err.key === getNamedValidationTranslationKey('typeMismatch.number', nameKey)))) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('required', nameKey),
            });
        }

        if (element.type && validity.typeMismatch) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey(`typeMismatch.${element.type.toLowerCase()}`, nameKey),
            });
        }

        if ('minLength' in element && validity.tooShort) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('tooShort', nameKey),
                params: {
                    minLength: element.minLength
                },
            });
        }

        if ('maxLength' in element && validity.tooLong) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('tooLong', nameKey),
                params: {
                    maxLength: element.maxLength
                },
            });
        }

        if ('pattern' in element && validity.patternMismatch) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('patternMismatch', nameKey),
            });
        }

        // Exclude match and notMatch errors if required error is already present, because it's redundant if there's no value
        if (
            !validationTranslationKeys.find((err) => err.key === getNamedValidationTranslationKey('required', nameKey))
            && (
                element.dataset.match || element.dataset.notMatch
            )
        ) {
            let matchElements: HTMLInputElement[] = [];
            let notMatchElements: HTMLInputElement[] = [];

            const refValue = element.value;

            const getMatchingElements = (pattern: string): HTMLInputElement[] => {
                if (/.*\*$/.test(pattern)) {
                    const regexPattern = new RegExp(`^${pattern.slice(0, -1)}`);
                    return Array.from(formRef.current.elements)
                        .filter((el) => el instanceof HTMLInputElement && el.name !== element.name)
                        .filter((el) => el instanceof HTMLInputElement && regexPattern.test(el.name)) as HTMLInputElement[];
                } else {
                    const specificElement = formRef.current.querySelector(`[name="${pattern}"]`) as HTMLInputElement;
                    return specificElement ? [specificElement] : [];
                }
            };

            if (element.dataset.match) {
                matchElements = getMatchingElements(element.dataset.match);
            }

            if (element.dataset.notMatch) {
                notMatchElements = getMatchingElements(element.dataset.notMatch);
            }

            if (matchElements.length > 0) {
                if (!matchElements.every((el) => el.value === refValue)) {
                    validationTranslationKeys.push({
                        key: getNamedValidationTranslationKey('matchError', nameKey),
                        params: { field: element.dataset.match }
                    });
                }
            }

            if (notMatchElements.length > 0) {
                const values = new Set<string>();

                for (const input of notMatchElements) {
                    values.add(input.value);

                    if (values.has(refValue)) {
                        validationTranslationKeys.push({
                            key: getNamedValidationTranslationKey('notMatchError', nameKey),
                            params: { field: element.dataset.notMatch }
                        });

                        break;
                    }
                }
            }
        }

        // For number inputs, replace decimal points and scientific notation.
        if ((!validationTranslationKeys.find((err) => err.key === getNamedValidationTranslationKey('typeMismatch.number', nameKey)))) {
            if (element.type === 'number') {
                if (
                    (!validationTranslationKeys.find((err) => err.key === getNamedValidationTranslationKey('required', nameKey)))
                    && element.dataset.noDecimals
                ) {
                    const round = parseInt(element.dataset.round, 10);
                    const value = parseFloat(element.value).toFixed(round);

                    if (value !== element.value) {
                        validationTranslationKeys.push({
                            key: getNamedValidationTranslationKey('noDecimalsAllowed', nameKey)
                        });
                    }
                }
            }

            if ('min' in element && validity.rangeUnderflow) {
                let min = element.min;
                if (element.type === 'date') {
                    min = moment(min).format('DD-MM-YYYY');
                }
                validationTranslationKeys.push({
                    key: getNamedValidationTranslationKey('rangeUnderflow', nameKey),
                    params: { min },
                });
            }

            if ('max' in element && validity.rangeOverflow) {
                let max = element.max;
                if (element.type === 'date') {
                    max = moment(max).format('DD-MM-YYYY');
                }
                validationTranslationKeys.push({
                    key: getNamedValidationTranslationKey('rangeOverflow', nameKey),
                    params: { max },
                });
            }
        }

        return validationTranslationKeys;
    }

    /**
     * Update form field objects based on the current form state.
     */
    const updateFormFieldObjects = (showErrors = false) => {
        const form = formRef.current;

        if (!form) {
            return [];
        }

        const updatedFormFieldObjects: {[key: string]: FormFieldObject} = {};

        // Loop over form elements and process only inputs, selects, and textareas.
        for (const element of formRef.current.elements) {
            if (
                !(element instanceof HTMLInputElement
                || element instanceof HTMLSelectElement
                || element instanceof HTMLTextAreaElement)
            ) {
                continue;
            }

            if (element.name === '') {
                continue;
            }

            let value: FileList | string | string[] = element.value;

            // For multi-selects, capture all selected values.
            if (element instanceof HTMLSelectElement && element.multiple) {
                value = Array.from(element.selectedOptions).map((option) => option.value);
            }

            if (element instanceof HTMLInputElement) {
                // For radios / checkboxes, ignore unchecked ones.
                if (
                    (element.type === 'radio' || element.type === 'checkbox')
                    && !element.checked
                ) {
                    value = '';
                }

                // For checkboxes, store a string value.
                if (element.type === 'checkbox') {
                    value = (element.checked) ? 'true' : 'false';
                }

                // For file input fields
                if (element.type === 'file') {
                    value = element.files;
                }
            }

            // Update field only if not already set, or if it's a checked radio/checkbox.
            if (
                updatedFormFieldObjects[element.name] === undefined
                || (
                    element instanceof HTMLInputElement
                    && (
                        element.type === 'radio' || element.type === 'checkbox'
                    )
                    && element.checked
                )
            ) {
                const errors = getValidationTranslationKeys(element);
                const isValid = element.checkValidity() && !errors.length;

                // Determine if errors should be shown for this field.
                let elementShowErrors = showErrors
                    || !!formFieldObjectsRef.current[element.name]?.showErrors
                    || showFormErrorsRef.current
                    || (element.type === 'file' && (
                        !!errors.find((err) => err.key.startsWith('file.'))
                    ));

                if (elementShowErrors) {
                    elementShowErrors = !isValid;
                }

                updatedFormFieldObjects[element.name] = {
                    value,
                    element,
                    isValid,
                    errors,
                    showErrors: elementShowErrors
                };
            }
        }

        setFormFieldObjects(updatedFormFieldObjects);
    }

    const handleInputChange = () => {
        updateFormFieldObjects();
    }

    const handleMutation = () => {
        updateFormFieldObjects();
    };

    useEffect(() => {
        const form = formRef.current;

        const observer = new MutationObserver(handleMutation);

        if (form) {
            observer.observe(form, {
                childList: true,
                subtree: true,
            });

            form.addEventListener('input', handleInputChange);
            form.addEventListener('change', handleInputChange);
        }

        updateFormFieldObjects();

        return () => {
            observer.disconnect();
            if (form) {
                form.removeEventListener('input', handleInputChange);
                form.removeEventListener('change', handleInputChange);
            }
        };
    }, [formRef.current]);

    useEffect(() => {
        const updatedFormData = {};
        const updatedErrors: FormErrors = {};

        for (const name in formFieldObjectsRef.current) {
            const formFieldObject = formFieldObjectsRef.current[name];

            updatedFormData[name] = {
                value: formFieldObject.value,
                isValid: formFieldObject.element.checkValidity() && !formFieldObject.errors.length
            };

            if (formFieldObject.showErrors) {
                updatedErrors[name] = formFieldObject.errors;
            }
        }

        setFormData(updatedFormData);
        setErrors(updatedErrors);
    }, [formFieldObjects])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setShowFormErrors(true);
        updateFormFieldObjects(true);

        if (isFormValid) {
            if (action) {
                formRef.current.submit();
            }

            onSubmit?.();
        }
    }

    return (
        <FormContext.Provider value={{ errors, formData, isFormValid }}>
            <form
                className={className}
                ref={formRef}
                noValidate
                method={method}
                action={action}
                target={target}
                encType={enctype}
                onSubmit={handleSubmit}
            >{children}</form>
        </FormContext.Provider>
    );
};

export const useForm = () => useContext(FormContext);

export default Form;

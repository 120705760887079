import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRoutePath } from '../utils/useRoutePath';
import { routeByPathname } from '../../routes';

const availableLanguages = ['nl', 'en'];
const fallbackLanguage = 'en';

interface LanguageContextType {
    code: string;
    change: (code: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const location = useLocation();
    const currentRoute = routeByPathname(location.pathname);
    const lang = currentRoute?.params?.lang;

    const [code, setCode] = useState(lang || i18n.language || 'nl');

    useEffect(() => {
        if (lang && availableLanguages.includes(lang)) {
            setCode(lang);
        } else {
            setCode(i18n.language || fallbackLanguage);
        }
    }, [lang, i18n.language]);

    useEffect(() => {
        if (!availableLanguages.includes(code)) {
            setCode(fallbackLanguage);
        } else if (!!lang && (lang !== code) && currentRoute) {
            const newPath = routePath(currentRoute.name, {...currentRoute.params, lang: code});

            if (newPath !== location.pathname) {
                navigate(newPath + location.search + location.hash, { replace: true });
            }
        }

        i18n.changeLanguage(code);
    }, [code]);

    return (
        <LanguageContext.Provider value={{ code, change: setCode }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const languageContext = () => {
    return useContext(LanguageContext);
};

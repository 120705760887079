import ButtonNew from '../../componentsNew/molecules/button/Button';

const EmailTemplatesFooterContent = () => {
    return (
        <ButtonNew
            type='submit'
        >Opslaan</ButtonNew>
    )
}

export default EmailTemplatesFooterContent;

import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../card';
import { ColorEnum, FontWeightEnum } from '../../enums';
import styles from './IncomeSourceCard.module.css';
import Text from '../../atoms/typography/Text';
import ButtonNew from '../../molecules/button/Button';
import PencilLineIcon from '../../atoms/icons/PencilLineIcon';
import DeleteBinIcon from '../../atoms/icons/DeleteBinIcon';
import { classNames, formatPrice } from '../../utils';
import IncomeVerificationIncomeSourceTypeEnum from '../../enums/screening/IncomeVerificationIncomeSourceTypeEnum';

interface IncomeSourceCardProps {
    readonly incomeType: IncomeVerificationIncomeSourceTypeEnum;
    readonly grossIncome?: number;
    readonly isDeleting?: boolean;
    readonly onEditClick: (e: MouseEvent<HTMLButtonElement>) => void;
    readonly onDeleteClick: (e: MouseEvent<HTMLButtonElement>) => void;
    readonly disabled?: boolean;
}

const IncomeSourceCard = ({
    incomeType,
    grossIncome,
    isDeleting = false,
    onEditClick,
    onDeleteClick,
    disabled = false,
}: IncomeSourceCardProps) => {
    const { t } = useTranslation();

    return (
        <Card
            className={classNames(styles.incomeSourceCard, isDeleting && styles.isDeleting)}
            hasPadding={false}
            borderColor={ColorEnum.Gray300}
        >
            <div className={styles.gridMd}>
                <div className={styles.gridSm}>
                    <div className={styles.textContainer}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >{t(`incomeSourceCard.incomeType.${incomeType}`)}</Text>
                    </div>
                    <div className={styles.buttonContainer}>
                        <ButtonNew
                            variant='secondary-gray'
                            iconBefore={<PencilLineIcon />}
                            onClick={onEditClick}
                            disabled={disabled}
                        />
                        <ButtonNew
                            variant='secondary-destructive'
                            iconBefore={<DeleteBinIcon />}
                            onClick={onDeleteClick}
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div>
                    <Text
                        color={ColorEnum.Gray500}
                        weight={FontWeightEnum.Regular}
                    >{t('incomeSourceCard.income.label')}&nbsp;</Text>
                    <Text
                        color={ColorEnum.Gray500}
                        weight={FontWeightEnum.Regular}
                    >{grossIncome ? formatPrice(grossIncome) : '-'}</Text>
                </div>
            </div>
        </Card>
    );
};

export default IncomeSourceCard;

import styles from './IncomeSourceSelectionContent.module.css';
import { useTranslation } from 'react-i18next';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import Form, { Data, FormErrors } from '../../../../molecules/form/Form';
import HighlightBlock from '../../../../molecules/highlightBlock/HighlightBlock';
import { Card } from '../../../../organisms/card';
import screeningSteps, { Action } from '../../../../organisms/screening-navigation/screeningSteps';
import FormError from '../../../../molecules/form/FormError';
import ScreeningNavigation from '../../../../organisms/screening-navigation/ScreeningNavigation';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRoutePath } from '../../../../utils/useRoutePath';
import { v4 as uuidv4 } from 'uuid';
import { routeByPathname } from '../../../../../routes';

const IncomeSourceSelectionContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const location = useLocation();
    const { homeseekerId, category } = useParams();

    const [ formData, setFormData ] = useState<Data>({});
    const [ formValid, setFormValid ] = useState<boolean>(false);
    const [ formErrors, setFormErrors ] = useState<FormErrors>({});

    const currentRoute = routeByPathname(location.pathname);

    const handleNavigationClick = (action: Action) => {
        if (action === 'previous') {
            if (currentRoute?.name === 'ScreeningIncomeVerificationIncomeSourceCategory') {
                navigate(routePath('ScreeningIncomeVerificationOverview', { homeseekerId }));
            }
            if (currentRoute?.name === 'ScreeningIncomeVerificationIncomeSourceType') {
                navigate(routePath('ScreeningIncomeVerificationIncomeSourceCategory', { homeseekerId }));
            }
        }
    }

    const handleSubmitOnIncomeSourceCategoryPage = () => {
        const params = {
            homeseekerId,
            category: formData['incomeSourceCategory']?.value as string
        }
        navigate(routePath('ScreeningIncomeVerificationIncomeSourceType', params));
    }

    const handleSubmitOnIncomeSourceTypePage = () => {
        const params = {
            homeseekerId,
            category,
            sourceId: uuidv4(),
            type: formData['incomeSourceType']?.value as string
        };

        navigate(routePath(`ScreeningIncomeVerificationProvideIncomeDetails`, params));
    }

    const handleSubmit = () => {
        if (formValid === true) {
            if (currentRoute?.name === 'ScreeningIncomeVerificationIncomeSourceCategory') {
                handleSubmitOnIncomeSourceCategoryPage();
            }

            if (currentRoute?.name === 'ScreeningIncomeVerificationIncomeSourceType') {
                handleSubmitOnIncomeSourceTypePage();
            }
        }
    }

    const handleFormChange = (data, isValid, errors) => {
        setFormData(data);
        setFormValid(isValid);
        setFormErrors(errors);
    }

    return (
        <Form
            onSubmit={handleSubmit}
            onChange={handleFormChange}
        >
            <div className={styles.contentContainer}>
                <div className={styles.gridLg}>
                    <Text
                        color={ColorEnum.Primary700}
                        weight={FontWeightEnum.Bold}
                        size={FontSizeEnum.DisplayXs}
                    >{t(screeningSteps.ScreeningIncomeVerificationProvideIncomeDetails.pageTitleTranslationKey)}</Text>
                    <Card elevated>
                        <div className={styles.gridMd}>
                            <Text
                                color={ColorEnum.Gray800}
                                weight={FontWeightEnum.Bold}
                            >{t('screening.incomeVerification.incomeSourceCategoryContent.title.category')}</Text>
                            <HighlightBlock type='info'>
                                {t('screening.incomeVerification.incomeSourceCategoryContent.explanation.highlight')}
                            </HighlightBlock>
                            <div>
                                <Outlet/>
                                <FormError errors={formErrors.incomeSourceCategory} />
                                <FormError errors={formErrors.incomeSourceType} />
                            </div>
                        </div>
                    </Card>
                    <ScreeningNavigation
                        onClick={handleNavigationClick}
                    />
                </div>
            </div>
        </Form>
    );
}

export default IncomeSourceSelectionContent;

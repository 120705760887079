import styles from './IncomeSourceSelectionContent.module.css';
import { useTranslation } from 'react-i18next';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import { RadioInput } from '../../../../atoms/radio-input';
import { ReactNode, useState } from 'react';
import { Option } from '../../../../atoms/radio-input/RadioInput';
import { capitalizeFirstLetter } from '../../../../utils';
import incomeTypes, { IncomeType } from './incomeTypes';

const IncomeSourceCategoryContent = () => {
    const { t } = useTranslation();

    const [ selectedIncomeSourceCategory, setSelectedIncomeSourceCategory ] = useState<string | null>(null);

    const categoryOptionLabel = (incomeType: IncomeType): ReactNode => {
        const types = incomeType.options.map((option) => {
            return t(`screening.incomeVerification.incomeSourceCategoryContent.category.options.type.${option}`);
        });

        return (
            <>
                <Text
                    size={FontSizeEnum.Md}
                    weight={FontWeightEnum.Regular}
                >{t(`screening.incomeVerification.incomeSourceCategoryContent.category.${incomeType.category}`)}</Text>
                <br/>
                <Text
                    className={styles.typeOptions}
                    color={ColorEnum.Gray500}
                    size={FontSizeEnum.Sm}
                    weight={FontWeightEnum.Light}
                >{capitalizeFirstLetter(types.join(', '))}.</Text>
            </>
        );
    }

    const categoryOptions: Option[] = Object.values(incomeTypes).map((incomeType): Option => {
        return {
            label: categoryOptionLabel(incomeType),
            value: incomeType.category
        }
    });

    return (
        <RadioInput
            className={styles.categoryOptions}
            name='incomeSourceCategory'
            value={selectedIncomeSourceCategory}
            options={categoryOptions}
            onChange={setSelectedIncomeSourceCategory}
            required
        />
    )
}

export default IncomeSourceCategoryContent;

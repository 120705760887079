
import api, { BodyData } from '../../../../utils/api';

export interface SubmitIncomeSourceDocumentsEmploymentPayload extends BodyData {
    employmentContract?: FileList,
    employerStatement?: FileList,
    payslipOne?: FileList,
    payslipTwo?: FileList,
    payslipThree?: FileList,
}

const submitIncomeDocumentsEmployment = async (homeseekerId: string, sourceId: string, data: SubmitIncomeSourceDocumentsEmploymentPayload): Promise<unknown> => {
    return await api.postFormData(`api/screening/${homeseekerId}/income-verification/${sourceId}/save/employment/documents`, data);
}

export default submitIncomeDocumentsEmployment;

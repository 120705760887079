import { ChangeEvent, useEffect, useState } from 'react';
import SkeletonComponent from '../../../../components/SkeletonComponent';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../enums';
import { Card } from '../../../organisms/card';
import { ListingCard } from '../../../organisms/listing-card';
import { classNames } from '../../../utils';
import styles from './ScreeningIntroductionContent.module.css';
import {useNavigate, useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../redux/actions/ui/setNotification';
import { useTranslation } from 'react-i18next';
import findScreeningIntroduction, { Agency, Homeseeker, Listing, Screening } from '../../../../api/screening/findScreeningIntroduction';
import Text from '../../../atoms/typography/Text'
import Checkbox from '../../../../components/forms/checkbox/Checkbox';
import Divider from '../../../atoms/divider/Divider';
import SmartPhoneLineIcon from '../../../atoms/icons/SmartPhoneLineIcon';
import ArticleLineIcon from '../../../atoms/icons/ArticleLineIcon';
import MoneyEuroCircleLineIcon from '../../../atoms/icons/MoneyEuroCircleLineIcon';
import PassportLineIcon from '../../../atoms/icons/PassportLineIcon';
import {FeaturedIconWithText} from '../../../molecules/featured-icon-with-text';
import startVerificationRequest from '../../../../api/screening/startVerificationRequest';
import moment from 'moment';
import ScreeningNavigation from '../../../organisms/screening-navigation/ScreeningNavigation';
import Form, { Data, FormErrors } from '../../../molecules/form/Form';
import FormError from '../../../molecules/form/FormError';
import screeningSteps from '../../../organisms/screening-navigation/screeningSteps';
import { languageContext } from '../../../providers/LanguageProvider';
import { useRoutePath } from '../../../utils/useRoutePath';

const ScreeningIntroductionContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language = languageContext();
    const routePath = useRoutePath();

    const { verificationRequestAuthorisationId } = useParams();

    const [ isFetchingScreeningIntroduction, setIsFetchingScreeningIntroduction ] = useState<boolean>(true);
    const [ isStartingVerification, setIsStartingVerification ] = useState<boolean>(false);
    const [ isStartingVerificationSuccess, setIsStartingVerificationSuccess ] = useState<boolean>(false);
    const [ agency, setAgency ] = useState<Agency | null>(null);
    const [ homeseeker, setHomeseeker ] = useState<Homeseeker | null>(null);
    const [ listing, setListing ] = useState<Listing | null>(null);
    const [ screening, setScreening ] = useState<Screening | null>(null);
    const [ acceptedTermsAndPolicy, setAcceptedTermsAndPolicy] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    useEffect(() => {
        fetchScreeningIntroduction();
    }, []);

    const fetchScreeningIntroduction = async () => {
        try {
            const response = await findScreeningIntroduction(verificationRequestAuthorisationId);
            setAgency(response.agency);
            setListing(response.listing);
            setHomeseeker(response.homeseeker);
            setScreening(response.screening);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingScreeningIntroduction(false);
        }
    }

    const screeningDeadlineDate = () => {
        if (!screening.deadlineDate) {
            return '';
        }

        return `${moment(screening.deadlineDate).locale(language.code).format('dddd, D MMMM YYYY')}`;
    }

    const handleFormDataChange = (_data: Data, _isValid: boolean, errors: FormErrors) => {
        setFormErrors(errors);
    }

    const handleSubmit = async () => {
        setIsStartingVerificationSuccess(false);
        setIsStartingVerification(true);

        const startTime = Date.now();

        try {
            const response = await startVerificationRequest({ verificationRequestAuthorisationId });
            const homeseekerId = response.homeseekerId;

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsStartingVerification(false);
            setIsStartingVerificationSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            if (homeseeker?.isMainTenant) {
                navigate(routePath('ScreeningHousehold', { verificationRequestAuthorisationId }));
            } else {
                navigate(routePath('ScreeningIdVerificationIntroduction', { homeseekerId }));
            }
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsStartingVerification(false);
            setIsStartingVerificationSuccess(false);
        }
    }

    return (
        <div className={classNames(styles.cardsGrid, styles.gridLg)}>
            <div className={styles.emptyDiv}></div>
            <Text
                color={ColorEnum.Primary700}
                weight={FontWeightEnum.Bold}
                size={FontSizeEnum.DisplayXs}
            >{t(screeningSteps.ScreeningIntroduction.pageTitleTranslationKey)}</Text>
            <div className={styles.listingCard}>
                {!isFetchingScreeningIntroduction ?
                    <ListingCard
                        listing={listing}
                    />
                    :
                    <SkeletonComponent height={300} width={300}/>
                }
            </div>
            <Form
                onChange={handleFormDataChange}
                onSubmit={handleSubmit}
            >
                <div className={styles.gridLg}>
                    <Card
                        elevated
                    >
                        <div className="ta-left">
                            <div className={styles.gridLg}>
                                {!isFetchingScreeningIntroduction ?
                                    <div className={styles.gridSm}>
                                        <Text color={ColorEnum.Gray800} html={t('screening.introduction.text', {
                                            agencyName: agency.name,
                                            listingCity: listing.city,
                                            homeseekerName: homeseeker.name
                                        })}></Text>
                                    </div>
                                    :
                                    <SkeletonComponent height={24}/>
                                }
                                <Divider/>
                                {!isFetchingScreeningIntroduction ?
                                    <>
                                        <Text color={ColorEnum.Gray800} weight={FontWeightEnum.Bold}>
                                            {t('screening.introduction.requirements.title')}
                                        </Text>
                                        <FeaturedIconWithText
                                            icon={<PassportLineIcon/>}
                                            text={t('screening.introduction.requirements.passport.text')}
                                        />
                                        <FeaturedIconWithText
                                            icon={<SmartPhoneLineIcon/>}
                                            text={t('screening.introduction.requirements.cellphone.text')}
                                        />
                                        <FeaturedIconWithText
                                            icon={<MoneyEuroCircleLineIcon/>}
                                            text={t('screening.introduction.requirements.income.text')}
                                        />
                                        <FeaturedIconWithText
                                            icon={<ArticleLineIcon/>}
                                            text={t('screening.introduction.requirements.employment.text')}
                                        />

                                        <Text color={ColorEnum.Gray800}
                                            html={t('screening.introduction.requirements.deadline.text', {
                                                screeningDeadlineDate: screeningDeadlineDate()
                                            })}
                                        ></Text>
                                    </>
                                    :
                                    <SkeletonComponent height={24}/>
                                }
                            </div>
                        </div>
                    </Card>
                    <Card
                        elevated
                        className={styles.gridMd}
                    >
                        <Text
                            weight={FontWeightEnum.Bold}
                        >{t('screening.introduction.terms.and.policy.title')}</Text>
                        <Checkbox
                            name="acceptedTermsAndPolicy"
                            label={<Text>{t('screening.introduction.terms.and.policy.label')}</Text>}
                            isChecked={acceptedTermsAndPolicy}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setAcceptedTermsAndPolicy(e.target.checked)}
                            required
                        />
                        <FormError errors={formErrors['acceptedTermsAndPolicy']}/>
                    </Card>
                    <ScreeningNavigation
                        isSubmitting={isStartingVerification}
                        isSuccessful={isStartingVerificationSuccess}
                    />
                </div>
            </Form>
        </div>
    );
}

export default ScreeningIntroductionContent;

import moment from 'moment';
import styles from './CalendarWidget.module.css';
import 'moment/locale/nl';
import { languageContext } from '../../providers/LanguageProvider';

interface CalendarWidgetProps {
    date: moment.Moment
}

const CalendarWidget = ({
    date
}: CalendarWidgetProps) => {
    const language = languageContext();

    const monthAbbreviation = date.locale(language.code).format('MMM').replace('.', '');

    return (
        <div className={styles.CalendarWidget}>
            <div className={styles.month}>{monthAbbreviation}</div>
            <div className={styles.dayOfMonth}>{date.locale(language.code).format('DD')}</div>
        </div>
    );
}

export default CalendarWidget;

import api from '../../../../utils/api';

export interface BusinessOwnershipDocument {
    fileSize: string;
    originalFileName: string;
}

export interface BusinessOwnershipDocuments {
    certifiedChamberOfCommerceExtract?: BusinessOwnershipDocument;
    annualStatement?: BusinessOwnershipDocument;
    taxReturn?: BusinessOwnershipDocument;
}

export interface FindIncomeSourceBusinessOwnershipResponse {
    startDate: string | null;
    documents: BusinessOwnershipDocuments;
}

const findIncomeSourceBusinessOwnership = async (sourceId: string): Promise<FindIncomeSourceBusinessOwnershipResponse> => {
    return await api.getWithoutAuth(`api/screening/income-verification/${sourceId}/documents/business-ownership`);
}

export default findIncomeSourceBusinessOwnership;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import fetchAgencies, { Agency } from '../../api/agency/settings/fetchAcencies';
import { setNotification } from '../../redux/actions/ui/setNotification';
import { useTranslation } from 'react-i18next';
import Select, { Item } from '../../componentsNew/organisms/select/Select';
import { PageWithSidePanel } from '../../componentsNew/atoms/page-with-side-panel';
import { PageContainer } from '../../componentsNew/atoms/page-container';
import TopNavigation from '../../components/navigation/TopNavigation';
import { Main } from '../../componentsNew/organisms/main';
import { SidePanel } from '../../componentsNew/organisms/side-panel';
import { TemplateVariableExplanation } from '../../componentsNew/templates/variable-explanation';
import PageHeader from '../../componentsNew/molecules/pageHeader/PageHeader';
import Text from '../../componentsNew/atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../componentsNew/enums';
import styles from './SettingsPage.module.css';
import HorizontalNavigationTabs from '../../components/navigation/HorizontalNavigationTabs';
import NavigationTab from '../../components/navigation/NavigationTab';
import { routeByPathname } from '../../routes';
import { useRoutePath } from '../../componentsNew/utils/useRoutePath';
import SettingsFooter from './SettingsFooter';
import Form, { Data, FormErrors } from '../../componentsNew/molecules/form/Form';

const SettingsPageNew = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routePath = useRoutePath();
    const [ queryParams, setQueryParams ] = useSearchParams();
    const location = useLocation();
    const currentRoute = routeByPathname(location.pathname);

    const [ isFetchingAgencies, setIsFetchingAgencies ] = useState<boolean>(true);
    const [ selectedAgencyId, setSelectedAgencyId ] = useState<string | null>(null);
    const [ agencyOptions, setAgencyOptions ] = useState<Item[]>([])
    const [ isSubmittingSettings, setIsSubmittingSettings ] = useState<boolean>(false);
    const [ isSubmittingSettingsSuccess, setIsSubmittingSettingsSuccess ] = useState<boolean>(false);

    const [, setIsFormValid] = useState<boolean>(false);
    const [, setFormData] = useState<Data>({});
    const [, setFormErrors] = useState<FormErrors>({});

    useEffect(() => {
        setSelectedAgencyId(queryParams.get('selectedAgencyId'));
    }, [queryParams]);

    useEffect(() => {
        tryFetchingAgencies();
    }, []);

    const createAgencyOptions = (agengies: Agency[]): Item[] => {
        return agengies.map((agency) => {
            return {
                value: agency.id,
                label: agency.name
            }
        });
    }

    useEffect(() => {
        queryParams.set('selectedAgencyId', selectedAgencyId);
        setQueryParams(queryParams);
    }, [selectedAgencyId]);

    const tryFetchingAgencies = async () => {
        setIsFetchingAgencies(true);

        try {
            const response = await fetchAgencies();
            const agencies = createAgencyOptions(response.result);

            if (agencies && agencies[0]) {
                setAgencyOptions(agencies);

                let foundAgency = null;


                if (selectedAgencyId) {
                    foundAgency = !agencies.find((agency) => agency.value === selectedAgencyId);
                }

                if (foundAgency === null) {
                    setSelectedAgencyId(agencies[0].value);
                }
            }
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('settingsPage.fetching.agencies.failed')
            }));
        } finally {
            setIsFetchingAgencies(false);
        }
    }

    const handleChangeAgency = (item: Item) => {
        setSelectedAgencyId(item.value);
    }

    const navigateToTab = (tabName: string) => {
        navigate({
            pathname: routePath(tabName),
            search: queryParams.toString()
        });
    }

    const handleFormDataChange = (data: Data, isValid: boolean, errors: FormErrors) => {
        setFormData(data);
        setIsFormValid(isValid);
        setFormErrors(errors);
    }

    const handleSubmit = async () => {
        setIsSubmittingSettingsSuccess(false);
        setIsSubmittingSettings(true);

        const startTime = Date.now();

        try {
            // TODO: add agencyId and currentRoute.name to submitSettings
            // TODO: add actual payload based on formData
            // TODO: add switch case inside submitSettings to handle different route endpoints
            // const response = await submitSettings(payload);

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsSubmittingSettings(false);
            setIsSubmittingSettingsSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: t('api.request.failed')
            }));
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingSettings(false);
            setIsSubmittingSettingsSuccess(false);
        }
    }

    return (
        <>
            <PageWithSidePanel>
                <PageContainer>
                    <TopNavigation showBackButton/>
                    <Form
                        onChange={handleFormDataChange}
                        onSubmit={handleSubmit}
                    >
                        <Main>
                            <PageHeader title={'settingsPage.title'}/>
                            <HorizontalNavigationTabs>
                                <NavigationTab
                                    title="Algemeen"
                                    name="SettingsGeneral"
                                    selectedName={currentRoute.name}
                                    onClick={navigateToTab}
                                />
                                <NavigationTab
                                    title="E-mail templates"
                                    name="SettingsEmailTemplates"
                                    selectedName={currentRoute.name}
                                    onClick={navigateToTab}
                                />
                                <NavigationTab
                                    title="E-mail notificaties"
                                    name="SettingsEmailNotifications"
                                    selectedName={currentRoute.name}
                                    onClick={navigateToTab}
                                />
                            </HorizontalNavigationTabs>
                            {isFetchingAgencies
                                ?
                                'loading'
                                :
                                <>
                                    <div className={styles.contentContainer}>
                                        {agencyOptions.length > 1 &&
                                            <div className={styles.contentSection}>
                                                <div className={styles.descriptionPanel}>
                                                    <Text
                                                        color={ColorEnum.Gray800}
                                                        weight={FontWeightEnum.Bold}
                                                        tag='h6'
                                                    >
                                                        {t('settingsPage.agencySelect.title')}
                                                    </Text>
                                                    <Text
                                                        size={FontSizeEnum.Sm}
                                                        color={ColorEnum.Gray800}
                                                        tag='p'
                                                    >
                                                        {t('settingsPage.agencySelect.description')}
                                                    </Text>
                                                </div>
                                                <div className={styles.settingsPanel}>
                                                    <div className={styles.formRow}>
                                                        <Select
                                                            selected={selectedAgencyId}
                                                            items={agencyOptions}
                                                            name='agencyd'
                                                            onChange={handleChangeAgency}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <Outlet />
                                    </div>
                                </>
                            }
                        </Main>
                        <SettingsFooter
                            isSubmitting={isSubmittingSettings}
                            isSuccessful={isSubmittingSettingsSuccess}
                        />
                    </Form>
                </PageContainer>
                <SidePanel title={t('sidepanel.variable.title')}>
                    <TemplateVariableExplanation/>
                </SidePanel>
            </PageWithSidePanel>
        </>
    );
}

export default SettingsPageNew;

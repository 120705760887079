import TopNavigation from '../../components/navigation/TopNavigation'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Text from '../../components/typography/text/Text'
import { useTranslation } from 'react-i18next'
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../enums/colorEnum'
import moment from 'moment'
import styles from './AgentsOverview.module.css'
import api from '../../utils/api'
import LoginBoxLine from '../../componentsNew/atoms/icons/LoginBoxLine';
import ButtonNew from '../../componentsNew/molecules/button/Button';
import PencilLineIcon from '../../componentsNew/atoms/icons/PencilLineIcon';
import Modal from '../../components/organisms/modal/Modal';
import { InputField } from '../../componentsNew/organisms/input-field';
import Label from '../../componentsNew/atoms/label/Label';
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum';
import FormError from '../../componentsNew/molecules/form/FormError';
import Form from '../../componentsNew/molecules/form/Form';
import deleteHomeseekerData from '../../api/homeseeker/deleteMeRequest';
import { setNewNotification, TYPES } from '../../redux/actions/ui/setNotification';
import {Badge} from '../../componentsNew/molecules/badge';

export default function AgentsProfileContainer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lastMonthName = useRef(getLastMonthName());

    const [agencies, setAgencies] = useState([]);
    const [deleteMeEmailAddress, setDeleteMeEmailAddress] = useState('');
    const [deleteMeModalOpen, setDeleteMeModalOpen] = useState(false);
    const [deleteMeFormErrors, setDeleteMeFormErrors] = useState({});

    useEffect(() => {
        api.get('api/agency-overview').then(res => {
            setAgencies(res.result)
        })
    }, []);

    function limitNumberToThreeDigits(number) {
        return number > 999 ? '999+' : number
    }

    function getLastMonthName() {
        const lastMonthName = moment().subtract(1, 'month').format('MMMM')
        return lastMonthName.charAt(0).toUpperCase() + lastMonthName.slice(1)
    }

    async function handleBillingReportLinkClick() {
        const csvData = await api.getFile('api/get-screening-billing-report-from-last-month-for-all-agencies')
        const url = window.URL.createObjectURL(new Blob([csvData]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'screening_billing_report.csv')
        link.click()
    }

    function switchToAgency(agencyId) {
        localStorage.setItem('agencyOverride', agencyId);
        navigate('/dashboard/advertenties');
        window.location.reload();
    }

    const handleDeleteMeFormChange = (data, isValid, errors) => {
        setDeleteMeFormErrors(errors);
    }

    const handleDeleteMeSubmit = async () => {
        try {
            await deleteHomeseekerData({email: deleteMeEmailAddress});
        } catch (error) {
            setNewNotification({
                message: error.message || 'Er is iets misgegaan',
                type: TYPES.FAILED,
                id: Date.now(),
            });
            return;
        }

        setDeleteMeEmailAddress('');
        setDeleteMeModalOpen(false);
        setNewNotification({
            message: 'De gegevens zijn verwijderd',
            type: TYPES.SUCCESS,
            id: Date.now(),
        });
    }

    function deleteMe() {
        return <>
            <ButtonNew variant={'tertiary-gray'} onClick={() => setDeleteMeModalOpen(true)}>Delete me verzoek</ButtonNew>
            <Modal
                title="Delete me verzoek"
                flowyColor={ColorEnum.Primary100}
                isOpen={deleteMeModalOpen}
                onCloseButtonClick={() => setDeleteMeModalOpen(false)}>
                <Form onChange={handleDeleteMeFormChange} onSubmit={handleDeleteMeSubmit}>
                    <div className={styles.deleteMeModalContent}>
                        <Text
                            size={FontSizeEnum.Lg}
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Gray800}>Delete Me verzoek</Text>
                        <Text size={FontSizeEnum.Md}>Verwijder alle gegevens van een persoon in Leadflow door middel van
                            e-mailadress.</Text>
                        <div>
                            <Label htmlFor={'delete-me-emailaddress'}>E-mailadres</Label>
                            <InputField
                                type='email'
                                required={true}
                                id='delete-me-emailaddress'
                                name='delete-me-emailaddress'
                                placeholder={'john.doe@test.nl'}
                                value={deleteMeEmailAddress}
                                onChange={(state) => setDeleteMeEmailAddress(state.value)} />
                        </div>
                        <FormError errors={deleteMeFormErrors['delete-me-emailaddress'] || null}></FormError>
                        <div>
                            <ButtonNew type={'submit'} variant={'primary'}>Verwijder gegevens</ButtonNew>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    }

    return (
        <>
            <div className="min-h-full">
                <TopNavigation />
                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <div className="sm:flex items-center justify-between mb-6 py-6">
                                <h1 className="text-4xl font-bold ">Makelaars</h1>
                                <div className={styles.buttonsContainer}>
                                    <Text size={FontSizeEnum.Md} color={ColorEnum.Primary500}>
                                        <a className={styles.link}
                                            onClick={() => handleBillingReportLinkClick()}>
                                            {t('admin.downloadBillingReport', { month: lastMonthName.current })}
                                        </a>
                                    </Text>
                                    <Text size={FontSizeEnum.Md} color={ColorEnum.Primary500}>
                                        <Link to={'/dubbele-advertenties'} className={styles.link}>
                                            {t('admin.mergeListings')}
                                        </Link>
                                    </Text>
                                    {deleteMe()}
                                </div>
                            </div>
                            <ul className="list-none">
                                {agencies.map((agency) => (
                                    <li
                                        key={agency.id}
                                        className="w-full  p-6 my-6 border-gray-300 bg-gray-100 rounded-md flex justify-between">
                                        <div>
                                            <h2 className="text-2xl pb-2">{agency.name}</h2>
                                            <p className="text-gray-500 ">{agency.id}</p>
                                            {!agency.isActive && <Badge label='Offboarded'></Badge>}
                                        </div>

                                        <div className={styles.agentRowButtonContainer}>
                                            <ButtonNew onClick={() => navigate(`/admin/agents/${agency.id}`)} iconAfter={<PencilLineIcon />} variant='secondary-gray' size={'sm'}>
                                                Aanpassen
                                            </ButtonNew>
                                            <ButtonNew onClick={() => switchToAgency(agency.id)} iconAfter={<LoginBoxLine />} variant='secondary-gray' size={'sm'}>
                                                Inloggen als deze makelaar
                                            </ButtonNew>
                                            <ButtonNew onClick={() => navigate(`/admin/onverwerkbare-leads/${agency.id}`)} variant={agency.monthlyDiscardedLeadsCount === 0 ? 'secondary-gray' : 'secondary'} size={'sm'}>
                                                Naar onverwerkbare leads {agency.monthlyDiscardedLeadsCount > 0 && `(${limitNumberToThreeDigits(agency.monthlyDiscardedLeadsCount)})`}
                                            </ButtonNew>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

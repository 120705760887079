import React, { ChangeEvent, useRef, useState } from 'react';
import styles from './HtmlTextArea.module.css';
import { FieldState } from '../../molecules/form/Form';
import { classNames } from '../../utils';

interface TextAreaProps {
    readonly id?: string;
    readonly name?: string;
    readonly placeholder?: string;
    readonly value: string;
    readonly onChange?: (state: FieldState) => void;
    readonly minlength?: number;
    readonly maxlength?: number;
    readonly required?: boolean;
    readonly disabled?: boolean;
    readonly rowCount?: 4;
}

const HtmlTextArea = ({
    id,
    name,
    placeholder,
    value = '',
    onChange,
    minlength,
    maxlength,
    required,
    disabled,
    rowCount = 4,
}: TextAreaProps) => {
    const textareaRef = useRef(null);
    const [textLength, setTextLength] = useState(value?.length || 0);
    const [isActive, setIsActive] = useState(false);

    const createFieldState = (target: HTMLInputElement): FieldState => {
        return {
            name: name,
            value: target.value,
        };
    }

    const handleChange = (e: ChangeEvent) => {
        setTextLength((e.target as HTMLInputElement).value.length);

        if (!onChange) {
            return;
        }

        onChange(createFieldState(e.target as HTMLInputElement));
    }

    const handleContainerClick = () => {
        textareaRef.current.focus();
    }

    const handleTextAreaFocus = () => {
        setIsActive(true);
    }

    const handleTextAreaBlur = () => {
        setIsActive(false);
    }

    return (
        <div
            className={classNames(styles.container, isActive && styles.active, disabled && styles.disabled)}
            onClick={handleContainerClick}
        >
            <textarea
                ref={textareaRef}
                className={styles.textArea}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                minLength={minlength}
                maxLength={maxlength}
                required={required}
                disabled={disabled}
                rows={rowCount}
                onFocus={handleTextAreaFocus}
                onBlur={handleTextAreaBlur}
            />
            {(!disabled && maxlength !== undefined) && <p className={styles.counter}>{`${textLength} / ${maxlength}`}</p>}
        </div>
    );
};

export default HtmlTextArea;

import api from '../../utils/api';

export interface ContactRequestListingResponse {
    listing: {
        street: string,
        showHouseNumber: boolean,
        houseNumber: string,
        postalCode: string,
        city: string,
        imageUrl: string,
        price: number,
        surface: number,
        rooms: number,
    },
    agent: {
        name: string,
        logo: string,
        privacyPolicy: string,
    }
}

export const findListingById = async (listingId: string): Promise<ContactRequestListingResponse> => {
    return await api.getWithoutAuth(`api/contact-request/listing/${listingId}`);
}

export default findListingById;

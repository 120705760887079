import { Device } from '../../../utils/useDeviceDetection';
import { ButtonVariant } from '../../molecules/button/ButtonVariant';

export type IconType = 'ExternalLinkLineIcon' | 'ArrowRightSLineIcon';

export type Action = 'skip' | 'next' | 'previous' | 'submit';

interface NavigationButton {
    translationKey: string;
    action: Action;
    variant: ButtonVariant;
    onlyShowOnDevice?: Device;
    iconBefore?: IconType;
    iconAfter?: IconType;
}

interface ScreeningSteps {
    [key: string]: {
        pageTitleTranslationKey?: string;
        buttons?: NavigationButton[];
    }
}

const screeningSteps: ScreeningSteps = {
    ScreeningIntroduction: {
        pageTitleTranslationKey: 'screening.introduction.page.title',
        buttons: [
            {
                translationKey: 'screening.introduction.button.submit',
                action: 'submit',
                variant: 'primary',
            },
        ],
    },
    ScreeningHousehold: {
        pageTitleTranslationKey: 'screening.household.page.title',
        buttons: [
            {
                translationKey: 'screening.household.button.submit',
                action: 'submit',
                variant: 'primary',
            },
        ],
    },
    ScreeningCoTenants: {
        pageTitleTranslationKey: 'screening.coTenants.page.title',
        buttons: [
            {
                translationKey: 'screening.coTenants.button.previous',
                action: 'previous',
                variant: 'secondary',
            },
            {
                translationKey: 'screening.coTenants.button.submit',
                action: 'submit',
                variant: 'primary',
            },
        ],
    },
    ScreeningIdVerificationIntroduction: {
        pageTitleTranslationKey: 'screening.idVerification.introduction.page.title',
        buttons: [
            {
                translationKey: 'screening.idVerification.introduction.button.submit',
                onlyShowOnDevice: 'mobile',
                action: 'submit',
                variant: 'primary',
                iconBefore: 'ExternalLinkLineIcon',
            },
            {
                translationKey: 'screening.idVerification.introduction.button.skip',
                onlyShowOnDevice: 'desktop',
                action: 'skip',
                variant: 'tertiary-gray',
                iconAfter: 'ArrowRightSLineIcon',
            },
        ],
    },
    ScreeningIdVerificationPending: {
        pageTitleTranslationKey: 'screening.idVerification.pending.page.title',
        buttons: [
            {
                translationKey: 'screening.idVerification.pending.button.previous',
                action: 'previous',
                variant: 'secondary',
            },
        ],
    },
    ScreeningIdVerificationSuccess: {
        pageTitleTranslationKey: 'screening.idVerification.success.page.title',
        buttons: [
            {
                translationKey: 'screening.idVerification.success.button.next',
                action: 'next',
                variant: 'primary',
            },
        ],
    },
    ScreeningIdVerificationFailure: {
        pageTitleTranslationKey: 'screening.idVerification.failure.page.title',
        buttons: [
            {
                translationKey: 'screening.idVerification.failure.button.previous',
                action: 'previous',
                variant: 'tertiary-gray',
                iconAfter: 'ArrowRightSLineIcon',
            },
            {
                translationKey: 'screening.idVerification.failure.button.next',
                action: 'next',
                variant: 'primary',
            },
        ],
    },
    ScreeningIdVerificationIDAnalyzerUnavailable: {
        pageTitleTranslationKey: 'screening.idVerification.idAnalyzerUnavailable.page.title',
        buttons: [
            {
                translationKey: 'screening.idVerification.idAnalyzerUnavailable.button.previous',
                action: 'previous',
                variant: 'tertiary-gray',
                iconAfter: 'ArrowRightSLineIcon',
            },
            {
                translationKey: 'screening.idVerification.idAnalyzerUnavailable.button.next',
                action: 'next',
                variant: 'primary',
            },

        ],
    },
    ScreeningIncomeVerificationOverview: {
        pageTitleTranslationKey: 'screening.incomeVerification.page.title',
        buttons: [
            {
                translationKey: 'screening.incomeVerification.overview.button.skip',
                action: 'skip',
                variant: 'tertiary-gray',
                iconAfter: 'ArrowRightSLineIcon',
            },
        ],
    },
    ScreeningIncomeVerificationIncomeSourceCategory: {
        pageTitleTranslationKey: 'screening.incomeVerification.page.title',
        buttons: [
            {
                translationKey: 'screening.incomeVerification.incomeSourceCategoryContent.button.previous',
                action: 'previous',
                variant: 'secondary',
            },
            {
                translationKey: 'screening.incomeVerification.incomeSourceCategoryContent.button.submit',
                action: 'submit',
                variant: 'primary',
            },
        ],
    },
    ScreeningIncomeVerificationIncomeSourceType: {
        pageTitleTranslationKey: 'screening.incomeVerification.page.title',
        buttons: [
            {
                translationKey: 'screening.incomeVerification.incomeSourceTypeContent.button.previous',
                action: 'previous',
                variant: 'secondary',
            },
            {
                translationKey: 'screening.incomeVerification.incomeSourceTypeContent.button.submit',
                action: 'submit',
                variant: 'primary',
            },
        ]
    },
    ScreeningIncomeVerificationProvideIncomeDetails: {
        pageTitleTranslationKey: 'screening.incomeVerification.page.title',
        buttons: [
            {
                translationKey: 'screening.incomeVerification.provideIncomeDetails.button.previous',
                action: 'previous',
                variant: 'secondary',
            },
            {
                translationKey: 'screening.incomeVerification.provideIncomeDetails.button.submit',
                action: 'submit',
                variant: 'primary',
            }
        ],
    },
    ScreeningIncomeVerificationProvideDocuments: {
        pageTitleTranslationKey: 'screening.incomeVerification.page.title',
        buttons: [
            {
                translationKey: 'screening.incomeVerification.provideDocuments.button.previous',
                action: 'previous',
                variant: 'secondary',
            },
            {
                translationKey: 'screening.incomeVerification.provideDocuments.button.submit',
                action: 'submit',
                variant: 'primary',
            }
        ],
    }
};

export default screeningSteps;

import style from '../../../pages/error/ErrorPage.module.css';
import IconWithFlowyBackground from '../../../components/molecules/iconWithFlowyBackground/IconWithFlowyBackground';
import Title from '../../../components/typography/title/Title';
import FontSizeEnum from '../../../components/typography/enums/fontSizeEnum';
import FontWeightEnum from '../../../components/typography/enums/fontWeightEnum';
import ColorEnum from '../../../enums/colorEnum';
import Button from '../../../components/buttons/button/Button';
import ButtonType from '../../../components/buttons/button/logic/models/buttonType';
import Text from '../../../components/typography/text/Text';
import { Trans } from 'react-i18next';
import React, { ReactNode } from 'react';
import styles from './ErrorContent.module.css';

interface ErrorContentProps {
    readonly title: string;
    readonly children?: ReactNode;
    readonly buttonText: string;
    readonly buttonTwoText?: string;
    readonly buttonTwoOnClick?: () => void;
}
const ErrorContent = (props: ErrorContentProps) => {

    return (
        <div className={style.root}>
            <IconWithFlowyBackground icon={'ri-emotion-sad-line'}/>
            <Title
                size={FontSizeEnum.Sm}
                weight={FontWeightEnum.Bold}
                color={ColorEnum.Gray700}
            >
                {props.title}
            </Title>

            <div>
                {props.children}
            </div>

            <div className={styles.buttonContainer}>
                {props.buttonTwoText && (
                    <Button
                        title={props.buttonTwoText}
                        buttonType={ButtonType.Secondary}
                        onClick={props.buttonTwoOnClick}
                    />
                )}
                <Button
                    title={props.buttonText}
                    buttonType={ButtonType.Primary}
                    onClick={() => location.replace('/')}
                />
            </div>
            <Text
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray500}
            >
                <Trans
                    i18nKey="errorPage.general.support"
                    components={{ Link: <a/> }}
                />
            </Text>
        </div>
    );
}
export default ErrorContent;

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export interface SkeletonComponentProps {
    readonly count?: number
    readonly width?: number
    readonly height?: number
}

function SkeletonComponent(props: SkeletonComponentProps) {
    return (
        <Skeleton
            count={props.count}
            height={props.height}
            width={props.width}
            style={{ borderRadius: '8px' }}
        />
    )
}

export default SkeletonComponent;



import { PageContainer } from '../../atoms/page-container';
import { TopHeader } from '../../organisms/top-header';
import { Card } from '../../organisms/card';
import { Main } from '../../organisms/main';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import MapPinLineIcon from '../../atoms/icons/MapPinLineIcon';
import CalendarIcon from '../../atoms/icons/CalendarIcon';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getViewingWithTimeslots, { Agency, Listing, Timeslot, Viewing } from '../../../api/viewing/getViewingWithTimeslots';
import moment from 'moment';
import TimeLineIcon from '../../atoms/icons/TimeLineIcon';
import IconWithText from '../../atoms/typography/IconWithText';
import styles from './ScheduleViewingPage.module.css';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListingCard } from '../../organisms/listing-card';
import { TimeslotsSelection } from '../../organisms/timeslots-selection';
import { classNames } from '../../utils';
import 'moment/locale/nl';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import SkeletonComponent from '../../../components/SkeletonComponent';
import { languageContext } from '../../providers/LanguageProvider';

const ScheduleViewingPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { viewingId } = useParams();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ agency, setAgency ] = useState<Agency | null>(null);
    const [ listing, setListing ] = useState<Listing | null>(null);
    const [ timeslots, setTimeslots ] = useState<Timeslot[] | []>([]);
    const [ viewing, setViewing ] = useState<Viewing | null>(null);

    const language = languageContext();

    useEffect(() => {
        fetchViewingWithTimeslots();
    }, []);

    const fetchViewingWithTimeslots = async () => {
        try {
            const response = await getViewingWithTimeslots(viewingId);
            setAgency(response.agency);
            setListing(response.listing);
            setTimeslots(response.timeslots);
            setViewing(response.viewing);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <PageContainer backgroundColor={ColorEnum.Gray100}>
            <TopHeader hasTranslationSelector={true}/>
            <Main>
                <div className={classNames(styles.cardsGrid, styles.gridXl)}>
                    <div className={styles.listingCard}>
                        {!isLoading ?
                            <ListingCard
                                listing={listing}
                            />
                            :
                            <SkeletonComponent height={300} width={300}/>
                        }
                    </div>
                    <div>
                        <Card
                            elevated
                        >
                            <div className="ta-center">
                                <div className={styles.gridLg}>
                                    <div className={styles.gridSm}>
                                        {!isLoading ?
                                            <>
                                                <Text
                                                    tag="h1"
                                                    color={ColorEnum.Primary700}
                                                    size={FontSizeEnum.DisplayXs}
                                                    weight={FontWeightEnum.Bold}
                                                >{t('viewing.page.title')}</Text>
                                                <Text
                                                    tag="h3"
                                                    color={ColorEnum.Gray400}
                                                    size={FontSizeEnum.Md}
                                                    weight={FontWeightEnum.Bold}
                                                >{agency ? agency.name : 'undefined'}</Text>
                                            </>
                                            :
                                            <>
                                                <SkeletonComponent height={30} width={300}/>
                                                <SkeletonComponent height={20} width={150}/>
                                            </>
                                        }
                                    </div>
                                    <div className={styles.gridSm}>
                                        <IconWithText iconDisplay='inline' icon={<MapPinLineIcon/>}>
                                            {!isLoading ?
                                                <>
                                                    {`${listing?.street} ${listing?.houseNumber} ${listing?.postalCode}, ${listing?.city}`}
                                                </>
                                                :
                                                <>
                                                    <SkeletonComponent height={20} width={300}/>
                                                </>
                                            }
                                        </IconWithText>
                                        <IconWithText iconDisplay='inline' icon={<CalendarIcon/>}>
                                            {!isLoading ?
                                                <>
                                                    {viewing ? moment(viewing.start_date_time).locale(language.code).format('dddd, D MMMM YYYY') : 'undefined'}
                                                </>
                                                :
                                                <>
                                                    <SkeletonComponent height={20} width={180}/>
                                                </>
                                            }
                                        </IconWithText>
                                    </div>
                                    <hr/>
                                    {!isLoading ?
                                        <>
                                            {(!viewing?.viewing_has_passed && timeslots.find((timeslot: Timeslot) => timeslot.hasAvailableSeats)) ? (
                                                <>
                                                    <div className={styles.gridSm}>
                                                        <Text
                                                            tag="h2"
                                                            color={ColorEnum.Primary700}
                                                            size={FontSizeEnum.Lg}
                                                            weight={FontWeightEnum.Bold}
                                                        >{t('viewing.page.choose.timeslot')}</Text>
                                                        {/* TODO: remove if-statement below after invitees to a viewing with only one timeslot are redirected to the accept page immediately */}
                                                        {(viewing?.minutes_per_timeslot) ? (
                                                            <IconWithText iconDisplay='inline' icon={<TimeLineIcon />}>
                                                                {t('general.minutes', { minutes: viewing.minutes_per_timeslot })}
                                                            </IconWithText>
                                                        ) : (
                                                            <IconWithText iconDisplay='inline' icon={<TimeLineIcon />}>
                                                                {t('general.minutes', { minutes: timeslots.length > 0 ? timeslots[0].duration : 1 })}
                                                            </IconWithText>
                                                        )}
                                                    </div>
                                                    <TimeslotsSelection timeslots={timeslots}/>
                                                </>
                                            ) : (
                                                <Card
                                                    backgroundColor={ColorEnum.Gray100}
                                                >
                                                    <Text
                                                        tag="p"
                                                        color={ColorEnum.Gray500}
                                                        size={FontSizeEnum.Md}
                                                        weight={FontWeightEnum.Regular}
                                                    >
                                                        {t('viewing.page.no.timeslots.available')}
                                                    </Text>
                                                </Card>
                                            )}
                                        </>
                                        :
                                        <>
                                            <SkeletonComponent height={100} width={300}/>
                                        </>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </Main>
        </PageContainer>
    );
};

export default ScheduleViewingPage;

import { useLocation } from 'react-router-dom';
import { routeByPathname } from '../../routes';
import styles from './SettingsFooter.module.css';
import GeneralSettingsFooterContent from './GeneralSettingsFooterContent';
import EmailTemplatesFooterContent from './EmailTemplatesFooterContent';
import EmailNotificationsFooterContent from './EmailNotificationsFooterContent';
import ButtonNew from '../../componentsNew/molecules/button/Button';
import ProcessRequestFeedback from '../../componentsNew/molecules/process-request-feedback/ProcessRequestFeedback';
import { useForm } from '../../componentsNew/molecules/form/Form';

interface SettingsFooterProps {
    isSubmitting?: boolean;
    isSuccessful?: boolean;
}

const SettingsFooter = ({
    isSubmitting = false,
    isSuccessful = false,
}: SettingsFooterProps) => {
    const form = useForm();
    const location = useLocation();
    const currentRoute = routeByPathname(location.pathname);

    console.log(form);

    const renderSettingsFooterContent = () => {
        switch (currentRoute.name) {
            case 'SettingsGeneral':
                return <GeneralSettingsFooterContent/>;
            case 'SettingsEmailTemplates':
                return <EmailTemplatesFooterContent/>;
            case 'SettingsEmailNotifications':
                return <EmailNotificationsFooterContent/>;
            default:
                return (
                    <ButtonNew disabled>Opslaan</ButtonNew>
                );
        }
    }

    return (
        <footer className={styles.settingsFooter}>
            <ProcessRequestFeedback
                isSubmitting={isSubmitting}
                isSuccessful={isSuccessful}
            />
            {renderSettingsFooterContent()}
        </footer>
    )
}

export default SettingsFooter;

import AddressContentModel from './logic/models/addressContentModel';
import styles from './AddressContent.module.css';
import {useTranslation} from 'react-i18next';
import {ListingProperties} from '../../../../listings/listingProperties/ListingProperties';
import Badge_Deprecated from '../../../../generics/badge_deprecated/Badge_Deprecated';

export interface AddressContentProps {
    readonly model: AddressContentModel;
}

export default function AddressContent(props: AddressContentProps) {
    const {t} = useTranslation();
    const statusBadgeModel = props.model.listingStatusTimer.asBadge();

    return (
        <>
            <div className={styles.addressContainer}>
                <h4 className={`${styles.addressText} text-lg font-bold text-primary`}>
                    {props.model.address.getAddressWithoutPostalCode()}
                </h4>
            </div>
            {props.model.isInfoVisible() ?
                <ListingProperties model={props.model.listingProperties} /> :
                <p className={styles.notFoundText}>{t('general.dataNotFound')}</p>
            }
            {statusBadgeModel &&
                <Badge_Deprecated model={statusBadgeModel}/>}
        </>
    );
}

import styles from './IntroductionContent.module.css';
import { Card } from '../../../../organisms/card';
import Text from '../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../../enums';
import Divider from '../../../../atoms/divider';
import { FeaturedIconWithText } from '../../../../molecules/featured-icon-with-text';
import PassportLineIcon from '../../../../atoms/icons/PassportLineIcon';
import { useTranslation } from 'react-i18next';
import CameraLineIcon from '../../../../atoms/icons/CameraLineIcon';
import EmotionHappyLineIcon from '../../../../atoms/icons/EmotionHappyLineIcon';
import QRCode from '../../../../atoms/qr-code/QRCode';
import useDeviceDetection from '../../../../../utils/useDeviceDetection';
import ScreeningNavigation from '../../../../organisms/screening-navigation/ScreeningNavigation';
import screeningSteps, { Action } from '../../../../organisms/screening-navigation/screeningSteps';
import { useNavigate, useParams } from 'react-router-dom';
import LeadflowWindow from '../../../../../LeadflowWindow';
import { useEffect, useState } from 'react';
import findIdVerificationStatus, { IdVerificationStatus } from '../../../../../api/screening/findIdVerificationStatus';
import findIDVerificationRedirect from '../../../../../api/screening/findIDVerificationRedirect';
import { setNotification } from '../../../../../redux/actions/ui/setNotification';
import { useDispatch } from 'react-redux';
import { useRoutePath } from '../../../../utils/useRoutePath';
import Form from '../../../../molecules/form/Form';

const IntroductionContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const device = useDeviceDetection();
    const navigate = useNavigate();
    const routePath = useRoutePath();

    const { homeseekerId } = useParams();

    const apiURL = `${LeadflowWindow.REACT_APP_LEADFLOW_API}/api/screening/${homeseekerId}/id-verification/redirect`;

    const [ idVerificationStatus, setIdVerificationStatus ] = useState<string | null>(null);
    const [ isFetchingIDVerificationRedirect, setIsFetchingIDVerificationRedirect ] = useState<boolean>(false);
    const [ isFetchingIDVerificationRedirectSuccess, setIsFetchingIDVerificationRedirectSuccess ] = useState<boolean>(false);

    useEffect(() => {
        fetchStatus();

        const fetchStatusInterval = setInterval(fetchStatus, 1000);

        return () => clearInterval(fetchStatusInterval);
    }, []);

    useEffect(() => {
        if (idVerificationStatus === IdVerificationStatus.PENDING) {
            navigate(routePath('ScreeningIdVerificationPending', { homeseekerId }));
        }
    }, [idVerificationStatus]);

    const handleNavigationClick = (action: Action) => {
        if (action === 'skip') {
            navigate(routePath('ScreeningIncomeVerificationOverview', { homeseekerId }));
        }
    }

    const fetchStatus = async () => {
        try {
            const response = await findIdVerificationStatus(homeseekerId);
            setIdVerificationStatus(response.status);
        } catch (error) {
            console.error('Error finding verification status', error);
        }
    };

    const handleSubmit = async () => {
        setIsFetchingIDVerificationRedirectSuccess(false);
        setIsFetchingIDVerificationRedirect(true);

        const startTime = Date.now();

        try {
            const response = await findIDVerificationRedirect(homeseekerId);

            if ('error' in response) {
                if (response.error === 'ID Analyzer is unavailable') {
                    navigate(routePath('ScreeningIdVerificationIDAnalyzerUnavailable', { homeseekerId }));
                }
            }

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsFetchingIDVerificationRedirect(false);
            setIsFetchingIDVerificationRedirectSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            if ('redirect' in response) {
                window.open(response.redirect);
            }
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingIDVerificationRedirect(false);
            setIsFetchingIDVerificationRedirectSuccess(false);
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
        >
            <div className={styles.contentContainer} data-test-id="screening-id-verification-introduction-content">
                <div className={styles.gridLg}>
                    <Text
                        color={ColorEnum.Primary700}
                        weight={FontWeightEnum.Bold}
                        size={FontSizeEnum.DisplayXs}
                    >{t(screeningSteps.ScreeningIdVerificationIntroduction.pageTitleTranslationKey)}</Text>
                    <Card elevated>
                        <div className={styles.gridMd}>
                            <Text
                                weight={FontWeightEnum.Bold}
                                color={ColorEnum.Gray800}
                            >{t('screening.idVerification.introduction.header')}</Text>
                            <Text color={ColorEnum.Gray800}
                            >{t('screening.idVerification.introduction.subHeader')}</Text>
                            <Divider/>
                            <Text
                                size={FontSizeEnum.Lg}
                                weight={FontWeightEnum.Bold}
                                color={ColorEnum.Gray800}
                            >{t('screening.idVerification.introduction.requirements.title')}</Text>
                            <FeaturedIconWithText
                                icon={<CameraLineIcon/>}
                                text={t('screening.idVerification.introduction.requirements.camera.text')}
                            />
                            <FeaturedIconWithText
                                icon={<PassportLineIcon/>}
                                text={t('screening.idVerification.introduction.requirements.passport.text')}
                            />
                            <FeaturedIconWithText
                                icon={<EmotionHappyLineIcon/>}
                                text={t('screening.idVerification.introduction.requirements.selfie.text')}
                            />
                            <Text color={ColorEnum.Gray800}
                            >{t('screening.idVerification.introduction.requirements.text')}</Text>
                            {device !== 'mobile' &&
                                <>
                                    <Text color={ColorEnum.Gray800}
                                    >{t('screening.idVerification.introduction.requirements.instructions')}</Text>
                                    <div className={styles.qrCodeContainer}>
                                        <QRCode
                                            url={apiURL}
                                        />
                                    </div>
                                </>
                            }
                            <Text
                                size={FontSizeEnum.Xs}
                                color={ColorEnum.Gray500}
                            >{t('screening.idVerification.introduction.requirements.termsAndConditions')}</Text>
                        </div>
                    </Card>
                    <ScreeningNavigation
                        onClick={handleNavigationClick}
                        isSubmitting={isFetchingIDVerificationRedirect}
                        isSuccessful={isFetchingIDVerificationRedirectSuccess}
                    />
                </div>
            </div>
        </Form>
    );
}

export default IntroductionContent;

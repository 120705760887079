import React, { ReactNode } from 'react';
import styles from './IconWithText.module.css';
import Text, { TextElements } from './Text';
import { FontSizeEnum, FontWeightEnum } from '../../enums';
import { fontSizeEnumAsCssVar } from '../../enums/FontSizeEnum';
import { classNames } from '../../utils';
import { fontWeightEnumAsCssVar } from '../../enums/FontWeightEnum';

interface IconWithTextProps {
    readonly icon: ReactNode;
    readonly fontSize?: FontSizeEnum;
    readonly fontWeight?: FontWeightEnum
    readonly children: TextElements | TextElements[];
    readonly iconDisplay?: 'inline';
}

const IconWithText = ({
    icon,
    fontSize = FontSizeEnum.Md,
    fontWeight = FontWeightEnum.Light,
    children,
    iconDisplay,
}: IconWithTextProps) => {
    return (
        <div
            className={classNames(iconDisplay && styles[iconDisplay], styles.IconWithText)}
            style={{ fontSize: fontSizeEnumAsCssVar(fontSize), fontWeight: fontWeightEnumAsCssVar(fontWeight) }}
        >
            <div className={styles.iconWrapper}>
                {icon}
            </div>
            <div className={styles.textWrapper}>
                <Text weight={fontWeight} size={fontSize}>{children}</Text>
            </div>
        </div>
    );
}

export default IconWithText;

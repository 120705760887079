import api, { BodyData } from '../../utils/api';
import { IdVerificationStatus } from './findIdVerificationStatus';

export interface IdVerificationOutcomePayload extends BodyData {
    idAnalyzerReference: string;
}

export interface IdVerificationOutcomeSuccessResponse {
    homeseekerId: string;
    status: IdVerificationStatus;
}

export interface IdVerificationOutcomeErrorResponse {
    homeseekerId: string;
    error: string;
}

export type IdVerificationOutcomeResponse = IdVerificationOutcomeErrorResponse | IdVerificationOutcomeSuccessResponse;

const findIdVerificationOutcome = async (data: IdVerificationOutcomePayload): Promise<IdVerificationOutcomeResponse> => {
    const response = await api.postWithoutAuth(`api/screening/id-verification/outcome`, data);

    return response.json();
}

export default findIdVerificationOutcome;

import DoorOpenLineIcon from '../../atoms/icons/DoorOpenLineIcon';
import MoneyEuroCircleLineIcon from '../../atoms/icons/MoneyEuroCircleLineIcon';
import Ruler2LineIcon from '../../atoms/icons/Ruler2LineIcon';
import IconWithText from '../../atoms/typography/IconWithText';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { formatPrice, formatRooms, formatSurface } from '../../utils';
import styles from './ListingDetails.module.css';

interface ListingDetailsProps {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    price?: number;
    surface?: number;
    rooms?: number;
}

const ListingDetails = ({
    street,
    houseNumber,
    postalCode,
    city,
    price,
    surface,
    rooms,
}: ListingDetailsProps) => {
    return (
        <div className={styles.grid}>
            <Text
                tag="h2"
                size={FontSizeEnum.DisplayXs}
                weight={FontWeightEnum.Bold}
            >{`${street} ${houseNumber}`}</Text>
            <Text
                tag="h3"
                color={ColorEnum.Gray500}
                size={FontSizeEnum.Md}
                weight={FontWeightEnum.Regular}
            >{`${postalCode}, ${city}`}</Text>
            <div className={styles.details}>
                <IconWithText
                    icon={<MoneyEuroCircleLineIcon/>}
                    fontWeight={FontWeightEnum.Regular}
                >{formatPrice(price, false)}</IconWithText>
                <IconWithText
                    icon={<Ruler2LineIcon/>}
                    fontWeight={FontWeightEnum.Regular}
                >{formatSurface(surface)}</IconWithText>
                <IconWithText
                    icon={<DoorOpenLineIcon/>}
                    fontWeight={FontWeightEnum.Regular}
                >{formatRooms(rooms)}</IconWithText>
            </div>
        </div>
    )
}

export default ListingDetails;

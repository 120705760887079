import ButtonNew from '../../componentsNew/molecules/button/Button';

const EmailNotificationsFooterContent = () => {
    return (
        <ButtonNew
            type='submit'
        >Opslaan</ButtonNew>
    )
}

export default EmailNotificationsFooterContent;

import AnnouncementPageFrame from '../../components/announcementPageFrame/AnnouncementPageFrame';
import SuccessPageFragment from './fragments/success/SuccessPageFragment';
import ErrorPageFragment from '../../components/errorPageFragment/ErrorPageFragment';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import LoadStateEnum from '../../enums/loadStateEnum';
import Loading from '../../components/empty-states/loading/Loading';
import optInToMarketingMessages from '../../api/homeseeker/optInToMarketingMessages';

export default function MarketingMessagesSubscribePage() {
    const [loadState, setLoadState] = useState(LoadStateEnum.Loading);
    const {id, listingCity} = useParams()

    useEffect(() => {
        optIn()
    }, []);

    async function optIn() {
        try {
            await optInToMarketingMessages({homeseekerId: id, huurwoningen: true, parariusPlus: false});
            setLoadState(LoadStateEnum.Success);
        } catch (e) {
            setLoadState(LoadStateEnum.Error);
        }
    }

    function renderPageFragment() {
        if (loadState === LoadStateEnum.Success) {
            return <SuccessPageFragment listingCity={listingCity}/>
        }
        return <ErrorPageFragment/>
    }

    return (
        <>
            {loadState === LoadStateEnum.Loading ?
                <Loading/> :
                <AnnouncementPageFrame>
                    {renderPageFragment()}
                </AnnouncementPageFrame>
            }
        </>
    )
}

import React from 'react';
import styles from './TopHeader.module.css';
import { Logo } from '../../atoms/logo';
import { LanguageSelect } from '../language-select';
import { ProfileDropdownMenu } from '../profile-dropdown-menu';
import useAuth from '../../../hooks/useAuth';
import useDeviceDetection from '../../../utils/useDeviceDetection';

interface Props {
    readonly hasTranslationSelector?: boolean;
}

const TopHeader = ({ hasTranslationSelector = false }: Props) => {
    const { user } = useAuth();
    const device = useDeviceDetection();

    return (
        <header className={styles.container}>
            <Logo isNavigationDisabled={device === 'mobile'} />
            <div className={styles.containerRight}>
                {hasTranslationSelector &&
                    <LanguageSelect align="right"/>
                }
                {user &&
                    <ProfileDropdownMenu isNavigationDisabled={device === 'mobile'} />
                }
            </div>
        </header>
    );
};

export default TopHeader;

import AnnouncementPageFrame from '../../components/announcementPageFrame/AnnouncementPageFrame';
import ConfirmationFragment from './fragments/confirmation/ConfirmationFragment';
import SuccessFragment from './fragments/success/SuccessFragment';
import { useEffect, useState } from 'react';
import ErrorPageFragment from '../../components/errorPageFragment/ErrorPageFragment';
import LoadStateEnum from '../../enums/loadStateEnum';
import Loading from '../../components/empty-states/loading/Loading';
import api from '../../utils/api';
import {useParams} from 'react-router-dom';

export default function MarketingMessagesUnsubscribePage() {

    const {id} = useParams()
    const [isUnsubscribeConfirmed, setIsUnsubscribeConfirmed] = useState(false)
    const [loadState, setLoadState] = useState(LoadStateEnum.Loading);

    useEffect(() => {
        if (isUnsubscribeConfirmed) {
            api.postWithoutAuth(
                'api/unsubscribe-homeseeker-from-marketing-messages',
                {homeseekerId: id}
            )
                .then(() => setLoadState(LoadStateEnum.Success))
                .catch(() => setLoadState(LoadStateEnum.Error))

        }
    }, [isUnsubscribeConfirmed])

    function renderSuccessPage() {
        if (loadState === LoadStateEnum.Success) {
            return <SuccessFragment/>
        }
        return <ErrorPageFragment/>
    }

    return (
        <>
            {isUnsubscribeConfirmed && loadState === LoadStateEnum.Loading ?
                <Loading/> :
                <AnnouncementPageFrame>
                    {isUnsubscribeConfirmed === false ?
                        <ConfirmationFragment
                            onUnsubscribeButtonClick={() => setIsUnsubscribeConfirmed(true)}
                        /> :
                        renderSuccessPage()
                    }
                </AnnouncementPageFrame>
            }
        </>
    )
}

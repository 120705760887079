import { useTranslation } from 'react-i18next';
import HighlightBlock from '../../../molecules/highlightBlock/HighlightBlock';
import { Card } from '../../../organisms/card';
import styles from './ScreeningCoTenantsContent.module.css';
import { InputField } from '../../../organisms/input-field';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../redux/actions/ui/setNotification';
import { useNavigate, useParams } from 'react-router-dom';
import findScreeningHouseholdComposition, { HouseholdComposition, MainHomeseeker } from '../../../../api/screening/findScreeningHouseholdComposition';
import SkeletonComponent from '../../../../components/SkeletonComponent';
import Label from '../../../atoms/label/Label';
import Text from '../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../../enums';
import Form, { Data, FormErrors } from '../../../molecules/form/Form';
import FormError from '../../../molecules/form/FormError';
import submitCoTenants, { CoTenantsPayload } from '../../../../api/screening/submitCoTenants';
import ScreeningNavigation from '../../../organisms/screening-navigation/ScreeningNavigation';
import screeningSteps, {Action} from '../../../organisms/screening-navigation/screeningSteps';
import { useRoutePath } from '../../../utils/useRoutePath';

const ScreeningCoTenantsContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routePath = useRoutePath();

    const { verificationRequestAuthorisationId } = useParams();

    const [ isFetchingHouseholdComposition, setIsFetchingHouseholdComposition ] = useState<boolean>(true);
    const [ isSubmittingCoTenants, setIsSubmittingCoTenants ] = useState<boolean>(false);
    const [ isSubmittingCoTenantsSuccess, setIsSubmittingCoTenantsSuccess ] = useState<boolean>(false);
    const [ householdComposition, setHouseholdComposition] = useState<HouseholdComposition>(null);
    const [ mainHomeseeker, setMainHomeseeker] = useState<MainHomeseeker>(null);

    const [ payload, setPayload ] = useState<CoTenantsPayload>({
        coTenants: [],
        screeningVerificationRequestAuthorisationId: verificationRequestAuthorisationId,
    });

    const [, setIsFormValid] = useState<boolean>(false);
    const [formData, setFormData] = useState<Data>({});
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    useEffect(() => {
        fetchScreeningHouseholdComposition();
    }, []);

    useEffect(() => {
        for (let i = 0; i < payload.coTenants.length; i++) {
            payload.coTenants[i] = {
                name: formData[`fullName-${i}`]?.value as string,
                email: formData[`email-${i}`]?.value as string,
            }
        }
    }, [formData]);

    const fetchScreeningHouseholdComposition = async () => {
        try {
            const response = await findScreeningHouseholdComposition(verificationRequestAuthorisationId);
            setPayload((prev) => {
                return {
                    ...prev,
                    coTenants: Array(response.householdComposition.adults - 1).fill({
                        name: '',
                        email: ''
                    }),
                };
            });
            setHouseholdComposition(response.householdComposition);
            setMainHomeseeker(response.mainHomeseeker);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingHouseholdComposition(false);
        }
    }

    const handleClick = (action: Action) => {
        if (action === 'previous') {
            navigate(routePath('ScreeningHousehold', { verificationRequestAuthorisationId }));
        }
    }

    const handleSubmit = async () => {
        setIsSubmittingCoTenantsSuccess(false);
        setIsSubmittingCoTenants(true);

        const startTime = Date.now();

        try {
            const response = await submitCoTenants(payload);
            const homeseekerId = response.homeseekerId;

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(300 - elapsedTime, 0);

            await new Promise(resolve => setTimeout(resolve, remainingTime));

            setIsSubmittingCoTenants(false);
            setIsSubmittingCoTenantsSuccess(true);

            await new Promise(resolve => setTimeout(resolve, 300 + 150));

            navigate(routePath('ScreeningIdVerificationIntroduction', { homeseekerId }));
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingCoTenants(false);
            setIsSubmittingCoTenantsSuccess(false);
        }
    }

    const handleFormDataChange = (data: Data, isValid: boolean, errors: FormErrors) => {
        setFormData(data);
        setIsFormValid(isValid);
        setFormErrors(errors);
    }

    const renderCoTenantsForms = (): ReactNode[] => {
        const coTenantsForms: ReactNode[] = [];

        for (let i = 0; i < householdComposition?.adults - 1; i++) {
            coTenantsForms.push(
                <Card
                    className={styles.gridMd}
                    elevated
                    key={i}
                >
                    <div>
                        <Text tag='h2' weight={FontWeightEnum.Bold}>{t('screening.coTenants.label.adult', { index: i + 2 })}</Text>
                    </div>
                    <div>
                        <Label htmlFor={`fullName-${i}`} required>{t('screening.coTenants.label.fullName')}</Label>
                        <InputField
                            id={`fullName-${i}`}
                            name={`fullName-${i}`}
                            value={formData[`fullName-${i}`]?.value as string}
                            maxlength={255}
                            required
                        />
                        <FormError errors={formErrors[`fullName-${i}`]} />
                    </div>
                    <div>
                        <Label htmlFor={`email-${i}`} required>{t('screening.coTenants.label.emailAddress')}</Label>
                        <InputField
                            id={`email-${i}`}
                            name={`email-${i}`}
                            type='email'
                            value={formData[`email-${i}`]?.value as string}
                            maxlength={255}
                            required
                            data-not-match="email*"
                        />
                        <FormError errors={formErrors[`email-${i}`]} />
                    </div>
                </Card>
            );
        }

        return coTenantsForms;
    }

    if (!isFetchingHouseholdComposition && householdComposition && mainHomeseeker) {
        return (
            <div className={styles.screeningCoTenantsContent}>
                <div className={styles.gridLg}>
                    <Text
                        color={ColorEnum.Primary700}
                        weight={FontWeightEnum.Bold}
                        size={FontSizeEnum.DisplayXs}
                    >{t(screeningSteps.ScreeningCoTenants.pageTitleTranslationKey)}</Text>
                    <HighlightBlock type='info'>{t('screening.coTenants.infoBlock')}</HighlightBlock>
                    <Form
                        className={styles.coTenantsForm}
                        onChange={handleFormDataChange}
                        onSubmit={handleSubmit}
                    >
                        <Card
                            className={styles.gridMd}
                            elevated
                        >
                            <div>
                                <Text tag='h2' weight={FontWeightEnum.Bold}>{t('screening.coTenants.label.contactInto')}</Text>
                            </div>
                            <div>
                                <Label htmlFor='name' required>{t('screening.coTenants.label.fullName')}</Label>
                                <InputField
                                    name='name'
                                    value={mainHomeseeker?.name}
                                    disabled
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor='name' required>{t('screening.coTenants.label.emailAddress')}</Label>
                                <InputField
                                    name='email'
                                    type='email'
                                    value={mainHomeseeker?.email}
                                    disabled
                                    required
                                />
                            </div>
                        </Card>
                        <div className={styles.gridLg}>
                            {renderCoTenantsForms()}
                            <ScreeningNavigation
                                isSubmitting={isSubmittingCoTenants}
                                isSuccessful={isSubmittingCoTenantsSuccess}
                                onClick={handleClick}
                            />
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.screeningCoTenantsContent}>
            <div className={styles.gridLg}>
                <SkeletonComponent height={30} width={200}/>
                <SkeletonComponent height={100}/>
                <SkeletonComponent height={250}/>
                <div className={styles.loadingButtonContainer}>
                    <SkeletonComponent height={42} width={100}/>
                    <SkeletonComponent height={42} width={100}/>
                </div>
            </div>
        </div>
    );
}

export default ScreeningCoTenantsContent;

import { useEffect, useState } from 'react';
import Modal from '../organisms/modal/Modal';
import Button from '../buttons/button/Button';
import ButtonType from '../buttons/button/logic/models/buttonType';
import ColorEnum from '../../enums/colorEnum';
import Title from '../typography/title/Title';
import FontSizeEnum from '../typography/enums/fontSizeEnum';
import FontWeightEnum from '../typography/enums/fontWeightEnum';
import styles from './CreateNoteModal.module.css';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../../componentsNew/atoms/text-area';
import CreateNoteModalEventManager, { CreateNoteModalCustomEventType, CreateNoteModalEventData } from '../../componentsNew/organisms/modal/CreateNoteModalEventManager';

const createNoteModal = CreateNoteModalEventManager.create<CreateNoteModalEventData>('create-note-modal');

interface CreateNoteModalProps {
    readonly isOpen: boolean;
    readonly onSubmitButtonClick: (note: string, homeseekerApplicationId: string) => void;
    readonly onCloseButtonClick: () => void;
}

type CreateNoteModalComponent = (props: CreateNoteModalProps) => JSX.Element;

const CreateNoteModal: CreateNoteModalComponent = ({
    isOpen,
    onSubmitButtonClick,
    onCloseButtonClick,
}: CreateNoteModalProps) => {
    const [note, setNote] = useState('')
    const {t} = useTranslation();

    const [homeseekerApplicationId, setHomeseekerApplicationId] = useState(null);

    const setModal = (e: CreateNoteModalCustomEventType) => {
        setHomeseekerApplicationId(e.detail.homeseekerApplicationId);
    }

    useEffect(() => {
        createNoteModal?.addListener(setModal);

        return () => {
            createNoteModal?.removeListener(setModal);
        };
    }, []);

    const handleCloseButtonClick = () => {
        onCloseButtonClick();
    }

    const handleSubmitButtonClick = () => {
        onSubmitButtonClick(note, homeseekerApplicationId);
        setNote('');
        onCloseButtonClick();
    }

    return (
        <Modal
            icon={'ri-pencil-line'}
            iconColor={ColorEnum.Primary600}
            iconBackgroundColor={ColorEnum.Primary100}
            flowyColor={ColorEnum.Primary100}
            isOpen={isOpen}
            onCloseButtonClick={handleCloseButtonClick}
            manager={createNoteModal}
        >
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <Title
                        size={FontSizeEnum.Xxsm}
                        weight={FontWeightEnum.Medium}
                        color={ColorEnum.Primary700}
                    >
                        {t('candidate.create.note.title')}
                    </Title>
                </div>
                <TextArea
                    value={note}
                    onChange={(id, val) => setNote(val)}
                    id="comment"
                />
                <div className={styles.createNoteButton}>
                    <Button
                        title={'Notitie opslaan'}
                        icon={'ri-add-line'}
                        buttonType={ButtonType.Primary}
                        onClick={() => handleSubmitButtonClick()}
                    />
                </div>
            </div>
        </Modal>
    )
}

Object.assign(CreateNoteModal, createNoteModal);

export default (CreateNoteModal as CreateNoteModalComponent & CreateNoteModalEventManager<CreateNoteModalEventData>);

import styles from './EmploymentContent.module.css';
import Text from '../../../../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum, IncomeFrequencyEnum } from '../../../../../enums';
import { useTranslation } from 'react-i18next';
import { Data, FormErrors } from '../../../../../molecules/form/Form';
import Label from '../../../../../atoms/label/Label';
import { InputField } from '../../../../../organisms/input-field';
import FormError from '../../../../../molecules/form/FormError';
import { useEffect, useState } from 'react';
import { Select } from '../../../../../organisms/select';
import DateInput from '../../../../../../components/atoms/dateInput/DateInput';
import { Item } from '../../../../../organisms/select/Select';
import moment from 'moment';
import EarningsCalculator from '../../../../../molecules/earnings-calculator/EarningsCalculator';
import { HtmlTextArea } from '../../../../../atoms/html-text-area';
import { CheckboxInput } from '../../../../../molecules/checkbox-input';
import QuestionLineIcon from '../../../../../atoms/icons/QuestionLineIcon';
import stringToBoolean from '../../../../../utils/stringToBoolean';
import { RadioInput } from '../../../../../atoms/radio-input';
import IncomeVerificationIncomeSourceTypeEnum from '../../../../../enums/screening/IncomeVerificationIncomeSourceTypeEnum';
import ContractTypeEnum from '../../../../../enums/screening/ContractTypeEnum';
import { IncomeSourceEmployment } from '../../../../../../api/screening/income-verification/find-income-source-details/findIncomeSourceEmployment';

interface EmploymentContentProps {
    storedData: IncomeSourceEmployment;
    formData: Data;
    formErrors: FormErrors;
    onChange: (data) => void;
}

const EmploymentContent = ({
    storedData,
    formData,
    formErrors,
    onChange,
}: EmploymentContentProps) => {
    const { t } = useTranslation();

    const endDate = formData['endDate']?.value ?? storedData.endDate;
    const startDate = formData['startDate']?.value ?? storedData.startDate;

    const determineContractTypeSelect = () => {
        if (formData['contractTypeSelect']?.value) {
            return formData['contractTypeSelect']?.value as string;
        }

        if (storedData.contractType === undefined) {
            return undefined;
        }

        if (
            storedData.contractType === ContractTypeEnum.FixedTerm
            || storedData.contractType === ContractTypeEnum.Indefinite
            || storedData.contractType === ContractTypeEnum.ZeroHour
        ) {
            return storedData.contractType;
        } else {
            return ContractTypeEnum.Other;
        }
    }

    const actualFormData = {
        employer: formData['employer']?.value as string ?? storedData.employer,
        contractTypeSelect: determineContractTypeSelect(),
        contractTypeTextArea: formData['contractTypeTextArea']?.value as string ?? storedData.contractType,
        endDateValue: endDate ? moment(endDate as string) : null,
        startDateValue: startDate ? moment(startDate as string) : null,
        grossIncome: formData['grossIncome']?.value as string ?? storedData.grossIncome?.toString(),
        incomeFrequency: formData['incomeFrequency']?.value as string ?? storedData.incomeFrequency,
        holidayAllowanceIncluded: formData['holidayAllowanceIncluded']?.value ?? storedData.holidayAllowanceIncluded,
        thirteenthMonthPayIncluded: formData['thirteenthMonthPayIncluded']?.value ?? storedData.thirteenthMonthPayIncluded,
        thirtyPercentFacilityApplicable: formData['thirtyPercentFacilityApplicable']?.value ?? storedData.thirtyPercentFacilityApplicable,
        additionalIncome: formData['additionalIncome']?.value as string ?? storedData.additionalIncome,
        additionalIncomeFrequency: formData['additionalIncomeFrequency']?.value as string ?? storedData.additionalIncomeFrequency,
        additionalIncomeComment: formData['additionalIncomeComment']?.value as string ?? storedData.additionalIncomeComment,
    };

    const contractTypes: Item[] = [
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.company.contractType.indefinite'),
            value: ContractTypeEnum.Indefinite,
        },
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.company.contractType.fixedTerm'),
            value: ContractTypeEnum.FixedTerm,
        },
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.company.contractType.zeroHour'),
            value: ContractTypeEnum.ZeroHour,
        },
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.company.contractType.other'),
            value: ContractTypeEnum.Other,
        },
    ];

    const incomeFrequencies = [
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.income.placeholder.incomeFrequency'),
            value: null,
            disabled: true,
        },
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.income.incomeFrequency.weekly'),
            value: IncomeFrequencyEnum.Weekly,
        },
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.income.incomeFrequency.monthly'),
            value: IncomeFrequencyEnum.Monthly,
        },
        {
            label: t('screening.incomeVerification.provideIncomeDetails.employment.income.incomeFrequency.annual'),
            value: IncomeFrequencyEnum.Annual,
        },
    ];

    const [hasExtraCompensation, setHasExtraCompensation] = useState<boolean>(!!storedData.additionalIncome);

    useEffect(() => {
        let contractType = formData['contractTypeSelect']?.value as string;

        if (contractType === ContractTypeEnum.Other) {
            contractType = formData['contractTypeTextArea']?.value as string;
        }

        onChange({
            type: IncomeVerificationIncomeSourceTypeEnum.Employment,
            employer: formData['employer']?.value as string,
            contractType,
            startDate: formData['startDate']?.value as string,
            endDate: formData['endDate']?.value as string,
            grossIncome: Number(formData['grossIncome']?.value as string),
            incomeFrequency: formData['incomeFrequency']?.value as IncomeFrequencyEnum,
            holidayAllowanceIncluded: stringToBoolean(formData['holidayAllowanceIncluded']?.value as string),
            thirteenthMonthPayIncluded: stringToBoolean(formData['thirteenthMonthPayIncluded']?.value as string),
            thirtyPercentFacilityApplicable: stringToBoolean(formData['thirtyPercentFacilityApplicable']?.value as string),
            additionalIncome: Number(formData['additionalIncome']?.value as string),
            additionalIncomeFrequency: formData['additionalIncomeFrequency']?.value as IncomeFrequencyEnum,
            additionalIncomeComment: formData['additionalIncomeComment']?.value as string
        });
    }, [formData])

    const handleSelectChange = (name: string, item: Item) => {
        const formDataClone = formData;
        formDataClone[name].value = item.value;
        onChange({...formData, ...formDataClone});
    }

    const handleHasExtraCompensationChange = (value: string) => {
        setHasExtraCompensation(stringToBoolean(value));
    }

    return (
        <div className={styles.gridMd}>
            <Text
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Bold}
            >{t('screening.incomeVerification.provideIncomeDetails.employment.title')}</Text>
            <div className={styles.gridXl}>
                <div className={styles.gridMd}>
                    <div className={styles.gridSm}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >{t('screening.incomeVerification.provideIncomeDetails.employment.company.title')}</Text>
                        <Text
                            color={ColorEnum.Gray800}
                        >{t('screening.incomeVerification.provideIncomeDetails.employment.company.text')}</Text>
                    </div>
                    <div>
                        <Label
                            htmlFor={'employer'}
                            fontSize={FontSizeEnum.Sm}
                            required
                        >
                            {t('screening.incomeVerification.provideIncomeDetails.employment.company.label.employer')}
                        </Label>
                        <InputField
                            id='employer'
                            name='employer'
                            placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.company.placeholder.employer')}
                            value={actualFormData.employer}
                            maxlength={255}
                            required
                        />
                        <FormError errors={formErrors['employer']}/>
                    </div>
                    <div>
                        <Label
                            htmlFor={'contractTypeSelect'}
                            fontSize={FontSizeEnum.Sm}
                            required
                        >
                            {t('screening.incomeVerification.provideIncomeDetails.employment.company.label.contractTypeSelect')}
                        </Label>
                        <Select
                            name='contractTypeSelect'
                            placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.company.placeholder.contractTypeSelect')}
                            items={contractTypes}
                            selected={actualFormData.contractTypeSelect}
                            onChange={(item: Item) => handleSelectChange('contractTypeSelect', item)}
                            fullWidth
                            required
                        />
                        <FormError errors={formErrors['contractTypeSelect']}/>
                    </div>
                    {actualFormData.contractTypeSelect === ContractTypeEnum.Other &&
                        <div>
                            <Label
                                htmlFor={'contractTypeTextArea'}
                                fontSize={FontSizeEnum.Sm}
                                required
                            >
                                {t('screening.incomeVerification.provideIncomeDetails.employment.company.label.contractTypeTextArea')}
                            </Label>
                            <HtmlTextArea
                                id='contractTypeTextArea'
                                name='contractTypeTextArea'
                                placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.company.placeholder.contractTypeTextArea')}
                                value={actualFormData.contractTypeTextArea}
                                maxlength={255}
                                required
                            />
                            <FormError errors={formErrors['contractTypeTextArea']}/>
                        </div>
                    }
                    <div>
                        <Label
                            htmlFor={'startDate'}
                            fontSize={FontSizeEnum.Sm}
                            required
                        >
                            {t('screening.incomeVerification.provideIncomeDetails.employment.company.label.startDate')}
                        </Label>
                        <DateInput
                            className={styles.dateInput}
                            name='startDate'
                            maximumValue={moment()}
                            value={actualFormData.startDateValue}
                            fullWidth
                            required
                        />
                        <FormError errors={formErrors['startDate']}/>
                    </div>
                    {(
                        actualFormData.contractTypeSelect &&
                        actualFormData.contractTypeSelect !== ContractTypeEnum.Indefinite &&
                        actualFormData.contractTypeSelect !== ContractTypeEnum.Other
                    ) &&
                        <div>
                            <Label
                                htmlFor={'endDate'}
                                fontSize={FontSizeEnum.Sm}
                                required
                            >
                                {t('screening.incomeVerification.provideIncomeDetails.employment.company.label.endDate')}
                            </Label>
                            <DateInput
                                className={styles.dateInput}
                                name='endDate'
                                minimumValue={moment()}
                                value={actualFormData.endDateValue}
                                fullWidth
                                required
                            />
                            <FormError errors={formErrors['endDate']}/>
                        </div>
                    }
                </div>
                <div className={styles.gridMd}>
                    <div className={styles.gridSm}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >{actualFormData.contractTypeSelect === ContractTypeEnum.ZeroHour ?
                                t('screening.incomeVerification.provideIncomeDetails.employment.income.title.zeroHour')
                                :
                                t('screening.incomeVerification.provideIncomeDetails.employment.income.title')
                            }
                        </Text>
                        <Text
                            color={ColorEnum.Gray800}
                        >{t('screening.incomeVerification.provideIncomeDetails.employment.income.text')}</Text>
                    </div>
                    <div className={styles.incomeInputContainer}>
                        <div>
                            <Label
                                htmlFor={'grossIncome'}
                                fontSize={FontSizeEnum.Sm}
                                required
                            >
                                {t('screening.incomeVerification.provideIncomeDetails.employment.income.label.grossIncome')}
                            </Label>
                            <InputField
                                className={styles.grossIncomeInput}
                                placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.income.label.grossIncome.placeholder')}
                                type='number'
                                id='grossIncome'
                                name='grossIncome'
                                value={actualFormData.grossIncome}
                                min='0'
                                max='999999999'
                                data-no-decimals
                                required
                            />
                            <FormError errors={formErrors['grossIncome']}/>
                        </div>
                        <div>
                            <Label
                                htmlFor={'incomeFrequency'}
                                fontSize={FontSizeEnum.Sm}
                                required
                            >
                                {t('screening.incomeVerification.provideIncomeDetails.employment.income.label.incomeFrequency')}
                            </Label>
                            <Select
                                name='incomeFrequency'
                                placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.income.placeholder.incomeFrequency')}
                                items={incomeFrequencies}
                                selected={actualFormData.incomeFrequency}
                                onChange={(item: Item) => handleSelectChange('incomeFrequency', item)}
                                fullWidth
                                required
                            />
                            <FormError errors={formErrors['incomeFrequency']}/>
                        </div>
                    </div>
                    <div>
                        <CheckboxInput
                            name='holidayAllowanceIncluded'
                            id='holidayAllowanceIncluded'
                            label={t('screening.incomeVerification.provideIncomeDetails.employment.income.label.holidayAllowanceIncluded')}
                            value='holidayAllowanceIncluded'
                            checked={stringToBoolean(actualFormData.holidayAllowanceIncluded)}
                        />
                    </div>
                    <div>
                        <CheckboxInput
                            name='thirteenthMonthPayIncluded'
                            id='thirteenthMonthPayIncluded'
                            label={t('screening.incomeVerification.provideIncomeDetails.employment.income.label.thirteenthMonthPayIncluded')}
                            value='thirteenthMonthPayIncluded'
                            checked={stringToBoolean(actualFormData.thirteenthMonthPayIncluded)}
                            tooltip
                            tooltipSize={'md'}
                            tooltipIcon={<QuestionLineIcon/>}
                            tooltipContent={t('screening.incomeVerification.provideIncomeDetails.employment.income.tooltip.thirteenthMonthPayIncluded')}
                        />
                    </div>
                    <div>
                        <CheckboxInput
                            name='thirtyPercentFacilityApplicable'
                            id='thirtyPercentFacilityApplicable'
                            label={t('screening.incomeVerification.provideIncomeDetails.employment.income.label.thirtyPercentFacilityApplicable')}
                            value='thirtyPercentFacilityApplicable'
                            checked={stringToBoolean(actualFormData.thirtyPercentFacilityApplicable)}
                            tooltip
                            tooltipSize={'md'}
                            tooltipIcon={<QuestionLineIcon/>}
                            tooltipContent={t('screening.incomeVerification.provideIncomeDetails.employment.income.tooltip.thirtyPercentFacilityApplicable')}
                        />
                    </div>
                </div>
                <div className={styles.gridMd}>
                    <div className={styles.gridSm}>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Semibold}
                        >{t('screening.incomeVerification.provideIncomeDetails.employment.compensation.label')}</Text>
                        <Text
                            color={ColorEnum.Gray800}
                            weight={FontWeightEnum.Light}
                        >{t('screening.incomeVerification.provideIncomeDetails.employment.compensation.supporting.text')}</Text>
                    </div>
                    <RadioInput
                        name='hasExtraCompensation'
                        value={`${hasExtraCompensation}`}
                        onChange={handleHasExtraCompensationChange}
                        isBoolean
                        required
                    />
                    <FormError errors={formErrors['hasExtraCompensation']}/>
                    {hasExtraCompensation && (
                        <>
                            <div className={styles.additionalIncomeInputContainer}>
                                <div>
                                    <Label
                                        htmlFor={'additionalIncome'}
                                        fontSize={FontSizeEnum.Sm}
                                        required
                                    >
                                        {t('screening.incomeVerification.provideIncomeDetails.employment.compensation.additional.income.input.label')}
                                    </Label>
                                    <InputField
                                        className={styles.additionalIncomeInput}
                                        placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.income.label.grossIncome.placeholder')}
                                        type='number'
                                        id='additionalIncome'
                                        name='additionalIncome'
                                        value={`${actualFormData.additionalIncome}`}
                                        min='0'
                                        max='999999999'
                                        required
                                    />
                                    <FormError errors={formErrors['additionalIncome']}/>
                                </div>
                                <div>
                                    <Label
                                        htmlFor={'additionalIncomeFrequency'}
                                        fontSize={FontSizeEnum.Sm}
                                        required
                                    >
                                        {t('screening.incomeVerification.provideIncomeDetails.employment.income.label.incomeFrequency')}
                                    </Label>
                                    <Select
                                        name='additionalIncomeFrequency'
                                        placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.income.placeholder.incomeFrequency')}
                                        items={incomeFrequencies}
                                        selected={actualFormData.additionalIncomeFrequency}
                                        onChange={(item: Item) => handleSelectChange('additionalIncomeFrequency', item)}
                                        fullWidth
                                        required
                                    />
                                    <FormError errors={formErrors['additionalIncomeFrequency']}/>
                                </div>
                            </div>
                            <div>
                                <HtmlTextArea
                                    id='additionalIncomeComment'
                                    name='additionalIncomeComment'
                                    placeholder={t('screening.incomeVerification.provideIncomeDetails.employment.compensation.additional.income.comment.placeholder')}
                                    value={actualFormData.additionalIncomeComment}
                                />
                                <FormError errors={formErrors['additionalIncomeComment']}/>
                            </div>
                        </>
                    )}
                </div>
                <EarningsCalculator
                    grossIncome={Number(actualFormData.grossIncome)}
                    grossIncomeFrequency={actualFormData.incomeFrequency as IncomeFrequencyEnum}
                    bonusses={actualFormData.additionalIncome ? Number(actualFormData.additionalIncome) : 0}
                    bonussesFrequency={actualFormData.additionalIncomeFrequency as IncomeFrequencyEnum}
                    holidayAllowanceIncluded={stringToBoolean(actualFormData.holidayAllowanceIncluded)}
                    hasThirteenthMonth={stringToBoolean(actualFormData.thirteenthMonthPayIncluded)}
                />
            </div>
        </div>
    );
}

export default EmploymentContent;

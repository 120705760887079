import TopNavigation from '../../../components/navigation/TopNavigation';
import React from 'react';
import ErrorContent from '../../organisms/ErrorComponent/ErrorContent';
import { useTranslation } from 'react-i18next';

const GeneralErrorPage = () => {

    const {t} = useTranslation();

    return (
        <>
            <TopNavigation/>
            <ErrorContent title={t('errorPage.general.title')} buttonText={t('errorPage.general.toHome')}></ErrorContent>
        </>
    )
}
export default GeneralErrorPage;

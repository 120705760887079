const formatBytes = (bytes?: number): string => {
    if (bytes < 1000) {
        return `${bytes}B`;
    }

    const units = ['KB', 'MB', 'GB'];
    let unitIndex = -1;

    while (bytes >= 1000 && unitIndex < units.length - 1) {
        bytes /= 1000;
        unitIndex++;
    }

    return `${Math.round(bytes)}${units[unitIndex]}`;
}

export default formatBytes;

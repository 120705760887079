import api from '../../../../utils/api';

export interface IncomeSourceFreelance {
    companyName: string;
    chamberOfCommerceNumber: string | null;
    startDate: string;
    grossIncome: number;
    grossIncomeRemark: string | null;
}

const findIncomeSourceFreelance = async (sourceId: string): Promise<IncomeSourceFreelance> => {
    return await api.getWithoutAuth(`api/screening/income-verification/details/freelance`, { sourceId });
}

export default findIncomeSourceFreelance;

import api, { BodyData } from '../../utils/api';

export interface SubmitHomeseekerApplicationPayload extends BodyData {
    email: string,
}

const deleteHomeseekerData = async (payload: SubmitHomeseekerApplicationPayload): Promise<unknown> => {
    return await api.postWithTemplate(`api/homeseeker/delete`, payload);
}

export default deleteHomeseekerData;

import styles from './ScreeningActivationFragment.module.css';
import Title from '../../../../typography/title/Title';
import FontSizeEnum from '../../../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../../../typography/enums/fontWeightEnum';
import ColorEnum from '../../../../../enums/colorEnum';
import Text from '../../../../typography/text/Text';
import TrialPeriodInfoSegment from './components/TrialPeriodInfoSegment';
import TextInput from '../../../../forms/textInput/TextInput';
import {Trans, useTranslation} from 'react-i18next';
import Checkbox from '../../../../forms/checkbox/Checkbox';
import { useEffect, useState } from 'react';
import api from '../../../../../utils/api';
import AgencyDetailsModelToJsonConverter, {AgencyDetailsModelJson} from '../../../../../converters/agency/agencyDetailsModelToJsonConverter'
import AgencyDetailsModel from '../../../../../models/agent/agencyDetailsModel'

interface ScreeningActivationFragmentProps {
    readonly onApprovePolicyCheckboxChange: (checked: boolean) => void
}

export default function ScreeningActivationFragment(props: ScreeningActivationFragmentProps) {
    const [agencyDetails, setAgencyDetails] = useState<AgencyDetailsModel>(AgencyDetailsModel.createEmpty())
    const {t} = useTranslation()

    useEffect(() => {
        fetchAgencyDetailsOnLoad()
    }, [])

    const fetchAgencyDetailsOnLoad = async () => {
        const res: AgencyDetailsModelJson = await api.get('api/get-agency-details-from-logged-in-agent')
        setAgencyDetails(AgencyDetailsModelToJsonConverter.convert(res))
    }

    return <>
        <div className={styles.modalTitle}>
            <Title size={FontSizeEnum.Xsm} weight={FontWeightEnum.Bold} color={ColorEnum.Gray800}>
                {t('candidate.screening.modal.title')}
            </Title>
            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                {t('candidate.screening.modal.title.description')}
            </Text>
        </div>

        <div className={styles.content}>
            <TrialPeriodInfoSegment/>

            <div className={styles.form}>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800} weight={FontWeightEnum.Bold}>
                    {t('candidate.screening.modal.form.title')}
                </Text>

                <TextInput
                    isDisabled
                    name="office"
                    label={t('candidate.screening.modal.form.office')}
                    value={agencyDetails.name}
                />
                <TextInput
                    isDisabled
                    name="email"
                    label={t('candidate.screening.modal.form.email')}
                    value={agencyDetails.defaultEmail}
                />

                <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray400}>
                    <Trans
                        i18nKey={'candidate.screening.modal.form.support'}
                        values={{agencyName: agencyDetails.name}}
                        components={{Link: <a className={styles.supportLink}/>}}
                    />
                </Text>

                <Checkbox
                    label={<Trans
                        i18nKey="candidate.screening.modal.approvePolicy"
                        components={{Link: <a/>}}
                    />}
                    onChange={(e) => props.onApprovePolicyCheckboxChange(e.target.checked)}
                />
            </div>
        </div>
    </>
}

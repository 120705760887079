import React, { ReactNode } from 'react';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { getColorCssVar } from '../../enums/ColorEnum';
import { fontSizeEnumAsCssVar } from '../../enums/FontSizeEnum';
import { fontWeightEnumAsCssVar } from '../../enums/FontWeightEnum';
import { AllowedTagNames } from './AllowedElements';
import styles from './Text.module.css';
import {classNames} from '../../utils';

export type TextElements = string | boolean | number | ReactNode;
interface TextPropsBase {
    readonly className?: string;
    readonly tag?: AllowedTagNames;
    readonly color?: ColorEnum;
    readonly size?: FontSizeEnum;
    readonly weight?: FontWeightEnum;
}

interface TextPropsDefault extends TextPropsBase {
    readonly children: TextElements | TextElements[];
    readonly html?: never;
}

interface TextPropsHtml extends TextPropsBase {
    readonly children?: never;
    readonly html: string;
}

type TextProps = TextPropsDefault | TextPropsHtml;

const Text = ({
    className,
    tag: Tag = 'span',
    color = ColorEnum.Inherit,
    size = FontSizeEnum.Md,
    weight = FontWeightEnum.Light,
    html,
    children
}: TextProps) => {
    const style = {
        color: getColorCssVar(color),
        fontSize: fontSizeEnumAsCssVar(size),
        fontWeight: fontWeightEnumAsCssVar(weight)
    };

    if (html) {
        return (
            <Tag
                style={style}
                className={classNames(styles.Text, className && className)}
                dangerouslySetInnerHTML={{__html: html}}
            ></Tag>
        );
    }

    return (
        <Tag
            style={style}
            className={classNames(styles.Text, className && className)}
        >
            {children}
        </Tag>
    );

}

export default Text;
